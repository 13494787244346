.page-wrapper {
  @include transition(all 0.5s ease);
  margin: 0 0 0 252px;
  padding: 56px 0 0;
  position: relative;
  left: 0;
  @include transition(all 0.2s ease);
  @include respond-below(lg) {
    margin: 0;
    padding: 60px 0 0;
  }
  .content {
    padding: 24px;
    background: $light-200;
    padding-bottom: 0;
    @include respond-below(lg) {
      padding: 15px;
      padding-bottom: 0;
    }
    &.content-two {
      padding-bottom: 24px;
      @include respond-below(md) {
        padding-bottom: 15px;
      }
    }
  }
}
.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .breadcrumb {
    margin-bottom: 0;
  }
  @include respond-below(sm) {
    flex-direction: column;
    align-items: self-start;
  }
  .page-title {
    margin-bottom: 10px;
  }
  h3 {
    font-weight: $font-weight-bold;
    color: $gray-900;
    font-size: $font-size-18;
    @include respond-below(lg) {
      font-size: $font-size-base;
    }
  }
  h4 {
    font-weight: $font-weight-semibold;
    color: $gray-900;
    font-size: $font-size-18;
    margin-bottom: 5px;
    @include respond-below(lg) {
      font-size: $font-size-18;
    }
  }
  h6 {
    font-size: $font-size-base;
    color: $gray-700;
    font-weight: $font-weight-normal;
    @include respond-below(lg) {
      font-size: $font-size-12;
    }
    @include respond-below(sm) {
      font-size: $font-size-12;
      margin-bottom: 20px;
    }
  }
}

.profile-uploader.profile-uploader-two {
    position: relative;
    width: 100%;
    border: 1px dashed gray;
    background-color: transparent;
    border-radius: 5px;
    padding: 15px 15px 12px;
    margin-bottom: 15px;
    .upload-icon {
        display: block;
        text-align: center;
        font-size: 20px;
        line-height: 0;
        margin-bottom: 15px;
    }
    input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        &.form-control {
            padding: 0;
        }
    }
    .drag-upload-btn {
        font-size: 12px;
        text-align: center;
        .upload-btn {
            font-size: 12px;
            margin-bottom: 5px;
            span {
                color: #4285f4;
            }
        }
        h6 {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;
        }
    }
}
.settings-profile-upload {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .profile-pic {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
        display: block;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .title-upload {
        h5 {
            margin-bottom: 5px;
        }
    }
}
.language-setup-table {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    overflow: auto;
    .table {
        thead {
            background: rgb(187, 187, 187);
            th {
                background: rgb(175, 175, 175);
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: 1px solid rgb(204, 204, 204); 
                    width: 50%;
                }
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
.tax-group-input {
    position: relative;
}

.btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    font-size: $font-size-14;
    font-size: $font-size-14;
}
 .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
}
.btn-check:checked+ .btn.btn-outline-success.rounded-btn {
  background-color: #1ABE17 !important;
    border-color: #1ABE17 !important;
    color: white !important;
}



// /* Apply red background and border for 'No' button when checked */
.btn-check:checked+ .btn.btn-outline-danger.rounded-btn  {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: white !important;
}
button {
    cursor: pointer;
    &:focus{
        outline: 0;
        box-shadow: none;
    }
}
.btn {
    border-radius: $border-radius-lg;
    padding: 0.5rem 0.85rem;
    font-size: $font-size-14;
    transition: all 0.5s;
    font-weight: $font-weight-medium;
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        transition: all 0.5s;
    }
    &.btn-lg {
        padding: 0.65rem 1rem;
        font-size: 0.95rem;
    }
    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
    }
    @each $color,
    $value in $theme-colors {
        &.btn-#{$color} {
            background-color: #{$value};
            border: 1px solid #{$value};
            color: $white;
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: #{darken($value, 7%)};
                border:1px solid #{darken($value, 7%)};
                color: $white;
            }
        }
    }
    &.btn-primary {
        background-color: $primary-900;
        border: 1px solid $primary-900;
        color: $white;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary-900, 7%);
            border: 1px solid darken($primary-900, 7%);
            color: $white;
        }
    }
    &.btn-light {
        background-color: $light-900;
        border: 1px solid $light-900;
        color: $gray-700;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($light-900, 3%);
            border: 1px solid $light-900;
            color: $gray-700;
        }
    }
    &.btn-white {
      background-color: $white;
      border-color: $white;
      color: $gray-900;
    }
}

.btn-group {
    .btn {
      padding: 0.45rem 0.75rem;
    }
  }
  .btn-group-lg {
    .btn {
      padding: 0.65rem 1rem;
    }
  }
  .btn-group-sm {
    .btn {
      padding: 0.25rem 0.5rem;
    }
  }
.btn {
    &.btn-w-xs {
      min-width: 5.625rem;
    }
    &.btn-w-sm {
      min-width: 6.975rem;
    }
    &.btn-w-md {
      min-width: 8.125rem;
    }
    &.btn-w-lg {
      min-width: 9.375rem;
    }
  }
  .btn-icon {
    i {
      padding: 0rem;
      margin: -0.5rem;
    }
    &.btn-sm {
      width: 1.75rem;
      height: 1.75rem;
      font-size: 0.8rem;
    }
    &.btn-lg {
      width: 2.75rem;
      height: 2.75rem;
      font-size: 1.2rem;
    }
  }
  .btn-list {
    > button, > a {
      margin: 0 0.375rem 0.375rem 0;
    }
  }
  [dir="rtl"] {
    .btn-list {
      button,
      div,
      a,
      input {
        margin: 0 0 0.375rem 0.375rem;
      }
    }
  }
  
  .btn-list {
    margin-block-end: -0.5rem;
  }
  .btn-square {
    border-radius: 0;
}
.btn-group-pill .btn:first-child {
    border-radius: 60px 0 0 60px;
  }
  .btn-group-pill .btn:last-child {
    border-radius: 0 60px 60px 0;
  }
  
  .btn-group-square .btn:first-child {
    border-radius: 0px 0 0 0px;
  }
  .btn-group-square .btn:last-child {
    border-radius: 0 0px 0px 0;
  }
.btn {
  &.btn-primary-light {
    background-color: rgba($primary, 0.1);
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
 
  &.btn-secondary-light {
    background-color: rgba($secondary, 0.1);
    color: $secondary;
    &:hover,
    &:focus,
    &:active {
      background-color: $secondary;
      color: $white;
      border-color: $secondary;
    }
  }
  &.btn-warning-light {
    background-color: rgba($warning, 0.1);
    color: $warning;
    &:hover,
    &:focus,
    &:active {
      background-color: $warning;
      color: $white;
      border-color: $warning;
    }
  }
  &.btn-info-light {
    background-color: rgba($info, 0.1);
    color: $info;
    &:hover,
    &:focus,
    &:active {
      background-color: $info;
      color: $white;
      border-color: $info;
    }
  }
  &.btn-success-light {
    background-color: rgba($success, 0.1);
    color: $success;
    &:hover,
    &:focus,
    &:active {
      background-color: $success;
      color: $white;
      border-color: $success;
    }
  }
  &.btn-danger-light {
    background-color: rgba($danger, 0.1);
    color: $danger;
    &:hover,
    &:focus,
    &:active {
      background-color: $danger;
      color: $white;
      border-color: $danger;
    }
  }
}
.btn {
  &.btn-primary-ghost {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    &:active {
      color: $primary;
      border: 1px solid $primary;
      background-color: transparent;
    }
  }
  &.btn-dark{
    background: $gray-900 !important;
    border-color: $gray-900 !important;
    box-shadow: none;
    color: $white;
    &:hover{
      background: #252a30 !important ;
    }
  }
  &.btn-secondary-ghost {
    background-color: transparent;
    color: $secondary;
    border: 1px solid $secondary;
    &:active {
      color: $secondary;
      border: 1px solid $secondary;
      background-color: transparent;
    }
  }
  &.btn-warning-ghost {
    background-color: transparent;
    color: $warning;
    border: 1px solid $warning;
    &:active {
      color: $warning;
      border: 1px solid $warning;
      background-color: transparent;
    }
  }
  &.btn-info-ghost {
    background-color: transparent;
    color: $info;
    border: 1px solid $info;
    &:active {
      color: $info;
      border: 1px solid $info;
      background-color: transparent;
    }
  }
  &.btn-success-ghost {
    background-color: transparent;
    color: $success;
    border: 1px solid $success;
    &:active {
      color: $success;
      border: 1px solid $success;
      background-color: transparent;
    }
  }
  &.btn-danger-ghost {
    background-color: transparent;
    color: $danger;
    border: 1px solid $danger;
    &:active {
      color: $danger;
      border: 1px solid $danger;
      background-color: transparent;
    }
  }
}
.btn-list {
  button {
    margin: 0 0.375rem 0.375rem 0;
  }
}
.btn-outline-primary {
    border: 1px solid $primary !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $primary !important;
        border:1px solid $primary !important;
        box-shadow: 0 3px 10px rgba($primary, 0.5);
        color: $white !important;
    }
}
.btn-outline-secondary {
    border: 1px solid $secondary !important;
    color: $secondary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $secondary !important;
        border:1px solid $secondary !important;
        box-shadow: 0 3px 10px rgba($secondary, 0.5);
        color: $white !important;
    }
}
.btn-outline-success {
    border: 1px solid $success !important;
    color: $success !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $success !important;
        border:1px solid $success !important;
        box-shadow: 0 3px 10px rgba($success, 0.5);
        color: $white !important;
    }
}
.btn-outline-danger {
    border: 1px solid $danger !important;
    color: $danger !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $danger !important;
        border:1px solid $danger !important;
        box-shadow: 0 3px 10px rgba($danger, 0.5);
        color: $white !important;
    }
}
.btn-outline-warning {
    border: 1px solid $warning !important;
    color: $warning !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $warning !important;
        border:1px solid $warning !important;
        box-shadow: 0 3px 10px rgba($warning, 0.5);
        color: $white !important;
    }
}
.btn-outline-info {
    border: 1px solid $info !important;
    color: $info !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $info !important;
        border:1px solid $info !important;
        box-shadow: 0 3px 10px rgba($info, 0.5);
        color: $white !important;
    }
}
.btn-outline-light {
    border: 1px solid $light !important;
    color: $gray-700 !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $light !important;
        border: 1px solid $light !important;
        color: $gray-700 !important;
    }
}
.btn-outline-dark {
    border: 1px solid $dark !important;
    color: $dark !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        background-color: $dark !important;
        border:1px solid $dark !important;
        box-shadow: 0 3px 10px rgba($dark, 0.5);
        color: $white !important;
    }
}
.btn-soft-primary {
    background-color: $primary-300 !important;
    color: $primary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($primary, .2);
       border-color: $primary;
    }    
}
.btn-soft-secondary {
    background-color: $secondary-300 !important;
    color: $secondary !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($secondary, .2);
       border-color: $secondary;
    }    
}
.btn-soft-success {
    background-color: $success-300 !important;
    color: $success !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($success, .2);
       border-color: $success;
    }    
}
.btn-soft-danger {
    background-color: $danger-300 !important;
    color: $danger !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($danger, .2);
       border-color: $danger;
    }    
}
.btn-soft-warning {
    background-color: $warning-300 !important;
    color: $warning !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($warning, .2);
       border-color: $warning;
    }    
}
.btn-soft-info {
    background-color: rgba($info, 0.3) !important;
    color: $info !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($info, .2);
       border-color: $info;
    }    
}
.btn-soft-light {
    background-color: rgba($light, .9) !important;
    color: $text-color !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($light, .2);
       border-color: $light;
       border-color: $text-color;
    }    
}
.btn-soft-dark {
    background-color: rgba($dark, 0.3) !important;
    color: $white !important;
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
       box-shadow: 0 3px 12px rgba($dark, .2);
       border-color: $dark;
    }    
}
.primary-hover {
  &:hover {
    background: $primary !important;
    color: $white;
  }
}

.page-loader {
  width: 48px;
  height: 48px;
  border: 4px solid;
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent $primary $primary transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s ease-in-out infinite;
}
.page-loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 12px solid;
  border-color: transparent $secondary $secondary transparent;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.warning-btn-hover {
    &:hover {
        background: $warning !important;
        border-color: $warning !important;
        color: $white;
    }
}
.success-btn-hover {
    &:hover {
        background: $success !important;
        border-color: $success !important;
        color: $white;
    }
}
.danger-btn-hover {
    &:hover {
        background: $danger !important;
        border-color: $danger !important;
        color: $white !important;
    }
}
.secondary-btn-hover {
    &:hover {
        background: $secondary !important;
        border-color: $secondary !important;
        color: $white;
    }
}
.primary-btn-hover {
    &:hover {
        background: $primary !important;
        border-color: $primary !important;
        color: $white !important;
    }
}
.btn {
  @include respond-below(sm) {
      padding: 5px 12px;
  }
}
@charset "UTF-8";
/****** Utils ******/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import 'bootstrap-icons/font/bootstrap-icons.css';


/******* Base *******/
body {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  color: #6F6F6F;
  line-height: 1.5;
  background-color: #F9F9FC;
  overflow-y: auto;
  overflow-x: hidden;
}

::selection {
  background: #E41F07;
  color: #FFF;
  text-shadow: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: inherit;
}

* {
  outline: none;
}

button {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

button:focus {
  box-shadow: none !important;
}

.flex-column {
  flex-direction: column !important;
}

.w-50px {
  width: 100px
}

.w-80 {
  width: 80% !important
}

@media (min-width: 992px) {
  .w-80 {
    width: 100% !important
  }
}

p {
  margin-bottom: 20px;
}

.color-texts {
  color: #878885
}

p:last-child {
  margin-bottom: 0;
}

strong {
  font-weight: 700;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #262A2A;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}

ol,
ul {
  list-style: none;
  margin-block-end: 0;
  padding: 0;
}

ul {
  list-style: none;
}

ul.list-disc {
  list-style: disc;
  padding-left: 15px;
}

ul.list-disc ul {
  list-style-type: circle;
}

ul.list-decimal {
  list-style: decimal;
  padding-left: 15px;
}

ul.list-decimal ul {
  list-style-type: decimal;
}

ul.list-icon {
  list-style: none;
  padding-left: 0;
}

ul.list-icon>li {
  padding-left: 1.875rem;
  counter-increment: li;
}

ul.list-icon>li i,
ul.list-icon>li .feather-icon,
ul.list-icon>li .svg-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: -1.25rem;
  position: relative;
  left: -10px;
  padding: 1px;
  top: -1px;
}

ul.list-icon>li i {
  font-size: 20px;
  top: 5px;
}

ul .btn-primary:not(:disabled):not(.disabled):active:focus,
ul .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.bg-primary {
  background-color: #E41F07 !important;
}

.bg-secondary {
  background-color: #FFA201 !important;
}

.bg-info {
  background-color: #1ECBE2 !important;
}

.bg-success {
  background-color: #5CB85C !important;
  color: #FFF !important;
}

.bg-warning {
  background-color: #FDA700 !important;
  color: #FFF !important;
}

.bg-danger {
  background-color: #FC0027 !important;
  color: #FFF !important;
}

.bg-dark {
  background-color: #171724 !important;
}

.bg-light {
  background-color: #FDFDFE !important;
}

.bg-white {
  background-color: #FFF !important;
}

.bg-pink {
  background-color: #EA00B7 !important;
}

.bg-purple {
  background-color: #4A00E5 !important;
}

.bg-teal {
  background-color: #02a8b5 !important;
}

.bg-blue {
  background-color: #339DFF !important;
}

.bg-tertiary {
  background-color: #3C2371 !important;
}

.bg-violet {
  background-color: #676DFF !important;
}

.bg-green {
  background-color: #00918E !important;
}

.bg-primary {
  background-color: #E41F07 !important;
}

.bg-primary.bg-opacity-10 {
  background-color: rgba(228, 31, 7, 0.1) !important;
}

.bg-primary.bg-opacity-25 {
  background-color: rgba(228, 31, 7, 0.25) !important;
}

.bg-primary.bg-opacity-50 {
  background-color: rgba(228, 31, 7, 0.5) !important;
}

.bg-primary.bg-opacity-75 {
  background-color: rgba(228, 31, 7, 0.75) !important;
}

.bg-primary.bg-opacity-100 {
  background-color: #e41f07 !important;
}

.bg-secondary {
  background-color: #FFA201 !important;
}

.bg-secondary.bg-opacity-10 {
  background-color: rgba(255, 162, 1, 0.1) !important;
}

.bg-secondary.bg-opacity-25 {
  background-color: rgba(255, 162, 1, 0.25) !important;
}

.bg-secondary.bg-opacity-50 {
  background-color: rgba(255, 162, 1, 0.5) !important;
}

.bg-secondary.bg-opacity-75 {
  background-color: rgba(255, 162, 1, 0.75) !important;
}

.bg-secondary.bg-opacity-100 {
  background-color: #ffa201 !important;
}

.bg-info {
  background-color: #1ECBE2 !important;
}

.bg-info.bg-opacity-10 {
  background-color: rgba(30, 203, 226, 0.1) !important;
}

.bg-info.bg-opacity-25 {
  background-color: rgba(30, 203, 226, 0.25) !important;
}

.bg-info.bg-opacity-50 {
  background-color: rgba(30, 203, 226, 0.5) !important;
}

.bg-info.bg-opacity-75 {
  background-color: rgba(30, 203, 226, 0.75) !important;
}

.bg-info.bg-opacity-100 {
  background-color: #1ecbe2 !important;
}

.bg-success {
  background-color: #5CB85C !important;
}

.bg-success.bg-opacity-10 {
  background-color: rgba(92, 184, 92, 0.1) !important;
}

.bg-success.bg-opacity-25 {
  background-color: rgba(92, 184, 92, 0.25) !important;
}

.bg-success.bg-opacity-50 {
  background-color: rgba(92, 184, 92, 0.5) !important;
}

.bg-success.bg-opacity-75 {
  background-color: rgba(92, 184, 92, 0.75) !important;
}

.bg-success.bg-opacity-100 {
  background-color: #5cb85c !important;
}

.bg-warning {
  background-color: #FDA700 !important;
}

.bg-warning.bg-opacity-10 {
  background-color: rgba(253, 167, 0, 0.1) !important;
}

.bg-warning.bg-opacity-25 {
  background-color: rgba(253, 167, 0, 0.25) !important;
}

.bg-warning.bg-opacity-50 {
  background-color: rgba(253, 167, 0, 0.5) !important;
}

.bg-warning.bg-opacity-75 {
  background-color: rgba(253, 167, 0, 0.75) !important;
}

.bg-warning.bg-opacity-100 {
  background-color: #fda700 !important;
}

.bg-danger {
  background-color: #FC0027 !important;
}

.bg-danger.bg-opacity-10 {
  background-color: rgba(252, 0, 39, 0.1) !important;
}

.bg-danger.bg-opacity-25 {
  background-color: rgba(252, 0, 39, 0.25) !important;
}

.bg-danger.bg-opacity-50 {
  background-color: rgba(252, 0, 39, 0.5) !important;
}

.bg-danger.bg-opacity-75 {
  background-color: rgba(252, 0, 39, 0.75) !important;
}

.bg-danger.bg-opacity-100 {
  background-color: #fc0027 !important;
}

.bg-dark {
  background-color: #171724 !important;
}

.bg-dark.bg-opacity-10 {
  background-color: rgba(23, 23, 36, 0.1) !important;
}

.bg-dark.bg-opacity-25 {
  background-color: rgba(23, 23, 36, 0.25) !important;
}

.bg-dark.bg-opacity-50 {
  background-color: rgba(23, 23, 36, 0.5) !important;
}

.bg-dark.bg-opacity-75 {
  background-color: rgba(23, 23, 36, 0.75) !important;
}

.bg-dark.bg-opacity-100 {
  background-color: #171724 !important;
}

.bg-light {
  background-color: #FDFDFE !important;
}

.bg-light.bg-opacity-10 {
  background-color: rgba(253, 253, 254, 0.1) !important;
}

.bg-light.bg-opacity-25 {
  background-color: rgba(253, 253, 254, 0.25) !important;
}

.bg-light.bg-opacity-50 {
  background-color: rgba(253, 253, 254, 0.5) !important;
}

.bg-light.bg-opacity-75 {
  background-color: rgba(253, 253, 254, 0.75) !important;
}

.bg-light.bg-opacity-100 {
  background-color: #fdfdfe !important;
}

.bg-primary {
  background-color: #E41F07 !important;
  border: 1px solid #E41F07 !important;
  color: #FFF;
}

.bg-secondary {
  background-color: #FFA201 !important;
  border: 1px solid #FFA201 !important;
  color: #FFF;
}

.bg-success {
  background-color: #5CB85C !important;
  border: 1px solid #5CB85C !important;
  color: #FFF;
}

.bg-info {
  background-color: #1ECBE2 !important;
  border: 1px solid #1ECBE2 !important;
  color: #FFF;
}

.bg-warning {
  background-color: #FDA700 !important;
  border: 1px solid #FDA700 !important;
  color: #FFF;
}

.bg-danger {
  background-color: #FC0027 !important;
  border: 1px solid #FC0027 !important;
  color: #FFF;
}

.bg-dark {
  background-color: #171724 !important;
  border: 1px solid #171724 !important;
  color: #FFF;
}

.bg-light {
  background-color: #FDFDFE !important;
  border: 1px solid #FDFDFE !important;
  color: #FFF;
}

.bg-black {
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #FFF;
}

.bg-purple {
  background-color: #4A00E5 !important;
  border: 1px solid #4A00E5 !important;
  color: #FFF;
}

.bg-yellow {
  background-color: #ffff00 !important;
  border: 1px solid #ffff00 !important;
  color: #FFF;
}

.bg-teal {
  background-color: #02a8b5 !important;
  border: 1px solid #02a8b5 !important;
  color: #FFF;
}

.bg-soft-primary {
  background-color: #F7BCB5;
  color: #E41F07;
}

.bg-soft-secondary {
  background-color: #FFE3B3;
  color: #FFA201;
}

.bg-soft-success {
  background-color: #CEEACE;
  color: #5CB85C;
}

.bg-soft-danger {
  background-color: #FEB3BE;
  color: #FC0027;
}

.bg-soft-warning {
  background-color: #FEE5B3;
  color: #FDA700;
}

.bg-soft-info {
  background-color: rgba(30, 203, 226, 0.3);
  color: #1ECBE2;
}

.bg-soft-light {
  background-color: rgba(253, 253, 254, 0.9);
  color: #6F6F6F;
}

.bg-soft-dark {
  background-color: rgba(23, 23, 36, 0.3);
  color: #FFF;
}

bg-light {
  color: #6F6F6F;
}

.bg-primary-gradient {
  background: linear-gradient(to right, #E41F07 0%, #8e77fa 100%) !important;
  color: #FFF;
}

.bg-secondary-gradient {
  background: linear-gradient(to right, #FFA201 0%, #28b7f9 100%) !important;
  color: #FFF;
}

.bg-warning-gradient {
  background: linear-gradient(to right, #FDA700 0%, #fbc434 100%) !important;
  color: #FFF;
}

.bg-info-gradient {
  background: linear-gradient(to right, #1ECBE2 0%, #00f2fe 100%) !important;
  color: #FFF;
}

.bg-success-gradient {
  background: linear-gradient(to right, #5CB85C 0%, #5cf9e2 100%) !important;
  color: #FFF;
}

.bg-danger-gradient {
  background: linear-gradient(to right, #FC0027 0%, #fd4a68 100%) !important;
  color: #FFF;
}

.bg-orange-gradient {
  background: linear-gradient(to right, #FF9D0A 0%, #FBB034 100%) !important;
  color: #FFF;
}

.bg-purple-gradient {
  background: linear-gradient(to right, #4A00E5 0%, #9b25b7 100%) !important;
  color: #FFF;
}

.bg-teal-gradient {
  background: linear-gradient(to right, #02a8b5 0%, #0695DD 100%) !important;
  color: #FFF;
}

.bg-light-gradient {
  background: linear-gradient(to right, #FDFDFE 0%, #D1D6DE 100%) !important;
  color: #000;
}

.bg-dark-gradient {
  background: linear-gradient(to right, #171724 0%, #54505D 100%) !important;
  color: #FFF;
}

.bg-outline-primary {
  background-color: #FFF;
  border: 1px solid #E41F07;
  color: #E41F07;
}

.bg-outline-secondary {
  background-color: #FFF;
  border: 1px solid #FFA201;
  color: #FFA201;
}

.bg-outline-warning {
  background-color: #FFF;
  border: 1px solid #FDA700;
  color: #FDA700;
}

.bg-outline-info {
  background-color: #FFF;
  border: 1px solid #1ECBE2;
  color: #1ECBE2;
}

.bg-outline-success {
  background-color: #FFF;
  border: 1px solid #5CB85C;
  color: #5CB85C;
}

.bg-outline-danger {
  background-color: #FFF;
  border: 1px solid #FC0027;
  color: #FC0027;
}

.bg-outline-dark {
  background-color: #FFF;
  border: 1px solid #171724;
  color: #171724;
}

.bg-outline-light {
  background-color: #FFF;
  border: 1px solid #FDFDFE !important;
  color: #000 !important;
}

.bg-primary-transparent {
  background-color: rgba(228, 31, 7, 0.1) !important;
  color: #E41F07 !important;
}

.bg-primary-transparent:hover {
  background-color: rgba(228, 31, 7, 0.1) !important;
  color: #E41F07 !important;
}

.bg-secondary-transparent {
  background-color: rgba(255, 162, 1, 0.1) !important;
  color: #FFA201 !important;
}

.bg-secondary-transparent:hover {
  background-color: rgba(255, 162, 1, 0.1) !important;
  color: #FFA201 !important;
}

.bg-info-transparent {
  background-color: rgba(30, 203, 226, 0.1) !important;
  color: #1ECBE2 !important;
}

.bg-info-transparent:hover {
  background-color: rgba(30, 203, 226, 0.1) !important;
  color: #1ECBE2 !important;
}

.bg-success-transparent {
  background-color: rgba(92, 184, 92, 0.1) !important;
  color: #5CB85C !important;
}

.bg-success-transparent:hover {
  background-color: rgba(92, 184, 92, 0.1) !important;
  color: #5CB85C !important;
}

.bg-warning-transparent {
  background-color: rgba(253, 167, 0, 0.1) !important;
  color: #FDA700 !important;
}

.bg-warning-transparent:hover {
  background-color: rgba(253, 167, 0, 0.1) !important;
  color: #FDA700 !important;
}

.bg-danger-transparent {
  background-color: rgba(252, 0, 39, 0.1) !important;
  color: #FC0027 !important;
}

.bg-danger-transparent:hover {
  background-color: rgba(252, 0, 39, 0.1) !important;
  color: #FC0027 !important;
}

.bg-light-transparent {
  background-color: rgba(253, 253, 254, 0.5) !important;
  color: #FDFDFE !important;
}

.bg-light-transparent:hover {
  background-color: rgba(253, 253, 254, 0.5) !important;
  color: #6F6F6F !important;
}

.bg-gray-100 {
  background-color: #C0C0C0;
}

.bg-gray-200 {
  background-color: #9B9B9B;
}

.bg-gray-300 {
  background-color: #898989;
}

.bg-gray-400 {
  background-color: #6F6F6F;
}

.bg-gray-500 {
  background-color: #6F7A7A;
}

.bg-gray-600 {
  background-color: #565E5E;
}

.bg-gray-700 {
  background-color: #424848;
}

.bg-gray-800 {
  background-color: #323737;
}

.bg-gray-900 {
  background-color: #262A2A;
}

.svg-primary {
  fill: #E41F07;
}

.svg-secondary {
  fill: #FFA201;
}

.svg-success {
  fill: #5CB85C;
}

.svg-danger {
  fill: #FC0027;
}

.svg-warning {
  fill: #FDA700;
}

.svg-white {
  fill: #FFF;
}

.svg-black {
  fill: #000;
}

.svg-pink {
  fill: #EA00B7;
}

.svg-orange {
  fill: #FF9D0A;
}

.svg-purple {
  fill: #4A00E5;
}

.svg-indigo {
  fill: #0092E4;
}

.svg-info {
  fill: #1ECBE2;
}

.svg-yellow {
  fill: #ffff00;
}

.svg-light {
  fill: #FDFDFE;
}

.svg-dark {
  fill: #171724;
}

.svg-teal {
  fill: #02a8b5;
}

.svg-default {
  fill: #6F6F6F;
}

.color-container {
  width: 5rem;
  height: 5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
}

.text-container {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
}

.color-box {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

/****** Layout ******/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 50px !important;
}

::-webkit-scrollbar-track {
  background: #898989;
}

::-webkit-scrollbar-thumb {
  background: #E41F07;
}

::-webkit-scrollbar-thumb:hover {
  background: #898989;
}

.swal2-header .swal2-title {
  font-size: 18px;
}

.none {
  display: none !important;
}

.text-red {
  color: #FC0027 !important;
}

.text-green {
  color: #5CB85C !important;
}

.input-blocks input[type=text]:focus {
  border-color: #E8E8E8;
}

.input-group .scanner-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #E41F07;
  border-radius: 5px !important;
  width: 40px;
  color: #FFF;
  font-weight: 600;
  padding: 0;
}

.input-group .scanner-set:hover {
  background: #262A2A;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 50px !important;
}

::-webkit-scrollbar-track {
  background: #898989;
}

::-webkit-scrollbar-thumb {
  background: #E41F07;
}

::-webkit-scrollbar-thumb:hover {
  background: #898989;
}

.swal2-header .swal2-title {
  font-size: 18px;
}

.none {
  display: none !important;
}

.text-red {
  color: #FC0027 !important;
}

.text-green {
  color: #5CB85C !important;
}

.input-blocks input[type=text]:focus {
  border-color: #E8E8E8;
}

.page-wrapper {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 0 0 0 276px;
  padding: 56px 0 0;
  position: relative;
  left: 0;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (max-width: 991.98px) {


  .w-50 {
    width: 100% !important;
  }

  body .display-5 {
    font-size: 30px;
    margin-top: 30px;
  }

  .header .header-user .user-menu {
    z-index: 9;
  }

  .header .nav-item.nav-search-inputs.me-auto {
    margin-right: 0 !important;
  }

  .page-wrapper {
    margin: 0;
    padding: 60px 0 0;
  }
}

.page-wrapper .content {
  padding: 24px;
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  .page-wrapper .content {
    padding: 15px 15px 0;
  }
}

.header {
  height: 56px;
  z-index: 999;
  background: #FFF;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.header .flag-nav>a img {
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

.header .searchinputs {
  padding: 0;
  position: relative;
}

.header .searchinputs input {
  width: 335px;
  height: 42px;
  border: 1px solid #F6F6F6;
  background: #F6F6F6;
  padding: 5px 10px;
  padding-right: 35px;
  border-radius: 5px;
  color: #262A2A;
}

@media (max-width: 1199.98px) {
  .header .searchinputs input {
    width: 170px;
  }
}

.header .searchinputs input::placeholder {
  color: #6F6F6F;
}

.header .searchinputs .search-addon button {
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 5px;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  color: #262A2A;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

@media (min-width: 992px) {
  .header .dropdown-menu {
    top: 85px;
    right: 0;
    left: auto;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    border: 0;
    display: block;
    border-radius: 5px;
    box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
  }
}

@media (max-width: 991.98px) {
  .header .dropdown-menu {
    transition: none;
  }
}



.header .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  margin-top: 8px !important;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  float: left;
  height: 56px;
  position: relative;
  text-align: center;
  width: 276px;
  z-index: 1;
  background: #FFF;
  padding: 0 15px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.header .header-left .logo {
  display: inline-block;
  line-height: 0;
}

.header .header-left .logo img {
  width: 130px;
  display: block;
}

.header .header-left .logo img.white-logo {
  display: none;
}

@media (max-width: 991.98px) {
  .header .header-left .logo {
    text-align: center;
    width: 100%;
  }

  .header .header-left .logo img {
    width: 140px;
  }
}

.header .header-left .logo-small {
  display: none;
}

@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
  }

}

@media (max-width: 991.98px) {
  .header .header-left #toggle_btn {
    display: none;
  }
}

@media (min-width: 991.98px) {
  .header #toggle_btn {
    font-size: 18px;
    padding: 4px;
    border-radius: 6px;
  }

  .header #toggle_btn .bar-icon {
    display: inline-block;
    width: 21px;
  }

  .header #toggle_btn .bar-icon span {
    background-color: #FFF;
    display: block;
    float: left;
    height: 2px;
    width: 21px;
    margin: 0 0 5px;
    border-radius: 50px;
  }

  .header #toggle_btn .bar-icon span:nth-child(2) {
    width: 15px;
  }
}

.header .bar-icon {
  display: inline-block;
  width: 31px;
  margin-top: 20px;
}

.header .bar-icon span {
  background-color: #E41F07;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
  transition: all 0.5s;
}

.header .bar-icon span:nth-child(2) {
  width: 16px;
}

.header .menu-opened .slide-nav .bar-icon {
  display: inline-block;
  width: 31px;
  margin-top: 20px;
}

.header .menu-opened .slide-nav .bar-icon span:first-child {
  position: relative;
  top: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.header .menu-opened .slide-nav .bar-icon span:nth-child(2) {
  display: none;
}

.header .menu-opened .slide-nav .bar-icon span:last-child {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
}

.header .menus-info {
  min-width: 500px;
  border-radius: 5px;
  padding: 8px;
}

@media (max-width: 1199.98px) {
  .header .menus-info {
    min-width: 430px;
  }
}

.header .menus-info .menu-list li {
  margin-bottom: 0;
  margin-right: 0;
}

.header .menus-info .menu-list li a {
  display: block;
  padding: 8px;
  background: #FFF;
  border-radius: 5px;
}

.header .menus-info .menu-list li a:hover {
  background: #F6F6F6;
}

.header .menus-info .menu-list li a .menu-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.header .menus-info .menu-list li a .menu-details .menu-list-icon {
  font-size: 18px;
  color: #FFF;
  width: 36px;
  min-width: 36px;
  height: 36px;
  padding: 5px;
  margin-right: 8px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.header .menus-info .menu-list li a .menu-details .menu-details-content p {
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 0;
}

.header .menus-info .menu-list li a .menu-details .menu-details-content span {
  font-weight: 400;
  color: #6F6F6F;
}

@media (max-width: 1199.98px) {
  .header .menus-info .menu-list li a .menu-details .menu-details-content span {
    font-size: 13px;
  }
}

.header .profilename {
  padding: 0px;
}

.header .profilename .profileset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 3px;
}

.header .profilename .profileset h6 {
  font-size: 14px;
  color: #FFA201;
  font-weight: 500;
}

.header .profilename .profileset h5 {
  font-size: 14px;
  color: #565E5E;
}

.header .profilename .profileset .profilesets {
  margin-left: 10px;
}

.header .profilename .profileset .user-img img {
  margin-top: 0;
}

.header .show .user-info::after {
  transform: rotate(-140deg);
  top: 10px;
}

.header .user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  padding: 0;
}

@media (max-width: 1199.98px) {
  .header .user-info span.user-detail {
    display: none;
  }
}

.header .user-info::after {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 0;
  border-left: 0;
  border-right: 2px solid #1B2950;
  border-bottom: 2px solid #1B2950;
  border-radius: 1px;
  transform: rotate(45deg);
  transition: all 0.5s;
  top: 8px;
  position: absolute;
  right: 12px;
  display: none;
}

@media (max-width: 1199.98px) {
  .header .user-info::after {
    top: 12px;
  }

  .card.shadow {
    height: 100%;
  }
}

.header .user-info .user-role {
  display: block;
  color: #67748E;
  font-size: 12px;
}

.header .user-info .user-letter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #FFF;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  margin-right: 0;
}

@media (max-width: 1199.98px) {
  .header .user-info .user-letter {
    margin-right: 0;
  }
}

.header .user-info .user-letter img {
  border-radius: 5px;
}

.header .user-info .user-name {
  font-size: 15px;
  font-weight: 600;
  color: #1B2950;
  display: flex;
  align-items: center;
}

.header .user-info .user-name img {
  margin-left: 10px;
}

.header .header-user {
  padding: 15px 24px;
  display: contents;
}

.header .header-user .user-menu {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  position: relative;
  height: 100%;
  border-bottom: 0;
  padding: 4px 1px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 991.98px) {
  /* .header .header-user .user-menu {
    display: none;
  } */
}

.header .header-user .user-menu .status {
  position: absolute;
  content: "";
  background: #E41F07;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  top: 3px;
  right: 3px;
}

.header .header-user .user-menu .header-title h3 {
  font-size: 24px;
  font-weight: 700;
}

.header .header-user .user-menu .header-title h6 {
  font-size: 12px;
  font-weight: 600;
}

.header .header-user .user-menu>li {
  margin-left: 10px;
  margin-inline-end: 0;
}

.header .header-user .user-menu>li:first-child {
  margin-left: 0;
}

@media (max-width: 1199.98px) {
  .header .header-user .user-menu>li {
    margin-left: 8px;
  }
}

.header .header-user .user-menu>li svg {
  width: 18px;
  color: #67748E;
}

.header .header-user .user-menu>li a:hover svg {
  color: #E41F07;
}

.header .header-user .user-menu .dropdown-toggle::after {
  border: 0;
  display: none;
}

.header .header-user .user-menu .nav-searchinputs {
  margin-right: auto;
  margin-left: 25px;
}

.header .header-user .user-menu .nav-item-box>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #F6F6F6;
  color: #262A2A;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  position: relative;
  border: 0;
}

.header .header-user .user-menu .nav-item-box>a:hover {
  background: #EAEAEA;
}

.header .header-user .user-menu .nav-item-email.nav-item-box {
  margin-left: 15px;
}

.header .header-user .user-menu.nav>li>a {
  padding: 0;
  display: flex;
  line-height: normal;
  color: #3E4265;
}

.header .header-user .user-menu.nav>li>a.btn-header-list {
  color: #FFF;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.header .header-user .user-menu.nav>li>a .badge {
  color: #FFF;
  font-weight: 700;
  position: absolute;
  top: 4px;
  right: 4px;
  background: #E41F07;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.header .header-user .user-menu.nav>li>a i {
  font-size: 18px;
}

.header .header-user .user-menu.nav>li>a .badge-success {
  background: #5CB85C;
  border: 3px solid #FFF;
  padding: 0;
  top: inherit;
  bottom: -3px;
  right: -3px;
}

.header .header-user .user-menu.nav>li.flag-nav a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.header .header-user .user-menu.nav>li.flag-nav a .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}

.header .header-user .user-menu.nav>li.flag-nav a .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}

.header .header-user .user-menu.nav>li.flag-nav .dropdown-menu {
  padding: 15px;
  min-width: 210px;
}

.header .header-user .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item {
  padding: 10px 10px;
  color: #67748E;
  border-radius: 10px;
  transition: none;
}

.header .header-user .user-menu.nav>li.flag-nav .dropdown-menu .dropdown-item.active {
  color: #FFF;
}

.header .header-user .user-menu.nav>li.flag-nav .dropdown-menu a img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.header .page-title-box {
  float: left;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 20px;
  border-radius: 0;
}

@media (max-width: 991.98px) {
  .header .page-title-box {
    display: none;
  }
}

.header .page-title-box a {
  color: #E41F07;
  font-size: 11px;
  font-weight: 700;
  border: 1px solid #E41F07;
  margin: 0;
  padding: 5px;
  border-radius: 5px;
}

.header .page-title-box a:hover {
  background: #E41F07;
  color: #FFF;
}

.header .mobile_btn {
  display: none;
  float: left;
}

@media (max-width: 991.98px) {
  .header .mobile_btn {
    color: #E41F07;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    width: 60px;
    z-index: 10;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.header .responsive-search {
  display: none;
  color: #FFF;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
}

@media (max-width: 991.98px) {
  .header .responsive-search {
    display: block;
  }
}

.header .top-nav-search form {
  position: relative;
}

@media (max-width: 991.98px) {
  .header .top-nav-search form {
    display: none;
  }
}

.header .top-nav-search form .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 40px;
  padding: 10px 50px 10px 15px;
  border-radius: 50px;
}

.header .top-nav-search form .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.header .top-nav-search form .btn {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  min-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  .header .top-nav-search.active form {
    display: block;
    position: absolute;
    left: 0;
  }

  .header .top-nav-search.active form .form-control {
    background-color: #FFF;
  }

  .header .top-nav-search.active form .btn {
    background-color: transparent;
    border-color: transparent;
    min-height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 15px;
  }
}

.header .userset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.header .userset .usernames {
  line-height: initial;
  text-align: right;
  padding-right: 10px;
}

.header .userset .usernames span {
  display: block;
  line-height: initial;
}

.header .menu-drop-user .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: 14px;
  color: #262A2A;
  padding: 10px;
  border-bottom: 1px solid #F5F5F5;
  border-radius: 0;
}

.header .menu-drop-user .dropdown-item:first-child {
  border-radius: 5px 5px 0 0;
}

.header .menu-drop-user .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
  border-bottom: 0;
}

.header .menu-drop-user .dropdown-item:hover {
  padding-left: 20px;
  letter-spacing: 0.5px;
}

.header .menu-drop-user .dropdown-item i {
  font-size: 16px;
  margin-right: 8px;
}

@media (max-width: 991.98px) {
  .header .has-arrow .dropdown-toggle>span:nth-child(2) {
    display: none;
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none !important;
  }
}

.header .profilename .user-img .status {
  bottom: 10px;
}

.header .user-img {
  display: inline-block;
  position: relative;
}

.header .user-img img {
  width: 38px;
  border-radius: 50%;
}

.header .user-img .status {
  border: 2px solid #FFF;
  height: 10px;
  width: 10px;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 30px;
  border-radius: 50%;
  display: inline-block;
  background: #5CB85C;
}

.header .mobile-user-menu {
  color: #FFF;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  text-align: right;
  width: 60px;
  z-index: 10;
  padding: 0 20px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 991.98px) {
  .header .mobile-user-menu {
    display: block;
  }
}

.header .mobile-user-menu a {
  color: #E41F07;
  padding: 0;
}

.header .mobile-user-menu a::after {
  display: none;
}

.header .mobile-user-menu .dropdown-menu {
  padding: 0;
}

.header .mobile-user-menu .dropdown-menu a {
  color: #323737;
  line-height: 40px;
  padding: 0 10px;
}

.logo-small img {
  width: 30px;
}

@media (min-width: 992px) {
  .mini-sidebar.expand-menu .header-left {
    flex-direction: row;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }

  .mini-sidebar .header-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 80px;
    padding: 0;
    flex-direction: column;
  }

  .mini-sidebar .header-left .logo-small {
    display: block;
    padding: 10px 0 0;
  }

  .mini-sidebar .header-left .logo {
    display: none;
  }

  .mini-sidebar .menu-arrow {
    display: none !important;
  }

  .mini-sidebar .chat-user {
    display: none !important;
  }

  .mini-sidebar .user-menu .badge {
    display: flex !important;
  }

  .mini-sidebar.expand-menu .menu-arrow {
    display: block !important;
  }

  .mini-sidebar.expand-menu .chat-user {
    display: block !important;
  }

  .mini-sidebar.expand-menu .logo-small {
    display: none;
  }

  .mini-sidebar.expand-menu .logo {
    display: block;
  }

  .mini-sidebar.expand-menu #toggle_btn {
    opacity: 1;
  }

  .mini-sidebar.expand-menu .header-left {
    padding: 0 15px;
    display: flex;
  }

  .mini-sidebar .noti-dot:before {
    display: none;
  }
}

.nav-list {
  position: relative;
  padding-right: 15px;
}

.nav-list::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 20px;
  background: #E8E8E8;
  z-index: 1;
}

.nav-list .nav .dark-mode-list .dark-mode-toggle {
  width: 66px;
  height: 36px;
  background: #F9F9FC;
  border: 1px solid #E8E8E8;
  font-size: 16px;
  border-radius: 5px;
  padding: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.nav-list .nav .dark-mode-list .dark-mode-toggle i {
  font-size: 16px;
  color: #262A2A;
  border-radius: 5px;
  line-height: 26px;
  width: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.nav-list .nav .dark-mode-list .dark-mode-toggle i.light-mode.active {
  color: #FFF;
  background: #5CB85C;
}

.nav-list .nav .dark-mode-list .dark-mode-toggle i.dark-mode.active {
  color: #FFF;
  background: #262A2A;
}

.nav-list .nav li {
  margin-right: 10px;
}

.nav-list .nav li:last-child {
  margin-right: 0;
}

.nav-list .nav li .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 36px;
  height: 36px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.nav-list .nav li .btn-header-list {
  color: #00918E;
  background: #E0F5D7;
  border: 1px solid #00918E;
}

.nav-list .nav li .btn-header-list:hover {
  color: #FFF;
  background: #00918E;
}

.nav-list .nav li .btn-help {
  color: #3C2371;
  background: #F3EDFF;
}

.nav-list .nav li .btn-help:hover {
  color: #FFF;
  background: #3C2371;
}

.nav-list .nav li .btn-chart-pie {
  color: #FFA201;
  background: #FFF7D8;
}

.nav-list .nav li .btn-chart-pie:hover {
  color: #FFF;
  background: #FFA201;
}

.mobile-show {
  display: none;
}

@media (max-width: 991.98px) {
  .mobile-show {
    display: block;
  }
}

.sidebar {
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  background-color: #FFF;
  margin-top: 0;
  z-index: 1001;
  position: fixed;
  top: 2px;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 276px;
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -575px;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
    background: #FFF;
  }
}

@media (max-width: 575.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .slimScrollDiv {
  border-right: 1px solid transparent;
}

@media (max-width: 575.98px) {
  .sidebar .slimScrollDiv {
    width: 100% !important;
  }
}

.sidebar .sidebar-menu {
  padding: 15px;
}

.sidebar .sidebar-menu .clinicdropdown {
  margin-bottom: 24px;
}

.sidebar .sidebar-menu .clinicdropdown>a {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 10px;
}

.sidebar .sidebar-menu .clinicdropdown>a img {
  width: 42px;
  height: 42px;
  border-radius: 5px;
}

.sidebar .sidebar-menu .clinicdropdown>a .user-names {
  margin-left: 10px;
}

.sidebar .sidebar-menu .clinicdropdown>a .user-names h5 {
  font-size: 16px;
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 2px;
}

.sidebar .sidebar-menu .clinicdropdown>a .user-names h6 {
  font-size: 14px;
  font-weight: 400;
  color: #6F6F6F;
  margin-bottom: 0;
}

.sidebar .sidebar-menu .submenu-two .subdrop.active {
  background: transparent !important;
}

.sidebar .sidebar-menu>ul>li {
  margin-bottom: 24px;
}

.sidebar .sidebar-menu>ul>li.active a {
  color: #FFF;
}

.sidebar .sidebar-menu>ul>li.active svg {
  color: #FE9F43;
}

.sidebar .sidebar-menu>ul>li svg {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.sidebar .sidebar-menu>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  padding: 10px 15px;
  position: relative;
  color: #FFA201;
}

.sidebar .sidebar-menu>ul>li>a svg {
  color: #637381;
}

.sidebar .sidebar-menu>ul>li>a img {
  color: #637381;
}

.sidebar .sidebar-menu>ul>li>a.active {
  background: rgba(254, 159, 67, 0.08);
  color: #FE9F43;
  border-radius: 5px;
}

.sidebar .sidebar-menu>ul>li>a.active svg {
  color: #FFF;
}

.sidebar .sidebar-menu>ul>li>a.active img {
  filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.sidebar .sidebar-menu>ul>li>a.active span {
  color: #FE9F43;
}

.sidebar .sidebar-menu>ul>li>a.active .menu-arrow {
  background: #FFEDDC;
}

.sidebar .sidebar-menu>ul>li>a.active .menu-arrow::before {
  border-color: #FE9F43;
}

.sidebar .sidebar-menu>ul>li>a span {
  font-size: 15px;
  font-weight: 500;
  color: #565E5E;
}

.sidebar .sidebar-menu>ul>li.active a {
  background: rgba(254, 159, 67, 0.08);
  border-radius: 5px;
}

.sidebar .sidebar-menu>ul>li.active a img {
  filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
}

.sidebar .sidebar-menu>ul>li.active a span {
  color: #FE9F43;
}

.sidebar .sidebar-menu>ul>li.submenu ul {
  display: none;
}

.sidebar .sidebar-menu>ul>li.submenu ul li a {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  display: block;
  padding: 8px 8px 8px 40px;
  padding-left: 30px;
}

.sidebar .sidebar-menu>ul>li ul {
  display: block;
  margin-bottom: 24px;
}

.sidebar .sidebar-menu>ul>li ul li {
  margin-bottom: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.sidebar .sidebar-menu>ul>li ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #6F6F6F;
  position: relative;
  width: 100%;
  padding: 8px;
}

.sidebar .sidebar-menu>ul>li ul li a i {
  color: #262A2A;
  font-size: 16px;
  background: #F6F6F6;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .sidebar-menu>ul>li ul li a span {
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  color: #262A2A;
  margin-left: 10px;
}

.sidebar .sidebar-menu>ul>li ul li a.active {
  color: #E41F07;
  background: #FCE9E6;
  border-radius: 5px;
}

.sidebar .sidebar-menu>ul>li ul li a.active span {
  color: #E41F07;
}

.sidebar .sidebar-menu>ul>li ul li a.active i {
  background: #E41F07;
  color: #FFF;
}

.sidebar .sidebar-menu>ul>li ul li a:hover {
  color: #E41F07;
}

.sidebar .sidebar-menu>ul>li ul li a:hover span {
  color: #E41F07;
}

.sidebar .sidebar-menu>ul>li ul>li.active a.subdrop {
  background: #FFEEEC;
  border-radius: 5px;
  color: #E41F07;
}

.sidebar .sidebar-menu>ul>li ul>li.active a.subdrop i {
  color: #FFF;
  background: #E41F07;
}

.sidebar .sidebar-menu>ul>li ul>li.active a.subdrop span {
  color: #E41F07;
}

.sidebar .sidebar-menu>ul>li .submenu-hdr {
  font-weight: 600;
  font-size: 12px;
  color: #6F6F6F;
  margin: 0 0 14px 0;
  text-transform: uppercase;
}

.sidebar .sidebar-menu>ul>li:first-child h6 {
  margin-top: 0;
}

.sidebar .sidebar-menu>ul>li .submenu ul {
  display: none;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.sidebar .sidebar-menu>ul li {
  margin-bottom: 5px;
}

.sidebar .sidebar-menu>ul li .submenu>ul {
  margin: 0;
}

.sidebar .sidebar-menu>ul li .submenu>ul li a {
  padding-left: 44px;
  font-size: 14px;
  background: transparent;
  position: relative;
}

.sidebar .sidebar-menu>ul li .submenu>ul li a:after {
  position: absolute;
  content: "";
  background-color: #898989;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  left: 16px;
  top: 14px;
}

.sidebar .sidebar-menu>ul li .submenu>ul li a:before {
  position: absolute;
  content: "";
  background-color: #898989;
  width: 0.5px;
  height: 8px;
  left: 19px;
  top: 0px;
}

.sidebar .sidebar-menu>ul li .submenu>a.subdrop {
  background: #F9F9FC;
}

.sidebar .sidebar-menu>ul li .submenu>a.subdrop i {
  background: #171724;
  color: #FFF;
}

.sidebar .sidebar-menu>ul li .submenu>a.subdrop.submenu-two {
  background: transparent;
}

.sidebar .sidebar-menu>ul li .submenu>a.active {
  background: #FCE9E6;
  border-radius: 5px;
}

.sidebar .sidebar-menu>ul li .submenu>a.active i {
  background: #E41F07;
  color: #FFF;
}

.sidebar .sidebar-menu>ul li .submenu>a.active span {
  color: #E41F07;
}

.sidebar .sidebar-menu>ul li .submenu>a.active .menu-arrow::before {
  border-right: 2px solid #E41F07;
  border-bottom: 2px solid #E41F07;
}

.sidebar .sidebar-menu .submenu-open:last-child {
  margin-bottom: 0;
}

.sidebar .sidebar-menu .submenu-open:last-child ul {
  border-bottom: none;
  padding-bottom: 0;
}

.sidebar .sidebar-menu .submenu-open .submenu.submenu-two ul li a {
  margin-left: 45px;
}

.sidebar .sidebar-menu .submenu-open .submenu.submenu-two.submenu-three ul li a {
  margin-left: 65px;
}

.sidebar .sidebar-menu .submenu-open .submenu ul li a {
  margin-left: 25px;
  padding-bottom: 4px;
  font-size: 14px;
  color: #B8BCC9;
}

.sidebar .sidebar-menu .submenu-open .submenu ul li a::after {
  content: "";
  background: rgba(50, 71, 92, 0.38);
  width: 8px;
  height: 8px;
  border: 2px solid #FFF;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: 0;
}

.sidebar .sidebar-menu .submenu-open .submenu ul li a:hover {
  color: #E41F07;
}

.sidebar .sidebar-menu .submenu-open .submenu ul li a:hover:after {
  background: #FE9F43;
  border: 2px solid #FDB;
}

.sidebar .sidebar-menu .menu-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 10px;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar .sidebar-menu .menu-arrow::before {
  height: 6px;
  width: 6px;
  border-top: 0;
  content: "";
  border-left: 0;
  border-right: 2px solid #262A2A;
  border-bottom: 2px solid #262A2A;
  transform: rotate(-45deg);
  position: absolute;
  left: 5px;
  top: 5px;
}

.sidebar .sidebar-menu .menu-arrow.inside-submenu {
  right: 10px;
}

.sidebar .sidebar-menu .menu-arrow.inside-submenu.inside-submenu-two {
  right: 60px;
}

.sidebar .sidebar-menu.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  background: #FFEDDC;
}

.sidebar .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 10px;
  color: #FFA201;
  position: relative;
}

.sidebar .nav-link.active {
  color: #E41F07;
  background: transparent;
  position: relative;
}

.sidebar .nav-link.active:after {
  content: "";
  border-width: 10px;
  border-color: transparent #E41F07 transparent transparent;
  border-style: solid;
  position: absolute;
  top: 36px;
  right: 0;
}

.sidebar .sidebar-left {
  width: 100px;
  -webkit-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
}

.sidebar .sidebar-right {
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: none;
}

@media (max-width: 991.98px) {
  .sidebar .sidebar-right {
    width: calc(100% - 70px);
  }
}

.sidebar .sidebar-right .slimScrollDiv {
  width: 250px !important;
  overflow: visible !important;
}

@media (max-width: 991.98px) {
  .sidebar .sidebar-right .slimScrollDiv {
    width: 100% !important;
  }
}

.sidebar .sidebar-right .tab-content {
  padding: 20px 20px 40px;
}

@media (max-width: 991.98px) {
  .sidebar .sidebar-right .tab-content {
    padding: 10px;
  }
}

.sidebar .sidebar-right p {
  font-size: 10px;
  color: #6F7A7A;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}

.sidebar .sidebar-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar .sidebar-right ul li.active a {
  background: linear-gradient(46.62deg, #E41F07 0%, #676DFF 93.64%);
  color: #FFF;
}

.sidebar .sidebar-right ul li a {
  font-weight: 400;
  font-size: 14px;
  color: #FFA201;
}

.sidebar .sidebar-right ul li a.active {
  color: #E41F07;
}

.sidebar .sidebar-right .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 18px;
  top: 0px;
  position: relative;
  font-weight: 700;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}

.sidebar .sidebar-right .menu-arrow:before {
  content: "\f105";
}

.sidebar .sub-menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.sidebar li a.subdrop .menu-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.sidebar .nav-items .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0;
  border-bottom: 1px solid #6F6F6F;
  padding: 20px 0;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar .nav-items .nav-link:hover {
  box-shadow: 0 0 0 150px #676DFF inset;
  color: #FFF;
}

.sidebar .nav-items .nav-link span {
  margin-top: 15px;
  font-weight: 500;
}

.sidebar .sidemenu a {
  width: 100%;
  font-weight: 500;
  display: block;
  border-radius: 5px;
  margin: 0 0 10px;
  padding: 10px;
}

.sidebar .sidemenu a:hover {
  background: linear-gradient(46.62deg, #E41F07 0%, #676DFF 93.64%);
  color: #FFF;
}

.sidebar .sidemenu a:hover svg {
  color: #FFF;
}

.sidebar .sidemenu a svg {
  stroke-width: 1px;
}

@media (min-width: 991.98px) {
  .mini-sidebar .page-wrapper {
    margin-left: 80px;
  }

  .mini-sidebar .user-names {
    display: none;
  }

  .mini-sidebar .header-left #toggle_btn {
    opacity: 1;
    transform: rotate(180deg);
  }

  .mini-sidebar .header-left #toggle_btn:after {
    border: 0;
  }

  .mini-sidebar .sidebar .sidebar-menu .clinicdropdown>a {
    padding: 5px;
  }

  .mini-sidebar.expand-menu .header-left #toggle_btn {
    opacity: 1;
    transform: rotate(180deg);
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu .clinicdropdown>a {
    padding: 10px;
  }

  .mini-sidebar .sidebar-right {
    display: none;
  }

  .mini-sidebar .sidebar .sidebar-menu ul li a span {
    display: none;
  }

  .mini-sidebar .sidebar .sidebar-menu ul li .submenu-hdr {
    display: none;
  }

  .mini-sidebar .sidebar .sidebar-menu ul li svg {
    margin-right: 0;
  }

  .mini-sidebar .sidebar {
    width: 80px;
    overflow: hidden;
  }

  .mini-sidebar.expand-menu .sidebar {
    display: block;
  }

  .expand-menu.mini-sidebar .sidebar .sidebar-menu .submenu-hdr {
    display: block;
  }

  .expand-menu.mini-sidebar .sidebar .user-names {
    display: block;
  }

  .expand-menu .sidebar-right {
    display: block;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .expand-menu .sidebar {
    width: 276px;
  }

  .expand-menu .sidebar .sidebar-menu ul>li>a span {
    display: inline-block;
    line-height: 0;
  }

  .expand-menu .sidebar .sidebar-menu ul>li svg {
    margin-right: 10px;
  }

  .expand-menu .sidebar .sidebar-menu .submenu-hdr {
    display: block;
  }

  .expand-menu .header-left {
    width: 276px;
  }

  #toggle_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    float: left;
  }
}

body.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li ul li a {
  justify-content: start;
}

body.mini-sidebar .sidebar .sidebar-menu>ul>li ul li a {
  /* justify-content: center; */
}

body.mini-sidebar .sidebar .sidebar-menu>ul>li.submenu-open ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-weight: 500;
  font-size: 15px;
  color: #67748E;
  position: relative;
  width: 100%;
  padding: 10px;
}

body.mini-sidebar .sidebar .sidebar-menu>ul>li ul>li.active a {
  padding: 10px;
  justify-content: center;
}

body.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li.submenu-open ul li a {
  justify-content: left;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  padding-left: 18px;
}

body.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li ul>li.active a {
  padding: 10px 15px;
  justify-content: start;
}

.sidebarrightmenu .sidebar-right {
  display: block;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.sidebar-overlay {
  display: none;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 60px;
  left: 0;
}

.sidebar-overlay.opened {
  display: block;
}

.mini-sidebar .active.subdrop~ul {
  display: none !important;
}

.mini-sidebar.expand-menu .active.subdrop~ul {
  display: block !important;
}

.active.subdrop~ul {
  display: block !important;
}

[data-sidebar=dark] .sidebar {
  background-color: #171724;
}

[data-sidebar=dark] .sidebar .sidebar-menu .clinicdropdown a {
  background-color: #000000;
}

[data-sidebar=dark] .sidebar .sidebar-menu .clinicdropdown a .user-names h5 {
  color: #FFF;
}

[data-sidebar=dark] .sidebar .sidebar-menu ul>li ul li a span {
  color: #a3a6a6;
}

[data-sidebar=dark] .sidebar .sidebar-menu .menu-arrow:before {
  border-right: 2px solid #a3a6a6;
  border-bottom: 2px solid #a3a6a6;
}

[data-sidebar=blue] .sidebar {
  background-color: #3C2371;
}

[data-sidebar=blue] .sidebar .sidebar-menu .clinicdropdown a {
  background-color: #2f0093;
}

[data-sidebar=blue] .sidebar .sidebar-menu .clinicdropdown a .user-names h5 {
  color: #FFF;
}

[data-sidebar=blue] .sidebar .sidebar-menu .clinicdropdown a .user-names h6 {
  color: #e4e4e4;
}

[data-sidebar=blue] .sidebar .sidebar-menu>ul>li .submenu-hdr {
  color: #FFF;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li .submenu-hdr {
  color: #FFF;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li ul li a {
  color: #FFF;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li ul li a.active i {
  background-color: #3C2371;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li ul li a.active span {
  color: #3C2371;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li ul li a.active .menu-arrow::before {
  border-right: 2px solid #3C2371;
  border-bottom: 2px solid #3C2371;
}

[data-sidebar=blue] .sidebar .sidebar-menu ul>li ul li a span {
  color: #FFF;
}

[data-sidebar=blue] .sidebar .sidebar-menu .menu-arrow:before {
  border-right: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
}

[data-sidebar=green] .sidebar {
  background-color: #00918E;
}

[data-sidebar=green] .sidebar .sidebar-menu .clinicdropdown a {
  background-color: #005351;
}

[data-sidebar=green] .sidebar .sidebar-menu .clinicdropdown a .user-names h5 {
  color: #FFF;
}

[data-sidebar=green] .sidebar .sidebar-menu .clinicdropdown a .user-names h6 {
  color: #e4e4e4;
}

[data-sidebar=green] .sidebar .sidebar-menu>ul>li .submenu-hdr {
  color: #FFF;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li .submenu-hdr {
  color: #FFF;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li ul li a {
  color: #FFF;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li ul li a.active i {
  background-color: #00918E;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li ul li a.active span {
  color: #00918E;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li ul li a.active .menu-arrow::before {
  border-right: 2px solid #00918E;
  border-bottom: 2px solid #00918E;
}

[data-sidebar=green] .sidebar .sidebar-menu ul>li ul li a span {
  color: #FFF;
}

[data-sidebar=green] .sidebar .sidebar-menu .menu-arrow:before {
  border-right: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
}

[data-color=red] .btn-green {
  background: #E41F07;
  border: 1px solid #E41F07;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li ul li a.active {
  color: #FFA201;
  background: transparent;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li ul li a.active i {
  background: #FFA201;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li ul li a:hover {
  color: #FFA201;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li .submenu>a.active {
  background: #fff6E6;
  color: #FFA201;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li .submenu>a.active i {
  background: #FFA201;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li .submenu>a.active span {
  color: #FFA201;
}

[data-color=yellow] .sidebar .sidebar-menu>ul>li .submenu>a.active .menu-arrow::before {
  border-right: 2px solid #FFA201;
  border-bottom: 2px solid #FFA201;
}

[data-color=yellow] .btn-green {
  background: #FFA201;
  border: 1px solid #FFA201;
}

[data-color=yellow] .select2-container--default .select2-results__option--selected {
  background: #FFA201;
}

[data-color=yellow] .btn.btn-primary {
  background-color: #FFA201;
  border: 1px solid #FFA201;
}

[data-color=yellow] .form-check-input:checked {
  background-color: #FFA201;
  border-color: #FFA201;
}

[data-color=yellow] .breadcrumb .breadcrumb-item.active {
  color: #FFA201;
}

[data-color=yellow] .btn-outline-primary {
  border: 1px solid #FFA201 !important;
  color: #FFA201 !important;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li ul li a.active {
  color: #3C2371;
  background: transparent;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li ul li a.active i {
  background: #3C2371;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li ul li a:hover {
  color: #3C2371;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li .submenu>a.active {
  background: #FFF6E6;
  color: #3C2371;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li .submenu>a.active i {
  background: #3C2371;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li .submenu>a.active span {
  color: #3C2371;
}

[data-color=blue] .sidebar .sidebar-menu>ul>li .submenu>a.active .menu-arrow::before {
  border-right: 2px solid #3C2371;
  border-bottom: 2px solid #3C2371;
}

[data-color=blue] .btn-green {
  background: #3C2371;
  border: 1px solid #3C2371;
}

[data-color=blue] .select2-container--default .select2-results__option--selected {
  background: #3C2371;
}

[data-color=blue] .btn.btn-primary {
  background-color: #3C2371;
  border: 1px solid #3C2371;
}

[data-color=blue] .form-check-input:checked {
  background-color: #3C2371;
  border-color: #3C2371;
}

[data-color=blue] .breadcrumb .breadcrumb-item.active {
  color: #3C2371;
}

[data-color=blue] .btn-outline-primary {
  border: 1px solid #3C2371 !important;
  color: #3C2371 !important;
}

[data-color=green] .sidebar .sidebar-menu>ul>li ul li a.active {
  color: #00918E;
  background: transparent;
}

[data-color=green] .sidebar .sidebar-menu>ul>li ul li a.active i {
  background: #00918E;
}

[data-color=green] .sidebar .sidebar-menu>ul>li ul li a:hover {
  color: #00918E;
}

[data-color=green] .sidebar .sidebar-menu>ul>li .submenu>a.active {
  background: #FFF6E6;
  color: #00918E;
}

[data-color=green] .sidebar .sidebar-menu>ul>li .submenu>a.active i {
  background: #00918E;
}

[data-color=green] .sidebar .sidebar-menu>ul>li .submenu>a.active span {
  color: #00918E;
}

[data-color=green] .sidebar .sidebar-menu>ul>li .submenu>a.active .menu-arrow::before {
  border-right: 2px solid #00918E;
  border-bottom: 2px solid #00918E;
}

[data-color=green] .btn-green {
  background: #00918E;
  border: 1px solid #00918E;
}

[data-color=green] .select2-container--default .select2-results__option--selected {
  background: #00918E;
}

[data-color=green] .btn.btn-primary {
  background-color: #00918E;
  border: 1px solid #00918E;
}

[data-color=green] .form-check-input:checked {
  background-color: #00918E;
  border-color: #00918E;
}

[data-color=green] .breadcrumb .breadcrumb-item.active {
  color: #00918E;
}

[data-color=green] .btn-outline-primary {
  border: 1px solid #00918E !important;
  color: #00918E !important;
}

[data-theme=dark] body {
  background-color: #171724;
  color: #a3a6a6;
}

[data-theme=dark] h1,
[data-theme=dark] h2,
[data-theme=dark] h3,
[data-theme=dark] h4,
[data-theme=dark] h5,
[data-theme=dark] h6,
[data-theme=dark] .h1,
[data-theme=dark] .h2,
[data-theme=dark] .h3,
[data-theme=dark] .h4,
[data-theme=dark] .h5,
[data-theme=dark] .h6 {
  color: #FFF;
}

[data-theme=dark] .header {
  background-color: #171724;
}

[data-theme=dark] .header .header-left {
  background-color: #171724;
}

[data-theme=dark] .header .header-left .logo img {
  display: none;
}

[data-theme=dark] .header .header-left .logo img.white-logo {
  display: block;
}

[data-theme=dark] .header .header-left #toggle_btn {
  color: #FFF;
}

[data-theme=dark] .header .dropdown-menu {
  background-color: #171724;
  box-shadow: none;
}

[data-theme=dark] .header .dropdown-menu a {
  color: #FFF;
}

[data-theme=dark] .header .dropdown-menu .topnav-dropdown-header {
  border-color: #393947;
}

[data-theme=dark] .header .dropdown-menu .topnav-dropdown-header .notification-title {
  color: #FFF;
}

[data-theme=dark] .header .menu-drop-user .dropdown-item {
  border-color: #393947;
}

[data-theme=dark] .header .notification-dropdown .notification-list li {
  border-bottom: 1px solid #393947;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .noti-title {
  color: #FFF;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .noti-details {
  color: #FFF;
}

[data-theme=dark] .header .notification-dropdown .notification-list li p.noti-time {
  color: #FFF;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .noti-pdf .noti-pdf-icon span {
  border: 1px solid #393947;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .noti-pdf .noti-pdf-text p {
  color: #FFF;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .noti-pdf .noti-pdf-text span {
  color: #B5B5B5;
}

[data-theme=dark] .header .notification-dropdown .notification-list li .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #565E5E;
}

[data-theme=dark] .header .notification-dropdown .topnav-dropdown-footer {
  background: #171724;
}

[data-theme=dark] .header .searchinputs input {
  border-color: #393947;
  background: transparent;
}

[data-theme=dark] .header .searchinputs .search-addon button {
  color: #B5B5B5;
}

[data-theme=dark] .header .menus-info .menu-list li a {
  background: transparent;
  border-radius: 5px;
}

[data-theme=dark] .header .menus-info .menu-list li a:hover {
  background: transparent;
}

[data-theme=dark] .header .menus-info .menu-list li a .menu-details .menu-list-icon {
  color: #FFF;
}

[data-theme=dark] .header .menus-info .menu-list li a .menu-details .menu-details-content p {
  color: #FFF;
  margin-bottom: 0;
}

[data-theme=dark] .header .menus-info .menu-list li a .menu-details .menu-details-content span {
  font-weight: 400;
  color: #B5B5B5;
}

[data-theme=dark] .sidebar {
  background-color: #171724;
}

[data-theme=dark] .sidebar .sidebar-menu .clinicdropdown a {
  background-color: #28283E;
}

[data-theme=dark] .sidebar .sidebar-menu .clinicdropdown a .user-names h5 {
  color: #FFF;
}

[data-theme=dark] .sidebar .sidebar-menu ul>li ul li a span {
  color: #a3a6a6;
}

[data-theme=dark] .sidebar .sidebar-menu .menu-arrow:before {
  border-right: 2px solid #a3a6a6;
  border-bottom: 2px solid #a3a6a6;
}

[data-theme=dark] .form-check-label,
[data-theme=dark] .form-label,
[data-theme=dark] .sub-header {
  color: #a3a6a6;
}

[data-theme=dark] .form-select option {
  background: transparent;
}

[data-theme=dark] .icon-form .form-icon {
  color: #a3a6a6;
}

[data-theme=dark] .drop-down>a,
[data-theme=dark] .export-dropdwon>a,
[data-theme=dark] .form-sorts>a {
  border-color: #393947;
  box-shadow: none;
  background-color: #1A1A25;
  color: #a3a6a6;
}

[data-theme=dark] .drop-down>a:hover,
[data-theme=dark] .export-dropdwon>a:hover,
[data-theme=dark] .form-sorts>a:hover {
  color: #a3a6a6;
}

[data-theme=dark] .manage-dropdwon .dropdown-menu {
  border-color: #393947;
  background-color: #1A1A25;
  color: #a3a6a6;
  box-shadow: none;
}

[data-theme=dark] .form-sorts .filter-set-content .filter-set-content-head a {
  color: #a3a6a6;
}

[data-theme=dark] .header-set .select2-container--default .select2-selection--single {
  border-color: #393947;
}

[data-theme=dark] .form-sorts .filter-dropdown-menu {
  border-color: #393947;
  background-color: #1A1A25;
  color: #a3a6a6;
  box-shadow: none;
}

[data-theme=dark] .status-radio:checked+label,
[data-theme=dark] .status-radio:not(:checked)+label {
  color: #a3a6a6;
}

[data-theme=dark] .filter-set-contents .filter-content-list {
  border-color: #393947;
  background-color: #1A1A25;
  color: #a3a6a6;
}

[data-theme=dark] .dataTables_length label .form-select,
[data-theme=dark] #dataTables_length label .form-select {
  border-color: #393947;
  box-shadow: none;
  background-color: #1A1A25;
  color: #a3a6a6;
}

[data-theme=dark] .view-icons {
  border-color: #393947;
}

[data-theme=dark] .toast {
  background-color: #1A1A25;
}

[data-theme=dark] .select2-selection--multiple {
  border-color: #393947;
  background: #171724;
  box-shadow: none;
  color: #a3a6a6;
}

[data-theme=dark] .twitter-bs-wizard .twitter-bs-wizard-nav .step-icon {
  border-color: #FFF;
  color: #FFF;
}

[data-theme=dark] .text-dark {
  color: #a3a6a6 !important;
}

[data-theme=dark] .select2-container--default .select2-selection--single {
  border-color: #393947;
  background: #171724;
  box-shadow: none;
}

[data-theme=dark] .select2-container--default .select2-selection--single .select2-selection__rendered {
  box-shadow: none;
  border: 1px solid #393947;
  background: #171724;
  color: #a3a6a6;
}

[data-theme=dark] .select2-container--default .select2-selection--single .select2-selection__arrow {
  box-shadow: none;
}

[data-theme=dark] .toggle-popup .sidebar-layout,
[data-theme=dark] .toggle-popup1 .sidebar-layout,
[data-theme=dark] .toggle-popup2 .sidebar-layout {
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .user-accordion-item .accordion-wrap {
  color: #a3a6a6;
}

[data-theme=dark] .user-accordion-item .accordion-wrap:hover {
  color: #a3a6a6;
}

[data-theme=dark] .user-accordion-item .content-collapse {
  border-color: #393947;
}

[data-theme=dark] .bootstrap-tagsinput {
  background: #171724;
  border-color: #393947;
}

[data-theme=dark] .user-accordion-item,
[data-theme=dark] .files-wrap,
[data-theme=dark] .contact-wrap,
[data-theme=dark] .notes-activity .note-download,
[data-theme=dark] .contact-tab-wrap,
[data-theme=dark] .contact-tab-view,
[data-theme=dark] .calls-box,
[data-theme=dark] .notes-activity .reply-box,
[data-theme=dark] .note-download {
  border-color: #393947;
  background: #171724;
  box-shadow: none;
}

[data-theme=dark] .contact-sidebar,
[data-theme=dark] .contact-tab-view {
  background-color: #1E1E2D;
  box-shadow: none;
}

[data-theme=dark] .action-drop .dropdown-toggle {
  border-color: #393947;
  color: #a3a6a6;
}

[data-theme=dark] .upcoming-info {
  border-color: #393947;
}

[data-theme=dark] .calls-box .caller-info .calls-user p span {
  color: #a3a6a6;
  font-weight: 500;
}

[data-theme=dark] .calls-box .caller-info .calls-action .dropdown-toggle {
  color: #a3a6a6;
  border-color: #393947;
}

[data-theme=dark] .bootstrap-tagsinput {
  box-shadow: none;
}

[data-theme=dark] .contact-grid {
  color: #a3a6a6;
  border-color: #393947;
  background: #171724;
  box-shadow: none;
}

[data-theme=dark] .contact-grid .grid-head .users-profile .name-user h6 a {
  color: #FFF;
}

[data-theme=dark] .contact-grid .grid-body .address-info p i {
  color: #a3a6a6;
}

[data-theme=dark] .contact-grid .grid-footer {
  border-color: #393947;
}

[data-theme=dark] .company-info li span {
  border-color: #393947;
}

[data-theme=dark] .contacts-action .btn-icon,
[data-theme=dark] .contacts-action .btn-icon.rating {
  color: #a3a6a6;
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .contacts-action .act-dropdown>a {
  color: #a3a6a6;
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .social-info li a {
  color: #a3a6a6;
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .view-header .form-sort .select2-container .select2-selection .select2-selection__rendered {
  color: #a3a6a6;
}

[data-theme=dark] .basic-info li span {
  color: #a3a6a6;
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .badge-day {
  color: #a3a6a6;
  background: #3d3d64;
}

[data-theme=dark] .contact-nav li a {
  border-color: transparent;
}

[data-theme=dark] .upcoming-info .dropdown .dropdown-toggle {
  color: #a3a6a6;
}

[data-theme=dark] .contact-sidebar ul,
[data-theme=dark] .view-header,
[data-theme=dark] .activity-wrap {
  border-color: #393947;
}

[data-theme=dark] .search-section {
  border-color: #393947;
}

[data-theme=dark] .select2-container--default .select2-results__option--selected {
  background: #E41F07;
}

[data-theme=dark] .form-floating>.form-control:not(:placeholder-shown)~label::after {
  background: transparent;
}

[data-theme=dark] .select2-dropdown {
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .select2-dropdown ul li {
  color: #FFF;
}

[data-theme=dark] .btn-outline-light {
  color: #a3a6a6 !important;
}

[data-theme=dark] .page-wrapper {
  background: #151525;
}

[data-theme=dark] .page-wrapper .page-header h3 {
  color: #FFF;
}

[data-theme=dark] .page-wrapper .page-header h4 {
  color: #FFF;
}

[data-theme=dark] .page-wrapper .page-header .head-icons a {
  border-color: #393947;
  background: transparent;
  color: #FFF;
}

[data-theme=dark] .page-wrapper .page-title {
  color: #FFF;
}

[data-theme=dark] .note-editable.card-block {
  color: #FFF;
}

[data-theme=dark] .accordion-item {
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .accordion-item .accordion-body {
  border-color: #393947;
  background: #28283E;
}

[data-theme=dark] .accordion-button {
  border-color: #393947;
  background: #28283E;
}

[data-theme=dark] .seprator-lg {
  border-color: #393947;
}

[data-theme=dark] .card {
  background: #1E1E2D;
  box-shadow: none;
}

[data-theme=dark] .card.bg-white {
  background: #1E1E2D !important;
}

[data-theme=dark] .card.main-card {
  background: #28283E;
  box-shadow: none;
}

[data-theme=dark] .card.custom-card {
  background: #28283E;
}

[data-theme=dark] .card.custom-card .card-header {
  border-color: #393947;
}

[data-theme=dark] .card.custom-card .card-footer {
  border-color: #393947;
}

[data-theme=dark] .card .card-header {
  border-color: #393947;
}

[data-theme=dark] .card .card-header .card-title {
  color: #FFF;
}

[data-theme=dark] .card .card-header .card-text {
  color: #a3a6a6;
}

[data-theme=dark] .card .card-body {
  color: #a3a6a6;
}

[data-theme=dark] .col-form-label {
  color: #a3a6a6;
}

[data-theme=dark] .custom-table .table-responsive {
  border-color: #393947;
}

[data-theme=dark] .btn-purple-light {
  background: #171724;
  border-color: #393947;
  color: #a3a6a6;
}

[data-theme=dark] .btn-purple-light:hover {
  background: #171724;
  border-color: #393947;
  color: #a3a6a6;
}

[data-theme=dark] .note-toolbar {
  background: #171724;
}

[data-theme=dark] .note-btn-group .note-btn {
  background: #171724;
  border-color: #393947;
  color: #a3a6a6;
}

[data-theme=dark] .note-toolbar {
  border-color: #393947;
}

[data-theme=dark] .table-action .action-icon {
  background: #171724;
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .table-action .action-icon:hover {
  background: #1A1A25;
  color: #FFF;
}

[data-theme=dark] .table-action .action-icon:hover i {
  color: #FFF;
}

[data-theme=dark] .toggle-popup .sidebar-layout .sidebar-header {
  border-color: #171724;
}

[data-theme=dark] table.table.table-primary {
  background: #171724;
}

[data-theme=dark] table.table thead {
  border-color: #393947;
}

[data-theme=dark] table.table thead tr {
  border-color: #393947;
}

[data-theme=dark] table.table thead tr th {
  background: #171724;
  color: #FFF;
  border-color: #393947;
}

[data-theme=dark] table.table tbody tr td {
  background: #171724;
  color: #B5B5B5;
  border-color: #393947;
}

[data-theme=dark] table.table tbody tr .table-avatar a {
  color: #FFF;
}

[data-theme=dark] table.table.dataTable {
  border-color: #393947;
}

[data-theme=dark] table.table.dataTable>thead {
  border-color: #393947;
}

[data-theme=dark] table.table.dataTable>thead>tr {
  border-color: #393947;
}

[data-theme=dark] table.table.dataTable>thead>tr th {
  background: #171724;
  color: #FFF;
  border-color: #393947;
}

[data-theme=dark] table.table.dataTable tbody tr td {
  background: #171724;
  color: #B5B5B5;
  border-color: #393947;
}

[data-theme=dark] .statistic-header h4 {
  color: #FFF;
}

[data-theme=dark] .statistic-header h4 i {
  color: #B5B5B5;
}

[data-theme=dark] .statistic-header .statistic-dropdown .dropdown-toggle {
  color: #FFF;
  border: 1px solid #1A1A25;
  box-shadow: none;
  background: #1A1A25;
}

[data-theme=dark] .statistic-header .statistic-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid #B5B5B5;
  border-right: 2px solid #B5B5B5;
}

[data-theme=dark] .statistic-header .statistic-dropdown .dropdown-menu {
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .statistic-header .statistic-dropdown .dropdown-menu a {
  background: transparent;
  color: #FFF;
}

[data-theme=dark] .toggle-popup .sidebar-layout .sidebar-header,
[data-theme=dark] .toggle-popup1 .sidebar-layout .sidebar-header,
[data-theme=dark] .toggle-popup2 .sidebar-layout .sidebar-header {
  border-color: #393947;
}

[data-theme=dark] .form-control,
[data-theme=dark] .form-select {
  border-color: #393947;
  background: #171724;
  color: #a3a6a6;
  box-shadow: none;
}

[data-theme=dark] .form-control:disabled,
[data-theme=dark] .form-select:disabled {
  border-color: #393947;
  background: #171724;
  color: #a3a6a6;
}

[data-theme=dark] .form-control:focus,
[data-theme=dark] .form-select:focus {
  border-color: #393947;
  background: #171724;
  color: #a3a6a6;
}

[data-theme=dark] .input-group-text {
  background: #393947;
  color: #a3a6a6;
  border-color: #393947;
}

[data-theme=dark] .radio label,
[data-theme=dark] .checkbox label {
  color: #a3a6a6;
}

[data-theme=dark] .apexcharts-text tspan {
  fill: #FFF;
}

[data-theme=dark] a {
  color: #FFF;
}

[data-theme=dark] a.label-add {
  color: #E41F07;
}

[data-theme=dark] .settings-sub-header {
  border-color: #393947;
}

[data-theme=dark] .profile-details {
  border-color: #393947;
}

[data-theme=dark] .profile-upload .profile-upload-img {
  border-color: #393947;
}

[data-theme=dark] .settings-sidebar ul li a {
  color: #B5B5B5;
}

[data-theme=dark] .settings-sidebar ul li a:hover {
  color: #FFF;
}

[data-theme=dark] .settings-sidebar ul li a.active {
  background: #28283D;
  color: #FFF;
}


[data-theme=dark] .security-grid {
  border-color: #393947;
  background: #1E1E2D;
}

[data-theme=dark] .security-grid .security-heading h5 {
  color: #FFF;
}

[data-theme=dark] .notification-wrap ul li .security-checkbox .checkboxs {
  color: #FFF;
}

[data-theme=dark] .notificaion-table .table tbody tr td {
  color: #FFF;
}

[data-theme=dark] .integration-grid {
  background: #28283E;
  border-color: #28283E;
}

[data-theme=dark] .integration-grid .integration-content p {
  color: #FFF;
}

[data-theme=dark] .storage-wrap {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .storage-wrap .storage-icon h6 {
  color: #FFF;
}

[data-theme=dark] .black-text {
  color: #FFF !important;
}

[data-theme=dark] .theme-type-images .theme-image {
  background: #171724;
  border-color: #393947;
}

[data-theme=dark] .theme-type-images .theme-image span {
  color: #FFF;
}

[data-theme=dark] .table tbody tr td a {
  color: #FFF;
}

[data-theme=dark] .text-black {
  color: #FFF !important;
}

[data-theme=dark] .lang-flag a {
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .modal .modal-content {
  background: #1E1E2D;
  border-color: #393947;
}

[data-theme=dark] .modal .modal-content .modal-header {
  border-color: #393947;
}

[data-theme=dark] .modal .modal-content .col-form-label {
  color: #FFF;
}

[data-theme=dark] .drag-attach {
  border-color: #393947;
}

[data-theme=dark] .upload-file {
  border-color: #393947;
  background: #171724;
}

[data-theme=dark] .lang-type li a {
  background: #171724;
}

[data-theme=dark] .dropdown-menu {
  background: #1E1E2D;
  border-color: #393947;
}

[data-theme=dark] .table-action .dropdown-menu a {
  color: #B5B5B5;
}

[data-theme=dark] .table-action .dropdown-menu a:hover {
  background: #28283D;
  color: #FFF;
}

[data-theme=dark] .btn-add {
  background: #1A1A25;
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .settings-sub-header.setting-item .table-action .action-icon {
  background: #1A1A25;
  border-color: #393947;
}

[data-theme=dark] .settings-sub-header.setting-item .table-action .action-icon:hover {
  background: #1A1A25;
  color: #FFF;
}

[data-theme=dark] .settings-sub-header.setting-item .table-action .action-icon:hover i {
  color: #FFF;
}

[data-theme=dark] .mail-wrapper {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .mail-wrapper .mail-wrap .email-action .info-icon:hover {
  color: #FFF;
}

[data-theme=dark] .mail-wrapper .mail-wrap .email-action .info-icon::after {
  background: #393947;
}

[data-theme=dark] .mail-wrapper .mail-collapse {
  border-color: #393947;
}

[data-theme=dark] .bank-box {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .bank-box.active::after {
  background-color: #28283E;
}

[data-theme=dark] .bank-box .table-action .action-icon:hover {
  background: #1A1A25;
}

[data-theme=dark] .custom-modal .modal-header .btn-close {
  border-color: #393947;
  background: #1A1A25;
  box-shadow: none;
}

[data-theme=dark] .storage-wrap .storage-icon .system-app-icon {
  background: #1E1E2D;
  border-color: #393947;
}

[data-theme=dark] .storage-wrap .setting-gateway a {
  color: #B5B5B5;
}

[data-theme=dark] .ip-wrap {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .ip-wrap .table-action .action-icon {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .ip-wrap .table-action .action-icon:hover {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .action-drop .dropdown-item {
  color: #B5B5B5;
}

[data-theme=dark] .action-drop .dropdown-item:hover {
  background: #28283E;
  color: #FFF;
}

[data-theme=dark] .notes-activity .note-download:hover {
  background-color: transparent;
}

[data-theme=dark] .notes-activity .note-download:hover a {
  background: #28283D;
  color: #B5B5B5;
}

[data-theme=dark] .notes-activity .note-download a {
  background: #28283D;
  color: #B5B5B5;
}

[data-theme=dark] .calls-box .caller-info .calls-action .dropdown-menu {
  border-color: #393947;
}

[data-theme=dark] .calls-box .caller-info .calls-action .dropdown-menu .dropdown-item:hover {
  background: #28283E;
  color: #FFF;
}

[data-theme=dark] .bootstrap-tagsinput .tag {
  background: #28283D;
}

[data-theme=dark] .contacts-action .act-dropdown .dropdown-item {
  color: #B5B5B5;
}

[data-theme=dark] .contacts-action .act-dropdown .dropdown-item:hover {
  background: #28283E;
  color: #FFF;
}

[data-theme=dark] .profile-pic-upload .profile-pic {
  border-color: #393947;
}

[data-theme=dark] .contact-wrap .contact-profile .avatar.company-avatar {
  border-color: #393947;
}

[data-theme=dark] .select2-container--default.select2-container--focus .select2-selection--multiple,
[data-theme=dark] .select2-container--default .select2-selection--multiple {
  border-color: #393947;
}

[data-theme=dark] .select2-container--classic .select2-selection--multiple .select2-selection__choice,
[data-theme=dark] .select2-container--default .select2-selection--multiple .select2-selection__choice,
[data-theme=dark] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background: #28283D;
  border-color: #393947;
}

[data-theme=dark] .title-name {
  color: #FFF !important;
}

[data-theme=dark] .kanban-wrapper .kanban-list-items {
  border-color: #393947;
}

[data-theme=dark] .kanban-wrapper .kanban-list-items .kanban-list-head {
  background: #28283E;
}

[data-theme=dark] .kanban-wrapper .kanban-list-items .kanban-list-head .kanban-action-btns .table-action .action-icon {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .leads-kanban-wrapper .kanban-card .kanban-card-head .kanban-card-title span {
  background: #1E1E2D;
}

[data-theme=dark] .leads-kanban-wrapper .kanban-card .kanban-card-footer span {
  border-color: #393947;
}

[data-theme=dark] .email-header .btn-white {
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .pipeline-list {
  border-color: #393947;
}

[data-theme=dark] .priority-info .dropdown .dropdown-toggle {
  border-color: #393947;
  color: #B5B5B5;
}

[data-theme=dark] .priority-info .dropdown a {
  color: #B5B5B5;
}

[data-theme=dark] .pipeline-item {
  border-color: #393947;
}

[data-theme=dark] .pipeline-item p {
  color: #FFF;
}

[data-theme=dark] .kanban-card {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .kanban-card .kanban-card-body ul li {
  color: #B5B5B5;
}

[data-theme=dark] .kanban-card .kanban-card-footer {
  border-color: #393947;
}

[data-theme=dark] .kanban-card .kanban-card-footer span {
  border-color: #393947;
}

[data-theme=dark] .campaign-tab {
  background: #28283E;
}

[data-theme=dark] .campaign-tab ul li a {
  border-color: transparent;
}

[data-theme=dark] .campaign-tab ul li a.active {
  color: #E41F07;
}

[data-theme=dark] .campaign-tab ul li a span {
  border-color: #393947;
}

[data-theme=dark] ul.project-mem li.more-set a {
  background: #28283E;
  border-color: #393947;
  color: #B5B5B5;
}

[data-theme=dark] .table-avatar .company-img {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .pipeline-progress span,
[data-theme=dark] .task-info p,
[data-theme=dark] .con-sidebar-title p,
[data-theme=dark] .task-actions ul li {
  color: #FFF;
}

[data-theme=dark] .contact-grid.project .grid-head {
  background-color: #28283E;
}

[data-theme=dark] .contact-grid.project .grid-head .users-profile .con-avatar {
  background-color: #1E1E2D;
  border-color: #393947;
}

[data-theme=dark] .access-wrap li:hover {
  background: #28283E;
}

[data-theme=dark] .task-wrap {
  background: #28283E;
  border-color: #393947;
}

[data-theme=dark] .rate-icon,
[data-theme=dark] .con-sidebar-title.border-line {
  border-color: #393947;
}

[data-theme=dark] .pipeline-list ul li a.bg-gray {
  color: #262A2A;
}

[data-theme=dark] .task-wrapper .task-accordion span {
  border-color: #393947;
  color: #B5B5B5;
}

[data-theme=dark] .task-drops .dropdown-toggle {
  background-color: transparent;
}

[data-theme=dark] .task-wrapper {
  border-color: #393947;
}

[data-theme=dark] .card.analytics-card {
  background-color: #1E1E2D;
}

[data-theme=dark] .card.analytics-card .card-header {
  background: transparent;
}

[data-theme=dark] .card.analytics-card .card-header .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #FFF;
}

[data-theme=dark] .activities-list ul li {
  background: #28283E;
}

[data-theme=dark] .activities-list ul li .activity-name h5 {
  color: #FFF;
}

[data-theme=dark] .campaign-card ul .campaign-wrap {
  background: #28283E;
}

[data-theme=dark] .campaign-card ul .campaign-wrap .campaign-info {
  border-color: #393947;
}

[data-theme=dark] .campaign-card ul .campaign-wrap .campaign-info h5 {
  color: #FFF;
}

[data-theme=dark] .card.analytics-card .card-body .table th {
  background: #171724;
  color: #FFF;
}

[data-theme=dark] .card.analytics-card .card-body .table th:first-child,
[data-theme=dark] .card.analytics-card .card-body .table th:last-child {
  border-color: #393947;
}

[data-theme=dark] .card.analytics-card .card-body .table td:first-child,
[data-theme=dark] .card.analytics-card .card-body .table td:last-child {
  border-color: #393947;
}

[data-theme=dark] .active-list ul li a {
  background: #1A1A25;
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .activity-title h4::after {
  background: #393947;
}

[data-theme=dark] .header-set {
  border-color: #393947;
}

[data-theme=dark] .header-set .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #FFF;
}

[data-theme=dark] .radio-activity li .active-type input[type=radio]~label {
  background: #1A1A25;
  border-color: #393947;
  color: #FFF;
}

[data-theme=dark] .tab-activity {
  box-shadow: none;
}

[data-theme=dark] .tab-activity ul li a span {
  color: #B5B5B5;
  border-color: #393947;
}

[data-theme=dark] .report-header {
  border-color: #393947;
}

[data-theme=dark] .location-flag-img {
  border-color: #393947;
}

[data-theme=dark] .table-imgname.flag-image span {
  color: #FFF;
}

[data-theme=dark] .membership-plans .plan-selected h4 {
  color: #FFF;
}

[data-theme=dark] .membership-plan-grid {
  background-color: #28283E;
  border-color: #393947;
}

[data-theme=dark] .membership-plan-grid .plan-price-head {
  border-color: #393947;
}

[data-theme=dark] .membership-plan-grid .plan-price-head .plan-type {
  color: #FFF;
}

[data-theme=dark] .membership-plan-grid .plans-features ul li {
  color: #FFF;
}

[data-theme=dark] .membership-plan-grid.active {
  background-color: #1E1E2D;
}

[data-theme=dark] .membership-plan-grid.active .plan-price-head {
  border-color: #393947;
}

[data-theme=dark] .membership-plan-grid.active .plan-price-head .plan-type {
  color: #FFF;
}

[data-theme=dark] .membership-plan-grid.active .plans-features ul li {
  color: #FFF;
}

[data-theme=dark] .file-manager aside {
  border-color: #393947;
}

[data-theme=dark] .notes-dash {
  background-color: #28283E;
}

[data-theme=dark] .notes-dash .notes-top-head {
  border-color: #393947;
}

[data-theme=dark] .notes-dash .notes-top-head-submenu {
  border-color: #393947;
}

[data-theme=dark] .notes-page-wrapper .content .section-card-body .notes-card-details {
  background-color: #28283E;
  border-color: #393947;
  box-shadow: none;
}

[data-theme=dark] .search-set .search-input input[type=search] {
  background-color: #28283E;
  border-color: #393947;
}

[data-theme=dark] .search-set .search-input input[type=search]::placeholder {
  color: #B5B5B5;
}

[data-theme=dark] .todo-wrapper-list {
  background-color: #28283E;
  border-color: #393947;
}

[data-theme=dark] .notes-page-wrapper .content .section-bulk-wrap {
  background-color: #28283E;
  box-shadow: none;
}

[data-theme=dark] .notes-page-wrapper .content .page-add-notes {
  border-color: #393947;
}

[data-theme=dark] .notes-page-wrapper .content .section-notes-slider {
  border-color: #393947;
}

[data-theme=dark] .fc-day {
  background-color: #28283E;
}

[data-theme=dark] .file-manager .folders {
  border-color: #393947;
  background-color: #28283E;
}

[data-theme=dark] .file-manager .accordion .accordion-item .accordion-body {
  background: transparent;
}

[data-theme=dark] table tbody td.action-table-data a {
  background-color: #28283E;
}

[data-theme=dark] .main-chat-blk .chat {
  background-color: #28283E;
}

[data-theme=dark] .main-chat-blk .chat .chat-header {
  background-color: #28283E;
  border-color: #393947;
  box-shadow: none;
}

[data-theme=dark] .main-chat-blk .main-wrapper .content .sidebar-group .sidebar {
  background-color: #28283E;
}

[data-theme=dark] .main-chat-blk .chat-page-wrapper .left-chat-title {
  background-color: #28283E;
  border-color: #393947;
}

[data-theme=dark] .main-chat-blk .chat .chat-header .user-details h5 {
  color: #FFF;
}

[data-theme=dark] .main-chat-blk .sidebar .user-list li .users-list-body h5 {
  color: #FFF;
}

[data-theme=dark] .main-chat-blk .sidebar .user-list li .users-list-body p {
  color: #B5B5B5;
}

[data-theme=dark] .main-chat-blk .chat-page-wrapper .setting-title-head h4 {
  color: #FFF;
}

[data-theme=dark] .main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .text-muted {
  color: #B5B5B5 !important;
}

[data-theme=dark] .main-chat-blk .chat .chat-body .messages .chat-profile-name h6 {
  color: #FFF;
}

[data-theme=dark] .main-chat-blk .sidebar .user-list li a {
  border-color: #393947;
}

[data-theme=dark] .main-chat-blk .last-seen {
  color: #B5B5B5;
}

[data-theme=dark] .daterangepicker {
  background-color: #28283E;
  border-color: #393947;
}

[data-theme=dark] .daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-color: #393947;
}

[data-theme=dark] .daterangepicker .drp-buttons {
  border-color: #393947;
}

[data-theme=dark] .daterangepicker .drp-buttons .btn-default {
  color: #B5B5B5;
}

[data-sidebar=sidebarbg1] .sidebar {
  background-image: url(../../../public/assets/img/theme/bg-01.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
}

[data-sidebar=sidebarbg1] .sidebar .sidebar-menu {
  background: rgba(255, 255, 255, 0.85);
}

[data-sidebar=sidebarbg2] .sidebar {
  background-image: url(../../../public/assets/img/theme/bg-02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
}

[data-sidebar=sidebarbg2] .sidebar .sidebar-menu {
  background: rgba(255, 255, 255, 0.85);
}

[data-sidebar=sidebarbg3] .sidebar {
  background-image: url(../../../public/assets/img/theme/bg-03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
}

[data-sidebar=sidebarbg3] .sidebar .sidebar-menu {
  background: rgba(255, 255, 255, 0.85);
}

[data-sidebar=sidebarbg4] .sidebar {
  background-image: url(../../../public/assets/img/theme/bg-04.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
}

[data-sidebar=sidebarbg4] .sidebar .sidebar-menu {
  background: rgba(255, 255, 255, 0.85);
}

.sidebar-contact {
  position: fixed;
  top: 0%;
  right: -350px;
  transform: translateY(-50%);
  width: 350px;
  height: auto;
  background: #fff;
  box-sizing: border-box;
  transition: 0.5s;
  z-index: 9999;
}

.sidebar-contact .toggle-theme {
  position: absolute;
  width: 35px;
  text-align: center;
  cursor: pointer;
  background: #fed713;
  top: 225px;
  right: 350px;
  padding: 6px;
  height: 35px;
  color: #000;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.sidebar-contact .toggle-theme .fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

#theme-settings {
  width: 26px;
  height: 26px;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.sidebar-themesettings {
  position: fixed;
  right: -380px;
  top: 0;
  background: #fff;
  transition: all 0.5s;
  width: 380px;
  height: 100vh;
  z-index: 99999;
  padding: 15px;
  overflow: auto;
}

.sidebar-themesettings.open {
  right: 0;
}

.sidebar-themesettings .themesettings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.sidebar-themesettings .themesettings-header h4 {
  color: #262A2A;
  font-size: 18px;
}

.sidebar-themesettings .themesettings-inner {
  padding: 24px;
  background: #fff;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin-top: 15px;
  padding-bottom: 9px;
}

.sidebar-themesettings .themesettings-inner .themesettings-content {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 15px;
}

.sidebar-themesettings .themesettings-inner .themesettings-content h6 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.sidebar-themesettings .input-themeselect {
  background: #F6F6F6;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  margin-bottom: 15px;
}

.sidebar-themesettings .input-themeselect input[type=radio] {
  display: none;
}

.sidebar-themesettings .input-themeselect input[type=radio]:checked~label .checkboxs-theme:after {
  background-color: #4A00E5;
  border-color: #4A00E5;
  content: "";
}

.sidebar-themesettings .input-themeselect input[type=radio]:checked~label .checkboxs-theme::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  right: 4px;
  position: absolute;
  top: -15px;
  z-index: 9;
}

.sidebar-themesettings .input-themeselect img {
  height: 80px;
}

.sidebar-themesettings .input-themeselect .checkboxs-theme {
  position: relative;
  width: 100%;
  display: block;
}

.sidebar-themesettings .input-themeselect .checkboxs-theme:after {
  position: absolute;
  content: "";
  border: 1px solid #E8E8E8;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  right: 0;
  top: -17px;
}

.sidebar-themeoverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: -100%;
  z-index: 10000;
}

.sidebar-themeoverlay.open {
  left: 0;
}

.theme-colorsset {
  margin: 15px 0;
}

.theme-colorsset ul {
  display: flex;
  align-items: center;
}

.theme-colorsset ul li .input-themeselects label {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  position: relative;
}

.theme-colorsset ul li .input-themeselects label.red-clr {
  background: #E41F07;
}

.theme-colorsset ul li .input-themeselects label.yellow-clr {
  background: #FFA201;
}

.theme-colorsset ul li .input-themeselects label.blue-clr {
  background: #3C2371;
}

.theme-colorsset ul li .input-themeselects label.green-clr {
  background: #00918E;
}

.theme-colorsset ul li .input-themeselects input[type=radio] {
  display: none;
}

.theme-colorsset ul li .input-themeselects input[type=radio]:checked~label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 10px;
  position: absolute;
}

.theme-colorsset ul li+li {
  margin-left: 10px;
}

.themesettings-footer {
  margin-top: 15px;
}

.themesettings-footer ul {
  display: flex;
  align-items: center;
}

.themesettings-footer ul li {
  width: 50%;
}

.themesettings-footer ul li+li {
  margin-left: 10px;
}

.themesettings-footer ul li .btn {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
}

.themesettings-footer ul li .btn-cancel {
  background: #F6F6F6;
  border: 1px solid #E8E8E8;
  color: #262A2A;
}

.themesettings-footer ul li .btn-reset {
  background: #00918E;
  color: #fff;
}

.topnav-dropdown-header {
  padding: 15px;
  border-bottom: 1px solid #E8E8E8;
}

.topnav-dropdown-header .notification-title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
}

.notification-dropdown .noti-content {
  width: 440px;
  height: 390px;
  overflow-y: auto;
  position: relative;
}

.notification-dropdown .notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-dropdown .notification-list li {
  margin-top: 0;
  border-bottom: 1px solid #F4F4F4;
  font-size: 14px;
}

.notification-dropdown .notification-list li:last-child {
  border-bottom: 0;
}

.notification-dropdown .notification-list li a {
  display: block;
  padding: 15px;
}

.notification-dropdown .notification-list li .avatar {
  width: 36px;
  height: 36px;
  margin: 0 16px 0 0;
  border-radius: 5px;
  position: relative;
}

.notification-dropdown .notification-list li .avatar img {
  border-radius: 5px;
}

.notification-dropdown .notification-list li .avatar .badge {
  display: inline-block;
  position: absolute;
  top: -7px;
  left: -7px;
  padding: 4px;
}

.notification-dropdown .notification-list li .noti-title {
  color: #323737;
  font-weight: 500;
}

.notification-dropdown .notification-list li .noti-details {
  font-weight: 500;
  color: #6F6F6F;
  margin: 0 0 8px;
}

.notification-dropdown .notification-list li .noti-sub-details {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #6F6F6F;
  position: relative;
  margin: 0 0 8px;
  padding: 0 0 0 15px;
}

.notification-dropdown .notification-list li .noti-sub-details::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: -webkit-fill-available;
  background: #6F6F6F;
  border-radius: 5px;
}

.notification-dropdown .notification-list li p.noti-time {
  font-size: 12px;
  color: #262A2A;
  margin: 0;
}

.notification-dropdown .notification-list li .noti-pdf {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 8px;
}

.notification-dropdown .notification-list li .noti-pdf .noti-pdf-icon {
  margin-right: 10px;
}

.notification-dropdown .notification-list li .noti-pdf .noti-pdf-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #E41F07;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.notification-dropdown .notification-list li .noti-pdf .noti-pdf-text {
  line-height: normal;
}

.notification-dropdown .notification-list li .noti-pdf .noti-pdf-text p {
  font-size: 12px;
  color: #262A2A;
  margin-bottom: 0;
}

.notification-dropdown .notification-list li .noti-pdf .noti-pdf-text span {
  font-size: 12px;
  color: #6F6F6F;
}

.notification-dropdown .notification-list li .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #565E5E;
}

.notification-dropdown .notification-list li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.notification-dropdown .notification-list li .list-item .list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.notification-dropdown .notification-list li .list-item .list-body {
  padding: 0 0 0 50px;
}

.notification-dropdown .notification-list li .list-item .list-body .message-author {
  color: #323737;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-dropdown .notification-list li .list-item .list-body .message-time {
  color: #565E5E;
  float: right;
  font-size: 11px;
}

.notification-dropdown .notification-list li .list-item .list-body .message-content {
  color: #323737;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topnav-dropdown-footer {
  background: #F9F9FC;
  text-align: right;
  padding: 15px;
}

.topnav-dropdown-footer a {
  font-weight: 600;
  margin-right: 15px;
}

.topnav-dropdown-footer a:last-child {
  margin-right: 0;
}

.topnav-dropdown-footer .view-link {
  color: #262A2A;
}

.topnav-dropdown-footer .view-link:hover {
  color: #6F6F6F;
}

.topnav-dropdown-footer .clear-link {
  color: #E41F07;
}

.topnav-dropdown-footer .clear-link:hover {
  color: #b31805;
}

.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.list-body {
  padding: 0 0 0 50px;
}

.list-body .message-author {
  color: #323737;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-body .message-time {
  color: #565E5E;
  float: right;
  font-size: 11px;
}

.list-body .message-content {
  color: #323737;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity {
  width: 100%;
}

.activity .activity-box {
  position: relative;
}

.activity .activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.activity .activity-list li {
  background-color: #FFF;
  position: relative;
  border: 1px solid #6F6F6F;
  margin: 0 0 10px;
  padding: 20px 10px 10px;
  font-size: 15px;
}

.activity .activity-list li .activity-user {
  height: 50px;
  margin: 0;
  padding: 0;
  width: 50px;
  position: absolute;
  top: 12px;
  left: 8px;
}

.activity .activity-list li .activity-content {
  background-color: #FFF;
  position: relative;
  margin: 0 0 0 60px;
  padding: 0;
}

.activity .activity-list li .time {
  color: #6F7A7A;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}

.activity .activity-list li a.name {
  color: #000;
}

.activity .activity-list li a {
  color: #FFA201;
  font-weight: 600;
  font-size: 15px;
}

.activity .activity-list li a img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

/******* Components ******/
.avatar {
  position: relative;
  height: 2.625rem;
  width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #FFF;
  font-weight: 500;
}

.avatar a.badge:hover {
  color: #FFF;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-right: 20px;
  object-fit: cover;
}

.pright-3 {
  padding-right: 1rem
}


@media (max-width: 992px) {
  .pright-3 {
    padding-right: 1.5rem
  }
}

.mright-20px {
  margin-right: 10px !important;
}

@media (max-width: 992px) {

  .avatar img {
    width: 100%;

    height: 100%;
    border-radius: 4px;
    margin-right: 40px;
    object-fit: cover;
  }

}

.avatar.avatar-rounded {
  border-radius: 50%;
}

.avatar.avatar-rounded img {
  border-radius: 50%;
}

.avatar.avatar-radius-0 {
  border-radius: 0;
}

.avatar.avatar-radius-0 img {
  border-radius: 0;
}

.avatar .avatar-badge {
  position: absolute;
  inset-block-start: -4%;
  inset-inline-end: -0.375rem;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.625rem;
  border: 2px solid #FFF;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar.online:before,
.avatar.offline:before {
  position: absolute;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  inset-inline-end: 0;
  inset-block-end: 0;
  border: 2px solid #FFF !important;
  z-index: 1;
}

.avatar.online:before {
  background-color: #5CB85C;
}

.avatar.offline:before {
  background-color: #6F7A7A;
}

.avatar.avatar-xs {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
}

.avatar.avatar-xs .avatar-badge {
  padding: 0.25rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
  inset-block-start: -25%;
  inset-inline-end: -0.5rem;
}

.avatar.avatar-sm {
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: 0.65rem;
}

.avatar.avatar-sm .avatar-badge {
  padding: 0.3rem;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.5rem;
  inset-block-start: -28%;
  inset-inline-end: -0.45rem;
}

.avatar.avatar-sm.online:before,
.avatar.avatar-sm.offline:before {
  width: 0.5rem;
  height: 0.5rem;
}

.avatar.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}

.avatar.avatar-md .avatar-badge {
  padding: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.65rem;
  inset-block-start: -6%;
  inset-inline-end: -13%;
}

.avatar.avatar-md.online:before,
.avatar.avatar-md.offline:before {
  width: 0.75rem;
  height: 0.75rem;
}

.avatar.avatar-md svg {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
}

.avatar.avatar-lg .avatar-badge {
  inset-block-start: -15%;
  inset-inline-end: -0.25%;
}

.avatar.avatar-lg.online:before,
.avatar.avatar-lg.offline:before {
  width: 0.8rem;
  height: 0.8rem;
}

.avatar.avatar-lg svg {
  width: 1.8rem;
  height: 1.8rem;
}

.avatar.avatar-xl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.25rem;
}

.avatar.avatar-xl .avatar-badge {
  inset-block-start: -8%;
  inset-inline-end: -0.2%;
}

.avatar.avatar-xl.online:before,
.avatar.avatar-xl.offline:before {
  width: 0.95rem;
  height: 0.95rem;
}

.avatar.avatar-xxl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 1.5rem;
}

.avatar.avatar-xxl .avatar-badge {
  inset-block-start: -4%;
  inset-inline-end: 0rem;
}

.avatar.avatar-xxl.online:before,
.avatar.avatar-xxl.offline:before {
  width: 1.05rem;
  height: 1.05rem;
  inset-block-end: 0.25rem;
}

.avatar.avatar-xxxl {
  width: 6rem;
  height: 6rem;
  line-height: 6rem;
  font-size: 1.75rem;
}

.avatar.avatar-xxxl .avatar-badge {
  inset-block-start: -4%;
  inset-inline-end: 0rem;
}

.avatar.avatar-xxxl.online:before,
.avatar.avatar-xxxl.offline:before {
  width: 1.05rem;
  height: 1.05rem;
  inset-block-end: 0.25rem;
}

.avatar-list-stacked {
  padding: 0;
}

.avatar-list-stacked.avatar-group-overlapped .avatar {
  margin-right: -0.875rem;
}

.avatar-list-stacked.avatar-group-overlapped .avatar:hover {
  z-index: 1;
}

.avatar-list-stacked.avatar-group-lg .avatar {
  width: 3.25rem;
  height: 3.25rem;
}

.avatar-list-stacked.avatar-group-lg .avatar>.initial-wrap {
  font-size: 0.95rem;
}

.avatar-list-stacked.avatar-group-lg.avatar-group-overlapped .avatar {
  margin-right: -1rem;
}

.avatar-list-stacked.avatar-group-sm .avatar {
  width: 2rem;
  height: 2rem;
}

.avatar-list-stacked.avatar-group-sm .avatar>.initial-wrap {
  font-size: 0.6rem;
}

.avatar-list-stacked.avatar-group-sm.avatar-group-overlapped .avatar {
  margin-right: -0.625rem;
}

.avatar-list-stacked .avatar {
  margin-inline-end: -0.875rem !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  transition: transform ease 200ms;
}

.avatar-list-stacked .avatar:last-child {
  margin-inline-end: 0 !important;
}

.avatar-list-stacked .avatar:hover {
  z-index: 1;
  transform: translateY(-0.188rem);
}

.avatar-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.avatar-group .avatar {
  width: 2.625rem;
  height: 2.625rem;
}

.avatar-group .avatar .avatar-img,
.avatar-group .avatar>.initial-wrap {
  border: 2px solid #FFF;
  font-size: 0.9rem;
}

.avatar-group.avatar-group-overlapped .avatar {
  margin-right: -0.875rem;
}

.avatar-group.avatar-group-overlapped .avatar:hover {
  z-index: 1;
}

.avatar-group.avatar-group-lg .avatar {
  width: 3.25rem;
  height: 3.25rem;
}

.avatar-group.avatar-group-lg .avatar>.initial-wrap {
  font-size: 0.95rem;
}

.avatar-group.avatar-group-lg.avatar-group-overlapped .avatar {
  margin-right: -1rem;
}

.avatar-group.avatar-group-sm .avatar {
  width: 2rem;
  height: 2rem;
}

.avatar-group.avatar-group-sm .avatar>.initial-wrap {
  font-size: 0.6rem;
}

.avatar-group.avatar-group-sm.avatar-group-overlapped .avatar {
  margin-right: -0.625rem;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #FDA700;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #FDA700;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: transparent;
}

.bor-b1 {
  border-bottom: 1px solid #898989;
}

.btn-primary {
  color: #FFF;
  background-color: #E41F07;
  border-color: #E41F07;
  font-size: 14px;
  font-size: 14px;
}

.btn-save {
  background: #E41F07;
  color: #FFF;
  font-size: 14px;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-save:hover {
  background: #b31805;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .btn-save {
    min-width: auto;
    padding: 5px 7px;
  }
}

.btn-reset {
  background: #6F6F6F;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  padding: 8px 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-reset:hover {
  background: #FFA201;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .btn-reset {
    min-width: auto;
    padding: 5px 7px;
  }
}

.btn-send {
  background: #E41F07;
  padding: 9px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px;
  border-radius: 8px;
}

.btn-send:hover {
  background: #b31805;
  color: #FFF;
}

.btn-send .send-mail {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.btn-download {
  background: #FFA201;
  padding: 9px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px;
  border-radius: 8px;
}

.btn-download:hover {
  background: #262A2A;
  color: #FFF;
}

.btn-download .download {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.btn-barcode {
  background: #FC0027;
  padding: 9px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-right: 15px;
  border-radius: 8px;
}

.btn-barcode:hover {
  background: #FC0027;
  color: #FFF;
}

.btn-barcode .print {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.btn-primary.add-em-payroll {
  padding: 9px 11px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
}

.btn-primary.add-em-payroll .me-2 {
  width: 16px;
  height: 16px;
}

.swal2-actions .swal2-confirm.btn:active {
  background-color: #E41F07;
  border-color: #E41F07;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #E41F07;
  border-color: #E41F07;
  color: #FFF;
}

.modal .card {
  border: 0;
}

.search-input .btn-searchset:active {
  background-color: transparent;
  border-color: transparent;
}

.search-input .btn-searchset:active svg {
  color: #FFA201;
}

.input-blocks input[type=text].datetimepicker {
  padding-left: 35px;
}

.iti__country-list {
  z-index: 101;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  border-radius: 3px;
  /* padding: 0.625rem 1.25rem; */
  /* padding: 0.5rem 0.85rem; */
  padding: 7px 10px;
  font-size: 0.85rem;
  transition: all 0.5s;
  font-weight: 600;
  box-shadow: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn:hover {
  transition: all 0.5s;
}

.btn.btn-lg {
  padding: 0.65rem 1rem;
  font-size: 0.95rem;
}

.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.btn.btn-primary {
  /* background-color: #E41F07; */
  /* border: 1px solid #E41F07; */
  color: #FFF;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  /* background-color: #c11a06; */
  /* border: 1px solid #c11a06; */
  /* color: #FFF; */
}

.btn {
  text-transform: none !important;
}

.btn.btn-secondary {
  background-color: #FFA201;
  border: 1px solid #b065c8 !important;
  color: #FFF;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary.focus,
.btn.btn-secondary:active,
.btn.btn-secondary.active {
  background-color: #dc8c00;
  border: 1px solid #dc8c00;
  color: #FFF !important;
}

.btn.btn-success {
  background-color: #5CB85C;
  border: 1px solid #5CB85C;
  color: #FFF;
}

.btn.buttonhover {
  background-color: #1C2E4A;
  border: 1px solid #1C2E4A;
  color: #FFF;
}

.btn.buttonhover:hover {
  background-color: #506EE4;
  border: 1px solid #506EE4;
  color: #FFF;
}

.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:active,
.btn.btn-success.active {
  background-color: #49a749;
  border: 1px solid #49a749;
  color: #FFF;
}

.btn.btn-info {
  background-color: #1ECBE2;
  border: 1px solid #1ECBE2;
  color: #FFF;
}

.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info.focus,
.btn.btn-info:active,
.btn.btn-info.active {
  background-color: #19afc3;
  border: 1px solid #19afc3;
  color: #FFF;
}

.btn.btn-warning {
  background-color: #FDA700;
  border: 1px solid #FDA700;
  color: #FFF;
}

.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:active,
.btn.btn-warning.active {
  background-color: #d98f00;
  border: 1px solid #d98f00;
  color: #FFF;
}

.btn.btn-danger {
  background-color: #FC0027;
  border: 1px solid #FC0027;
  color: #FFF;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:active,
.btn.btn-danger.active {
  background-color: #d80021;
  border: 1px solid #d80021;
  color: #FFF;
}

.btn.btn-dark {
  background-color: #171724;
  border: 1px solid #171724;
  color: #FFF;
}

.btn.btn-dark:hover,
.btn.btn-dark:focus,
.btn.btn-dark.focus,
.btn.btn-dark:active,
.btn.btn-dark.active {
  background-color: #09090e;
  border: 1px solid #09090e;
  color: #FFF;
}

.btn.btn-light {
  background-color: #E9EDF4 !important;
  border: 1px solid #E9EDF4;
  color: #515B73;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light.focus,
.btn.btn-light:active,
.btn.btn-light.active {
  background-color: #e5e5f2;
  border: 1px solid #e5e5f2;
  color: #FFF;
}

.btn.btn-black {
  background-color: #000;
  border: 1px solid #000;
  color: #FFF;
}

.btn.btn-black:hover,
.btn.btn-black:focus,
.btn.btn-black.focus,
.btn.btn-black:active,
.btn.btn-black.active {
  background-color: black;
  border: 1px solid black;
  color: #FFF;
}

.btn.btn-purple {
  background-color: #4A00E5;
  border: 1px solid #4A00E5;
  color: #FFF;
}

.btn.btn-purple:hover,
.btn.btn-purple:focus,
.btn.btn-purple.focus,
.btn.btn-purple:active,
.btn.btn-purple.active {
  background-color: #3e00c1;
  border: 1px solid #3e00c1;
  color: #FFF;
}

.btn.btn-yellow {
  background-color: #ffff00;
  border: 1px solid #ffff00;
  color: #FFF;
}

.btn.btn-yellow:hover,
.btn.btn-yellow:focus,
.btn.btn-yellow.focus,
.btn.btn-yellow:active,
.btn.btn-yellow.active {
  background-color: #dbdb00;
  border: 1px solid #dbdb00;
  color: #FFF;
}

.btn.btn-teal {
  background-color: #02a8b5;
  border: 1px solid #02a8b5;
  color: #FFF;
}

.btn.btn-teal:hover,
.btn.btn-teal:focus,
.btn.btn-teal.focus,
.btn.btn-teal:active,
.btn.btn-teal.active {
  background-color: #028792;
  border: 1px solid #028792;
  color: #FFF;
}

.btn-group .btn {
  padding: 0.45rem 0.75rem;
}

.btn-group-lg .btn {
  padding: 0.65rem 1rem;
}

.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
}

.btn.btn-w-xs {
  min-width: 5.625rem;
}

.btn.btn-w-sm {
  min-width: 6.975rem;
}

.btn.btn-w-md {
  min-width: 8.125rem;
}

.btn.btn-w-lg {
  min-width: 9.375rem;
}

.btn-icon i {
  padding: 0rem;
  margin: -0.5rem;
}

.btn-icon.btn-sm {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.8rem;
}

.btn-icon.btn-lg {
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.2rem;
}

.btn-list>button,
.btn-list>a {
  margin: 0 0.375rem 0.375rem 0;
}

[dir=rtl] .btn-list button,
[dir=rtl] .btn-list div,
[dir=rtl] .btn-list a,
[dir=rtl] .btn-list input {
  margin: 0 0 0.375rem 0.375rem;
}

.btn-list {
  margin-block-end: -0.5rem;
}

.btn-square {
  border-radius: 0;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}

.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}

.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn.btn-primary-light {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.btn.btn-primary-light:hover,
.btn.btn-primary-light:focus,
.btn.btn-primary-light:active {
  background-color: #E41F07;
  color: #FFF;
  border-color: #E41F07;
}

.btn.btn-secondary-light {
  background-color: rgba(255, 162, 1, 0.1);
  color: #FFA201;
}

.btn.btn-secondary-light:hover,
.btn.btn-secondary-light:focus,
.btn.btn-secondary-light:active {
  background-color: #FFA201;
  color: #FFF;
  border-color: #FFA201;
}

.btn.btn-warning-light {
  background-color: rgba(253, 167, 0, 0.1);
  color: #FDA700;
}

.btn.btn-warning-light:hover,
.btn.btn-warning-light:focus,
.btn.btn-warning-light:active {
  background-color: #FDA700;
  color: #FFF;
  border-color: #FDA700;
}

.btn.btn-info-light {
  background-color: rgba(30, 203, 226, 0.1);
  color: #1ECBE2;
}

.btn.btn-info-light:hover,
.btn.btn-info-light:focus,
.btn.btn-info-light:active {
  background-color: #1ECBE2;
  color: #FFF;
  border-color: #1ECBE2;
}

.btn.btn-success-light {
  background-color: rgba(92, 184, 92, 0.1);
  color: #5CB85C;
}

.btn.btn-success-light:hover,
.btn.btn-success-light:focus,
.btn.btn-success-light:active {
  background-color: #5CB85C;
  color: #FFF;
  border-color: #5CB85C;
}

.btn.btn-danger-light {
  background-color: rgba(252, 0, 39, 0.1);
  color: #FC0027;
}

.btn.btn-danger-light:hover,
.btn.btn-danger-light:focus,
.btn.btn-danger-light:active {
  background-color: #FC0027;
  color: #FFF;
  border-color: #FC0027;
}

.btn.btn-primary-ghost {
  background-color: transparent;
  color: #E41F07;
  border: 1px solid #E41F07;
}

.btn.btn-primary-ghost:active {
  color: #E41F07;
  border: 1px solid #E41F07;
  background-color: transparent;
}

.btn.btn-dark {
  background: #131022 !important;
  border-color: #131022 !important;
  box-shadow: none;
  color: #fff;
}

.btn.btn-dark:hover {
  background: #E41F07;
}

.btn.btn-secondary-ghost {
  background-color: transparent;
  color: #FFA201;
  border: 1px solid #FFA201;
}

.btn.btn-secondary-ghost:active {
  color: #FFA201;
  border: 1px solid #FFA201;
  background-color: transparent;
}

.btn.btn-warning-ghost {
  background-color: transparent;
  color: #FDA700;
  border: 1px solid #FDA700;
}

.btn.btn-warning-ghost:active {
  color: #FDA700;
  border: 1px solid #FDA700;
  background-color: transparent;
}

.btn.btn-info-ghost {
  background-color: transparent;
  color: #1ECBE2;
  border: 1px solid #1ECBE2;
}

.btn.btn-info-ghost:active {
  color: #1ECBE2;
  border: 1px solid #1ECBE2;
  background-color: transparent;
}

.btn.btn-success-ghost {
  background-color: transparent;
  color: #5CB85C;
  border: 1px solid #5CB85C;
}

.btn.btn-success-ghost:active {
  color: #5CB85C;
  border: 1px solid #5CB85C;
  background-color: transparent;
}

.btn.btn-danger-ghost {
  background-color: transparent;
  color: #FC0027;
  border: 1px solid #FC0027;
}

.btn.btn-danger-ghost:active {
  color: #FC0027;
  border: 1px solid #FC0027;
  background-color: transparent;
}

.btn-list button {
  margin: 0 0.375rem 0.375rem 0;
}

.btn-outline-primary {
  border: 1px solid #E41F07 !important;
  color: #E41F07 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  background-color: #E41F07 !important;
  border: 1px solid #E41F07 !important;
  box-shadow: 0 3px 10px rgba(228, 31, 7, 0.5);
  color: #FFF !important;
}

.btn-outline-secondary {
  border: 1px solid #FFA201 !important;
  color: #FFA201 !important;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active {
  background-color: #FFA201 !important;
  border: 1px solid #FFA201 !important;
  box-shadow: 0 3px 10px rgba(255, 162, 1, 0.5);
  color: #FFF !important;
}

.btn-outline-success {
  border: 1px solid #5CB85C !important;
  color: #5CB85C !important;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus,
.btn-outline-success:active,
.btn-outline-success.active {
  background-color: #5CB85C !important;
  border: 2px solid #5CB85C !important;
  box-shadow: 0 3px 10px rgba(92, 184, 92, 0.5);
  color: #FFF !important;
}

.btn-outline-danger {
  border: 1px solid #FC0027 !important;
  color: #FC0027 !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus,
.btn-outline-danger:active,
.btn-outline-danger.active {
  background-color: #FC0027 !important;
  border: 1px solid #FC0027 !important;
  box-shadow: 0 3px 10px rgba(252, 0, 39, 0.5);
  color: #FFF !important;
}

.btn-outline-warning {
  border: 1px solid #FDA700 !important;
  color: #FDA700 !important;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus,
.btn-outline-warning:active,
.btn-outline-warning.active {
  background-color: #FDA700 !important;
  border: 1px solid #FDA700 !important;
  box-shadow: 0 3px 10px rgba(253, 167, 0, 0.5);
  color: #FFF !important;
}

.btn-outline-info {
  border: 1px solid #1ECBE2 !important;
  color: #1ECBE2 !important;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus,
.btn-outline-info:active,
.btn-outline-info.active {
  background-color: #1ECBE2 !important;
  border: 1px solid #1ECBE2 !important;
  box-shadow: 0 3px 10px rgba(30, 203, 226, 0.5);
  color: #FFF !important;
}

.btn-outline-light {
  border: 1px solid #FDFDFE;
  color: #515B73 !important;

}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light.focus,
.btn-outline-light:active,
.btn-outline-light.active {
  background-color: #FDFDFE;
  border: 1px solid #FDFDFE;
  color: #171724 !important;
}

.btn-outline-dark {
  border: 1px solid #171724 !important;
  color: #171724 !important;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark.focus,
.btn-outline-dark:active,
.btn-outline-dark.active {
  background-color: #171724 !important;
  border: 1px solid #171724 !important;
  box-shadow: 0 3px 10px rgba(23, 23, 36, 0.5);
  color: #FFF !important;
}

.btn-soft-primary {
  background-color: #F7BCB5 !important;
  color: #E41F07 !important;
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary.focus,
.btn-soft-primary:active,
.btn-soft-primary.active {
  box-shadow: 0 3px 12px rgba(228, 31, 7, 0.2);
  border-color: #E41F07;
}

.btn-soft-secondary {
  background-color: #FFE3B3 !important;
  color: #FFA201 !important;
}

.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary.focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active {
  box-shadow: 0 3px 12px rgba(255, 162, 1, 0.2);
  border-color: #FFA201;
}

.btn-soft-success {
  background-color: #CEEACE !important;
  color: #5CB85C !important;
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success.focus,
.btn-soft-success:active,
.btn-soft-success.active {
  box-shadow: 0 3px 12px rgba(92, 184, 92, 0.2);
  border-color: #5CB85C;
}

.btn-soft-danger {
  background-color: #FEB3BE !important;
  color: #FC0027 !important;
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger.focus,
.btn-soft-danger:active,
.btn-soft-danger.active {
  box-shadow: 0 3px 12px rgba(252, 0, 39, 0.2);
  border-color: #FC0027;
}

.btn-soft-warning {
  background-color: #FEE5B3 !important;
  color: #FDA700 !important;
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning.focus,
.btn-soft-warning:active,
.btn-soft-warning.active {
  box-shadow: 0 3px 12px rgba(253, 167, 0, 0.2);
  border-color: #FDA700;
}

.btn-soft-info {
  background-color: rgba(30, 203, 226, 0.3) !important;
  color: #1ECBE2 !important;
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info.focus,
.btn-soft-info:active,
.btn-soft-info.active {
  box-shadow: 0 3px 12px rgba(30, 203, 226, 0.2);
  border-color: #1ECBE2;
}

.btn-soft-light {
  background-color: rgba(253, 253, 254, 0.9) !important;
  color: #6F6F6F !important;
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light.focus,
.btn-soft-light:active,
.btn-soft-light.active {
  box-shadow: 0 3px 12px rgba(253, 253, 254, 0.2);
  border-color: #FDFDFE;
  border-color: #6F6F6F;
}

.btn-soft-dark {
  background-color: rgba(23, 23, 36, 0.3) !important;
  color: #FFF !important;
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark.focus,
.btn-soft-dark:active,
.btn-soft-dark.active {
  box-shadow: 0 3px 12px rgba(23, 23, 36, 0.2);
  border-color: #171724;
}

.badge.badge-primary {
  background: rgba(228, 31, 7, 0.1);
  box-shadow: 0 1px 1px rgba(228, 31, 7, 0.5);
  color: #E41F07;
}

.badge.badge-primary {
  background: #E41F07;
  box-shadow: 0 1px 1px rgba(228, 31, 7, 0.5);
  color: #FFF;
}

.badge.badge-soft-primary {
  background: rgba(228, 31, 7, 0.1);
  box-shadow: 0 1px 1px rgba(228, 31, 7, 0.5);
  color: #E41F07;
}

.badge.badge-secondary {
  background: #FFA201;
  box-shadow: 0 1px 1px rgba(255, 162, 1, 0.5);
  color: #FFF;
}

.badge.badge-soft-secondary {
  background: rgba(255, 162, 1, 0.1);
  box-shadow: 0 1px 1px rgba(255, 162, 1, 0.5);
  color: #FFA201;
}

.badge.badge-success {
  background: #5CB85C;
  box-shadow: 0 1px 1px rgba(92, 184, 92, 0.5);
  color: #FFF;
}

.badge.badge-soft-success {

  background: rgba(26, 190, 23, 0.1);
  color: #1ABE17 !important;
}

.badge.badge-info {
  background: #1ECBE2;
  box-shadow: 0 1px 1px rgba(30, 203, 226, 0.5);
  color: #FFF;
}

.badge.badge-soft-info {
  background: rgba(30, 203, 226, 0.1);
  box-shadow: 0 1px 1px rgba(30, 203, 226, 0.5);
  color: #1ECBE2;
}

.badge.badge-warning {
  background: #FDA700;
  box-shadow: 0 1px 1px rgba(253, 167, 0, 0.5);
  color: #FFF;
}

.badge.badge-soft-warning {
  background: rgba(253, 167, 0, 0.1);
  box-shadow: 0 1px 1px rgba(253, 167, 0, 0.5);
  color: #FDA700;
}

.badge.badge-danger {
  background: #FC0027;
  box-shadow: 0 1px 1px rgba(252, 0, 39, 0.5);
  color: #FFF;
}

.badge.badge-soft-danger {
  background: rgba(232, 38, 70, 0.1);
  color: #E82646 !important;
}

.badge.badge-dark {
  background: #171724;
  box-shadow: 0 1px 1px rgba(23, 23, 36, 0.5);
  color: #FFF;
}

.badge.badge-soft-dark {
  background: rgba(23, 23, 36, 0.1);
  box-shadow: 0 1px 1px rgba(23, 23, 36, 0.5);
  color: #171724;
}

.badge.badge-light {
  background: #FDFDFE;
  box-shadow: 0 1px 1px rgba(253, 253, 254, 0.5);
  color: #FFF;
}

.badge.badge-soft-light {
  background: rgba(253, 253, 254, 0.1);
  box-shadow: 0 1px 1px rgba(253, 253, 254, 0.5);
  color: #FDFDFE;
}

.badge.badge-black {
  background: #000;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.badge.badge-soft-black {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  color: #000;
}

.badge.badge-purple {
  background: #4A00E5;
  box-shadow: 0 1px 1px rgba(74, 0, 229, 0.5);
  color: #FFF;
}

.badge.badge-soft-purple {
  background: rgba(74, 0, 229, 0.1);
  box-shadow: 0 1px 1px rgba(74, 0, 229, 0.5);
  color: #4A00E5;
}

.badge.badge-yellow {
  background: #ffff00;
  box-shadow: 0 1px 1px rgba(255, 255, 0, 0.5);
  color: #FFF;
}

.badge.badge-soft-yellow {
  background: rgba(255, 255, 0, 0.1);
  box-shadow: 0 1px 1px rgba(255, 255, 0, 0.5);
  color: #ffff00;
}

.badge.badge-teal {
  background: #02a8b5;
  box-shadow: 0 1px 1px rgba(2, 168, 181, 0.5);
  color: #FFF;
}

.badge.badge-soft-teal {
  background: rgba(2, 168, 181, 0.1);
  box-shadow: 0 1px 1px rgba(2, 168, 181, 0.5);
  color: #02a8b5;
}

.badge {
  padding: 0.25rem 0.45rem;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 4px;
  color: #262A2A;
}

.badge.badge-xs {
  font-size: 10px;
  padding: 0px 5px;
  line-height: 18px;
}

.badge.badge-sm {
  font-size: 11px;
  padding: 5px 8px;
  line-height: 11px;
}

.badge.badge-md {
  font-size: 12px;
  padding: 5px 12px;
  line-height: 1.5;
}

.badge.badge-lg {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 30px;
}

.badge.badge-xl {
  font-size: 16px;
  padding: 0px 15px;
  line-height: 35px;
}

.bg-light-danger {
  background-color: #FFEEEC !important;
}

.bg-light-danger .badge-dots {
  background-color: #FC1A3D;
}

.bg-light-success {
  background-color: #EFF8EF !important;
}

.bg-light-success .badge-dots {
  background-color: #6CBF6C;
}

.bg-light-info {
  background-color: #E9FAFC !important;
}

.bg-light-info .badge-dots {
  background-color: #35D0E5;
}

.bg-light-warning {
  background-color: #FFF7D8 !important;
}

.bg-light-warning .badge-dots {
  background-color: #FDB01A;
}

.border-container {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #FDFDFE;
}

.border {
  border: 1px solid #6F6F6F !important;
}

.border.border-dashed {
  border-style: dashed !important;
}

.border.border-dotted {
  border-style: dotted !important;
}

.border.border-primary {
  border: 1px solid #E41F07 !important;
  opacity: 1;
}

.border.border-primary.border-opacity-10 {
  border-color: rgba(228, 31, 7, 0.1) !important;
}

.border.border-primary.border-opacity-25 {
  border-color: rgba(228, 31, 7, 0.25) !important;
}

.border.border-primary.border-opacity-50 {
  border-color: rgba(228, 31, 7, 0.5) !important;
}

.border.border-primary.border-opacity-75 {
  border-color: rgba(228, 31, 7, 0.75) !important;
}

.border.border-primary.border-opacity-100 {
  border-color: #e41f07 !important;
}

.border.border-secondary {
  border: 1px solid #FFA201 !important;
  opacity: 1;
}

.border.border-secondary.border-opacity-10 {
  border-color: rgba(255, 162, 1, 0.1) !important;
}

.border.border-secondary.border-opacity-25 {
  border-color: rgba(255, 162, 1, 0.25) !important;
}

.border.border-secondary.border-opacity-50 {
  border-color: rgba(255, 162, 1, 0.5) !important;
}

.border.border-secondary.border-opacity-75 {
  border-color: rgba(255, 162, 1, 0.75) !important;
}

.border.border-secondary.border-opacity-100 {
  border-color: #ffa201 !important;
}

.border.border-info {
  border: 1px solid #1ECBE2 !important;
  opacity: 1;
}

.border.border-info.border-opacity-10 {
  border-color: rgba(30, 203, 226, 0.1) !important;
}

.border.border-info.border-opacity-25 {
  border-color: rgba(30, 203, 226, 0.25) !important;
}

.border.border-info.border-opacity-50 {
  border-color: rgba(30, 203, 226, 0.5) !important;
}

.border.border-info.border-opacity-75 {
  border-color: rgba(30, 203, 226, 0.75) !important;
}

.border.border-info.border-opacity-100 {
  border-color: #1ecbe2 !important;
}

.border.border-success {
  border: 1px solid #5CB85C !important;
  opacity: 1;
}

.border.border-success.border-opacity-10 {
  border-color: rgba(92, 184, 92, 0.1) !important;
}

.border.border-success.border-opacity-25 {
  border-color: rgba(92, 184, 92, 0.25) !important;
}

.border.border-success.border-opacity-50 {
  border-color: rgba(92, 184, 92, 0.5) !important;
}

.border.border-success.border-opacity-75 {
  border-color: rgba(92, 184, 92, 0.75) !important;
}

.border.border-success.border-opacity-100 {
  border-color: #5cb85c !important;
}

.border.border-warning {
  border: 1px solid #FDA700 !important;
  opacity: 1;
}

.border.border-warning.border-opacity-10 {
  border-color: rgba(253, 167, 0, 0.1) !important;
}

.border.border-warning.border-opacity-25 {
  border-color: rgba(253, 167, 0, 0.25) !important;
}

.border.border-warning.border-opacity-50 {
  border-color: rgba(253, 167, 0, 0.5) !important;
}

.border.border-warning.border-opacity-75 {
  border-color: rgba(253, 167, 0, 0.75) !important;
}

.border.border-warning.border-opacity-100 {
  border-color: #fda700 !important;
}

.border.border-danger {
  border: 1px solid #FC0027 !important;
  opacity: 1;
}

.border.border-danger.border-opacity-10 {
  border-color: rgba(252, 0, 39, 0.1) !important;
}

.border.border-danger.border-opacity-25 {
  border-color: rgba(252, 0, 39, 0.25) !important;
}

.border.border-danger.border-opacity-50 {
  border-color: rgba(252, 0, 39, 0.5) !important;
}

.border.border-danger.border-opacity-75 {
  border-color: rgba(252, 0, 39, 0.75) !important;
}

.border.border-danger.border-opacity-100 {
  border-color: #fc0027 !important;
}

.border.border-white {
  border: 1px solid #FFF !important;
  opacity: 1;
}

.border.border-white.border-opacity-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border.border-white.border-opacity-25 {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.border.border-white.border-opacity-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border.border-white.border-opacity-75 {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border.border-white.border-opacity-100 {
  border-color: white !important;
}

.border.border-light {
  border: 1px solid #FDFDFE !important;
  opacity: 1;
}

.border.border-light.border-opacity-10 {
  border-color: rgba(253, 253, 254, 0.1) !important;
}

.border.border-light.border-opacity-25 {
  border-color: rgba(253, 253, 254, 0.25) !important;
}

.border.border-light.border-opacity-50 {
  border-color: rgba(253, 253, 254, 0.5) !important;
}

.border.border-light.border-opacity-75 {
  border-color: rgba(253, 253, 254, 0.75) !important;
}

.border.border-light.border-opacity-100 {
  border-color: #fdfdfe !important;
}

.border.border-dark {
  border: 1px solid #171724 !important;
  opacity: 1;
}

.border.border-dark.border-opacity-10 {
  border-color: rgba(23, 23, 36, 0.1) !important;
}

.border.border-dark.border-opacity-25 {
  border-color: rgba(23, 23, 36, 0.25) !important;
}

.border.border-dark.border-opacity-50 {
  border-color: rgba(23, 23, 36, 0.5) !important;
}

.border.border-dark.border-opacity-75 {
  border-color: rgba(23, 23, 36, 0.75) !important;
}

.border.border-dark.border-opacity-100 {
  border-color: #171724 !important;
}

.border.border-orange {
  border: 1px solid #FF9D0A !important;
  opacity: 1;
}

.border.border-orange.border-opacity-10 {
  border-color: rgba(255, 157, 10, 0.1) !important;
}

.border.border-orange.border-opacity-25 {
  border-color: rgba(255, 157, 10, 0.25) !important;
}

.border.border-orange.border-opacity-50 {
  border-color: rgba(255, 157, 10, 0.5) !important;
}

.border.border-orange.border-opacity-75 {
  border-color: rgba(255, 157, 10, 0.75) !important;
}

.border.border-orange.border-opacity-100 {
  border-color: #ff9d0a !important;
}

.border.border-opacity-10 {
  --bs-border-opacity: 0.1 !important;
}

.border.border-opacity-25 {
  --bs-border-opacity: 0.25 !important;
}

.border.border-opacity-50 {
  --bs-border-opacity: 0.5 !important;
}

.border.border-opacity-75 {
  --bs-border-opacity: 0.75 !important;
}

.border.border-opacity-100 {
  --bs-border-opacity: 1 !important;
}

.border.border-1 {
  border-width: 1px !important;
}

.border.border-2 {
  border-width: 2px !important;
}

.border.border-3 {
  border-width: 3px !important;
}

.border.border-4 {
  border-width: 4px !important;
}

.border.border-5 {
  border-width: 5px !important;
}

.border.border-block-start-dashed {
  border-top-style: dashed;
}

.border.border-block-start-dotted {
  border-top-style: dotted;
}

.border.border-inline-end-dashed {
  border-inline-end-style: dashed;
}

.border.border-inline-end-dotted {
  border-inline-end-style: dotted;
}

.border.border-block-end-dashed {
  border-bottom-style: dashed;
}

.border.border-block-end-dotted {
  border-bottom-style: dotted;
}

.border.border-inline-start-dashed {
  border-inline-start-style: dashed;
}

.border.border-inline-start-dotted {
  border-inline-start-style: dotted;
}

.border.border-0 {
  border: 0 !important;
}

.border.border-top-0 {
  border-block-start: 0 !important;
}

.border.border-end-0 {
  border-inline-end: 0 !important;
}

.border.border-bottom-0 {
  border-block-end: 0 !important;
}

.border.border-start-0 {
  border-inline-start: 0 !important;
}

.border-top {
  border-top: 1px solid #6F6F6F !important;
}

.border-top.border-block-start-dashed {
  border-top-style: dashed !important;
}

.border-top.border-block-start-dotted {
  border-top-style: dotted !important;
}

.border-top.border-primary {
  border-color: #E41F07 !important;
}

.border-top.border-secondary {
  border-color: #FFA201 !important;
}

.border-top.border-warning {
  border-color: #FDA700 !important;
}

.border-top.border-info {
  border-color: #1ECBE2 !important;
}

.border-top.border-success {
  border-color: #5CB85C !important;
}

.border-top.border-danger {
  border-color: #FC0027 !important;
}

.border-top.border-light {
  border-color: #FDFDFE !important;
}

.border-top.border-dark {
  border-color: #171724 !important;
}

.border-end {
  border-inline-end: 1px solid #6F6F6F !important;
}

.border-end.border-inline-end-dashed {
  border-inline-end-style: dashed !important;
}

.border-end.border-inline-end-dotted {
  border-inline-end-style: dotted !important;
}

.border-end.border-primary {
  border-color: #E41F07 !important;
}

.border-end.border-secondary {
  border-color: #FFA201 !important;
}

.border-end.border-warning {
  border-color: #FDA700 !important;
}

.border-end.border-info {
  border-color: #1ECBE2 !important;
}

.border-end.border-success {
  border-color: #5CB85C !important;
}

.border-end.border-danger {
  border-color: #FC0027 !important;
}

.border-end.border-light {
  border-color: #FDFDFE !important;
}

.border-end.border-dark {
  border-color: #171724 !important;
}

.border-bottom {
  border-bottom: 1px solid #6F6F6F !important;
}

.border-bottom.border-block-end-dashed {
  border-bottom-style: dashed !important;
}

.border-bottom.border-block-end-dotted {
  border-bottom-style: dotted !important;
}

.border-bottom.border-primary {
  border-color: #E41F07 !important;
}

.border-bottom.border-secondary {
  border-color: #FFA201 !important;
}

.border-bottom.border-warning {
  border-color: #FDA700 !important;
}

.border-bottom.border-info {
  border-color: #1ECBE2 !important;
}

.border-bottom.border-success {
  border-color: #5CB85C !important;
}

.border-bottom.border-danger {
  border-color: #FC0027 !important;
}

.border-bottom.border-light {
  border-color: #FDFDFE !important;
}

.border-bottom.border-dark {
  border-color: #171724 !important;
}

.border-start {
  border-inline-start: 1px solid #6F6F6F !important;
}

.border-start.border-inline-start-dashed {
  border-inline-start-style: dashed !important;
}

.border-start.border-inline-start-dotted {
  border-inline-start-style: dotted !important;
}

.border-start.border-primary {
  border-color: #E41F07 !important;
}

.border-start.border-secondary {
  border-color: #FFA201 !important;
}

.border-start.border-warning {
  border-color: #FDA700 !important;
}

.border-start.border-info {
  border-color: #1ECBE2 !important;
}

.border-start.border-success {
  border-color: #5CB85C !important;
}

.border-start.border-danger {
  border-color: #FC0027 !important;
}

.border-start.border-light {
  border-color: #FDFDFE !important;
}

.border-start.border-dark {
  border-color: #171724 !important;
}

.br-1 {
  border-radius: 0.0625rem;
}

.br-2 {
  border-radius: 0.125rem;
}

.br-3 {
  border-radius: 0.1875rem;
}

.br-4 {
  border-radius: 0.25rem;
}

.br-5 {
  border-radius: 0.3125rem;
}

.br-6 {
  border-radius: 0.375rem;
}

.br-7 {
  border-radius: 0.4375rem;
}

.br-8 {
  border-radius: 0.5rem;
}

.br-9 {
  border-radius: 0.5625rem;
}

.br-10 {
  border-radius: 0.625rem;
}

.br-15 {
  border-radius: 0.9375rem;
}

.br-20 {
  border-radius: 1.25rem;
}

.rounded {
  border-radius: 4px !important;
}

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #898989;
  background-color: #FFF;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #FFF;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #898989;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #E41F07 !important;
  color: #FFF !important;
  text-shadow: none !important;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #FFF;
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #FFF !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.event-form .input-group .form-control {
  height: 40px;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

.apexcharts-legend.apexcharts-align-center.position-right {
  display: none;
}

.apexcharts-toolbar {
  display: none !important;
}

#sales_charts,
.chart-set {
  overflow: hidden;
}

.apexcharts-canvas {
  width: 100% !important;
}

.h-250 {
  height: 250px;
}

.chat-user-actives .call-meet-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: #FFF;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.chat-user-actives .call-meet-blk:hover {
  background-color: #000;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.chat-user-actives .call-meet-blk .meet-border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  width: 39px;
  height: 39px;
  background: #FFF;
  border: 0.5px solid #898989;
  border-radius: 10px;
  margin-right: 10px;
}

.chat-user-actives .call-meet-blk .meet-border svg {
  width: 16px;
  color: #339DFF;
}

.chat-user-actives .call-meet-blk .recent-user-titles h5 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-user-actives .call-meet-blk .recent-user-titles p {
  font-size: 10px;
  color: #6F6F6F;
}

.new-join-link .chat-user-actives .meet-border {
  background: gray;
  border: 0;
  border-radius: 6px;
}

.new-join-link .chat-user-actives .meet-border:hover {
  background: #E41F07;
}

.new-join-link .chat-user-actives .meet-border:hover svg {
  color: #FFF;
}

.new-join-link .chat-user-actives .meet-border svg {
  color: #E41F07;
}

.new-join-link .chat-user-actives .recent-user-titles h5 {
  color: #E41F07;
  width: 100%;
  margin-bottom: 0;
  font-size: 15px;
}

.meet-chat-blk .join-call .join-video {
  position: relative;
  margin-bottom: 25px;
}

.meet-chat-blk .join-call .join-video img {
  border-radius: 10px;
}

.meet-chat-blk .join-call .join-video .video-avatar {
  color: #FFF;
  background: #5D5D5D;
  font-size: 50px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.meet-chat-blk .join-call .join-video .video-avatar.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 426px;
}

.meet-chat-blk .join-call .join-video .call-icons {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.meet-chat-blk .join-call .join-video .call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item:last-child {
  margin-right: 0px;
}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item a {
  color: #FFF;
  background: #FFF;
  border-radius: 10px;
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 20px;
  white-space: nowrap;
}

@media (max-width: 767.98px) {


  body .header .header-left .logo img.school_logo {
    width: 40px;
    object-fit: contain !important;
  }

  .img_logo img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 0 auto;
    text-align: center;
    border-radius: 70px;
  }

  body .img_logo {
    text-align: center;
    display: block !important;
  }

  body .new_data .form-check-input {
    margin-top: -0.5em;
    position: unset;
    margin-left: 0;
  }

  body .grid-container-singleView {
    padding-bottom: 30px;
  }

  body .new_data label {
    margin-bottom: 0;
  }

  body .new_data {
    flex-flow: column;
    gap: 0px !important;
    align-items: flex-start !important;
  }

  body .d-flex.point_div {
    flex-flow: column !important;
    text-align: left !important;
    gap: 8px !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  button.btn.float-end {
    float: inline-start !important;
    margin-left: 0;
  }

  body .ResponseType {
    flex-flow: column;
    gap: 11px;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .all_data {
    display: flex;
    flex-flow: wrap;
    gap: 6px;
    margin-top: 10px;
  }

  .repetdays {
    display: flex;
    flex-flow: wrap;
  }

  .row.stage_section .col {
    flex: unset;
  }

  .Teacher_section.demo input {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0 !important;
    /* padding: 10px 0 !important; */
  }

  .Teacher_section.demo {
    flex-flow: column;
    width: 100%;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  body .all_list_div {
    flex-flow: column;
    justify-content: left;
    align-items: flex-start !important;
  }


  body .Teacher_Type button.btn {
    margin-left: 0 !important;
  }

  body .Teacher_Type {
    flex-flow: column;
    justify-content: left !important;
    text-align: left !important;
    align-items: flex-start !important;
  }

  body .page-wrapper .content {
    padding: 8px;
  }

  h4.mt-5.mini_text {
    font-size: 14px;
    margin-top: 10px !important;
  }

  input#validationCustom03 {
    width: 100% !important;
    max-width: 100% !important;
  }

  /* body .student_view {
    padding: 15px;
  } */

  body ul.ul_btn li button {
    max-width: 100%;
  }

  body ul.ul_btn li {
    width: 100%;
  }


  body .new_input {
    width: 100% !important;
    margin-left: 0 !important;
  }

  a.dropdown-toggle.d-flex.flex-column.align-items-center {
    position: relative;
  }

  a.dropdown-toggle.d-flex.flex-column.align-items-center:before {

    content: "";
    position: absolute;
    color: #1c2d4a;
    top: -1px;
    left: 19px;
    font-size: 23px;
    /* transform: rotate(85deg); */
    background-image: url(https://e7.pngegg.com/pngimages/217/548/png-clipart-drop-down-list-computer-icons-arrow-menu-hamburger-button-arrow-angle-rectangle-thumbnail.png);
    width: 20px;
    height: 20px;
    background-size: cover;
  }


  .page-wrapper .content {
    padding-top: 15px !important;
  }

  .d-flex.content-assign.pb-4 .col-md-6 {
    padding-left: 0;
  }

  .Mark_Own_Attendance.space_btn {
    padding-bottom: 50px !important;
  }

  button.btn.btn-primary.filter_btn {
    height: 42px;
    margin: 0;
  }

  .box-margin {
    text-align: end;
  }

  .Mark_Own_Attendance .form-control {
    width: 100% !important;
  }

  .Mark_Own_Attendance .react-datepicker-wrapper {
    width: 100%;
  }

  .d-flex.withdraw_btn,
  body .space_div {
    padding-bottom: 40px !important;
  }


  button.btn.btn-primary.mobile_btn_hide {
    display: none;
  }

  button.btn.btn-primary.d-none.dektop_btn_hide {
    display: block !important;
  }

  body .Teacher_Leave_mobile .react-datepicker__input-container input[type="text"],
  body .Teacher_Leave_mobile .d-flex.flex-column,
  body .Teacher_Leave_mobile select.form-control.form-select {
    width: 100% !important;
  }

  body .Assign_section input.form-control {
    width: 100% !important;
  }

  body .Assign_section button.btn.btn-primary {
    height: 42px;
    margin: 0;
  }

  body button.mobile_btn_sec {
    margin-left: 0 !important;
  }


  body .Assign_section {
    margin-bottom: 40px;
  }

  body .mobile_section {
    padding-bottom: 40px;
  }


  .mobile_section .border.border-dark.rounded {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .filters.d-flex.gap-3.my-3.align-items-center.flex-wrap select.form-control.form-select {
    width: 100% !important;
  }

  /* body .page-wrapper .content {
    padding: 0px;
  } */

  td.ant-table-cell {
    white-space: pre;
  }

  body .ant-table-wrapper {
    margin-top: 15px;
  }

  body ul.ul_btn {
    margin-top: 9px;
    /* padding: 0; */
  }

  span.d-none.mobile_block {
    display: block !important;
  }

  body .row.justify-content-center.align-items-center.vh-100.overflow-auto.flex-wrap {
    height: 100vh !important;
  }

  body .header .header-user {
    display: flex !important;
    justify-content: right;
  }



  .mbil_css .justify-content-end,
  .mb-2.ms-auto .justify-content-end {
    justify-content: left !important;
  }


  .filters.d-flex.gap-3.mb-3.align-items-center.flex-wrap .d-flex.flex-column,
  .filters.d-flex.gap-3.mb-3.align-items-center select.form-control.form-select,
  body .react-datepicker__input-container input[type="text"] {
    width: 100% !important;
  }

  body .d-flex.justify-content-between.align-items-center.mb-4 {
    flex-flow: column;
    text-align: center;
    gap: 20px;
  }

  body .display-5 {
    font-size: 20px;
  }

  body .page-wrapper {
    padding: 89px 0 0;
  }

  .meet-chat-blk .join-call .join-video .call-icons .call-items .call-item a {
    width: 45px;
    height: 45px;
  }

  .card.shadow {
    height: auto;
  }

  .d-flex.menu_flex {
    flex-flow: column;
    align-items: baseline;
  }

  body h1.labelText {
    font-size: 14px;
  }


  .accordion-body {
    padding: 0;

  }

  /* 
  body .ant-table-wrapper {
    margin-top: 130px;
  } */

  .div-table.mt-4 {
    overflow-x: scroll;
    margin-top: 0 !important;
  }


  .parameters .progress.mb-4 {
    margin-bottom: 0 !important;
  }


}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item a i {
  color: #6F6F6F;
}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item a.stop {
  color: #FFF;
  background: #FC0027;
}

.meet-chat-blk .join-call .join-video .call-icons .call-items .call-item a.stop i {
  color: #FFF;
}

.meet-chat-blk .join-call .join-video .meet-drop ul li i {
  font-size: 18px;
}

.meet-chat-blk .join-call .join-content {
  text-align: center;
  max-width: 500px;
  margin: auto;
}

.meet-chat-blk .join-call .join-content h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {



  body .Back_btn .btn {
    margin-left: 0 !important;
  }

  .Teacher_section select.form-select,
  .Teacher_section input {
    width: 100% !important;
  }


  body .modal-header button.btn.btn-close {

    height: 22px !important;

  }

  body .modal {
    top: 40%;
  }

  body .TeacherListing .col-md-6 {
    padding-left: 0;
  }

  body .Back_btn {
    flex-flow: wrap;
  }

  body .container-fluid.father-info,
  body .container-fluid.mother-info {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
  }

  body form.row.mt-5.needs-validation {
    margin-top: 10px !important;
  }

  body .StudentList input.form-control {
    width: 100% !important;
  }

  body .mobile_flex button.btn {
    margin-left: 0 !important;
    float: left;
  }

  body .mobile_flex {
    display: flex;
    flex-flow: wrap;
    justify-content: left;
    padding-bottom: 40px !important;
  }

  body .header .header-user {
    background: #ffff;
  }

  .Student_section .btn_grup button.btn.btn-dark {
    margin-left: 0;
    margin-right: 0;
  }

  .meet-chat-blk .join-call .join-content h4 {
    font-size: 18px;
  }

  .container-fluid.d-flex.mt-4 {
    display: block !important;
  }

  .newmwnu {
    flex-flow: column;
  }

  .filters.d-flex.gap-3.mb-3.align-items-center {
    flex-flow: wrap;
  }

  .btn_grup {
    display: flex;
    justify-content: space-between;
  }

}

.meet-chat-blk .join-call .join-content .event-user-group {
  margin-bottom: 10px;
}

.meet-chat-blk .join-call .join-content .event-user-group .avatar-group .avatar {
  width: 38px;
  height: 38px;
  border: 3px solid #FFF;
}

.meet-chat-blk .join-call .join-content .event-user-group .avatar-group .avatar+.avatar {
  margin-left: -20px;
}

.meet-chat-blk .join-call .join-content .event-user-group .avatar-group .avatar .avatar-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  background: gray;
}

.meet-chat-blk .join-call .join-content p {
  font-size: 13px;
  color: #6F6F6F;
}

.meet-chat-blk .join-call .join-content .btn-join-call .btn-primary {
  background: #E41F07;
  border-radius: 6px 6px 6px 6px;
  color: #FFF;
  font-size: 14px;
  border: 1px solid #E41F07;
  font-weight: 600;
  min-width: 105px;
  padding: 8px 15px;
}

.meet-chat-blk .join-call .join-content .btn-join-call .btn-primary:hover {
  background: #FC0027;
  border: 1px solid gray;
  color: #E41F07;
}

.conference-meet-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.conference-meet-group .meeting-list {
  width: 100%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.conference-meet-group .meeting-list.add-meeting {
  width: 65%;
  margin-right: 24px;
}

@media (max-width: 767.98px) {
  .conference-meet-group .meeting-list.add-meeting {
    display: none;
    margin-right: 0px;
  }
}

.conference-meet-group .right-user-side {
  width: 0;
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.conference-meet-group .right-user-side.right-side-party {
  width: 0;
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.conference-meet-group .right-user-side.right-side-party .party-msg-blk .last-call-time .call-recent {
  background: none;
}

.conference-meet-group .right-user-side.right-side-party .party-msg-blk .last-call-time .call-recent svg {
  color: #E41F07;
  width: 14px;
}

.conference-meet-group .right-user-side.chat-rooms {
  width: 0;
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.conference-meet-group .right-user-side.chat-rooms .slime-grp {
  padding: 0;
}

.conference-meet-group .right-user-side.chat-rooms .slime-grp .left-chat-title {
  padding: 20px;
  border-bottom: 1px solid #E1E8ED;
  border-radius: 10px 10px 0 0;
}

.conference-meet-group .right-user-side.chat-rooms.open-chats {
  width: 35%;
  opacity: 1;
}

@media (max-width: 767.98px) {
  .conference-meet-group .right-user-side.chat-rooms.open-chats {
    width: 100%;
  }
}

.conference-meet-group .right-user-side.open-message {
  width: 35%;
  opacity: 1;
}

@media (max-width: 767.98px) {
  .conference-meet-group .right-user-side.open-message {
    width: 100%;
  }
}

.meet-drop {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.meet-drop ul li .chats-dot a {
  width: 50px;
  height: 50px;
  color: #6B7280;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 767.98px) {
  .meet-drop ul li .chats-dot a {
    width: 38px;
    height: 38px;
  }
}

.meet-drop.meet-mutes {
  background: #E41F07;
  right: inherit;
  left: 20px;
}

.meet-drop.meet-mutes ul li a {
  color: #FFF;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 767.98px) {
  .meet-drop.meet-mutes ul li a {
    width: 38px;
    height: 38px;
  }
}

.meet-drop.meet-mutes-bottom {
  background: #E41F07;
  bottom: 20px;
  border-radius: 50px;
  top: inherit;
}

.meet-drop.meet-mutes-bottom ul li a {
  color: #FFF;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 767.98px) {
  .meet-drop.meet-mutes-bottom ul li a {
    width: 38px;
    height: 38px;
  }
}

.meet-drop.meet-mutes-bottom ul li a i {
  font-size: 20px;
}

.meet-call-menu-blk .video-call-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 767.98px) {
  .meet-call-menu-blk .video-call-action {
    display: block;
  }
}

.meet-call-menu-blk .video-call-action ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.meet-call-menu-blk .video-call-action ul li {
  margin-right: 5px;
}

.meet-call-menu-blk .video-call-action ul li:last-child {
  margin-right: 0;
}

.meet-call-menu-blk .video-call-action ul li a {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #D9D9D9;
  border: 1px solid #D9D9D9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #000;
  font-size: 18px;
}

.meet-call-menu-blk .video-call-action ul li a.call-end {
  background-color: #FC0027;
  border-color: #FC0027;
  color: #FFF;
  width: 60px;
  height: 60px;
  rotate: 135deg;
  font-size: 20px;
}

.meet-call-menu-blk .video-call-action ul li a.stop {
  color: #FFF;
  background: #FC0027;
  border-color: #FC0027;
}

.meet-call-menu-blk .video-call-action ul li a.stop i {
  color: #FFF;
}

.meet-call-menu-blk .video-call-action ul li.active a {
  background: #E41F07;
  border-color: #E41F07;
  color: #FFF;
}

.meet-call-menu-blk .video-call-action ul li.active a img {
  filter: invert(1) brightness(100);
}

@media (max-width: 767.98px) {
  .meet-call-menu-blk .video-call-action ul {
    margin-bottom: 5px;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .meet-call-menu-blk .video-call-action ul:last-child {
    margin-bottom: 0px;
  }
}

.recession-meet-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .recession-meet-blk {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .recession-meet-blk .reccession-head {
    margin-bottom: 5px;
  }
}

.recession-meet-blk .reccession-head h5 {
  font-size: 20px;
  font-weight: 700;
  color: #262A2A;
  margin-bottom: 0px;
}

@media (max-width: 767.98px) {
  .recession-meet-blk .reccession-head h5 {
    font-size: 18px;
  }
}

.recession-meet-blk .reccession-head ul li {
  font-size: 15px;
  font-weight: 400;
  color: #6F6F6F;
  margin-right: 25px;
}

.recession-meet-blk .reccession-head ul li:last-child {
  margin-right: 0px;
}

@media (max-width: 767.98px) {
  .recession-meet-blk .reccession-head ul li {
    margin-bottom: 10px;
  }
}

.recession-meet-blk .partispant-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.recession-meet-blk .partispant-blk .btn-primary {
  background: #1b2950;
  border-radius: 6px;
  color: #FFF;
  border: 1px solid #1b2950;
  margin-right: 10px;
  font-weight: 700;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.recession-meet-blk .partispant-blk .btn-primary:hover {
  background: #E41F07;
  border: 1px solid #E41F07;
}

@media (max-width: 767.98px) {
  .recession-meet-blk .partispant-blk .btn-primary {
    font-size: 13px;
  }
}

.recession-meet-blk .partispant-blk .btn-primary:last-child {
  margin-right: 0;
}

.recession-meet-blk .partispant-blk span a {
  width: 42px;
  height: 42px;
  background: #E8E8E8;
  border-radius: 5px;
  color: #6F6F6F;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.recession-meet-blk .partispant-blk span a:hover {
  background: #E41F07;
  color: #FFF;
}

.recording-time {
  position: relative;
  padding-left: 20px;
}

.recording-time::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 4px;
  background-color: #FC0027;
  width: 13px;
  height: 13px;
  border-radius: 50px;
}

.recording-time::after {
  content: "";
  position: absolute;
  left: 3px;
  top: 7px;
  background-color: #FFF;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}

.page-content {
  width: 100%;
}

.join-call {
  width: 100%;
  margin: 0 auto;
}

.join-video {
  position: relative;
  margin-bottom: 25px;
}

.join-video img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.join-video.join-video-front img {
  height: 423px;
}

.video-avatar {
  color: #FFF;
  background: #5D5D5D;
  font-size: 50px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-avatar h4 {
  font-weight: 700;
  font-size: 24px;
  color: #FFF;
}

.video-avatar.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.join-video.video-hide>img,
.video-wrap.video-hide>video {
  display: none;
}

.join-content p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
  color: #000;
}

.join-content label {
  font-size: 18px;
  margin-bottom: 0;
  color: #000;
  font-weight: 700;
}

.part-name {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  padding: 5px 15px;
}

.part-name h4 {
  font-weight: 500;
  font-size: 15px;
  color: #6F6F6F;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  .part-name h4 {
    font-size: 13px;
  }
}

.part-name.sub-part-name {
  background: #FFF;
  bottom: 10px;
  top: inherit;
}

.part-name.sub-part-name h4 {
  color: #262A2A;
  font-size: 12px;
}

.grid-join .part-name {
  background: rgba(0, 0, 0, 0.3);
  top: inherit;
  bottom: 20px;
}

.grid-join .part-name h4 {
  color: #FFF;
}

.join-hover-img {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.join-hover-img .event-user-group .avatar-group {
  background: rgba(0, 0, 0, 0.3);
  padding: 2px 10px;
  border-radius: 50px;
}

.join-hover-img .event-user-group .avatar-group .avatar {
  width: 26px;
  height: 26px;
  border: 1px solid #FFF;
}

.join-hover-img .event-user-group .avatar-group .avatar+.avatar {
  margin-left: -16px;
}

.join-hover-img .event-user-group .avatar-group .avatar:last-child {
  margin-right: 0px;
}

.join-hover-img .event-user-group .avatar-group .avatar .avatar-title {
  color: #1F2937;
  font-size: 12px;
  font-weight: 600;
  background: #F3F4F6;
}

.more-icon {
  position: absolute;
  bottom: 10px;
  right: 12px;
}

@media (max-width: 767.98px) {
  .more-icon {
    bottom: 8px;
    right: 13px;
  }
}

.more-icon a {
  color: #6F6F6F;
  width: 30px;
  height: 30px;
  background: #FFF;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.more-icon a:hover {
  opacity: 0.8;
}

.more-icon a.other-mic-off.stop i {
  color: #FC0027;
}

.more-icon.audio-more-icon a.other-mic-off.stop {
  background-color: #FC0027 !important;
}

.more-icon.audio-more-icon a.other-mic-off.stop i {
  color: #FFF;
}

.call-recent.recent-part a.other-mic-off.stop,
.call-recent.recent-part a.other-video-off.stop {
  background: #FC0027 !important;
}

.call-recent.recent-part a.other-mic-off.stop i,
.call-recent.recent-part a.other-video-off.stop i {
  color: #FFF;
}

.meeting .part-name {
  top: 15px;
  left: 15px;
}

.meeting .more-icon {
  top: 15px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vertical-view .vertical-sec .part-name h4 {
  font-size: 14px;
  font-weight: 400;
}

.horizontal-view .join-video.single-user .part-name h4 {
  font-size: 14px;
  font-weight: 400;
}

.meeting-wrapper,
.meeting {
  width: inherit;
  max-width: inherit;
}

.meet-footer {
  position: fixed;
  bottom: 25px;
  width: 100%;
  max-width: 950px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
}

.show-chat-list,
.show-invite-list,
.show-chat-list .user-sidebar-right,
.show-invite-list .invite-sidebar-right {
  transition: all 0.8s ease;
}

.user-sidebar-right,
.invite-sidebar-right {
  background: #dedede;
  border-radius: 10px;
  width: 410px;
  position: absolute;
  top: 105px;
  bottom: 117px;
  right: 0;
  margin-right: -410px;
  transition: all 0.8s ease;
  overflow: hidden;
  font-size: 13px;
  z-index: 3;
}

.join-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.join-contents.grid-view {
  align-content: center;
}

.ripple {
  width: 114px;
  height: 114px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.multigrid-view .text-box {
  height: 61px;
  width: 61px;
}

.multigrid-view .ripple {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.meeting .join-video {
  margin: 0 5px 10px;
  width: 100%;
}

.join-video.user-active {
  background: #415B6A;
  border-radius: 10px;
  width: 100%;
  height: 370px;
}

.join-video.user-active.audio-calls {
  height: 520px;
}

.join-video.vertical-active {
  margin-right: 24px;
}

.join-video.single-user {
  width: 100%;
  min-height: 230px;
  box-sizing: border-box;
  margin-right: 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background: #415B6A;
  border-radius: 10px;
  padding: 0;
  flex-grow: 1;
}

@media (max-width: 1280px) {
  .join-video.single-user {
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .join-video.single-user {
    min-height: 203px;
  }
}

@media (max-width: 991.98px) {

  body .react-datepicker__input-container input[type="text"] {
    height: 42px;
  }

  body .all_data .checkbox {
    max-width: 104px;

    align-items: center;
    padding: 6px 3px;

    font-size: 11px;

  }

  .join-video.single-user {
    min-height: 230px;
  }

  body .authen-overlay-item {
    left: 15%;
    top: 15%;
    transform: translate(0%, 51%);
  }

  .login-background.d-lg-flex.align-items-center.justify-content-center.d-lg-block.d-none.flex-wrap.vh-100.overflowy-auto {
    background: rgba(75, 111, 255, 0.6);
    display: block !important;
    height: 50vh !important;
  }

  .row.justify-content-center.align-items-center.vh-100.overflow-auto.flex-wrap {
    height: 50vh !important;
  }

  .card.text-white.bg-primary.py-5 {
    display: none;
  }
}

.join-video.single-user:nth-child(even) {
  margin-right: 0px;
}

@media (max-width: 767.98px) {
  .join-video.single-user:nth-child(even) {
    margin-right: 10px;
  }
}

@media (max-width: 767.98px) {
  .join-video.single-user {
    width: 100%;
    min-height: 257px;
  }
}

@media (max-width: 767.98px) {
  .multi-grid .join-video.single-user {
    width: 45%;
  }
}

.multi-grid .join-video.single-user:nth-child(even) {
  margin-right: 24px;
}

@media (max-width: 767.98px) {
  .multi-grid .join-video.single-user:nth-child(even) {
    margin-right: 0px;
  }
}

.multi-grid .join-video.single-user:nth-child(4n) {
  margin-right: 0px;
}

@media (max-width: 767.98px) {
  .multi-grid .join-video.single-user:nth-child(4n):nth-child(4n) {
    margin-right: 0px;
  }
}

.multigrid-view .join-video.single-user {
  flex-grow: 0;
}

.horizontal-view .join-video.single-user:nth-child(even) {
  margin-right: 24px;
}

.horizontal-view .join-video.single-user:nth-child(5) {
  margin-right: 0px;
}

@media (max-width: 767.98px) {
  .horizontal-view .join-video.single-user:nth-child(3n) {
    margin-right: 0;
  }
}

.text-avatar {
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-avatar h4 {
  color: #FFF;
}

.text-box {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 36px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.more-icon.mic-view .mic-on,
.more-icon .mic-off {
  display: none;
}

.more-icon.mic-view .mic-off {
  display: inline-flex;
}

.grid-view .join-video.single-user {
  width: 47%;
}

@media (max-width: 767.98px) {
  .grid-view .join-video.single-user {
    width: 40%;
  }
}

@media (max-width: 767.98px) {
  .grid-view .join-video.single-user:nth-child(2n) {
    margin-right: 0px;
  }
}

@media (max-width: 767.98px) {
  .grid-view .join-video.single-user:nth-child(3n) {
    margin-right: 24px;
  }
}

.join-contents.multigrid-view {
  max-width: 100%;
  justify-content: center;
}

.meeting {
  position: absolute;
  top: 80px;
  bottom: 107px;
  left: 0;
  right: 0;
}

.meeting-list {
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.user-active .more-icon .handraise-on {
  display: none;
}

.user-active .more-icon .handraise-on.active {
  display: block;
  animation: mymove 5s;
}

.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
  animation: mymove 5s infinite;
  opacity: 1;
}

@keyframes mymove {
  from {
    color: #0074E0;
  }

  to {
    color: transparent;
  }
}

.join-contents.vertical-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-content: center;
  max-width: 1300px;
}

@media (max-width: 767.98px) {
  .join-contents.vertical-view {
    display: block;
  }
}

.join-contents.vertical-view .join-video.user-active {
  flex: 1;
  margin-bottom: 24px;
}

.join-contents.vertical-view .join-video.single-user {
  width: 97%;
  flex-grow: 1;
  display: flex;
}

@media (max-width: 767.98px) {
  .join-contents.vertical-view .join-video.single-user {
    width: 100%;
  }
}

.join-contents.vertical-view .vertical-sec {
  width: 20%;
}

@media (max-width: 991.98px) {
  .join-contents.vertical-view .vertical-sec {
    width: 30%;
  }
}

@media (max-width: 767.98px) {
  .join-contents.vertical-view .vertical-sec {
    width: 100%;
  }
}

.join-contents.vertical {
  flex-direction: column;
  height: 200px;
}

.meeting .join-contents.vertical .join-video.user-active {
  flex: 1 0 100%;
}

.meeting .join-contents.vertical .join-video.single-user {
  flex: 0;
}

.meeting .join-contents.vertical .join-video {
  width: auto;
}

.chat-footer .btn:hover {
  background: #0066c5;
  color: #FFF;
}

.join-contents.show-whiteboard {
  display: none;
}

.join-contents.single-view {
  padding-top: 0;
  margin-top: 25px;
  position: relative;
}

.join-contents.single-view .single-user {
  position: absolute;
  right: 8px;
  top: 40px;
  width: 130px;
  height: 130px;
  min-height: 130px;
}

.join-contents.single-view .single-user img {
  height: 130px;
}

.record-time {
  position: absolute;
  top: 20px;
  z-index: 1;
  position: absolute;
  left: 20px;
}

.record-time span {
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  border-radius: 130px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 34px;
  position: relative;
  padding-left: 15px;
}

.record-time span:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 7px;
  background-color: #FFF;
  width: 18px;
  height: 18px;
  border-radius: 50px;
}

.record-time span:after {
  content: "";
  position: absolute;
  left: 12px;
  top: 12px;
  background-color: #FC0027;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.volume-col {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.volume-col .inner-volume-col {
  background-color: #898989;
  border-radius: 130px;
  width: 30px;
  height: 120px;
  position: relative;
  z-index: 9;
}

.volume-col #volume {
  position: absolute;
  left: 50%;
  top: 15px;
  margin: 0 auto;
  height: 80px;
  width: 5px;
  background: #FFF;
  border-radius: 15px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.volume-col #volume .ui-slider-range-min {
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #E41F07;
  border: none;
  border-radius: 10px;
  outline: none;
}

.volume-col #volume .ui-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #E41F07;
  position: absolute;
  left: 50%;
  margin-top: -8px;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.volume-col #player {
  width: auto;
  height: 104px;
  position: relative;
  margin: 0 auto;
  top: 0px;
}

.volume-col #player i {
  position: absolute;
  margin-top: -6px;
  color: #666;
}

.volume-col .volume-icons {
  color: #FFF;
  margin-left: 0px;
  font-size: 18px;
  margin-top: 22px;
}

.chat-msg-blk {
  padding: 15px;
}

.chat-msg-blk .chats {
  max-width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  display: -webkit-flex;
}

@media (max-width: 767.98px) {
  .chat-msg-blk .chats {
    max-width: 100%;
  }
}

.chat-msg-blk .chats .chat-action-btns .chat-user-time .chats-dot a {
  color: #00918E;
}

.chat-msg-blk .chats.chats-right {
  margin-left: auto;
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}

.chat-msg-blk .chats.chats-right .chat-content {
  order: 1;
  -webkit-order: 1;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content {
  background-color: #E8E8E8;
  border-radius: 8px;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .chat-time {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col {
  position: relative;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li {
  margin-right: 8px;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li:last-child {
  margin-right: 0px;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col {
  position: relative;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col.image-not-download a img {
  -webkit-filter: blur(4px);
  filter: blur(1px);
  border-color: #000;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col.image-not-download span {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col.image-not-download span i {
  color: #FFF;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col .download-action {
  position: absolute;
  right: 8px;
  bottom: 8px;
  line-height: 0;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col .download-action a {
  color: #FFF;
  font-size: 10px;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content .download-col ul li .image-download-col img {
  border-radius: 5px;
}

.chat-msg-blk .chats.chats-right .chat-content .message-content::before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #E8E8E8;
  border-right: 0;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 8px;
  right: -4px;
  left: auto;
}

.chat-msg-blk .chats.chats-right .chat-avatar {
  padding: 8px 0px 0 15px;
}

.chat-msg-blk .chats.chats-right .chat-action-btns {
  order: 2;
  -webkit-order: 2;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
}

.chat-msg-blk .chats.chats-right .chat-action-btns .chat-user-time .chats-dot a {
  color: #00918E;
}

.chat-msg-blk .chats .chat-avatar {
  padding: 8px 15px 0 8px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  flex-shrink: 0;
}

.chat-msg-blk .chats .chat-avatar .dreams_chat {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.chat-msg-blk .chats .message-content {
  background-color: #E8E8E8;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 500;
  margin-bottom: 5px;
  position: relative;
}

.chat-msg-blk .chats .message-content h4 {
  font-size: 13px;
  color: #262A2A;
  margin-bottom: 0px;
  line-height: 1.4;
  font-weight: 500;
}

.chat-msg-blk .chats .message-content .chat-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 10px;
  font-weight: 500;
  color: #00918E;
  margin-bottom: 0;
}

.chat-msg-blk .chats .message-content .chat-time svg {
  width: 10px;
}

.chat-msg-blk .chats .message-content::before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #E8E8E8;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 8px;
  left: -4px;
  right: auto;
}

.chat-msg-blk .chats .chat-profile-name h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: left;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  font-size: 8px;
  font-weight: 500;
  color: #6F6F6F;
}

.chat-msg-blk .chats .chat-profile-name h6 i {
  font-size: 12px;
  color: #008024;
}

.chat-msg-blk .chat-line {
  margin: 0px 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
}

.chat-msg-blk .chat-line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  background-color: #00918E;
  width: 100%;
  height: 1px;
}

.chat-msg-blk .chat-line .chat-date {
  background-color: #00918E;
  color: #00918E;
  font-size: 13px;
  padding: 7px 14px;
  border-radius: 3px;
  display: inline-block;
  font-weight: 600;
  position: relative;
}

.right-user-side.call-user-side .slime-grp .right-sidebar-profile {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 0;
  padding-bottom: 10px;
}

.right-user-side.call-user-side .slime-grp .right-sidebar-profile .profile-call-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.right-user-side.call-user-side .slime-grp .right-sidebar-profile .edit-group svg {
  width: 16px;
  color: #00918E;
}

.right-user-side.call-user-side .slime-grp .chat-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-bottom: 1px solid #00918E;
  padding-bottom: 20px;
}

.right-user-side.call-user-side .slime-grp .chat-options ul li a {
  color: #00918E;
  width: 30px;
  height: 30px;
  background-color: gray;
  padding: 0;
  border-radius: 50px;
}

.right-user-side.call-user-side .slime-grp .chat-options ul li a:hover {
  background: #E41F07;
  border-color: #E41F07;
}

.right-user-side.call-user-side .slime-grp .chat-options ul li a:hover svg {
  color: #FFF;
}

.right-user-side.call-user-side .slime-grp .chat-options ul li a svg {
  width: 15px;
}

.right-user-side.right-side-party .slime-grp {
  padding: 0px;
}

.right-user-side.right-side-party .slime-grp .left-chat-title {
  padding: 20px;
  margin-bottom: 0;
  background-color: #F9F9FC;
  border-radius: 10px 10px 0 0;
}

.right-user-side.right-side-party .slime-grp .left-chat-title h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.right-user-side.right-side-party .slime-grp .left-chat-title h4 span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 25px;
  height: 25px;
  background-color: #FDA700;
  border-radius: 50px;
  font-size: 14px;
  color: #FFF;
  margin-left: 10px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk {
  padding: 20px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list {
  border-radius: 0.25rem;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  padding: 5px;
  display: flex;
  display: -webkit-flex;
  border-radius: 6px;
  background-color: #F6F7F8;
  margin-bottom: 10px;
  border: 0;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item:hover {
  background-color: #F6F7F8;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .avatar {
  margin-right: 0.8rem;
  display: inline-block;
  margin-bottom: 0;
  height: 40px;
  width: 40px;
  position: relative;
  flex-shrink: 0;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .avatar::before {
  top: 0;
  bottom: inherit;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .avatar img {
  border-radius: 0px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-grow: 1;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .name-list-user {
  display: grid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .name-list-user h5 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 2px;
  color: #6F6F6F;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .name-list-user p {
  font-size: 12px;
  color: #FC0027;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .name-list-user p svg {
  width: 14px;
  height: 14px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .chat-user-time .chats-dot a {
  color: #FC0027;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .last-chat-time .text-muted {
  font-size: 10px;
  color: #262A2A;
  font-weight: 500;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .last-chat-time .new-message-count {
  width: 20px;
  height: 20px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  line-height: 0;
  font-size: 11px;
  background-color: #E41F07;
  color: #FFF;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 5px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .last-call-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .last-call-time .call-recent a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 30px;
  height: 30px;
  background: #B8BCC9;
  color: #262A2A;
  border-radius: 50px;
}

.right-user-side.right-side-party .slime-grp .card-body-blk .user-list .user-list-item .users-list-body .last-call-time .call-recent a svg {
  color: #262A2A;
}

.right-user-side .slimScrollDiv {
  height: calc(100vh - 110px) !important;
}

.right-user-side .slimScrollDiv .slimscroll {
  height: calc(100vh - 110px) !important;
}

.right-user-side .slime-grp {
  background: #FFF;
  box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.4705882353);
  border: 1px solid #E1E8ED;
  border-radius: 10px;
  padding: 15px;
}

.right-user-side .slime-grp .left-chat-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px;
  background-color: #F9F9FC;
}

.right-user-side .slime-grp .left-chat-title .chat-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 0;
}

.right-user-side .slime-grp .left-chat-title .contact-close_call .close_profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 26px;
  height: 26px;
  background: transparent;
  border-radius: 50px;
}

.right-user-side .slime-grp .left-chat-title .contact-close_call .close_profile:hover {
  background: #E41F07;
}

.right-user-side .slime-grp .left-chat-title .contact-close_call .close_profile:hover svg {
  color: #FFF;
}

.right-user-side .slime-grp .left-chat-title .contact-close_call .close_profile svg {
  width: 16px;
  color: #FC0027;
}

.right-user-side .slime-grp .chat-footer {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 15px;
  background: #FFF;
}

.right-user-side .slime-grp .chat-footer form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  position: relative;
}

.right-user-side .slime-grp .chat-footer form .comman-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.right-user-side .slime-grp .chat-footer form .comman-icon a {
  color: #6F6F6F;
}

.right-user-side .slime-grp .chat-footer form .smile-col {
  right: inherit;
  left: 15px;
}

.right-user-side .slime-grp .chat-footer form .attach-col {
  right: inherit;
  left: 45px;
}

.right-user-side .slime-grp .chat-footer form .micro-col {
  right: 50px;
  left: inherit;
}

.right-user-side .slime-grp .chat-footer form .micro-col a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.right-user-side .slime-grp .chat-footer form .send-chat {
  right: 5px;
}

.right-user-side .slime-grp .chat-footer form .send-chat a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 34px;
  height: 34px;
  background: #E41F07;
  border-radius: 50px;
}

.right-user-side .slime-grp .chat-footer form .send-chat a:hover {
  background: #1b2950;
}

.right-user-side .slime-grp .chat-footer form .send-chat a:hover svg {
  color: #FFF;
}

.right-user-side .slime-grp .chat-footer form .send-chat a svg {
  width: 14px;
  color: #FFF;
}

.right-user-side .slime-grp .chat-footer form .chat_form {
  border-radius: 20px;
  margin-right: 0px;
  background: #FFF;
  border: 1px solid rgba(145, 158, 171, 0.3);
  border-radius: 6px;
  height: 48px;
  padding: 10px 70px;
}

.right-user-side .slime-grp .chat-footer form .chat_form::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2);
}

.owl-carousel.video-slide .owl-nav.disabled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
}

.owl-carousel.video-slide .owl-nav {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media (max-width: 1280px) {
  .owl-carousel.video-slide .owl-nav {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }
}

.owl-carousel.video-slide .owl-nav button.owl-next,
.owl-carousel.video-slide .owl-nav button.owl-prev {
  background-color: #D9D9D9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.owl-carousel.video-slide .owl-nav button.owl-next:hover,
.owl-carousel.video-slide .owl-nav button.owl-prev:hover {
  background-color: #E41F07;
}

.owl-carousel.video-slide .owl-nav button.owl-next:hover i,
.owl-carousel.video-slide .owl-nav button.owl-prev:hover i {
  color: #FFF;
}

.owl-carousel.video-slide .owl-nav button.owl-next i,
.owl-carousel.video-slide .owl-nav button.owl-prev i {
  color: #6F6F6F;
}

.audio-call-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  height: 100%;
}

.audio-call-group ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  width: 100%;
}

.audio-call-group ul li {
  position: relative;
}

.audio-call-group ul li.active .avatar::before {
  content: "";
  width: 250px;
  height: 250px;
  position: absolute;
  border: 1px solid #E41F07;
  border-radius: 50%;
  left: -25px;
  top: -25px;
}

@media (max-width: 991.98px) {
  .audio-call-group ul li.active .avatar::before {
    width: 150px;
    height: 150px;
    left: -10px;
    top: -10px;
  }
}

@media (max-width: 767.98px) {
  .audio-call-group ul li.active .avatar::before {
    width: 100px;
    height: 100px;
    left: -5px;
    top: -5px;
  }
}

.audio-call-group ul li .avatar {
  width: 200px;
  height: 200px;
  margin: 0;
  position: relative;
}

@media (max-width: 991.98px) {
  .audio-call-group ul li .avatar {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 767.98px) {
  .audio-call-group ul li .avatar {
    width: 90px;
    height: 90px;
  }
}

.audio-call-group ul li .avatar img {
  width: 100%;
  position: relative;
}

.audio-call-group ul li .user-audio-call h5 {
  font-size: 14px;
  color: #FFF;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  margin-top: 35px;
}

@media (max-width: 767.98px) {
  .audio-call-group ul li .user-audio-call h5 {
    margin-top: 15px;
  }
}

.audio-call-group ul li .more-icon {
  position: absolute;
  bottom: 10px;
  right: inherit;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
}

@media (max-width: 767.98px) {
  .audio-call-group ul li .more-icon {
    bottom: 0px;
  }
}

.audio-call-group ul li .more-icon a {
  background-color: #E41F07;
  color: #FFF;
}

.audio-volume {
  position: absolute;
  width: fit-content;
  height: 134px;
  bottom: 20px;
  left: 20px;
  background: #898989;
  border-radius: 50px;
  padding: 10px 14px;
  width: 30px;
  padding-bottom: 30px;
}

.audio-volume .volume-icons a {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;
  left: 0;
  border-radius: 50px;
  background: #FFF;
  border: 1px solid #FDA700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.custom-input {
  width: 100%;
  height: 4px;
  width: 3px;
  height: 100%;
  background: #FFF;
  border-radius: 5px;
  writing-mode: vertical-lr;
}

.custom-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #E41F07 !important;
  border-radius: 50%;
  cursor: pointer;
}

.custom-input::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #E41F07 !important;
  border-radius: 50%;
  cursor: pointer;
}

.table-top-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 575.98px) {
  .table-top-head.low-stock-top-head {
    flex-wrap: wrap;
    justify-content: start;
  }
}

.table-top-head.low-stock-top-head li {
  margin-bottom: 10px;
}

.table-top-head.low-stock-top-head li:last-child {
  margin-right: 0;
}

.table-top-head li {
  margin-right: 10px;
  flex-shrink: 0;
}

.table-top-head li:last-child {
  margin-right: 0;
}

.table-top-head li #collapse-header {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .table-top-head li #collapse-header {
    display: none;
  }
}

.table-top-head li a {
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #898989;
  background: #FFF;
  border-radius: 8px;
  padding: 6px;
}

.table-top-head li a svg {
  color: #262A2A;
}

.table-top-head li a:hover {
  background: #e3e3e3;
}

.table-top-head li a label {
  margin-right: 10px;
}

.table-top-head li a.btn-secondary {
  background-color: #262A2A;
  color: #FFF;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
}

.table-top-head li a.btn-secondary:hover {
  background-color: #E41F07;
}

.table-top-head li a.btn-secondary svg {
  width: 16px;
  margin-right: 5px;
  color: #FFF;
}

@media (max-width: 767.98px) {
  .table-top-head li a.btn-secondary {
    padding: 5px;
    font-size: 14px;
  }
}

.table-top-head li a .action-edit.sales-action {
  color: #339DFF;
}

.table-top-head li .status-toggle {
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #C0C0C0;
  background: #FFF;
  border-radius: 8px;
  padding: 6px 10px;
}

.table-top-head li .status-toggle label {
  margin-right: 10px;
}

.main-chat-blk .slimscroll {
  height: calc(100vh - 60px) !important;
}

.main-chat-blk .slimscroll-active-sidebar a {
  color: #6F6F6F;
}

.main-chat-blk .emoj-group ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .emoj-group ul li+li {
  margin-left: 10px;
}

.main-chat-blk .emoj-group ul li a {
  width: 34px;
  height: 34px;
  background: #f6f7f8;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.main-chat-blk .emoj-group ul li a i {
  color: #424242;
}

.main-chat-blk .message-content {
  position: relative;
}

.main-chat-blk .message-content:hover .emoj-group {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.main-chat-blk .emoj-group {
  position: absolute;
  top: 50%;
  right: -100px;
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.main-chat-blk .rig-emoji-group {
  position: absolute;
  right: 210px;
}

.main-chat-blk .wrap-emoji-group {
  position: absolute;
  right: 290px;
}

.main-chat-blk .emoj-group-list-foot {
  display: none;
  position: absolute;
  top: -50px;
  left: -90px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.main-chat-blk .emoj-group-list-foot ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f6f7f8;
  border-radius: 50px;
  padding: 5px;
}

.main-chat-blk .emoj-group-list-foot ul li a {
  padding: 0;
  display: flex;
  align-items: center;
}

.main-chat-blk .emoj-group-list-foot ul li.add-emoj a {
  background: #EDEDED;
}

.main-chat-blk .emoj-group-list-foot ul li.add-emoj a i {
  font-size: 20px;
  color: #424242;
}

.main-chat-blk .down-emoji-circle {
  position: absolute;
  left: 10px !important;
  top: -38px !important;
}

.main-chat-blk .emoj-action-foot .down-emoji-circle ul {
  padding: 10px;
}

.main-chat-blk .down-emoji-circle ul li+li {
  margin-left: 14px;
}

.main-chat-blk .emoj-action-foot .down-emoji-circle ul li:last-child a {
  width: 34px;
  height: 34px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-chat-blk .chat-page-wrapper .content {
  padding: 0;
}

.main-chat-blk .chat-page-wrapper .sidebar {
  position: initial;
}

@media (max-width: 991.98px) {
  .main-chat-blk .chat-page-wrapper .sidebar {
    margin-left: 0;
  }
}

.main-chat-blk .chat-page-wrapper .left-chat-title {
  padding: 12px;
  border-bottom: 1px solid #E0E0E0;
  min-height: 72px;
}

.main-chat-blk .chat-page-wrapper .setting-title-head h4 {
  font-size: 18px;
  color: #262A2A;
  font-weight: 700;
}

.main-chat-blk .chat-page-wrapper .chat .slimscroll {
  height: calc(100vh - 135px) !important;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item {
  font-size: 14px;
  cursor: pointer;
  padding: 7px 14px;
  font-weight: 500;
  color: #424242;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item i {
  font-size: 16px;
  color: #424242;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item span {
  float: right;
  font-size: 18px;
  color: #424242;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item.active {
  background: none;
  color: inherit;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:focus,
.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:hover,
.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:active {
  background-color: #e6e6e6;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu {
  background: #FFF;
  border: 1px solid #E5E7EB;
  box-sizing: border-box;
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 173px;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item {
  margin: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu.chat-drop-menu .dropdown-item i {
  font-size: 16px;
  color: #424242;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:hover,
.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:focus,
.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item:active {
  background-color: #F8ECFF;
}

.main-chat-blk .chat-page-wrapper .dropdown-menu .dropdown-item span {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .chat-page-wrapper .contact-close_call {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@-webkit-keyframes wave {

  0%,
  100%,
  60% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes wave {

  0%,
  100%,
  60% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.main-chat-blk .chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  background: #FFF;
}

.main-chat-blk .chat .chat-header {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  padding: 15px 24px;
  align-items: center;
  border-bottom: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
  background: var(--White, #FFF);
  box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
  position: relative;
}

.main-chat-blk .chat .chat-header .user-details {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.main-chat-blk .chat .chat-header .user-details figure {
  margin-right: 1rem;
}

.main-chat-blk .chat .chat-header .user-details h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1;
  color: #262A2A;
}

.main-chat-blk .chat .chat-header .chat-options ul {
  margin-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.main-chat-blk .chat .chat-header .chat-options ul>li>a {
  font-size: 14px;
  color: #262A2A;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.main-chat-blk .chat .chat-header .chat-options ul>li>a i {
  font-size: 20px;
}

.main-chat-blk .chat .chat-header .chat-options ul>li>a:not(.no-bg):hover {
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}

.main-chat-blk .chat .chat-body {
  flex: 1;
  padding: 24px;
  padding-bottom: 40px;
}

.main-chat-blk .chat .chat-body:not(.no-message) {
  overflow: hidden;
}

.main-chat-blk .chat .chat-body .messages {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: flex-start;
  -webkit-align-items: flex-start;
}

.main-chat-blk .chat .chat-body .messages .chats {
  max-width: 75%;
  margin-bottom: 1.5rem;
  display: flex;
  display: -webkit-flex;
}

.main-chat-blk .chat .chat-body .messages .chats:last-child {
  margin-bottom: 0;
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin-bottom: 0;
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time .avatar {
  margin-right: 1rem;
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time h5 {
  font-size: 15px;
  margin-bottom: 0;
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time .time {
  color: #FFF;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time .time i {
  color: rgba(247, 247, 247, 0.5);
}

.main-chat-blk .chat .chat-body .messages .chats .chat-time .time i img {
  width: 14px;
  height: auto;
  margin-left: 2px;
  vertical-align: 0px;
}

.main-chat-blk .chat .chat-body .messages .chats .message-content {
  border-radius: 0px 15px 15px 15px;
  background: var(--light-color, #F6F7F8);
  padding: 14px 20px;
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.031372549);
}

.main-chat-blk .chat .chat-body .messages .chats .message-content a {
  color: #299cdb;
}

.main-chat-blk .chat .chat-body .messages .chats .message-content.award-link a {
  color: #262A2A;
  margin-bottom: 2px;
  display: block;
}

.main-chat-blk .chat .chat-body .messages .chats .message-content.award-link img {
  border-radius: 10px;
}

.main-chat-blk .chat .chat-body .messages .chats .message-content.chat-award-link a {
  display: flex;
}

.main-chat-blk .chat .chat-body .messages .chats .message-content.fancy-msg-box .image-not-download span {
  position: absolute;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.main-chat-blk .chat .chat-body .messages .chat-profile-name h6 {
  margin-bottom: 0;
  color: #262A2A;
  font-weight: 600;
  font-size: 15px;
}

.main-chat-blk .chat .chat-body .messages .chat-profile-name h6 span {
  font-size: 12px;
  color: #6F7A7A;
  font-weight: 400;
  padding-left: 10px;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right {
  margin-left: auto;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-time {
  justify-content: flex-end;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .message-content {
  border-radius: 15px 0px 15px 15px;
  background: rgba(255, 159, 67, 0.12);
  color: #262A2A;
  padding: 15px;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .message-content .chat-time {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .message-content .chat-time .time {
  color: #6F7A7A;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .message-content .chat-time .time i {
  color: #299cdb;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-action-btns {
  order: 2;
  -webkit-order: 2;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-action-col {
  width: 20px;
  text-align: center;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-read-col {
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  padding-bottom: 22px;
  color: #565E5E;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-content {
  order: 1;
  -webkit-order: 1;
}

.main-chat-blk .chat .chat-body .messages .chats.chats-right .chat-avatar {
  padding: 8px 0px 0 15px;
}

.main-chat-blk .chat .chat-body .messages .download-col {
  position: relative;
}

.main-chat-blk .chat .chat-body .messages .download-col ul {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  display: flex;
  display: -webkit-flex;
}

.main-chat-blk .chat .chat-body .messages .download-col ul li+li {
  margin-left: 5px;
}

.main-chat-blk .chat .chat-body .messages .download-col .image-download-col {
  position: relative;
}

.main-chat-blk .chat .chat-body .messages .download-col .image-download-col a>img {
  border: 1px solid transparent;
  width: 80px;
  border-radius: 8px;
}

.main-chat-blk .chat .chat-body .messages .download-col .image-download-col.image-not-download a>img {
  -webkit-filter: blur(1px);
  filter: blur(1px);
}

.main-chat-blk .chat .chat-body .messages .download-col .download-action {
  position: absolute;
  right: 8px;
  bottom: 8px;
  line-height: 0;
}

.main-chat-blk .chat .chat-body .messages .download-col .download-action a {
  color: #FFF;
  font-size: 10px;
}

.main-chat-blk .chat .chat-body .messages .download-col .download-action div+div {
  margin-left: 8px;
}

.main-chat-blk .chat .chat-body .messages .download-col .download-action div img {
  width: 13px;
}

.main-chat-blk .chat .chat-body .messages .file-download {
  position: relative;
  background: #E8E8E8;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 7px;
}

.main-chat-blk .chat .chat-body .messages .file-download .download-action {
  position: absolute;
  right: 8px;
  bottom: 8px;
  line-height: 0;
  bottom: 14px;
}

.main-chat-blk .chat .chat-body .messages .file-download .download-action a {
  color: #FFF;
  font-size: 10px;
  color: #323737;
}

.main-chat-blk .chat .chat-body .messages .file-download .download-action div+div {
  margin-left: 8px;
}

.main-chat-blk .chat .chat-body .messages .file-download .download-action div img {
  width: 13px;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-type {
  width: 54px;
  height: 54px;
  background-color: #FFF;
  border-radius: 4px;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-type i {
  color: #6F7A7A;
  font-size: 24px;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details span.file-name {
  color: #262A2A;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details span.file-size {
  color: #6F7A7A;
  display: inline-block;
  width: 100%;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul li {
  list-style: none;
  color: #262A2A;
  font-size: 14px;
  position: relative;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul li::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #C2C2C2;
  right: -8px;
  top: 5px;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul li:last-child::before {
  display: none;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul li a {
  color: #E41F07;
}

.main-chat-blk .chat .chat-body .messages .file-download .file-details ul li+li {
  padding-left: 15px;
}

.main-chat-blk .chat .chat-body.no-message {
  user-select: none;
}

.main-chat-blk .chat .chat-footer {
  position: relative;
}

.main-chat-blk .chat .chat-footer form {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
}

@media (max-width: 991.98px) {
  .main-chat-blk .chat .chat-footer form {
    padding-bottom: 15px;
  }
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn {
  margin-left: 0px;
  color: #6F6F6F;
  font-size: 20px;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn:last-child {
  margin-right: 0;
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn.send-btn {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: #E41F07;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF !important;
  margin-left: 10px;
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn.send-btn:hover {
  background-color: #FFF;
  border-color: #323737;
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn.send-btn:hover i {
  color: #323737;
}

.main-chat-blk .chat .chat-footer form .form-buttons .btn.send-btn:focus {
  box-shadow: none;
}

.main-chat-blk .chat .chat-footer form .specker-col span {
  color: #323737;
  font-size: 25px;
}

.main-chat-blk .chat .chat-footer form .attach-col {
  right: inherit;
  left: 60px;
}

.main-chat-blk .chat .chat-footer form .attach-col i {
  color: #323737;
  font-size: 20px;
}

.main-chat-blk .chat .chat-footer form .smile-col {
  right: inherit;
  left: 15px;
}

.main-chat-blk .chat .chat-footer form .smile-col i {
  color: #323737;
  font-size: 20px;
  font-size: 22px;
}

.main-chat-blk .chat .chat-footer form .smile-foot {
  margin-right: 10px;
}

.main-chat-blk .chat .chat-footer form .smile-foot .action-circle {
  width: 38px;
  height: 38px;
  background: #FFF;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.main-chat-blk .chat .chat-footer form .smile-foot .action-circle:hover {
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}

.main-chat-blk .chat .chat-footer form .smile-foot .action-circle i {
  color: #262A2A;
  font-size: 18px;
}

.main-chat-blk .chat .chat-footer form .form-control::-webkit-input-placeholder {
  color: #262A2A;
  opacity: 1;
}

.main-chat-blk .chat .chat-footer form .form-control::-moz-placeholder {
  color: #262A2A;
  opacity: 1;
}

.main-chat-blk .chat .chat-footer form .form-control:-ms-input-placeholder {
  color: #262A2A;
  opacity: 1;
}

.main-chat-blk .chat .chat-footer form .form-control:-moz-placeholder {
  color: #262A2A;
  opacity: 1;
}

.main-chat-blk .chat-options ul.list-inline .list-inline-item {
  margin-bottom: 0;
}

.main-chat-blk .last-seen {
  color: #262A2A;
  font-size: 14px;
}

.main-chat-blk .user_callog {
  display: flex;
  margin-top: 0px;
}

.main-chat-blk .calllog_p {
  margin-left: 5px;
}

.main-chat-blk .chat-options.chat-option-profile .list-inline-item a {
  background: #E41F07;
  width: 38px;
  height: 38px;
  border-radius: 50px;
  color: #262A2A;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #FFF !important;
}

.main-chat-blk .chat-options.chat-option-profile .list-inline-item a:hover {
  background: #1b2950;
  color: #FFF;
}

.main-chat-blk .chat-options.chat-option-profile .list-inline {
  border-bottom: 1px solid #EDEDED;
  margin-bottom: 0;
  padding-bottom: 15px;
}

.main-chat-blk .last-seen-profile span {
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
  display: block;
}

.main-chat-blk .header_button {
  position: absolute;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  color: #FFF;
  text-align: center;
  background: #ffa977;
  bottom: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.main-chat-blk .btn-warning:hover {
  color: #FFF;
  background-color: #E94C39;
  border-color: #E73520;
}

.main-chat-blk .right_sidebar_profile {
  margin-top: 0px;
}

.main-chat-blk .account_details {
  background-color: #898989;
  border-color: #6F6F6F;
  border-radius: 5px !important;
  border-bottom: 0 !important;
}

.main-chat-blk .security_details {
  background-color: #898989;
  border-color: #6F6F6F;
  border-radius: 5px !important;
  border-bottom: 0 !important;
}

.main-chat-blk .close_icon {
  font-size: 18px;
}

.main-chat-blk .button_plus {
  margin-left: 0px;
}

.main-chat-blk .chat-search {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  opacity: 0;
  visibility: hidden;
}

.main-chat-blk .chat-search input[type=text] {
  padding: 8px 8px 8px 35px;
  width: 100%;
  min-height: 47px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #E5E7EB;
  font-size: 13px;
  background-color: #FFF;
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
}

.main-chat-blk .chat-search input[type=text]:hover {
  border-color: #9B9B9B;
}

.main-chat-blk .chat-search input[type=text]:focus {
  border-color: #9B9B9B;
}

.main-chat-blk .chat-search input[type=text]:focus-visible {
  border-color: #9B9B9B;
}

.main-chat-blk .chat-search.visible-chat {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  z-index: 9;
}

.main-chat-blk .form-control-feedback {
  z-index: 2;
  display: block;
  text-align: center;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.main-chat-blk .form-control-feedback i {
  font-size: 16px;
  color: #262A2A;
}

.main-chat-blk .user-chat-search {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  opacity: 0;
  visibility: hidden;
}

.main-chat-blk .user-chat-search input[type=text] {
  padding: 8px 8px 8px 35px;
  width: 100%;
  min-height: 47px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #E5E7EB;
  font-size: 13px;
  background-color: #FFF;
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
}

.main-chat-blk .user-chat-search input[type=text]:hover {
  border-color: #9B9B9B;
}

.main-chat-blk .user-chat-search input[type=text]:focus {
  border-color: #9B9B9B;
}

.main-chat-blk .user-chat-search input[type=text]:focus-visible {
  border-color: #9B9B9B;
}

.main-chat-blk .user-chat-search.visible-chat {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  z-index: 9;
}

.main-chat-blk .all-chats {
  position: relative;
}

.main-chat-blk .close-btn-chat {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #262A2A;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  position: absolute;
  right: 12px;
  top: 9px;
  cursor: pointer;
}

.main-chat-blk .user-close-btn-chat {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #262A2A;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  position: absolute;
  right: 12px;
  top: 9px;
  cursor: pointer;
}

.main-chat-blk .main_content {
  width: 100% !important;
}

.main-chat-blk .avatar-main {
  height: 50px;
  width: 50px;
}

.main-chat-blk .archive-btn {
  border: 1px solid #008024;
  color: #565E5E;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  padding: 2px 6px;
  margin-right: 1.5rem;
}

.main-chat-blk .archive-btn-grey {
  border: 1px solid #909090;
  color: #6F7A7A;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 500;
  padding: 2px 6px;
  margin-top: 0.25rem;
}

.main-chat-blk .right_sidebar_icon {
  list-style: none;
}

.main-chat-blk .btn.btn-outline-light:hover {
  background: #e6e6e6;
}

.main-chat-blk .chat-avatar {
  padding: 8px 15px 0 8px;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  flex-shrink: 0;
}

.main-chat-blk .chat-profile-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.main-chat-blk .chat-profile-name .chat-action-col a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-chat-blk .delete-pop-btn .btn-primary {
  margin: 0 0 0 10px;
}

.main-chat-blk .delete-model-head {
  padding: 40px;
}

.main-chat-blk .delete-model-head .modal-header {
  padding: 0;
  margin-bottom: 32px;
}

.main-chat-blk .delete-model-head .modal-body {
  padding: 0;
}

.main-chat-blk .delete-chat-notify {
  margin-bottom: 32px;
}

.main-chat-blk .delete-chat-notify .form-check {
  margin-bottom: 14px;
}

.main-chat-blk .chats.chats-right {
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}

.main-chat-blk .message-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .message-star .avatar {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.main-chat-blk .message-star .avatar img {
  width: 100%;
}

.main-chat-blk .message-star h6 {
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.main-chat-blk .message-star h6 span i {
  color: #FEC14D;
}

.main-chat-blk .chat-voice-group ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 0;
  margin-bottom: 0;
}

.main-chat-blk .chat-voice-group ul li span {
  width: 28px;
  height: 28px;
  background: #E41F07;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.main-chat-blk .chat-voice-group li {
  margin-bottom: 0;
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
  list-style: none;
}

.main-chat-blk .chat-voice-group li+li {
  padding-left: 10px;
}

.main-chat-blk .file-download-col .download-action {
  position: absolute;
  right: 8px;
  bottom: 8px;
  line-height: 0;
}

.main-chat-blk .file-download-col .download-action a {
  color: #FFF;
  font-size: 10px;
}

.main-chat-blk .file-download-col .download-action div+div {
  margin-left: 8px;
}

.main-chat-blk .file-download-col .download-action div img {
  width: 13px;
}

.main-chat-blk .file-download-col:after {
  content: "";
  display: table;
  clear: both;
}

.main-chat-blk .file-download-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .file-download-col ul li {
  float: left;
  width: 50%;
  padding: 5px;
}

.main-chat-blk .file-download-col ul li .image-download-col {
  position: relative;
}

.main-chat-blk .file-download-col ul li .image-download-col a>img {
  width: 100%;
}

.main-chat-blk .file-download-col ul li.full-width {
  width: 100%;
}

.main-chat-blk .chat-select .select2-container .select2-selection--single {
  border: 0;
  height: 24px;
}

.main-chat-blk .chat-select .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 40px;
  padding-left: 0px;
}

.main-chat-blk .chat-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #6F7A7A;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  font-weight: 600;
}

.main-chat-blk .chat-select .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 24px;
  right: 7px;
}

.main-chat-blk .chat-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #424848;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.main-chat-blk .chat-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #262A2A;
}

.main-chat-blk .chat-select .select2-container--default .select2-selection--multiple {
  border: 1px solid #ddd;
  min-height: 40px;
}

.main-chat-blk .chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #424848;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -5px;
  border-color: #424848;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
}

.main-chat-blk .chat-select .select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
  top: 7px;
}

.main-chat-blk .chat-select .select2-container--open .select2-dropdown--below {
  margin-top: 10px;
}

.main-chat-blk .success-pass {
  margin-bottom: 40px;
  text-align: center;
}

.main-chat-blk .review-files p {
  margin-bottom: 5px;
  color: #262A2A;
  font-size: 14px;
}

.main-chat-blk .like-chat-grp ul {
  padding: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-top: 10px;
}

.main-chat-blk .like-chat-grp ul li {
  list-style: none;
}

.main-chat-blk .like-chat-grp ul li a {
  border: 1px solid #E0E0E0;
  border-radius: 50px;
  padding: 3px 12px;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #262A2A;
  font-weight: 400;
  font-size: 14px;
}

.main-chat-blk .like-chat-grp ul li a img {
  margin-left: 5px;
}

.main-chat-blk .like-chat-grp ul li+li {
  margin-left: 10px;
}

.main-chat-blk .like-chat-grp ul li.like-chat a {
  background: #FEF6DC;
}

.main-chat-blk .like-chat-grp ul li.comment-chat a {
  background: #FFE8E9;
}

.main-chat-blk .chat-line {
  margin: 0px 0 20px;
  position: relative;
  text-align: center;
  width: 100%;
}

.main-chat-blk .chat-line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  background-color: #E8E8E8;
  width: 100%;
  height: 1px;
}

.main-chat-blk .chat-date {
  border-radius: 100px;
  background: #E8E8E8;
  color: #262A2A;
  font-size: 14px;
  padding: 4px 20px;
  display: inline-block;
  font-weight: 400;
  position: relative;
  border: 1px solid #E0E0E0;
}

.main-chat-blk .chat+.sidebar-group {
  margin-right: 0px;
}

.main-chat-blk .chat+.sidebar-group .sidebar {
  margin-right: 0px;
}

.main-chat-blk .group-comman-theme {
  padding: 0 24px;
}

.main-chat-blk .group-comman-theme h6 {
  margin-bottom: 15px;
}

.main-chat-blk .group-comman-theme ul {
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #EDEDED;
}

.main-chat-blk .group-comman-theme ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.main-chat-blk .group-comman-theme ul li:last-child {
  margin-bottom: 0;
}

.main-chat-blk .group-comman-theme ul li .avatar {
  flex-shrink: 0;
  margin-right: 10px;
}

.main-chat-blk .theme-content h6 {
  margin-bottom: 6px;
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
}

.main-chat-blk .theme-content p {
  margin-bottom: 0;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #262A2A;
  font-size: 14px;
}

.main-chat-blk .chat-message-grp {
  padding: 24px;
  padding-top: 0;
}

.main-chat-blk .chat-message-grp ul {
  padding: 0;
}

.main-chat-blk .chat-message-grp ul li {
  list-style: none;
  margin-bottom: 15px;
}

.main-chat-blk .chat-message-grp ul li:last-child {
  margin-bottom: 0px;
}

.main-chat-blk .chat-message-grp ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.main-chat-blk .chat-message-grp ul li a:hover .stared-group h6 {
  color: #323737;
}

.main-chat-blk .stared-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .stared-group span {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-right: 10px;
  background: #F6F7F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.main-chat-blk .stared-group span i {
  font-size: 16px;
}

.main-chat-blk .stared-group h6 {
  font-size: 15px;
  font-weight: 500;
  color: #6F6F6F;
  margin-bottom: 0;
}

.main-chat-blk .star-message {
  background: #FEF6DC;
}

.main-chat-blk .star-message i {
  color: #FEC14D;
}

.main-chat-blk .mute-message {
  background: rgba(235, 253, 255, 0.9803921569);
}

.main-chat-blk .mute-message i {
  color: #299cdb;
}

.main-chat-blk .block-message {
  background: #FFE8E9;
}

.main-chat-blk .block-message i {
  color: #4A00E5;
}

.main-chat-blk .report-message {
  background: #F8ECFF;
}

.main-chat-blk .report-message i {
  color: #262A2A;
}

.main-chat-blk .delete-message {
  background: #FFE8E9;
}

.main-chat-blk .delete-message i {
  color: #FD3352;
}

.main-chat-blk .count-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .count-group span {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  color: #FFF;
  font-size: 10px;
  background: #E41F07;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 5px;
}

.main-chat-blk .count-group i {
  color: #424242;
  font-size: 16px;
}

.main-chat-blk .nav.nav-tabs.chat-tabs .nav-item .nav-link {
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  background-color: transparent;
  border: 0;
}

.main-chat-blk .nav.nav-tabs.chat-tabs .nav-item .nav-link.active {
  font-size: 16px;
  font-weight: bold;
  color: #E94C39;
}

.main-chat-blk .header-top ul li a {
  color: #000;
  font-size: 18px;
}

.main-chat-blk .set-search {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50px;
  width: 28px;
  height: 28px;
}

.main-chat-blk .set-search:hover {
  background: #F5F5F5;
  border-color: #C0C0C0;
}

.main-chat-blk .header-action {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-chat-blk .header-action li {
  display: inline-block;
  margin-left: 10px;
}

.main-chat-blk .header-action li a {
  color: #262A2A;
  font-size: 18px;
}

.main-chat-blk .user-list {
  border-radius: 0.25rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.main-chat-blk .user-list li a {
  padding: 17px 20px;
  display: flex;
  display: -webkit-flex;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.main-chat-blk .user-list li .avatar {
  margin-right: 0.8rem;
  display: inline-block;
  margin-bottom: 0;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  position: relative;
  z-index: 0;
}

.main-chat-blk .user-list li .avatar .letter-avatar {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  margin-right: 0.8rem;
  margin-bottom: 0;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  background-color: #6F6F6F;
  font-size: 16px;
  font-weight: 600;
  color: #676DFF;
}

.main-chat-blk .user-list li a.admin-shade {
  box-shadow: none;
}

.main-chat-blk .user-list .avatar>a>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-chat-blk .user-list .avatar>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-chat-blk .avatar-away::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 0px;
  right: 2px;
  border: 2px solid #FFF;
  z-index: 9;
  background-color: #ffff00;
}

.main-chat-blk .avatar-offline::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 0px;
  right: 2px;
  border: 2px solid #FFF;
  z-index: 9;
}

.main-chat-blk .avatar-online::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 0px;
  right: 2px;
  border: 2px solid #FFF;
  z-index: 9;
  background-color: #00918E;
}

.main-chat-blk .animate-typing-col {
  color: #5CB85C;
}

.main-chat-blk .animate-typing-col .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: -1px;
  background: #0D9B35;
  -webkit-animation: wave 1.3s linear infinite;
  animation: wave 1.3s linear infinite;
  opacity: 0.6;
}

.main-chat-blk .animate-typing-col .dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.main-chat-blk .animate-typing-col .dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.main-chat-blk .chat_form {
  border-radius: 20px;
  margin-right: 0px;
  background: #FFF;
  border: 1px solid #C2C2C2;
  box-shadow: 0px 4px 4px #F5F8FF;
  border-radius: 6px;
  height: 40px;
  padding: 10px 10px;
}

.main-chat-blk .contact-close a {
  color: #262A2A;
  opacity: 0.5;
  border-color: #898989;
}

.main-chat-blk .main-wrapper .content .sidebar-group.right-sidebar .sidebar {
  width: 310px;
}

.main-chat-blk .primary-title {
  color: #E94C39;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.main-chat-blk .primary-title i {
  font-size: 14px;
}

.main-chat-blk .contact-action {
  padding: 0;
  margin: 0;
  list-style: none;
}

.main-chat-blk .contact-action li {
  padding: 11px 0;
  border-bottom: 1px solid #f1f1f1;
}

.main-chat-blk .contact-action li a {
  color: #262A2A;
  color: #262A2A;
  font-size: 14px;
  font-weight: bold;
}

.main-chat-blk .contact-action li.report-contact a {
  color: #FC0027;
}

.main-chat-blk .contact-action li.delete-chat a {
  color: #FC0027;
}

.main-chat-blk .contact-action li.sign-out a {
  color: #FC0027;
}

.main-chat-blk .left-sidebar {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.main-chat-blk .left-sidebar-wrap {
  background: #FBFBFB;
  overflow: hidden;
  width: 448px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex: 1;
}

.main-chat-blk .right-sidebar {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  background-color: #FDFDFE;
  opacity: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  position: relative;
  width: 320px;
}

.main-chat-blk .right-sidebar .right-sidebar-wrap {
  background-color: #FFF;
  border-left: 1px solid #E0E0E0;
  border-radius: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: calc(100vh - 0px);
}

.main-chat-blk .right-sidebar .right-sidebar-wrap .sidebar-body {
  padding: 0;
}

.main-chat-blk .right-sidebar .right-sidebar-wrap .right-sidebar-profile {
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.main-chat-blk .right-sidebar .contact-close_call .close_profile {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #262A2A;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.main-chat-blk .right-sidebar .contact-close_call .close_profile.close-star {
  color: #FDA700;
}

.main-chat-blk .right-sidebar .contact-close_call .close_profile.close-trash {
  color: #FC0027;
}

.main-chat-blk .right-sidebar.video-right-sidebar {
  width: 371px;
}

.main-chat-blk .right-sidebar.video-right-sidebar .chat {
  margin-left: 0 !important;
  display: block;
}

.main-chat-blk .right-sidebar.video-right-sidebar .chat .chat-body {
  padding-bottom: 110px;
}

.main-chat-blk .video-call-chat .left-chat-title.with-bg {
  background: transparent;
}

.main-chat-blk .video-call-chat .left-chat-title {
  border: 0;
}

.main-chat-blk .video-call-chat .chat-title h4 {
  font-size: 18px;
  font-weight: 600;
  color: #262A2A;
}

.main-chat-blk .video-call-chat .chat-title span {
  font-size: 12px;
  font-weight: 400;
  color: #262A2A;
}

.main-chat-blk .chat.video-call-chat .chat-body {
  background-image: none;
}

.main-chat-blk .chat.video-call-chat .chat-body .messages .chats .message-content {
  background: #F5F5F5;
}

.main-chat-blk .chat.video-call-chat .chat-body .messages .chats.chats-right .message-content {
  background: #F8ECFF;
}

.main-chat-blk .member-details {
  margin-bottom: 10px;
}

.main-chat-blk .chat-member-details {
  padding: 0 24px;
}

.main-chat-blk .chat-member-details .member-details ul {
  padding: 0;
  border-bottom: 1px solid #EDEDED;
}

.main-chat-blk .chat-member-details .member-details ul li {
  list-style: none;
  margin-bottom: 15px;
}

.main-chat-blk .chat-member-details .member-details ul li h6 {
  font-size: 15px;
  font-weight: 400;
  color: #262A2A;
  margin-bottom: 5px;
}

.main-chat-blk .chat-member-details .member-details ul li h5 {
  font-size: 15px;
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 0px;
}

.main-chat-blk .chat-member-details .member-details ul li span {
  font-size: 16px;
  font-weight: 400;
  color: #262A2A;
}

.main-chat-blk .chat-member-details .member-details ul li a {
  font-size: 15px;
  font-weight: 500;
  color: #323737;
  border-radius: 100px;
  padding: 5px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-chat-blk .chat-member-details .member-details ul li a:hover {
  color: #262A2A;
}

.main-chat-blk .chat-member-details .member-details ul li a+a {
  margin-left: 10px;
}

.main-chat-blk .right-sidebar.show-right-sidebar {
  margin-right: 0;
}

.main-chat-blk .right-sidebar.video-right-sidebar.show-right-sidebar {
  margin-right: 0;
}

.main-chat-blk .right-sidebar.hide-right-sidebar {
  margin-right: -320px;
}

.main-chat-blk .right-sidebar.video-right-sidebar.hide-right-sidebar {
  margin-right: -371px;
}

.main-chat-blk .status-modal .custom-status-close {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #262A2A;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}

.main-chat-blk .group-description .description-sub h5 span a {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  color: #262A2A;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  border-color: #C0C0C0;
}

.main-chat-blk .list_group_notread {
  color: #E94C39 !important;
}

.main-chat-blk .chat-cont-type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-chat-blk .chat-cont-type .chat-type-wrapper {
  margin-bottom: 0;
}

.main-chat-blk .chat-cont-type .chat-type-wrapper p {
  color: #6F7A7A;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.main-chat-blk .group-type-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 400;
  color: #262A2A;
  margin-right: 24px;
}

.main-chat-blk .group-type-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.main-chat-blk .group-type-radio input:checked~.checkmark {
  background-color: #323737;
}

.main-chat-blk .group-type-radio input:checked~.checkmark:after {
  display: block;
}

.main-chat-blk .group-type-radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.main-chat-blk .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #FFF;
  border: 1px solid #E4E4E4;
  border-radius: 50%;
}

.main-chat-blk .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.main-chat-blk .pass-login label {
  color: #262A2A;
  font-size: 14px;
  font-weight: 500;
}

.main-chat-blk .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #323737 !important;
}

.main-chat-blk .right-sidebar-profile>.avatar {
  background: rgba(66, 11, 161, 0.1);
}

.main-chat-blk .group_img {
  position: relative;
  display: inline-block;
}

.main-chat-blk .group-name-search {
  margin-bottom: 14px;
}

.main-chat-blk .parti-wrapper {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.main-chat-blk .group_header {
  width: 38px !important;
  height: 38px !important;
  border: 2px solid #FFF;
}

.main-chat-blk .last-chat-time i.missed-col {
  width: 17px;
  display: inline-block;
  margin-top: 10px;
}

.main-chat-blk .last-chat-time i.missed-col img {
  max-width: 100%;
  height: auto;
}

.main-chat-blk .right-sidebar-head {
  padding: 0px 24px;
}

.main-chat-blk .right-sidebar-head h5 {
  font-size: 15px;
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 0px;
}

.main-chat-blk .right-sidebar-head.share-media .share-media-blk {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .nav-tabs {
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .nav .nav-item.active {
  background: #E41F07;
  border-radius: 6px;
  color: #262A2A;
  color: #FFF;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .nav .nav-item {
  padding: 2px 10px;
  font-size: 14px;
  color: #262A2A;
  font-weight: 500;
  border: 0;
  margin-right: 10px;
  background: #F9F9FC;
  color: #262A2A;
  border-radius: 6px;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .nav .nav-item:last-child {
  margin-right: 0px;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li {
  margin: 0px 6px 6px 0px;
  position: relative;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li img {
  border-radius: 8px;
  width: 84px;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li span {
  position: absolute;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li span i {
  font-size: 20px;
  color: #FFF;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li:hover span i {
  color: #898989;
}

.main-chat-blk .right-sidebar-head.share-media .about-media-tabs .share-media-img li.blur-media img {
  filter: blur(2px);
}

.main-chat-blk .media-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}

.main-chat-blk .media-file:last-child {
  margin-bottom: 0;
}

.main-chat-blk .media-doc-blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .media-doc-blk span {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: #F5F5F5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
}

.main-chat-blk .media-doc-blk span i {
  font-size: 24px;
}

.main-chat-blk .document-detail ul {
  padding: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .document-detail ul li {
  list-style: none;
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.main-chat-blk .document-detail ul li::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 13px;
  background: #C2C2C2;
  right: -8px;
  top: 5px;
}

.main-chat-blk .document-detail ul li:last-child::before {
  display: none;
}

.main-chat-blk .document-detail ul li+li {
  padding-left: 15px;
}

.main-chat-blk .document-detail h6 {
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
}

.main-chat-blk .media-download a {
  color: #262A2A;
}

.main-chat-blk .social-media-col {
  margin-top: 35px;
}

.main-chat-blk .social-media-col:after {
  content: "";
  display: table;
  clear: both;
}

.main-chat-blk .social-media-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .social-media-col ul li {
  float: left;
}

.main-chat-blk .social-media-col ul li a {
  color: #676DFF;
  font-size: 18px;
}

.main-chat-blk .social-media-col ul li+li {
  margin-left: 10px;
}

.main-chat-blk .social-media-col h6 {
  font-weight: 600;
  color: #000;
  font-size: 15px;
  margin-bottom: 20px;
}

.main-chat-blk .media-link-grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.main-chat-blk .media-link-detail h6 a {
  color: #262A2A;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.main-chat-blk .media-link-detail span a {
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
}

.main-chat-blk .media-link-detail a:hover {
  color: #323737;
}

.main-chat-blk .share-media-blk a {
  color: #323737;
  font-weight: 500;
}

.main-chat-blk .link-img {
  flex-shrink: 0;
}

.main-chat-blk .link-img img {
  border-radius: 4px;
  margin-right: 10px;
}

.main-chat-blk .settings-col {
  margin-top: 35px;
}

.main-chat-blk .settings-col h6 {
  font-weight: 600;
  color: #000;
  font-size: 15px;
  margin-bottom: 20px;
}

.main-chat-blk .settings-col:after {
  content: "";
  display: table;
  clear: both;
}

.main-chat-blk .settings-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .settings-col ul li {
  color: #565E5E;
  font-weight: 500;
}

.main-chat-blk .settings-col ul li+li {
  margin-top: 15px;
}

.main-chat-blk .settings-col .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
  margin-bottom: 0;
  margin-right: 10px;
}

.main-chat-blk .settings-col .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.main-chat-blk .settings-col .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6F7A7A;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-chat-blk .settings-col .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: #FFF;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main-chat-blk .settings-col input:checked+.slider {
  background-color: #339DFF;
}

.main-chat-blk .settings-col input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.main-chat-blk .settings-col input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.main-chat-blk .settings-col .slider.round {
  border-radius: 34px;
}

.main-chat-blk .settings-col .slider.round:before {
  border-radius: 50%;
}

.main-chat-blk .report-col {
  margin-top: 35px;
  padding: 12px;
  padding-bottom: 50px;
}

.main-chat-blk .report-col:after {
  content: "";
  display: table;
  clear: both;
}

.main-chat-blk .report-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .report-col ul:after {
  content: "";
  display: table;
  clear: both;
}

.main-chat-blk .report-col ul li {
  display: block;
  width: 100%;
  font-weight: 500;
  position: relative;
}

.main-chat-blk .report-col ul li a {
  color: #FC0027;
  padding-left: 25px;
}

.main-chat-blk .report-col ul li a span {
  position: absolute;
  left: 0;
  top: 0px;
  font-size: 16px;
}

.main-chat-blk .report-col ul li+li {
  margin-top: 19px;
}

.main-chat-blk .load-more-btn {
  font-weight: 600;
  margin-top: 10px;
}

.main-chat-blk .load-more-btn i {
  background: #0057FF;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  color: #FFF;
  line-height: 8px;
  font-size: 12px;
  vertical-align: top;
}

.main-chat-blk .chat-message-grp.chat-encrypt {
  padding-bottom: 0;
}

.main-chat-blk .chat-message-grp.chat-encrypt ul {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.main-chat-blk .participants-list {
  padding: 0px 24px;
}

.main-chat-blk .participants-list .participants-list-group h6.parti-search {
  font-size: 20px !important;
  display: flex;
  align-items: center;
}

.main-chat-blk .participants-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .participants-list ul li {
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  background-color: #FFF;
  padding: 10px;
}

.main-chat-blk .participants-list ul li+li {
  margin-top: 10px;
}

.main-chat-blk .participants-list .users-list-body {
  flex: 1;
  position: relative;
  min-width: 0px;
  display: flex;
  display: -webkit-flex;
  width: 100%;
}

.main-chat-blk .participants-list .users-list-body h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #262A2A;
}

.main-chat-blk .participants-list .users-list-body p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #262A2A;
  font-size: 14px;
  font-weight: 400;
}

.main-chat-blk .participants-list .admin-profiles {
  display: inline-block;
  color: #323737;
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 500;
  background: #F8ECFF;
}

.main-chat-blk .participants-list .admin-profiles:hover {
  background: #680A83;
  color: #FFF;
}

.main-chat-blk .participants-list-group {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.main-chat-blk .participants-list-group h6 {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #262A2A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.main-chat-blk .chat-message-grp.group-exits {
  padding: 0px 24px;
  padding-bottom: 30px;
}

.main-chat-blk .grp-delete .chat-action-col {
  display: flex;
  align-items: center;
  height: 100%;
}

.main-chat-blk .grp-delete .chat-action-col>a {
  color: #6F7A7A;
}

.main-chat-blk .chat-action-col>a {
  color: #262A2A;
}

.main-chat-blk .darkmode-btn {
  width: 35px;
  height: 32px;
  background-color: #339DFF;
  border: 1px solid #FBFBFB;
  border-radius: 5px;
  color: #FFF;
  font-size: 16px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 0 auto;
}

.main-chat-blk .darkmode-btn:hover {
  color: #FFF;
}

.main-chat-blk .darkmode-btn:focus {
  color: #FFF;
}

.main-chat-blk .add-contacts-btn {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  background-color: #323737;
  color: #FFF;
  font-size: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 0 auto;
}

.main-chat-blk .add-contacts-btn:hover {
  color: #FFF;
}

.main-chat-blk .add-contacts-btn:focus {
  color: #FFF;
}

.main-chat-blk .group-call-tab .nav-tabs .nav-link {
  color: #323737;
  border: 0;
  border-radius: 10px;
  font-weight: 500;
  padding: 6px 25px;
}

.main-chat-blk .group-call-tab .nav-tabs .nav-link:hover {
  color: #323737;
}

.main-chat-blk .group-call-tab .nav-tabs .nav-link+.nav-link {
  margin-left: 10px;
}

.main-chat-blk .group-call-tab .nav-tabs .nav-link.active {
  background-color: rgba(90, 7, 139, 0.1);
  color: #323737;
}

.main-chat-blk .group-call-tab nav {
  padding: 0 15px 20px;
  position: relative;
}

.main-chat-blk .group-call-tab nav:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #6F6F6F;
  height: 1px;
}

.main-chat-blk .header_image {
  width: 195px;
}

.main-chat-blk .chat_avator_img {
  position: relative;
}

.main-chat-blk .group_img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.main-chat-blk .dreams_chat {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.main-chat-blk .voice_pop {
  padding-right: 17px;
}

.main-chat-blk .status_carousel {
  margin-top: 50px;
}

.main-chat-blk .carousel_img {
  width: 650px;
  height: 434px;
}

.main-chat-blk .video_content {
  min-height: 200px;
  margin-top: 70px;
}

.main-chat-blk .voice-call-content {
  min-height: 200px;
}

.main-chat-blk .chatimage img {
  width: 120px;
  height: 120px;
}

.main-chat-blk .send-btn_status {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF !important;
  background-color: #FFA977 !important;
  margin-left: 15px;
  font-size: 22px;
}

.main-chat-blk .status_telegram {
  color: #FFF;
}

.main-chat-blk .media-lists {
  display: flex;
  justify-content: flex-start;
  margin: 0 -5px 0px;
  flex-wrap: wrap;
}

.main-chat-blk .media-lists .media-image {
  margin: 5px;
  flex-basis: 74px;
  min-width: 74px;
}

.main-chat-blk .media-lists .media-image img {
  max-width: 100%;
}

.main-chat-blk .avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.main-chat-blk .list-group-item {
  background-color: inherit;
  border-color: #ebebeb;
}

.main-chat-blk ul.list-inline .list-inline-item {
  margin-bottom: 0.5rem;
}

.main-chat-blk .form-control {
  border-radius: 5px;
}

.main-chat-blk .form-control:focus {
  box-shadow: none;
  border-color: #e6e6e6;
}

.main-chat-blk .custom-control-label {
  line-height: 25px;
}

.main-chat-blk .custom-control-input:checked~.custom-control-label::before {
  background-color: #0a80ff;
}

.main-chat-blk .open_drop {
  color: #FFF !important;
}

.main-chat-blk .send-btn_status:focus {
  box-shadow: none !important;
  border-color: #ffa977 !important;
}

.main-chat-blk .avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.main-chat-blk .avatar .avatar-title {
  color: #FFF;
  background: #680A83;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 600;
}

.main-chat-blk .avatar>a {
  width: 100%;
  height: 100%;
  display: block;
  transition: color 0.3s;
  color: #0a80ff;
}

.main-chat-blk .avatar>a>img,
.main-chat-blk .avatar>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-chat-blk .avatar.avatar-sm {
  height: 1.3rem;
  width: 1.3rem;
}

.main-chat-blk .avatar.avatar-sm .avatar-title {
  font-size: 14px;
}

.main-chat-blk .avatar.avatar-lg {
  height: 3.8rem;
  width: 3.8rem;
}

.main-chat-blk .avatar.avatar-lg .avatar-title {
  font-size: 29px;
}

.main-chat-blk .avatar.avatar-xl {
  height: 100px;
  width: 100px;
}

.main-chat-blk .header_drop_icon {
  border-radius: 6px;
}

.main-chat-blk .accordion-col .accordion-title {
  cursor: pointer;
  padding: 15px 0 8px;
}

.main-chat-blk .accordion-col .accordion-title .primary-title i {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.main-chat-blk .accordion-col .accordion-title.active .primary-title i {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
}

.main-chat-blk .accordion-col .accordion-content {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}

.main-chat-blk .accordion-col .accordion-content:last-child {
  border-bottom: 0;
}

.main-chat-blk .accordion-col .accordion-content h6 {
  font-size: 14px;
}

.main-chat-blk .status-right {
  font-weight: 500;
  color: #444444;
  font-size: 16px;
  text-align: center;
}

.main-chat-blk .status-right p {
  margin-top: 20px;
  margin-bottom: 0;
}

.main-chat-blk .star-drop .btn-outline-light {
  color: #424242;
  border: 0;
  padding: 0;
}

.main-chat-blk .star-drop .btn-outline-light i {
  font-size: 20px;
}

.main-chat-blk .star-drop .btn.btn-outline-light:hover {
  background: transparent;
  border: 0;
}

.main-chat-blk .chat.star-chat-group {
  background-image: none;
}

.main-chat-blk .chat.star-chat-group .chat-body {
  background-image: none;
}

.main-chat-blk .chat.star-chat-group .chat-body .messages .chats {
  max-width: 100%;
}

.main-chat-blk .chat.star-chat-group .chat-body .messages .chats .message-content {
  background: #F5F5F5;
}

.main-chat-blk .chat.star-chat-group .chat-body .messages .chats .message-content.award-link a {
  word-break: break-all;
}

.main-chat-blk .chat.star-chat-group .chat-body .messages .chats .message-content.award-link img {
  max-width: 100%;
}

.main-chat-blk .chat .chat-body .messages .chats.forward-chat-msg {
  display: none;
}

.main-chat-blk .chats.forward-chat-msg {
  background: #EDEDED;
  border-radius: 15px;
  padding: 20px;
  border-left: 2px solid #680A83;
}

.main-chat-blk .chats.forward-chat-msg .chat-avatar {
  padding: 0;
  padding-right: 15px;
}

.main-chat-blk .chat-title h4 a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #4B0973;
  margin-bottom: 0;
}

.main-chat-blk .left-chat-title .add-section>a {
  width: 30px;
  height: 30px;
  background-color: #680A83;
  border-radius: 30px;
  color: #FFF;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  font-size: 13px;
}

.main-chat-blk .add-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-chat-blk .add-section ul li {
  float: left;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.main-chat-blk .add-section ul li+li {
  margin-left: 10px;
}

.main-chat-blk .add-section ul li a {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  font-size: 20px;
}

.main-chat-blk .all-chats .add-section ul li:hover {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.main-chat-blk .online {
  color: #008024;
  font-weight: 500;
}

.main-chat-blk .top-online-contacts {
  padding: 24px 24px 0px;
}

.main-chat-blk .fav-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}

.main-chat-blk .fav-title h6 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .fav-title h6 i {
  font-size: 22px;
}

.main-chat-blk .fav-title a {
  margin-bottom: 0;
  color: #E41F07;
  font-weight: 500;
}

.main-chat-blk .fav-title a:hover {
  color: #0A0A0A;
}

.main-chat-blk .top-contacts-box .profile-img {
  position: relative;
  width: 46px;
  height: 46px;
}

.main-chat-blk .top-contacts-box .profile-img img {
  max-width: 100%;
  height: auto;
  border-radius: 50px;
}

.main-chat-blk .profile-name span {
  color: #585858;
  font-size: 12px;
  display: inline-block;
  margin-top: 9px;
}

.main-chat-blk .top-contacts-box .profile-img.online:before {
  content: "";
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  top: 0px;
  background-color: #49E073;
  width: 10px;
  height: 10px;
  border: 2px solid #FFF;
  border-radius: 50px;
}

.main-chat-blk .dropdown-menu .dropdown-item span.edit-profile-icon,
.main-chat-blk .dropdown-menu .dropdown-item span.profile-icon-col {
  font-size: 15px;
}

.main-chat-blk .avatar {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  flex-shrink: 0;
}

.main-chat-blk .avatar>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-chat-blk .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #650681;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-chat-blk .avatar-away::before,
.main-chat-blk .avatar-offline::before,
.main-chat-blk .avatar-online::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #FFF;
}

.main-chat-blk .avatar-online::before {
  background-color: #00e65b;
}

.main-chat-blk .avatar-offline::before {
  background-color: #ff0100;
}

.main-chat-blk .avatar-away::before {
  background-color: #ffbc34;
}

.main-chat-blk .avatar .border {
  border-width: 3px !important;
}

.main-chat-blk .avatar .rounded {
  border-radius: 6px !important;
}

.main-chat-blk .avatar .avatar-title {
  font-size: 18px;
}

.main-chat-blk .avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.main-chat-blk .avatar-xs .border {
  border-width: 2px !important;
}

.main-chat-blk .avatar-xs .rounded {
  border-radius: 4px !important;
}

.main-chat-blk .avatar-xs .avatar-title {
  font-size: 10px;
}

.main-chat-blk .avatar-xs.avatar-away::before,
.main-chat-blk .avatar-xs.avatar-offline::before,
.main-chat-blk .avatar-xs.avatar-online::before {
  border-width: 1px;
}

.main-chat-blk .avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.main-chat-blk .avatar-sm .border {
  border-width: 3px !important;
}

.main-chat-blk .avatar-sm .rounded {
  border-radius: 4px !important;
}

.main-chat-blk .avatar-sm .avatar-title {
  font-size: 15px;
}

.main-chat-blk .avatar-sm.avatar-away::before,
.main-chat-blk .avatar-sm.avatar-offline::before,
.main-chat-blk .avatar-sm.avatar-online::before {
  border-width: 2px;
}

.main-chat-blk .avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.main-chat-blk .avatar-lg .border {
  border-width: 3px !important;
}

.main-chat-blk .avatar-lg .rounded {
  border-radius: 8px !important;
}

.main-chat-blk .avatar-lg .avatar-title {
  font-size: 24px;
}

.main-chat-blk .avatar-lg.avatar-away::before,
.main-chat-blk .avatar-lg.avatar-offline::before,
.main-chat-blk .avatar-lg.avatar-online::before {
  border-width: 3px;
}

.main-chat-blk .avatar-xl {
  width: 5rem;
  height: 5rem;
}

.main-chat-blk .avatar-xl .border {
  border-width: 4px !important;
}

.main-chat-blk .avatar-xl .rounded {
  border-radius: 8px !important;
}

.main-chat-blk .avatar-xl .avatar-title {
  font-size: 28px;
}

.main-chat-blk .avatar-xl.avatar-away::before,
.main-chat-blk .avatar-xl.avatar-offline::before,
.main-chat-blk .avatar-xl.avatar-online::before {
  border-width: 4px;
}

.main-chat-blk .avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

.main-chat-blk .avatar-xxl .border {
  border-width: 6px !important;
}

.main-chat-blk .avatar-xxl .rounded {
  border-radius: 8px !important;
}

.main-chat-blk .avatar-xxl .avatar-title {
  font-size: 30px;
}

.main-chat-blk .avatar-xxl.avatar-away::before,
.main-chat-blk .avatar-xxl.avatar-offline::before,
.main-chat-blk .avatar-xxl.avatar-online::before {
  border-width: 4px;
}

.main-chat-blk .avatar-group {
  display: flex;
  display: -webkit-flex;
  padding-left: 15px;
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
}

.main-chat-blk .avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.main-chat-blk .avatar-group .avatar-xs+.avatar-xs {
  margin-left: -15px;
}

.main-chat-blk .avatar-group .avatar-sm+.avatar-sm {
  margin-left: -0.625rem;
}

.main-chat-blk .avatar-group .avatar-lg+.avatar-lg {
  margin-left: -1rem;
}

.main-chat-blk .avatar-group .avatar-xl+.avatar-xl {
  margin-left: -1.28125rem;
}

.main-chat-blk .avatar-group .avatar:hover {
  z-index: 1;
}

.main-chat-blk .avatar-group.group-col {
  display: block;
}

.main-chat-blk .avatar-group.group-col>div {
  display: flex;
  display: -webkit-flex;
  padding-bottom: 5px;
}

.main-chat-blk .avatar-group.group-col .avatar {
  margin-right: 0;
}

.main-chat-blk .contact-close {
  float: right;
  left: 95%;
  right: 0px;
  position: relative;
  top: 5%;
}

.main-chat-blk .status_contactclose {
  float: right;
  left: 95%;
  right: 0px;
  position: relative;
  top: 1%;
}

.main-chat-blk .status-active {
  border: 4px solid #650681;
}

.main-chat-blk .avatar.avatar-xl .avatar-title {
  font-size: 39px;
}

.main-chat-blk .nav.nav-tabs {
  border: none;
  line-height: 2;
}

.main-chat-blk .badge {
  padding: 5px 10px;
  font-size: 11px;
}

.main-chat-blk .badge.badge-success {
  background: #0abb87;
}

.main-chat-blk .badge.badge-danger {
  background: #fd397a;
}

.main-chat-blk .badge.badge-secondary {
  background: dimgray;
}

.main-chat-blk .badge.badge-info {
  background: #00bcd4;
}

.main-chat-blk .badge.badge-warning {
  background: #ffb822;
}

.main-chat-blk .badge.badge-dark {
  background: #3a3f51;
}

.main-chat-blk .badge.badge-primary {
  background: #0a80ff;
}

.main-chat-blk .badge.badge-light {
  background: #e6e6e6;
}

.main-chat-blk .btn {
  position: relative;
}

.main-chat-blk .btn .badge {
  padding: 2px 6px;
  right: 7px;
  top: -7px;
  position: absolute;
}

.main-chat-blk .input-group .input-group-text {
  font-size: 14px;
}

.main-chat-blk .tooltip>.tooltip-inner {
  text-align: left;
  max-width: 500px;
  --bs-tooltip-bg: $primary;
}

.main-chat-blk .tooltip {
  --bs-tooltip-bg: $primary;
}

.main-chat-blk .tooltip .arrow {
  display: none;
  background-color: #E41F07;
}

.main-chat-blk .tooltip .tooltip-inner {
  background-color: #E41F07;
}

.main-chat-blk .sticky-top {
  z-index: auto;
}

.main-chat-blk .tab-content {
  padding-top: 30px;
}

.main-chat-blk .tab-content .form-item {
  margin-bottom: 0.8rem;
}

.main-chat-blk .main-wrapper {
  height: 100vh;
  overflow: hidden;
}

.main-chat-blk .main-wrapper .content {
  display: flex;
  display: -webkit-flex;
}

.main-chat-blk .main-wrapper .content .sidebar-group {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
  width: 340px;
}

@media (max-width: 767.98px) {
  .main-chat-blk .main-wrapper .content .sidebar-group {
    width: 100%;
  }
}

.main-chat-blk .main-wrapper .content .sidebar-group .sidebar {
  background: #FFF;
  overflow: hidden;
  width: 340px !important;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid rgb(224, 224, 224);
}

@media (max-width: 767.98px) {
  .main-chat-blk .main-wrapper .content .sidebar-group .sidebar {
    width: 100% !important;
  }
}

.main-chat-blk .main-wrapper .content .sidebar-group .sidebar:not(.active) {
  display: none;
}

.main-chat-blk .header-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: 600;
  background-color: #680A83;
}

.main-chat-blk .header-top .logo {
  margin-top: 0 !important;
}

.main-chat-blk .header-top>span {
  font-size: 22px;
}

.main-chat-blk .header-top ul {
  margin-bottom: 0;
  display: flex;
}

.main-chat-blk .header-top ul.header-action {
  margin-top: 14px !important;
}

.main-chat-blk .chat-header ul {
  margin-bottom: 0;
}

.main-chat-blk .chat-header ul li:not(.list-inline-item) {
  margin-bottom: 0;
  width: 48px;
  margin-right: 15px !important;
}

.main-chat-blk .chat-header ul li:last-child {
  margin-right: 0 !important;
}

.main-chat-blk .sidebar-body {
  flex: 1;
  overflow: auto;
  padding: 24px 24px 10px;
  width: 100%;
}

.main-chat-blk .pin-chat img {
  margin-right: 6px;
}

.main-chat-blk .sidebar-body .profile-name {
  font-weight: 600;
  color: #0A0A0A;
  font-size: 18px;
  margin-bottom: 0;
}

.main-chat-blk .online-profile {
  position: relative;
}

.main-chat-blk .online-profile span {
  position: relative;
  color: #565656;
  font-size: 13px;
  padding-left: 10px;
}

.main-chat-blk .online-profile span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  background-color: #49E073;
  width: 8px;
  height: 8px;
  border-radius: 50px;
}

.main-chat-blk .close_profile4 {
  color: #000;
  border-color: #e6e6e6;
}

.main-chat-blk .sidebar .user-list li a {
  padding: 10px;
  display: flex;
  display: -webkit-flex;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #E0E0E0;
  box-shadow: none;
  border-radius: 5px;
  position: relative;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}

.main-chat-blk .sidebar .user-list li a:hover {
  background-color: rgba(254, 159, 67, 0.08);
  border: 1px solid rgba(254, 159, 67, 0.08);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}

.main-chat-blk .sidebar .user-list li.item-typing {
  background-color: #F1F6FF;
}

.main-chat-blk .sidebar .user-list li figure {
  margin-right: 1rem;
}

.main-chat-blk .sidebar .user-list li .users-list-body {
  flex: 1;
  position: relative;
  min-width: 0px;
  display: flex;
}

.main-chat-blk .lock-icon {
  color: #000000;
}

.main-chat-blk .sidebar .user-list li .users-list-body>div:first-child {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-chat-blk .parti-notify-check {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.main-chat-blk .sidebar .user-list li .users-list-body h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  color: #0A0A0A;
}

.main-chat-blk .sidebar .user-list li .users-list-body p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #232323;
  font-size: 14px;
}

.main-chat-blk .sidebar .user-list li .users-list-body p.missed-call-col {
  color: #DE3E44;
}

.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time {
  padding-left: 15px;
  position: relative;
}

.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .text-muted {
  color: #424242 !important;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  text-align: right;
}

.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .contact-toggle {
  color: #680A83;
}

.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .new-message-count {
  width: 20px;
  height: 20px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  line-height: 0;
  font-size: 11px;
  background-color: #E41F07;
  color: #FFF;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .chat-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: right;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  width: 18px;
  height: 24px;
}

.main-chat-blk .sidebar .user-list li.unread h5 {
  color: #FFF;
}

.main-chat-blk .sidebar .user-list li.unread p {
  color: #FFF;
}

.main-chat-blk .sidebar .user-list li.unread .text-muted {
  color: rgba(255, 255, 255, 0.4) !important;
}

.main-chat-blk .sidebar .user-list li.user-list-item.item-typing .users-list-body p {
  color: #0D9B35;
}

.main-chat-blk .rightside_tab {
  padding-top: 5px !important;
}

.main-chat-blk .chat-pin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.main-chat-blk .chat-pin .green-check {
  color: #0D9B35;
}

.main-chat-blk .chat-pin img {
  width: 10px;
}

.main-chat-blk .chat-pin .check {
  color: #53BDEB;
}

.main-chat-blk .select-message-box {
  margin-top: 50px;
}

.main-chat-blk .select-message-box h4 {
  margin-bottom: 5px;
}

.main-chat-blk .select-message-box p {
  margin-top: 0;
  margin-bottom: 24px;
  color: #424242;
  font-size: 16px;
  font-weight: 400;
}

.main-chat-blk .select-message-box .btn i {
  font-size: 15px;
}

.main-chat-blk .group-description {
  padding: 0px 24px;
}

.main-chat-blk .group-description .description-sub {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.main-chat-blk .group-description h5 {
  font-size: 15px;
  font-weight: 500;
  color: #0A0A0A;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.main-chat-blk .group-description h5 a:hover {
  color: #650681 !important;
}

.main-chat-blk .group-description p {
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.main-chat-blk .group-description p.description-date {
  color: #424242;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}

.main-chat-blk .encrypted-message p {
  font-size: 12px;
  color: #424242;
  font-weight: 400;
  margin-bottom: 0;
}

.main-chat-blk .group-view-all {
  margin-top: 10px;
}

.main-chat-blk .group-view-all a {
  color: #680A83;
  font-size: 14px;
  font-weight: 500;
}

.main-chat-blk .group-view-all a:hover {
  color: #0A0A0A;
}

.main-chat-blk .exit-bg-message {
  background: #FFE8E9;
}

.main-chat-blk .exit-bg-message i {
  color: #DE3E44;
}

.main-chat-blk .disapper-message {
  background: #E7FAF2;
}

.main-chat-blk .disapper-message i {
  color: #1BA345;
}

.main-chat-blk .group-setting-msg {
  background: #F8ECFF;
}

.main-chat-blk .group-setting-msg i {
  color: #680A83;
}

.main-chat-blk .group-setting .stared-group span {
  width: 50px;
  height: 50px;
}

.main-chat-blk .edit-set-details p {
  font-size: 14px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 0;
}

.main-chat-blk .group-send-msg {
  background: #FEF6DC;
}

.main-chat-blk .parcipant-check p a {
  color: #680A83;
}

.main-chat-blk .select-group-chat .dropdown-toggle::after {
  display: none;
}

.main-chat-blk .select-group-chat .dropdown-toggle i {
  margin-left: 15px;
}

.main-chat-blk .select-group-chat .dropdown-toggle:hover {
  color: #0A0A0A;
}

.main-chat-blk .select-group-chat .dropdown-toggle {
  font-weight: 600;
  color: #0A0A0A;
  font-size: 18px;
}

.main-chat-blk .select-group-chat .dropdown-menu .dropdown-item {
  font-size: 14px;
  font-weight: 400;
  color: #0A0A0A;
}

.main-chat-blk .select-group-chat .dropdown-menu li:first-child .dropdown-item:hover {
  border-radius: 5px 5px 0 0;
}

.main-chat-blk .select-group-chat .dropdown-menu li:last-child .dropdown-item:hover {
  border-radius: 0 0 5px 5px;
}

.main-chat-blk .dropzone {
  background: #FFF;
  border: 1px dashed #C2C2C2;
  border-radius: 5px;
  padding: 10px;
  min-height: 70px;
  text-align: center;
}

.main-chat-blk .dropzone.dz-clickable .dz-message {
  display: none;
}

.main-chat-blk .dropzone p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #0A0A0A;
}

.main-chat-blk .dropzone span {
  color: #424242;
  font-size: 14px;
  font-weight: 400;
}

.main-chat-blk .uplaod-image img {
  border-radius: 20px;
}

.main-chat-blk .upload-img-file .modal-body {
  padding: 0;
}

.main-chat-blk .upload-img-file .modal-content {
  position: relative;
}

.main-chat-blk .upload-img-file .modal-header {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 0;
  right: 0;
}

.main-chat-blk .upload-image-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-chat-blk .upload-image-list li+li {
  margin-left: 10px;
}

.main-chat-blk .upload-img-file .modal-header .close {
  background-color: transparent;
  color: #FFF;
}

.main-chat-blk .check-star i {
  color: #FEC001;
}

.main-chat-blk .emoj-group-list {
  display: none;
  position: absolute;
  top: -50px;
  left: -90px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.main-chat-blk .emoj-group-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f6f7f8;
  border-radius: 50px;
  padding: 5px;
}

.main-chat-blk .emoj-group-list ul li a {
  padding: 0;
}

.main-chat-blk .emoj-group-list ul li.add-emoj a {
  background: #EDEDED;
}

.main-chat-blk .emoj-group-list ul li.add-emoj a i {
  font-size: 20px;
  color: #424242;
}

.main-chat-blk .chat .slimscroll {
  height: calc(100vh - 60px) !important;
}

.main-chat-blk .settings-option {
  display: none;
  text-align: right;
}

.main-chat-blk .settings-option a {
  font-size: 15px;
  font-weight: 500;
  color: #680A83;
}

.main-chat-blk .empty-dark-img {
  display: none;
}

.main-chat-blk .bootstrap-datetimepicker-widget span {
  position: relative;
}

.chat-window .card .card-header {
  background-color: #FFF;
  border-bottom: 1px solid #898989;
}

.chat-window .badge-success {
  background-color: #5CB85C !important;
}

.chat-page .footer {
  display: none;
}

.chat-search .input-group .form-control {
  margin: 0;
  padding: 0 0 0 36px;
  height: 46px;
}

.chat-search .input-group .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #424848;
  pointer-events: none;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
}

.chat-cont-left .chat-scroll {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0;
}

.chat-cont-left .chat-scroll .media {
  border-bottom: 1px solid #898989;
  margin: 0;
  padding: 10px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.chat-cont-left .chat-scroll .media.active {
  background-color: #C0C0C0;
}

.chat-cont-left .chat-scroll .media:hover {
  background-color: #C0C0C0;
}

.chat-cont-left .chat-scroll .media .media-img-wrap {
  margin: 0 15px 0 0;
  position: relative;
}

.chat-cont-left .chat-scroll .media .media-img-wrap .avatar>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat-cont-left .chat-scroll .media .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.chat-cont-left .chat-scroll .media .media-body .user-name {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #262A2A;
  font-size: 16px;
  font-weight: 700;
}

.chat-cont-left .chat-scroll .media .media-body .user-last-chat {
  color: #323737;
  font-size: 14px;
  line-height: 24px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-cont-left .chat-scroll .media .media-body .last-chat-time {
  color: #565E5E;
  font-size: 13px;
}

.chat-cont-left .chat-scroll .media .media-body div:last-child {
  text-align: right;
}

.chat-cont-right {
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}

.chat-cont-right .chat-scroll {
  max-height: calc(100vh - 293px);
  overflow-y: auto;
}

.chat-cont-right .msg_head .back-user-list {
  display: none;
  margin: 0 0 0 -10px;
  padding: 0 10px;
}

.chat-cont-right .img_cont {
  position: relative;
  height: 45px;
  width: 45px;
}

.chat-cont-right .img_cont .user_img {
  height: 45px;
  min-width: 45px;
  width: 45px;
  border: 1.5px solid #E41F07;
}

.chat-cont-right .user_info {
  margin: 0 auto 0 15px;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-cont-right ul.list-unstyled {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent {
  margin-bottom: 20px;
}

.chat-cont-right ul.list-unstyled .media.sent .avatar {
  order: 3;
  height: 30px;
  width: 30px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 20px 0 0;
  padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box {
  margin: 0;
  padding: 0 0 0 50px;
  position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box+.msg-box {
  margin: 5px 0 0;
  padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box p {
  margin: 0;
  padding: 0;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box:first-child::before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid #e3e3e3;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  right: -12px;
  left: auto;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box>div {
  margin: 0;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #e3e3e3;
  display: inline-block;
  position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box:first-child>div {
  border-radius: 10px 0 10px 10px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -1px;
  padding: 4px 0;
  width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments>div {
  margin: 0 1px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments img {
  max-width: 100%;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-attachments .chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  color: #FFF;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin: 5px 0 0;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  clear: both;
  flex-direction: row-reverse;
  list-style: none;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info li {
  margin: 0;
  padding: 0 0 0 16px;
  position: relative;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info li:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #E41F07;
  content: "";
  height: 4px;
  width: 4px;
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-cont-right ul.list-unstyled .media.sent .media-body .chat-msg-info a {
  color: #323737;
}

.chat-cont-right ul.list-unstyled .media.received {
  position: relative;
  margin-bottom: 20px;
}

.chat-cont-right ul.list-unstyled .media.received .media-body {
  position: relative;
  margin: 0 0 0 20px;
  padding: 0;
}

.chat-cont-right ul.list-unstyled .media.received .media-body::before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #E41F07;
  border-top: 6px solid #E41F07;
  border-left: 6px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  top: 0px;
  right: auto;
  left: -6px;
  width: 0;
}

.chat-cont-right ul.list-unstyled .media.received .media-body>div {
  margin: 0;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #E41F07;
  display: table;
  position: relative;
}

.chat-cont-right ul.list-unstyled .media.received .media-body>div p {
  color: #FFF;
  margin: 0;
  padding: 0;
}

.chat-cont-right ul.list-unstyled .media.received .msg-box {
  display: block;
}

.chat-cont-right ul.list-unstyled .media.received .msg-box+.msg-box {
  margin-top: 5px;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 5px 0 0;
  padding: 0;
  clear: both;
  list-style: none;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-info .chat-time {
  color: rgba(255, 255, 255, 0.8);
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -1px;
  padding: 4px 0;
  width: 100%;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments>div {
  margin: 0 1px;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments img {
  max-width: 100%;
}

.chat-cont-right ul.list-unstyled .media.received .chat-msg-attachments .chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
  color: #FFF;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

.chat-cont-right ul.list-unstyled .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.chat-cont-right ul.list-unstyled .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100%;
}

.chat-cont-right ul.list-unstyled .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin: 0 0 0 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
}

.card-footer {
  background-color: #FFF;
  border-top: 1px solid #898989;
  margin: 0;
  padding: 1rem 1.5rem;
}

.card-footer .type_msg {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .chat-cont-right {
    position: absolute;
    right: -100px;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
  }

  .chat-cont-right .msg_head .back-user-list {
    display: flex;
    align-items: center;
  }
}

.page-header .breadcrumb {
  margin-bottom: 0;
}

.page-header h3 {
  font-weight: 700;
  color: #262A2A;
  font-size: 18px;
}

@media (max-width: 991.98px) {
  .page-header h3 {
    font-size: 14px;
  }
}

.page-header h4 {
  font-weight: 700;
  color: #262A2A;
  font-size: 18px;
  margin-bottom: 5px;
}

@media (max-width: 991.98px) {
  .page-header h4 {
    font-size: 18px;
  }
}

.page-header h6 {
  font-size: 14px;
  color: #424848;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .page-header h6 {
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .page-header h6 {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.page-header .btn-filter {
  background: #E41F07;
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 0;
}

.page-header .btn-filter:hover {
  background: linear-gradient(46.62deg, #E41F07 0%, #676DFF 93.64%);
}

.page-header .page-btn {
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .page-header .page-btn {
    margin-left: 0;
    margin-top: 20px;
  }
}

.page-header .btn-added {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: #E41F07;
  padding: 8px 11px;
  color: #FFF;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  border-radius: 8px;
}

.page-header .btn-added:active {
  color: #FFF;
}

.page-header .btn-added img {
  filter: brightness(0) invert(1);
}

@media (max-width: 991.98px) {
  .page-header .btn-added {
    padding: 10px 10px;
  }
}

.page-header .btn-added:hover {
  background: #262A2A;
}

.page-header .btn-added .me-2 {
  width: 16px;
  height: 16px;
}

.page-header .btn-added.downloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: #262A2A;
  padding: 9px 11px;
  color: #FFF;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  margin: 0 15px 0 15px;
}

.page-header .btn-added.downloader img {
  filter: brightness(0) invert(1);
}

@media (max-width: 991.98px) {
  .page-header .btn-added.downloader {
    padding: 10px 10px;
  }
}

.page-header .btn-added.downloader:hover {
  background: #E41F07;
}

.page-header .btn-added.downloader .me-2 {
  width: 16px;
  height: 16px;
}

.page-header .btn-added.printer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: #FC0027;
  padding: 9px 11px;
  color: #FFF;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
}

.page-header .btn-added.printer img {
  filter: brightness(0) invert(1);
}

@media (max-width: 991.98px) {
  .page-header .btn-added.printer {
    padding: 10px 10px;
  }
}

.page-header .btn-added.printer:hover {
  background: #262A2A;
}

.page-header .btn-added.printer .me-2 {
  width: 16px;
  height: 16px;
}

@media (max-width: 767.98px) {
  .page-header .add-item {
    display: block !important;
  }

  .page-header .add-item .page-btn {
    margin-left: 0;
  }
}

@media (max-width: 575.98px) {
  .page-header .add-item .page-btn {
    margin-left: 0;
    margin-bottom: 24px;
  }
}

.page-header .add-item .page-title.modal-datail {
  line-height: 2;
}

.page-header .add-item.d-flex {
  margin-right: auto;
}

.page-header .add-item.payslip-list {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
}

.page-wrapper.details-blk {
  padding: 0;
  margin: 0;
}

.history-modal-profile .content {
  padding: 24px;
}

.history-modal-profile .right-sidebar-profile {
  background: #F9F9FC;
  border-radius: 8px;
  padding: 24px;
}

.history-modal-profile .right-sidebar-profile .list-inline-item a {
  background: #E41F07;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.history-modal-profile .right-sidebar-profile .list-inline-item a:hover {
  background: #1b2950;
  color: #FFF !important;
}

.history-modal-profile .right-sidebar-profile .avatar {
  margin-bottom: 24px;
  width: 150px;
  height: 150px;
}

.history-modal-profile .right-sidebar-profile .avatar img {
  border-radius: 8px;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  background-color: #FFF;
  display: block;
  opacity: 0.4;
  margin: 0 1px;
  border-radius: 50%;
}

.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}

.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}

.msg-typing span :nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.phone-call-icon svg {
  width: 18px;
}

.phone-call-icon .income-success {
  color: #5CB85C;
}

.phone-call-icon .income-success-missed {
  color: #FC0027;
}

.history-modal-profile .content {
  padding: 24px;
}

.history-modal-profile .right-sidebar-profile {
  background: #F9F9FC;
  border-radius: 8px;
  padding: 24px;
}

.history-modal-profile .right-sidebar-profile .list-inline-item a {
  background: #E41F07;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  color: #FFF !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.history-modal-profile .right-sidebar-profile .list-inline-item a:hover {
  background: #1b2950;
  color: #FFF !important;
}

.history-modal-profile .right-sidebar-profile .avatar {
  margin-bottom: 24px;
  width: 150px;
  height: 150px;
}

.history-modal-profile .right-sidebar-profile .avatar img {
  border-radius: 8px;
}

.modal-profile-detail .modal-userlist li {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

.modal-profile-detail .modal-userlist li span {
  display: block;
  color: #6F6F6F;
}

.modal-profile-detail .modal-userlist li:last-child {
  margin-bottom: 0;
}

.img-cropper .dropup .dropdown-toggle::after {
  content: "";
  display: none;
}

.dropdown-auto {
  overflow: auto;
}

.dropdown-divider {
  margin: 0;
}

.dropdown .dropdown-toggle:after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropdown .dropdown-toggle.btn-primary {
  color: #FFF;
}

.dropdown .dropdown-menu {
  font-family: "Noto Sans", sans-serif;
}

.dropdown-menu {
  border-color: #6F6F6F;
  box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, 0.15);
  font-size: 0.875rem;
  color: #6F6F6F;
  background-color: #FFF;
  padding: 0;
  z-index: 10;
}

.dropdown-divider {
  border-color: #6F6F6F;
}

.dropdown-item-text {
  color: #6F6F6F;
}

.dropdown-header {
  color: #6F6F6F;
}

.dropdown-item {
  color: #6F6F6F;
  white-space: unset;
  padding: 0.5rem 0.9375rem;
  font-size: 0.8125rem;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6F6F6F;
  opacity: 0.5;
}

.dropdown-item:not(.active):hover,
.dropdown-item:not(.active):focus,
.dropdown-item:not(.active):active {
  color: #E41F07;
  background-color: rgba(228, 31, 7, 0.05);
}

.dropdown-item.active {
  background-color: #E41F07 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.btn.dropdown-toggle-split {
  opacity: 0.85;
}

.dropdown-menu-dark {
  background-color: #171724;
  border-color: rgba(255, 255, 255, 0.1);
}

.dropdown-menu-dark li a {
  color: #FFF;
}

.btn-list a.dropdown-item {
  margin-block-end: 0;
}

.btn-list .btn-soft-success:hover {
  transform: translateY(0);
}

.btn-list .btn-soft-danger:hover {
  transform: translateY(0);
}

.dropdown-menu.dropdown-menu-primary {
  background-color: #E41F07;
}

.dropdown-menu.dropdown-menu-primary li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-primary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-primary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-primary .dropdown-item:active,
.dropdown-menu.dropdown-menu-primary .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropdown-menu-secondary {
  background-color: #FFA201;
}

.dropdown-menu.dropdown-menu-secondary li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-secondary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:active,
.dropdown-menu.dropdown-menu-secondary .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropdown-menu-warning {
  background-color: #FDA700;
}

.dropdown-menu.dropdown-menu-warning li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-warning .dropdown-item:hover,
.dropdown-menu.dropdown-menu-warning .dropdown-item:focus,
.dropdown-menu.dropdown-menu-warning .dropdown-item:active,
.dropdown-menu.dropdown-menu-warning .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropdown-menu-info {
  background-color: #1ECBE2;
}

.dropdown-menu.dropdown-menu-info li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-info .dropdown-item:hover,
.dropdown-menu.dropdown-menu-info .dropdown-item:focus,
.dropdown-menu.dropdown-menu-info .dropdown-item:active,
.dropdown-menu.dropdown-menu-info .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropdown-menu-success {
  background-color: #5CB85C;
}

.dropdown-menu.dropdown-menu-success li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-success .dropdown-item:hover,
.dropdown-menu.dropdown-menu-success .dropdown-item:focus,
.dropdown-menu.dropdown-menu-success .dropdown-item:active,
.dropdown-menu.dropdown-menu-success .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropdown-menu-danger {
  background-color: #FC0027;
}

.dropdown-menu.dropdown-menu-danger li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-danger .dropdown-item:hover,
.dropdown-menu.dropdown-menu-danger .dropdown-item:focus,
.dropdown-menu.dropdown-menu-danger .dropdown-item:active,
.dropdown-menu.dropdown-menu-danger .dropdown-item.active {
  color: #FFF;
}

.dropdown-menu.dropmenu-item-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-primary .dropdown-item:active,
.dropdown-menu.dropmenu-item-primary .dropdown-item.active {
  background-color: rgba(228, 31, 7, 0.1) !important;
  color: #E41F07;
}

.dropdown-menu.dropmenu-item-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-item-secondary .dropdown-item.active {
  background-color: rgba(255, 162, 1, 0.1) !important;
  color: #FFA201;
}

.dropdown-menu.dropmenu-item-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-item-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-item-warning .dropdown-item:active,
.dropdown-menu.dropmenu-item-warning .dropdown-item.active {
  background-color: rgba(253, 167, 0, 0.1) !important;
  color: #FDA700;
}

.dropdown-menu.dropmenu-item-info .dropdown-item:hover,
.dropdown-menu.dropmenu-item-info .dropdown-item:focus,
.dropdown-menu.dropmenu-item-info .dropdown-item:active,
.dropdown-menu.dropmenu-item-info .dropdown-item.active {
  background-color: rgba(30, 203, 226, 0.1) !important;
  color: #1ECBE2;
}

.dropdown-menu.dropmenu-item-success .dropdown-item:hover,
.dropdown-menu.dropmenu-item-success .dropdown-item:focus,
.dropdown-menu.dropmenu-item-success .dropdown-item:active,
.dropdown-menu.dropmenu-item-success .dropdown-item.active {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: #5CB85C;
}

.dropdown-menu.dropmenu-item-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-item-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-item-danger .dropdown-item:active,
.dropdown-menu.dropmenu-item-danger .dropdown-item.active {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: #FC0027;
}

.dropdown-menu.dropmenu-light-primary {
  background-color: rgba(var(#FFA201), 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-primary .dropdown-item:active,
.dropdown-menu.dropmenu-light-primary .dropdown-item.active {
  color: #FFF;
  background-color: #E41F07 !important;
}

.dropdown-menu.dropmenu-light-secondary {
  background-color: rgba(var(#FFA201), 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-light-secondary .dropdown-item.active {
  color: #FFF;
  background-color: #FFA201 !important;
}

.dropdown-menu.dropmenu-light-info {
  background-color: rgba(30, 203, 226, 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-info .dropdown-item:hover,
.dropdown-menu.dropmenu-light-info .dropdown-item:focus,
.dropdown-menu.dropmenu-light-info .dropdown-item:active,
.dropdown-menu.dropmenu-light-info .dropdown-item.active {
  color: #FFF;
  background-color: #1ECBE2 !important;
}

.dropdown-menu.dropmenu-light-warning {
  background-color: rgba(253, 167, 0, 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-light-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-light-warning .dropdown-item:active,
.dropdown-menu.dropmenu-light-warning .dropdown-item.active {
  color: #FFF;
  background-color: #FDA700 !important;
}

.dropdown-menu.dropmenu-light-success {
  background-color: rgba(92, 184, 92, 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-success .dropdown-item:hover,
.dropdown-menu.dropmenu-light-success .dropdown-item:focus,
.dropdown-menu.dropmenu-light-success .dropdown-item:active,
.dropdown-menu.dropmenu-light-success .dropdown-item.active {
  color: #FFF;
  background-color: #5CB85C !important;
}

.dropdown-menu.dropmenu-light-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-light-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-light-danger .dropdown-item:active,
.dropdown-menu.dropmenu-light-danger .dropdown-item.active {
  color: #FFF;
  background-color: #FC0027 !important;
}

.dropdown-divider {
  margin: 0;
}

.bd-example>.dropdown-menu {
  position: static;
  display: block;
}

.inbox-menu {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
}

.inbox-menu li {
  display: inline-block;
  width: 100%;
}

.inbox-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}

.inbox-menu li a:hover {
  background: rgba(33, 33, 33, 0.05);
}

.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}

.compose-btn {
  margin-bottom: 25px;
}

.top-action-left .btn-group {
  margin-bottom: 5px;
}

.top-action-left .dropdown-toggle {
  height: 40px;
}

.top-action-left .dropdown-toggle::after {
  display: none;
}

.table-inbox .starred.fas.fa-star {
  color: #FDA700;
}

.table-inbox .checked {
  background-color: #898989;
}

.table-inbox th {
  border-color: #F2ECFF;
}

.table-inbox tbody tr td {
  font-size: 14px;
  color: #FFA201;
  border-color: #E8E8E8;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 700;
}

.email-header .btn-white {
  min-width: auto;
  height: 38px;
  border: 1px solid #E8E8E8;
}

.email-header .btn-white i {
  margin-left: 5px;
}

.file-manager {
  color: #6F6F6F;
}

.file-manager .search-dropdown {
  height: auto;
}

.file-manager .form-sort {
  width: auto;
}

.file-manager .form-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-right: 27px;
}

.file-manager .form-sort.owned-by {
  margin-right: 10px;
}

.file-manager.notes-page-wrapper .content .page-add-notes {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.file-manager.notes-page-wrapper .content .section-bulk-wrap {
  margin-top: 0;
}

.file-manager.notes-page-wrapper .content .section-bulk-wrap .form-sort svg.fa-filter {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.file-manager.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .select-bluk {
  margin-right: 15px;
}

.file-manager .page-header .btn-added {
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .file-manager .page-header .form-sort {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 575.98px) {
  .file-manager .page-header .table-top-head {
    margin-bottom: 15px;
  }
}

@media (max-width: 991.98px) {
  .file-manager .page-header .table-top-head li:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .file-manager .bulk-action-type {
    align-items: start !important;
  }
}

@media (max-width: 575.98px) {
  .file-manager .bulk-action-type {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .file-manager .bulk-action-type .form-sort {
    margin-top: 0;
    margin-bottom: 15px;
    width: 100%;
  }
}

.file-manager aside {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  border-radius: 8px;
}

.file-manager aside h5 {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #C0C0C0;
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
}

.file-manager aside .btn {
  font-weight: 700;
}

.file-manager aside .dropdown>a {
  width: auto;
  height: auto;
  color: #FFF;
  border-radius: 8px;
}

.file-manager aside .dropdown>a:hover,
.file-manager aside .dropdown>a:focus {
  border-color: #6F6F6F;
  background: #6F6F6F;
}

.file-manager aside .dropdown ul {
  width: 100%;
}

.file-manager aside .dropdown ul li a {
  font-size: 15px;
}

.file-manager aside ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #6F6F6F;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px 0;
  padding: 7px 10px;
  border-radius: 5px;
}

.file-manager aside ul li a span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.file-manager aside ul li a:hover,
.file-manager aside ul li a.active {
  background: #6F6F6F;
  color: #FFF;
}

.file-manager aside span {
  font-size: 13px;
  font-weight: 500;
}

.file-manager aside h6 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  z-index: 2;
}

.file-manager aside .space-manager {
  color: #FFF;
  background: #6F6F6F;
  border-radius: 8px;
  position: relative;
}

.file-manager aside .space-manager img {
  position: absolute;
  top: 0;
  right: 0;
}

.file-manager aside .space-manager h6 a {
  color: #FFF;
}

.file-manager aside .space-manager p {
  color: #B8BCC9;
}

.file-manager .progress {
  height: 6px;
  border-radius: 5px;
}

.file-manager .search-set .search-input input[type=search] {
  width: 170px;
  height: 40px;
}

.file-manager .budgeted-role-notes .search-set .search-input input[type=search] {
  width: 298px;
}

.file-manager h4 {
  font-size: 18px;
  font-weight: 600;
}

.file-manager h6 {
  font-size: 16px;
  font-weight: 600;
}

.file-manager h6 a {
  color: #6F6F6F;
}

.file-manager h6 a:hover {
  color: #E41F07;
}

.file-manager .overview .detail a.bg {
  border-radius: 8px 8px 0 0;
}

.file-manager .overview .detail a.bg span {
  width: 66px;
  height: 66px;
  border-radius: 100%;
}

.file-manager .overview .detail a.bg span img {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.file-manager .overview .detail a:hover img {
  transform: scale(1.2);
}

.file-manager .info {
  border-radius: 0 0 8px 8px;
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
}

.file-manager .info span {
  color: #6F6F6F;
  font-size: 13px;
}

.file-manager .folders {
  border: 1px solid #C0C0C0;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  border-radius: 8px;
  background: #FFF;
}

.file-manager .folders h6 {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 8px;
}

.file-manager .dropdown>a {
  color: #6F6F6F;
  width: 25px;
  height: 25px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.file-manager .dropdown>a:hover {
  background: #E41F07;
}

.file-manager .dropdown>a:hover i {
  color: #FFF;
}

.file-manager .project-plan {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.file-manager .project-plan label {
  color: #6F6F6F;
  margin-right: 9px;
  font-size: 13px;
}

.file-manager .project-plan ul li {
  font-size: 13px;
  position: relative;
  padding: 0 9px 0 14px;
  color: #6F6F6F;
}

.file-manager .project-plan ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #6F6F6F;
  border-radius: 5px;
}

.file-manager .avatar-wrap a svg {
  color: #6F6F6F;
}

.file-manager .avatar-wrap a:hover svg {
  color: #E41F07;
}

.file-manager .accordion {
  border: none;
  --bs-accordion-border-width: none;
}

.file-manager .accordion .accordion-item {
  border: none;
  border-bottom: 1px solid #C0C0C0;
  background: none;
}

.file-manager .accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}

.file-manager .accordion .accordion-item .accordion-button {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #6F6F6F;
  padding: 0;
  display: inline-block;
  width: auto;
}

.file-manager .accordion .accordion-item .accordion-button:after {
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
  background-image: none;
  content: "View All";
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #6F6F6F;
  transform: none;
  width: 60px;
  text-align: right;
}

.file-manager .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  content: "Hide";
}

.file-manager .accordion .accordion-item .accordion-button.collapsed {
  padding: 0;
}

.file-manager .accordion .accordion-item .accordion-button:hover:after {
  color: #E41F07;
}

.file-manager .accordion .accordion-item .accordion-body {
  border: none;
  padding: 0;
}

.file-manager .accordion .accordion-item .accordion-body .head img {
  min-width: 32px;
  width: 32px;
  height: 32px;
}

.file-manager .accordion .accordion-item .accordion-body .owl-carousel {
  padding-top: 15px;
}

.file-manager .owl-carousel .owl-item {
  margin-left: -0.1px !important;
}

.file-manager .owl-carousel .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  top: 27px !important;
  transform: none !important;
  background: #FFF !important;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  z-index: 99999 !important;
}

.file-manager .owl-carousel .owl-nav {
  top: -20px;
  left: 43px;
  display: inline-table;
}

.file-manager .owl-carousel .owl-nav button {
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  cursor: pointer;
}

.file-manager .owl-carousel .owl-nav button.owl-next,
.file-manager .owl-carousel .owl-nav button.owl-prev {
  position: relative;
  width: 15px;
}

.file-manager .owl-carousel .owl-nav button.owl-next i,
.file-manager .owl-carousel .owl-nav button.owl-prev i {
  color: #6F6F6F;
}

.file-manager .owl-carousel .owl-nav button.owl-next:hover,
.file-manager .owl-carousel .owl-nav button.owl-prev:hover {
  background: none;
}

.file-manager .owl-carousel .owl-nav button.owl-next:hover i,
.file-manager .owl-carousel .owl-nav button.owl-prev:hover i {
  color: #E41F07;
}

.file-manager .owl-carousel.folders-carousel .owl-nav {
  left: 75px;
}

.file-manager .owl-carousel.video-section .owl-nav {
  left: 60px;
}

.file-manager .owl-carousel.video-section .info a svg {
  color: #6F6F6F;
}

.file-manager .all-files {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  border: none;
}

.file-manager .all-files .btn-grp a {
  padding: 6px 8px;
  border-radius: 8px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

@media (max-width: 575.98px) {
  .file-manager .all-files .btn-grp a {
    margin-bottom: 15px;
    display: inline-block;
  }
}

.file-manager .all-files .btn-grp a.btn-outline-secondary {
  color: #6F6F6F;
  background: #F9F9FC;
  border-color: #F9F9FC;
}

.file-manager .all-files .btn-grp a:hover {
  background: #6F6F6F;
  border-color: #6F6F6F;
  color: #FFF;
}

.file-manager .all-files .table tbody tr td {
  display: table-cell;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  border-color: #E8E8E8;
}

.file-manager .all-files .table tbody tr td a {
  font-size: 14px;
  font-weight: 500;
}

.file-manager .all-files .table tbody tr td a.product-img img {
  width: 32px;
  height: 32px;
  border: 0;
}

.file-manager #file-delete {
  font-size: 14px;
}

aside {
  padding: 24px;
}

.color-primary {
  color: #6F6F6F;
}

.icon-select {
  position: relative;
  width: 149px;
  height: 38px;
}

.icon-select .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 100;
}

.icon-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 30px;
}

.bg-light-orange {
  background: #FAD2CD;
}

.bg-light-orange span {
  background: #FAD2CD;
}

.bg-light-red {
  background: #FECCD4;
}

.bg-light-red span {
  background: #FECCD4;
}

.bg-light-green {
  background: #CEEACE;
}

.bg-light-green span {
  background: #CEEACE;
}

.seprator-sm {
  margin: 0 0 24px 0;
  padding: 0 0 9px 0;
  border-bottom: 1px solid #C0C0C0;
}

.seprator-lg {
  margin: 0 0 24px 0;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #C0C0C0;
}

.group-avatar .avatar {
  position: relative;
  width: 43px;
  height: 43px;
  line-height: 43px;
  margin: 0;
}

.group-avatar .avatar+.avatar {
  margin-left: -1.2rem;
}

.group-avatar .avatar:hover {
  z-index: 1;
}

.group-avatar .count a {
  color: #5CB85C;
  line-height: 27px;
  font-size: 13px;
  font-weight: 500;
}

.group-avatar .count a:hover {
  color: #E41F07;
}

.group-avatar .avatar img {
  display: block;
  border-radius: 100%;
  width: 100%;
  border: 2px solid #FFF;
}

.video-section .item {
  opacity: 0.4;
  transition: 0.4s ease all;
  transform: scale(0.8);
}

@media (max-width: 1000px) {
  .video-section .item {
    margin: 0;
    transform: scale(0.9);
  }
}

.video-section .active .item {
  opacity: 1;
  transform: scale(1);
}

.video-section .owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.video-section video {
  max-width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.delete-alert {
  padding: 10px 20px;
  background: #FECCD4;
  border: none;
  border-radius: 8px;
}

.delete-alert p {
  font-size: 15px;
}

.delete-alert .btn {
  font-weight: 700;
}

.deleted-info {
  display: none;
}

.deleted-info .bin-bg {
  width: 184px;
  height: 184px;
  border-radius: 100%;
  background: #FDFDFE;
  margin: 0 auto;
}

.popup-toggle {
  cursor: pointer;
}

.toggle-sidebar {
  width: 456px;
  padding: 25px;
  position: fixed;
  right: -456px;
  top: 0;
  height: 100%;
  background: #FFF;
  z-index: 100001;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 575.98px) {
  .toggle-sidebar {
    width: 320px;
    right: -320px;
  }
}

.toggle-sidebar.open-sidebar {
  right: 0;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.toggle-sidebar .head .color-primary:hover {
  color: #FC0027;
}

.toggle-sidebar h4,
.toggle-sidebar h5 {
  font-weight: 700;
}

.toggle-sidebar h4 {
  font-size: 20px;
}

.toggle-sidebar h5 {
  font-size: 18px;
}

.toggle-sidebar h6 {
  font-size: 15px;
  font-weight: 600;
}

.toggle-sidebar .nav-tabs {
  border-bottom: 1px solid #C0C0C0;
}

.toggle-sidebar .nav-tabs .nav-link {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  background: #F9F9FC;
  color: #6F6F6F;
}

.toggle-sidebar .nav-tabs .nav-link:hover,
.toggle-sidebar .nav-tabs .nav-link.active {
  background: #E41F07;
  color: #FFF;
}

.toggle-sidebar .tab-content .tab-pane ul li h6 {
  margin-bottom: 5px;
}

.toggle-sidebar .tab-content .tab-pane ul li p.location {
  background: #F9F9FC;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
}

.toggle-sidebar .tab-content .tab-pane ul li p.location img {
  width: 20px;
  height: 20px;
}

.toggle-sidebar .tab-content .tab-pane ul li .note-editor.note-airframe .note-statusbar,
.toggle-sidebar .tab-content .tab-pane ul li .note-editor.note-frame .note-statusbar {
  border-top: none;
}

.toggle-sidebar .tab-content .tab-pane .avatar-access span a {
  display: inline-flex;
  margin-right: 3px;
  padding-right: 3px;
}

.toggle-sidebar .tab-content .tab-pane .avatar-access span:first-child a {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #C0C0C0;
}

.toggle-sidebar .tab-content .tab-pane .avatar-access span:last-child a {
  margin-right: 0;
  padding-right: 0;
}

.toggle-sidebar .tab-content .tab-pane .avatar-access span .add {
  background: #E41F07;
  color: #FFF;
}

.toggle-sidebar .tab-content .tab-pane .avatar-access span .add:hover {
  background: #6F6F6F;
}

.toggle-sidebar p {
  color: #6F6F6F;
  font-size: 15px;
}

.toggle-sidebar .show-all {
  font-weight: 700;
}

.toggle-sidebar .show-all:hover {
  color: #6F6F6F;
}

.text-danger {
  color: #FC0027 !important;
}

.avatar-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
}

.text-secondary {
  color: #6F6F6F !important;
}

.text-primary {
  color: #E41F07 !important;
}

.text-default {
  color: #6F6F6F;
}

.icons-list {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.icons-list li {
  display: block;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #e6ebf1;
  color: #E41F07;
  margin: 5px;
  -webkit-box-shadow: 0 2px 3px rgb(215, 197, 255);
  -moz-box-shadow: 0 2px 3px rgb(215, 197, 255);
  box-shadow: 0 2px 3px rgb(215, 197, 255);
}

.progress {
  background-color: #E8E8E8;
  height: 1.2rem;
  border-radius: 0.25rem;
}

.progress-stacked {
  background-color: #E8E8E8;
}

.progress-bar {
  color: #FFF;
  background-color: #E41F07;
}

@keyframes progress-animate {
  0% {
    width: 0;
  }
}

.progress-animate {
  position: relative;
  border-radius: 0.625rem;
}

.progress-animate .progress-bar {
  position: relative;
  border-radius: 0.625rem;
  animation: 2s progress-animate;
}

.progress.progress-xs,
.progress-stacked.progress-xs {
  height: 0.3125rem;
}

.progress.progress-sm,
.progress-stacked.progress-sm {
  height: 0.5rem;
}

.progress.progress-lg,
.progress-stacked.progress-lg {
  height: 1rem;
}

.progress.progress-xl,
.progress-stacked.progress-xl {
  height: 1.25rem;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical .progress-bar {
  width: 100%;
}

.progress-vertical.progress-xl {
  width: 15px;
}

.progress-vertical.progress-lg {
  width: 12px;
}

.progress-vertical.progress-sm {
  width: 8px;
}

.progress-vertical.progress-xs {
  width: 5px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom .progress-bar {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.progress-vertical-bottom.progress-xl {
  width: 15px;
}

.progress-vertical-bottom.progress-lg {
  width: 12px;
}

.progress-vertical-bottom.progress-sm {
  width: 8px;
}

.progress-vertical-bottom.progress-xs {
  width: 5px;
}

.progress-custom {
  overflow: visible;
  position: relative;
}

.progress-custom .progress-bar {
  position: relative;
  overflow: visible;
}

.progress-custom .progress-bar:after {
  content: "";
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  box-shadow: 0 0.313rem 0.313rem rgba(228, 31, 7, 0.5);
  background: #FFF;
  position: absolute;
  inset-inline-end: -0.375rem;
  inset-block-start: -0.375rem;
  border: 0.25rem solid #E41F07;
}

.progress-custom .progress-bar.bg-secondary:after {
  border: 0.25rem solid #FFA201;
  box-shadow: 0 0.313rem 0.313rem rgba(var(--secondary-rgb), 0.2);
}

.progress-custom .progress-bar.bg-warning:after {
  border: 0.25rem solid #FDA700;
  box-shadow: 0 0.313rem 0.313rem rgba(var(--warning-rgb), 0.2);
}

.progress-custom .progress-bar.bg-info:after {
  border: 0.25rem solid #1ECBE2;
  box-shadow: 0 0.313rem 0.313rem rgba(var(--info-rgb), 0.2);
}

.progress-custom .progress-bar.bg-success:after {
  border: 0.25rem solid #5CB85C;
  box-shadow: 0 0.313rem 0.313rem rgba(var(--success-rgb), 0.2);
}

.progress-custom .progress-bar.bg-danger:after {
  border: 0.25rem solid #FC0027;
  box-shadow: 0 0.313rem 0.313rem rgba(var(--danger-rgb), 0.2);
}

.progress-custom .progress-bar-value {
  width: 1.875rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 0.188rem;
  background: #E41F07;
  box-shadow: 0 0.313rem 0.313rem rgba(0, 0, 0, 0.4);
  font-size: 0.625rem;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  position: absolute;
  inset-block-end: 1.5rem;
  inset-inline-end: -0.688rem;
}

.progress-custom .progress-bar-value:after {
  content: "";
  border-block-start: 0.438rem solid #E41F07;
  border-inline-start: 0.438rem solid transparent;
  border-inline-end: 0.438rem solid transparent;
  position: absolute;
  inset-block-end: -0.375rem;
  inset-inline-start: 28%;
}

.progress-custom .progress-bar-value.bg-secondary {
  background-color: #FFA201;
}

.progress-custom .progress-bar-value.bg-secondary:after {
  border-block-start: 0.438rem solid #FFA201;
}

.progress-custom .progress-bar-value.bg-success {
  background-color: #5CB85C;
}

.progress-custom .progress-bar-value.bg-success:after {
  border-block-start: 0.438rem solid #5CB85C;
}

.progress-custom .progress-bar-value.bg-warning {
  background-color: #FDA700;
}

.progress-custom .progress-bar-value.bg-warning:after {
  border-block-start: 0.438rem solid #FDA700;
}

.progress-custom .progress-bar-value.bg-danger {
  background-color: #FC0027;
}

.progress-custom .progress-bar-value.bg-danger:after {
  border-block-start: 0.438rem solid #FC0027;
}

.progress-custom .progress-bar-value.bg-info {
  background-color: #1ECBE2;
}

.progress-custom .progress-bar-value.bg-info:after {
  border-block-start: 0.438rem solid #1ECBE2;
}

[dir=rtl] .progress-bar-title {
  border-radius: 0 0.313rem 0.313rem 0;
}

.progress-item-1,
.progress-item-2,
.progress-item-3 {
  position: absolute;
  margin-block-start: -0.25rem;
  z-index: 1;
  height: 0.938rem;
  width: 0.938rem;
  border-radius: 1.563rem;
  background-color: #FDFDFE;
}

.progress-item-1 {
  inset-inline-start: 25%;
}

.progress-item-2 {
  inset-inline-start: 50%;
}

.progress-item-3 {
  inset-inline-start: 75%;
}

.custom-progress-3 {
  overflow: visible;
  border-radius: 0.625rem;
}

.custom-progress-3 .progress-bar {
  position: relative;
  overflow: visible;
  margin: 0.313rem;
  border-radius: 0.25rem;
}

.custom-progress-3 .progress-bar:before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 0.25rem;
  inset-inline-start: 0.125rem;
  background-color: #FFF;
  border-radius: 50%;
}

.custom-progress-3 .progress-bar .progress-bar-value {
  border: 0.125rem solid #E41F07;
  width: 2.5rem;
  height: 1.563rem;
  line-height: 1.313rem;
  border-radius: 3.125rem;
  background: #FFF;
  font-size: 0.625rem;
  font-weight: 600;
  color: #E41F07;
  text-align: center;
  position: absolute;
  inset-inline-end: -0.625rem;
}

.custom-progress-3 .progress-bar .progress-bar-value.secondary {
  border: 0.125rem solid #FFA201;
  color: #FFA201;
}

.custom-progress-3 .progress-bar .progress-bar-value.warning {
  border: 0.125rem solid #FDA700;
  color: #FDA700;
}

.custom-progress-3 .progress-bar .progress-bar-value.info {
  border: 0.125rem solid #1ECBE2;
  color: #1ECBE2;
}

.custom-progress-3 .progress-bar .progress-bar-value.success {
  border: 0.125rem solid #5CB85C;
  color: #5CB85C;
}

.custom-progress-3 .progress-bar .progress-bar-value.danger {
  border: 0.125rem solid #FC0027;
  color: #FC0027;
}

.custom-progress-4.progress {
  background-color: rgba(228, 31, 7, 0.1);
  border-radius: 0.25rem;
}

.custom-progress-4.progress.secondary {
  background-color: rgba(255, 162, 1, 0.1);
}

.custom-progress-4.progress.secondary .progress-bar-label {
  color: #FFA201;
}

.custom-progress-4.progress.warning {
  background-color: rgba(253, 167, 0, 0.1);
}

.custom-progress-4.progress.warning .progress-bar-label {
  color: #FDA700;
}

.custom-progress-4.progress.info {
  background-color: rgba(30, 203, 226, 0.1);
}

.custom-progress-4.progress.info .progress-bar-label {
  color: #1ECBE2;
}

.custom-progress-4.progress.success {
  background-color: rgba(92, 184, 92, 0.1);
}

.custom-progress-4.progress.success .progress-bar-label {
  color: #5CB85C;
}

.custom-progress-4.progress.danger {
  background-color: rgba(252, 0, 39, 0.1);
}

.custom-progress-4.progress.danger .progress-bar-label {
  color: #FC0027;
}

.custom-progress-4.progress .progress-bar {
  border-radius: 0.25rem;
}

.custom-progress-4.progress .progress-bar-label {
  position: absolute;
  inset-inline-end: 0.313rem;
  font-weight: 600;
  font-size: 0.813rem;
  color: #E41F07;
}

.page-link {
  color: #6F6F6F;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
}

.page-link:focus {
  box-shadow: none;
  background-color: #FDFDFE;
}

.page-link:hover {
  color: #E41F07;
  background-color: #FDFDFE;
  border-color: #6F6F6F;
}

.page-item.active .page-link {
  color: #FFF;
  background-color: #E41F07;
  border-color: #E41F07;
}

.disabled>.page-link,
.page-link.disabled {
  color: #6F6F6F;
  background-color: #FFF;
  border-color: #6F6F6F;
  opacity: 0.7;
}

[dir=rtl] .pagination .page-link .bx-chevron-left::before {
  content: "\ea50";
}

[dir=rtl] .pagination .page-link .bx-chevron-right::before {
  content: "\ea4d";
}

[dir=rtl] .pagination .page-link .ri-arrow-right-s-line:before {
  content: "\ea64";
}

[dir=rtl] .pagination .page-link .ri-arrow-left-s-line:before {
  content: "\ea6e";
}

.pagination-style-1 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-1 .pagination .page-item .page-link {
  border: 0;
  border-radius: 4px;
  font-size: 0.8125rem;
}

.pagination-style-1 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-1 .pagination .page-item.active .page-link {
  border-radius: 4px;
  background-color: #E41F07;
  color: #FFF;
}

.pagination-style-1 .pagination .page-item.active:hover .page-link {
  border-radius: 4px;
  background-color: #E41F07;
  color: #FFF;
}

.pagination-style-1 .pagination .page-item:hover .page-link {
  background-color: #FDFDFE;
  color: #E41F07;
}

.pagination-style-2 .pagination {
  border-radius: 4px;
}

.pagination-style-2 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-2 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
}

.pagination-style-2 .pagination .page-item.active .page-link {
  background-color: #FFF;
  color: #E41F07;
  position: relative;
  font-weight: bold;
}

.pagination-style-2 .pagination .page-item.active .page-link:before {
  position: absolute;
  content: "";
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #E41F07;
}

.pagination-style-2 .pagination .page-item:hover .page-link {
  background-color: transparent;
}

.pagination-style-3 .pagination {
  border-radius: 50px;
  padding: 0.25rem;
  align-items: center;
}

.pagination-style-3 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-3 .pagination .page-item .page-link {
  border: 0;
  border-radius: 50px;
  font-size: 0.8125rem;
}

.pagination-style-3 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-3 .pagination .page-item.active .page-link {
  background-color: #E41F07;
  color: #FFF;
}

.pagination-style-3 .pagination .page-item.active:hover .page-link {
  background-color: #E41F07;
}

.pagination-style-3 .pagination .page-item:hover .page-link {
  background-color: #FDFDFE;
}

.pagination-style-4 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
  border-radius: 4px;
}

.pagination-style-4 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-4 .pagination .page-item.active .page-link {
  border: 0;
  border-radius: 4px;
  background-color: #E41F07;
  color: #FFF;
}

.pagination-style-4 .pagination .page-item.active:hover .page-link {
  background-color: #E41F07;
}

.pagination-style-4 .pagination .page-item:hover .page-link {
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262A2A;
  font-family: "Inter", sans-serif !important;

  font-weight: 700;
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.fs-sm {
  font-size: 0.6875rem;
}

.fs-base {
  font-size: 0.875rem;
}

.fs-lg {
  font-size: 1.125rem;
}

.fs-1 {
  font-size: 0.0625rem;
}

.fs-2 {
  font-size: 0.125rem;
}

.fs-3 {
  font-size: 0.1875rem;
}

.fs-4 {
  font-size: 0.25rem;
}

.fs-5 {
  font-size: 0.3125rem;
}

.fs-6 {
  font-size: 0.375rem;
}

.fs-7 {
  font-size: 0.4375rem;
}

.fs-8 {
  font-size: 0.5rem;
}

.fs-9 {
  font-size: 0.5625rem;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-11 {
  font-size: 0.6875rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-13 {
  font-size: 0.8125rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-15 {
  font-size: 0.9375rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 1.0625rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-19 {
  font-size: 1.1875rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-21 {
  font-size: 1.3125rem;
}

.fs-22 {
  font-size: 1.375rem;
}

.fs-23 {
  font-size: 1.4375rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-25 {
  font-size: 1.5625rem;
}

.fs-26 {
  font-size: 1.625rem;
}

.fs-27 {
  font-size: 1.6875rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-29 {
  font-size: 1.8125rem;
}

.fs-30 {
  font-size: 1.875rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-34 {
  font-size: 2.125rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-38 {
  font-size: 2.375rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.text-default {
  color: #6F6F6F !important;
}

.text-muted {
  color: #9595b5 !important;
}

.text-primary {
  color: #E41F07 !important;
  opacity: 1;
}

.text-primary.text-opacity-75 {
  color: rgba(228, 31, 7, 0.75) !important;
}

.text-primary.text-opacity-25 {
  color: rgba(228, 31, 7, 0.25) !important;
}

.text-primary.text-opacity-50 {
  color: rgba(228, 31, 7, 0.5) !important;
}

.text-secondary {
  color: #FFA201 !important;
  opacity: 1;
}

.text-info {
  color: #1ECBE2 !important;
  opacity: 1;
}

.text-success {
  color: #5CB85C !important;
  opacity: 1;
}

.text-warning {
  color: #FDA700 !important;
  opacity: 1;
}

.text-danger {
  color: #FC0027 !important;
  opacity: 1;
}

.text-blue {
  color: #339DFF !important;
}

.text-green {
  color: #00918E !important;
}

.text-pink {
  color: #EA00B7 !important;
}

.text-teal {
  color: #02a8b5 !important;
}

.text-orange {
  color: #FF9D0A !important;
}

.text-indigo {
  color: #0092E4 !important;
}

.text-purple {
  color: #4A00E5 !important;
}

.text-dark {
  color: #171724 !important;
}

.text-light {
  color: #FDFDFE !important;
}

.text-gray {
  color: #6F7A7A !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #FFF !important;
}

.text-fixed-white {
  color: #FFF !important;
}

.text-fixed-black {
  color: #000 !important;
}

.text-gray-1 {
  color: #C0C0C0;
}

.text-gray-2 {
  color: #9B9B9B;
}

.text-gray-3 {
  color: #898989;
}

.text-gray-4 {
  color: #6F6F6F;
}

.text-gray-5 {
  color: #6F7A7A;
}

.text-gray-6 {
  color: #565E5E;
}

.text-gray-7 {
  color: #424848;
}

.text-gray-8 {
  color: #323737;
}

.text-gray-9 {
  color: #262A2A;
}

a {
  color: #262A2A;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a:hover {
  color: #E41F07;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a:focus {
  outline: 0;
}

.link-primary {
  color: #3D5EE1 !important;
}

.link-primary:hover,
.link-primary:focus,
.link-primary:active {
  color: #E41F07 !important;
}

.link-secondary {
  color: #FFA201 !important;
}

.link-secondary:hover,
.link-secondary:focus,
.link-secondary:active {
  color: #FFA201 !important;
}

.link-success {
  color: #5CB85C !important;
}

.link-success:hover,
.link-success:focus,
.link-success:active {
  color: #5CB85C !important;
}

.link-danger {
  color: #FC0027 !important;
}

.link-danger:hover,
.link-danger:focus,
.link-danger:active {
  color: #FC0027 !important;
}

.link-warning {
  color: #FDA700 !important;
}

.link-warning:hover,
.link-warning:focus,
.link-warning:active {
  color: #FDA700 !important;
}

.link-info {
  color: #1ECBE2 !important;
}

.link-info:hover,
.link-info:focus,
.link-info:active {
  color: #1ECBE2 !important;
}

.link-light {
  color: #FDFDFE !important;
}

.link-light:hover,
.link-light:focus,
.link-light:active {
  color: #FDFDFE !important;
}

.link-dark {
  color: #171724 !important;
}

.link-dark:hover,
.link-dark:focus,
.link-dark:active {
  color: #171724 !important;
}

hr.text-danger {
  border-color: #FC0027 !important;
}

.blockquote-container {
  padding: 1.25rem;
  border-left: 4px solid #6F6F6F;
  position: relative;
}

.blockquote-container.text-end {
  border-right: 4px solid #6F6F6F;
  border-left: 0;
}

.blockquote.custom-blockquote {
  font-size: 0.85rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 4px;
  position: relative;
}

.blockquote.custom-blockquote:before {
  content: "\f10d";
  font-family: "Fontawesome";
  z-index: 0;
  font-size: 2rem;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0.5rem;
}

.blockquote.custom-blockquote.primary {
  background-color: rgba(228, 31, 7, 0.1);
  border-inline-start: 2px solid #E41F07;
}

.blockquote.custom-blockquote.primary:before {
  color: rgba(228, 31, 7, 0.1);
}

.blockquote.custom-blockquote.secondary {
  background-color: rgba(255, 162, 1, 0.1);
  border-inline-start: 2px solid #FFA201;
}

.blockquote.custom-blockquote.secondary:before {
  color: rgba(255, 162, 1, 0.1);
}

.blockquote.custom-blockquote.info {
  background-color: rgba(30, 203, 226, 0.1);
  border-inline-start: 2px solid #1ECBE2;
}

.blockquote.custom-blockquote.info:before {
  color: rgba(30, 203, 226, 0.1);
}

.blockquote.custom-blockquote.warning {
  background-color: rgba(253, 167, 0, 0.1);
  border-inline-start: 2px solid #FDA700;
}

.blockquote.custom-blockquote.warning:before {
  color: rgba(253, 167, 0, 0.1);
}

.blockquote.custom-blockquote.success {
  background-color: rgba(92, 184, 92, 0.1);
  border-inline-start: 2px solid #5CB85C;
}

.blockquote.custom-blockquote.success:before {
  color: rgba(92, 184, 92, 0.1);
}

.blockquote.custom-blockquote.danger {
  background-color: rgba(252, 0, 39, 0.1);
  border-inline-start: 2px solid #FC0027;
}

.blockquote.custom-blockquote.danger:before {
  color: rgba(252, 0, 39, 0.1);
}

[data-theme-mode=dark] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}

[data-theme-mode=dark] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(33, 37, 41, 0.3) !important;
}

[data-theme-mode=dark] .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(33, 37, 41, 0.3) !important;
}

@media screen and (min-width: 576px) {
  .w-sm-auto {
    width: auto;
  }
}

[dir=rtl] .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.transform-none {
  transform: none !important;
}

.w-9 {
  width: 9px;
}

.h-9 {
  height: 9px;
}

.list-circle {
  list-style: circle;
  padding-left: 2rem;
}

.alert {
  padding: 0.625rem 0.85rem;
  border-radius: 4px;
  font-size: 0.8125rem;
}

.alert.alert-dismissible {
  padding: 0.625rem 2.25rem 0.625rem 0.85rem;
}

.alert i {
  font-size: 20px;
}

.alert .btn-close {
  background-image: none;
  padding: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert .btn-close i {
  font-size: 18px;
  line-height: 18px;
}

.alert:last-child {
  margin-bottom: 0;
}

.alert .alert-link {
  font-weight: 600;
}

[dir=rtl] .alert.alert-dismissible {
  padding: 0.625rem 0.85rem 0.625rem 2.25rem;
}

.alert-warning {
  background-color: rgba(253, 167, 0, 0.1);
  color: #FDA700;
  border-color: rgba(253, 167, 0, 0.1);
}

.alert-warning .alert-link {
  color: #FDA700;
}

.alert-warning .btn-close {
  color: #FDA700;
}

.alert-warning .btn-close.custom-close {
  background-color: #FDA700;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-primary {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
  border-color: rgba(228, 31, 7, 0.1);
}

.alert-primary .alert-link {
  color: #E41F07;
}

.alert-primary .custom-alert-icon {
  color: #E41F07;
}

.alert-primary .btn-close {
  color: #E41F07;
}

.alert-primary .btn-close.custom-close {
  background-color: #E41F07;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-secondary {
  background-color: rgba(255, 162, 1, 0.1);
  color: #FFA201;
  border-color: rgba(255, 162, 1, 0.1);
}

.alert-secondary .alert-link {
  color: #FFA201;
}

.alert-secondary .btn-close {
  color: #FFA201;
}

.alert-secondary .btn-close.custom-close {
  background-color: #FFA201;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-success {
  background-color: rgba(92, 184, 92, 0.1);
  color: #5CB85C;
  border-color: rgba(92, 184, 92, 0.1);
}

.alert-success .alert-link {
  color: #5CB85C;
}

.alert-success .btn-close {
  color: #5CB85C;
}

.alert-success .btn-close.custom-close {
  background-color: #5CB85C;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-info {
  background-color: rgba(30, 203, 226, 0.1);
  color: #1ECBE2;
  border-color: rgba(30, 203, 226, 0.1);
}

.alert-info .alert-link {
  color: #1ECBE2;
}

.alert-info .btn-close {
  color: #1ECBE2;
}

.alert-info .btn-close.custom-close {
  background-color: #1ECBE2;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-danger {
  background-color: rgba(252, 0, 39, 0.1);
  color: #FC0027;
  border-color: rgba(252, 0, 39, 0.1);
}

.alert-danger .alert-link {
  color: #FC0027;
}

.alert-danger .btn-close {
  color: #FC0027;
}

.alert-danger .btn-close.custom-close {
  background-color: #FC0027;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-light {
  background-color: #FDFDFE;
  color: #6F6F6F;
  border-color: #FDFDFE;
}

.alert-light .alert-link {
  color: #6F6F6F;
}

.alert-light .btn-close {
  color: #6F6F6F;
}

.alert-light .btn-close.custom-close {
  background-color: #FDFDFE;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-dark {
  background-color: rgba(23, 23, 36, 0.1);
  color: #6F6F6F;
  border-color: rgba(23, 23, 36, 0.1);
}

.alert-dark .alert-link {
  color: #6F6F6F;
}

.alert-dark .btn-close {
  color: #FFF;
}

.alert-dark .btn-close.custom-close {
  background-color: #171724;
  color: #FFF;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  padding: 0.85rem;
}

.alert-solid-primary {
  background-color: #E41F07;
  color: #FFF;
  border-color: #E41F07;
}

.alert-solid-primary .btn-close {
  color: #FFF;
}

.alert-solid-secondary {
  background-color: #FFA201;
  color: #FFF;
  border-color: #FFA201;
}

.alert-solid-secondary .btn-close {
  color: #FFF;
}

.alert-solid-warning {
  background-color: #FDA700;
  color: #FFF;
  border-color: #FDA700;
}

.alert-solid-warning .btn-close {
  color: #FFF;
}

.alert-solid-info {
  background-color: #1ECBE2;
  color: #FFF;
  border-color: #1ECBE2;
}

.alert-solid-info .btn-close {
  color: #FFF;
}

.alert-solid-success {
  background-color: #5CB85C;
  color: #FFF;
  border-color: #5CB85C;
}

.alert-solid-success .btn-close {
  color: #FFF;
}

.alert-solid-danger {
  background-color: #FC0027;
  color: #FFF;
  border-color: #FC0027;
}

.alert-solid-danger .btn-close {
  color: #FFF;
}

.alert-solid-light {
  background-color: #FDFDFE;
  color: #6F6F6F;
  border-color: #6F6F6F;
}

.alert-solid-dark {
  background-color: #171724;
  color: #FFF;
  border-color: #171724;
}

.alert-solid-dark .btn-close {
  color: #FFF;
}

.alert-outline-primary {
  background-color: #FFF;
  color: #E41F07;
  border-color: #E41F07;
}

.alert-outline-primary .btn-close {
  color: #E41F07;
}

.alert-outline-secondary {
  background-color: #FFF;
  color: #FFA201;
  border-color: #FFA201;
}

.alert-outline-secondary .btn-close {
  color: #FFA201;
}

.alert-outline-info {
  background-color: #FFF;
  color: #1ECBE2;
  border-color: #1ECBE2;
}

.alert-outline-info .btn-close {
  color: #1ECBE2;
}

.alert-outline-warning {
  background-color: #FFF;
  color: #FDA700;
  border-color: #FDA700;
}

.alert-outline-warning .btn-close {
  color: #FDA700;
}

.alert-outline-success {
  background-color: #FFF;
  color: #5CB85C;
  border-color: #5CB85C;
}

.alert-outline-success .btn-close {
  color: #5CB85C;
}

.alert-outline-danger {
  background-color: #FFF;
  color: #FC0027;
  border-color: #FC0027;
}

.alert-outline-danger .btn-close {
  color: #FC0027;
}

.alert-outline-light {
  background-color: #FFF;
  color: #6F6F6F;
  border-color: #FDFDFE;
}

.alert-outline-dark {
  background-color: #FFF;
  color: #171724;
  border-color: #171724;
}

.alert-outline-dark .btn-close {
  color: #171724;
}

.alert-primary.custom-alert-icon {
  border-inline-start: 0.313rem solid #E41F07 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-primary.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-primary.custom-alert-icon>i {
  color: #E41F07;
}

.alert-secondary.custom-alert-icon {
  border-inline-start: 0.313rem solid #FFA201 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-secondary.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-secondary.custom-alert-icon>i {
  color: #FFA201;
}

.alert-warning.custom-alert-icon {
  border-inline-start: 0.313rem solid #FDA700 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-warning.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-warning.custom-alert-icon>i {
  color: #FDA700;
}

.alert-danger.custom-alert-icon {
  border-inline-start: 0.313rem solid #FC0027 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-danger.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-danger.custom-alert-icon>i {
  color: #FC0027;
}

.alert-success.custom-alert-icon {
  border-inline-start: 0.313rem solid #5CB85C !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-success.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-info.custom-alert-icon {
  border-inline-start: 0.313rem solid #1ECBE2 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-info.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-light.custom-alert-icon {
  border-inline-start: 0.313rem solid #FDFDFE !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-light.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.alert-dark.custom-alert-icon {
  border-inline-start: 0.313rem solid #171724 !important;
  color: #9595b5;
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  font-size: 0.813rem;
}

.alert-dark.custom-alert-icon .btn-close {
  color: #6F6F6F;
}

.custom-alert1 {
  margin-block-end: 0;
  background-color: #FFF;
  border: 0;
  padding: 1.25rem;
  color: #6F6F6F;
}

.custom-alert1 p {
  margin-block-end: 2.5rem;
  color: #9595b5;
  font-size: 0.8rem;
}

.custom-alert1 .custom-alert-icon {
  font-size: 40px;
  margin-bottom: 0.85rem;
}

.custom-alert1 .custom-alert-icon i {
  font-size: 40px;
}

.custom-alert1 .btn-close {
  padding: 0;
  margin-block-end: 1rem;
}

.custom-alert1.alert-primary {
  border-block-start: 0.313rem solid #E41F07;
}

.custom-alert1.alert-primary .custom-alert-icon {
  color: #E41F07;
}

.custom-alert1.alert-secondary {
  border-block-start: 0.313rem solid #FFA201;
}

.custom-alert1.alert-secondary .custom-alert-icon {
  color: #FFA201;
}

.custom-alert1.alert-warning {
  border-block-start: 0.313rem solid #FDA700;
}

.custom-alert1.alert-warning .custom-alert-icon {
  color: #FDA700;
}

.custom-alert1.alert-danger {
  border-block-start: 0.313rem solid #FC0027;
}

.custom-alert1.alert-danger .custom-alert-icon {
  color: #FC0027;
}

.alert-img {
  display: flex;
  align-items: center;
}

.alert-img .avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 400px) {
  .btn-close.custom-close {
    margin: 9px 9px 9px 0;
  }
}

.op-1-1 {
  opacity: 1;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.custom-popover {
  --bs-popover-max-width: 12.5rem;
  --bs-popover-border-color: #E41F07;
  --bs-popover-header-bg: #E41F07;
  --bs-popover-header-color: #FFF;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
}

.popover {
  background-color: #FFF;
  border: 1px solid #6F6F6F;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  font-size: 0.8rem;
  border-radius: 4px;
  z-index: 999;
}

.popover .popover-header {
  background-color: #FFF;
  border-block-end: 1px solid #6F6F6F;
}

.popover .popover-body {
  color: #6F6F6F;
}

.popover.only-body {
  border-radius: 0.3rem;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
  border-top-color: #FDFDFE;
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
  border-right-color: #FDFDFE;
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
  border-left-color: #FDFDFE;
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom-color: #FDFDFE;
}

.header-primary .popover-header {
  background-color: #E41F07;
  color: #FFF;
}

.header-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #E41F07;
}

.header-secondary .popover-header {
  background-color: #FFA201;
  color: #FFF;
}

.header-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #FFA201;
}

.header-warning .popover-header {
  background-color: #FDA700;
  color: #FFF;
}

.header-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #FDA700;
}

.header-info .popover-header {
  background-color: #1ECBE2;
  color: #FFF;
}

.header-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #1ECBE2;
}

.header-success .popover-header {
  background-color: #5CB85C;
  color: #FFF;
}

.header-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #5CB85C;
}

.header-danger .popover-header {
  background-color: #FC0027;
  color: #FFF;
}

.header-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #FC0027;
}

.popover-primary.popover {
  border: 1px solid #E41F07;
}

.popover-primary.popover .popover-header {
  background-color: #E41F07;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-primary.popover .popover-body {
  background-color: #E41F07;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary.bs-popover-top>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary.bs-popover-top>.popover-arrow::before {
  border-top-color: #E41F07;
}

.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary.bs-popover-end>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary.bs-popover-end>.popover-arrow::before {
  border-right-color: #E41F07;
}

.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary.bs-popover-start>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary.bs-popover-start>.popover-arrow::before {
  border-left-color: #E41F07;
}

.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary.bs-popover-bottom>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary.bs-popover-bottom>.popover-arrow::before,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary.bs-popover-bottom .popover-header::before {
  border-bottom-color: #E41F07;
}

.popover-secondary.popover {
  border: 1px solid #FFA201;
}

.popover-secondary.popover .popover-header {
  background-color: #FFA201;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-secondary.popover .popover-body {
  background-color: #FFA201;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary.bs-popover-top>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary.bs-popover-top>.popover-arrow::before {
  border-top-color: #FFA201;
}

.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary.bs-popover-end>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary.bs-popover-end>.popover-arrow::before {
  border-right-color: #FFA201;
}

.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary.bs-popover-start>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary.bs-popover-start>.popover-arrow::before {
  border-left-color: #FFA201;
}

.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary.bs-popover-bottom>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary.bs-popover-bottom>.popover-arrow::before,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary.bs-popover-bottom .popover-header::before {
  border-bottom-color: #FFA201;
}

.popover-warning.popover {
  border: 1px solid #FDA700;
}

.popover-warning.popover .popover-header {
  background-color: #FDA700;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-warning.popover .popover-body {
  background-color: #FDA700;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning.bs-popover-top>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning.bs-popover-top>.popover-arrow::before {
  border-top-color: #FDA700;
}

.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning.bs-popover-end>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning.bs-popover-end>.popover-arrow::before {
  border-right-color: #FDA700;
}

.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning.bs-popover-start>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning.bs-popover-start>.popover-arrow::before {
  border-left-color: #FDA700;
}

.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning.bs-popover-bottom>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning.bs-popover-bottom>.popover-arrow::before,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning.bs-popover-bottom .popover-header::before {
  border-bottom-color: #FDA700;
}

.popover-info.popover {
  border: 1px solid #1ECBE2;
}

.popover-info.popover .popover-header {
  background-color: #1ECBE2;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-info.popover .popover-body {
  background-color: #1ECBE2;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info.bs-popover-top>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info.bs-popover-top>.popover-arrow::before {
  border-top-color: #1ECBE2;
}

.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info.bs-popover-end>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info.bs-popover-end>.popover-arrow::before {
  border-right-color: #1ECBE2;
}

.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info.bs-popover-start>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info.bs-popover-start>.popover-arrow::before {
  border-left-color: #1ECBE2;
}

.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info.bs-popover-bottom>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info.bs-popover-bottom>.popover-arrow::before,
.popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info.bs-popover-bottom .popover-header::before {
  border-bottom-color: #1ECBE2;
}

.popover-success.popover {
  border: 1px solid #5CB85C;
}

.popover-success.popover .popover-header {
  background-color: #5CB85C;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-success.popover .popover-body {
  background-color: #5CB85C;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success.bs-popover-top>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success.bs-popover-top>.popover-arrow::before {
  border-top-color: #5CB85C;
}

.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success.bs-popover-end>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success.bs-popover-end>.popover-arrow::before {
  border-right-color: #5CB85C;
}

.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success.bs-popover-start>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success.bs-popover-start>.popover-arrow::before {
  border-left-color: #5CB85C;
}

.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success.bs-popover-bottom>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success.bs-popover-bottom>.popover-arrow::before,
.popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success.bs-popover-bottom .popover-header::before {
  border-bottom-color: #5CB85C;
}

.popover-danger.popover {
  border: 1px solid #FC0027;
}

.popover-danger.popover .popover-header {
  background-color: #FC0027;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-danger.popover .popover-body {
  background-color: #FC0027;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger.bs-popover-top>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger.bs-popover-top>.popover-arrow::before {
  border-top-color: #FC0027;
}

.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger.bs-popover-end>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger.bs-popover-end>.popover-arrow::before {
  border-right-color: #FC0027;
}

.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger.bs-popover-start>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger.bs-popover-start>.popover-arrow::before {
  border-left-color: #FC0027;
}

.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger.bs-popover-bottom>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger.bs-popover-bottom>.popover-arrow::before,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger.bs-popover-bottom .popover-header::before {
  border-bottom-color: #FC0027;
}

.popover-purple.popover {
  border: 1px solid #4A00E5;
}

.popover-purple.popover .popover-header {
  background-color: #4A00E5;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-purple.popover .popover-body {
  background-color: #4A00E5;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple.bs-popover-top>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple.bs-popover-top>.popover-arrow::before {
  border-top-color: #4A00E5;
}

.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple.bs-popover-end>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple.bs-popover-end>.popover-arrow::before {
  border-right-color: #4A00E5;
}

.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple.bs-popover-start>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple.bs-popover-start>.popover-arrow::before {
  border-left-color: #4A00E5;
}

.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple.bs-popover-bottom>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple.bs-popover-bottom>.popover-arrow::before,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple.bs-popover-bottom .popover-header::before {
  border-bottom-color: #4A00E5;
}

.popover-teal.popover {
  border: 1px solid #02a8b5;
}

.popover-teal.popover .popover-header {
  background-color: #02a8b5;
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #FFF;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-teal.popover .popover-body {
  background-color: #02a8b5;
  color: #FFF;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal.bs-popover-top>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal.bs-popover-top>.popover-arrow::before {
  border-top-color: #02a8b5;
}

.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal.bs-popover-end>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal.bs-popover-end>.popover-arrow::before {
  border-right-color: #02a8b5;
}

.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal.bs-popover-start>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal.bs-popover-start>.popover-arrow::before {
  border-left-color: #02a8b5;
}

.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal.bs-popover-bottom>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal.bs-popover-bottom>.popover-arrow::before,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal.bs-popover-bottom .popover-header::before {
  border-bottom-color: #02a8b5;
}

.popover-primary-light.popover {
  border: 1px solid rgba(228, 31, 7, 0.1);
}

.popover-primary-light.popover .popover-header {
  background-color: rgba(228, 31, 7, 0.1);
  border-block-end: 1px solid rgba(228, 31, 7, 0.1);
  color: #E41F07;
  border-radius: 0;
}

.popover-primary-light.popover .popover-body {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary-light.bs-popover-top>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(228, 31, 7, 0.1);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary-light.bs-popover-end>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(228, 31, 7, 0.1);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary-light.bs-popover-start>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(228, 31, 7, 0.1);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary-light.bs-popover-bottom>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary-light.bs-popover-bottom>.popover-arrow::before,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(228, 31, 7, 0.1);
}

.popover-secondary-light.popover {
  border: 1px solid rgba(255, 162, 1, 0.1);
}

.popover-secondary-light.popover .popover-header {
  background-color: rgba(255, 162, 1, 0.1);
  border-block-end: 1px solid rgba(255, 162, 1, 0.1);
  color: #FFA201;
  border-radius: 0;
}

.popover-secondary-light.popover .popover-body {
  background-color: rgba(255, 162, 1, 0.1);
  color: #FFA201;
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary-light.bs-popover-top>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary-light.bs-popover-end>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(255, 162, 1, 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary-light.bs-popover-start>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::before,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-warning-light.popover {
  border: 1px solid rgba(253, 167, 0, 0.1);
}

.popover-warning-light.popover .popover-header {
  background-color: rgba(253, 167, 0, 0.1);
  border-block-end: 1px solid rgba(253, 167, 0, 0.1);
  color: #FDA700;
  border-radius: 0;
}

.popover-warning-light.popover .popover-body {
  background-color: rgba(253, 167, 0, 0.1);
  color: #FDA700;
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning-light.bs-popover-top>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(253, 167, 0, 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning-light.bs-popover-end>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(253, 167, 0, 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning-light.bs-popover-start>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(253, 167, 0, 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning-light.bs-popover-bottom>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning-light.bs-popover-bottom>.popover-arrow::before,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(253, 167, 0, 0.1);
}

.popover-info-light.popover {
  border: 1px solid rgba(30, 203, 226, 0.1);
}

.popover-info-light.popover .popover-header {
  background-color: rgba(30, 203, 226, 0.1);
  border-block-end: 1px solid rgba(30, 203, 226, 0.1);
  color: #1ECBE2;
  border-radius: 0;
}

.popover-info-light.popover .popover-body {
  background-color: rgba(30, 203, 226, 0.1);
  color: #1ECBE2;
}

.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info-light.bs-popover-top>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(30, 203, 226, 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info-light.bs-popover-end>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(30, 203, 226, 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info-light.bs-popover-start>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(30, 203, 226, 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info-light.bs-popover-bottom>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info-light.bs-popover-bottom>.popover-arrow::before,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(30, 203, 226, 0.1);
}

.popover-success-light.popover {
  border: 1px solid rgba(92, 184, 92, 0.1);
}

.popover-success-light.popover .popover-header {
  background-color: rgba(92, 184, 92, 0.1);
  border-block-end: 1px solid rgba(92, 184, 92, 0.1);
  color: #5CB85C;
  border-radius: 0;
}

.popover-success-light.popover .popover-body {
  background-color: rgba(92, 184, 92, 0.1);
  color: #5CB85C;
}

.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success-light.bs-popover-top>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(92, 184, 92, 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success-light.bs-popover-end>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(92, 184, 92, 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success-light.bs-popover-start>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(92, 184, 92, 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success-light.bs-popover-bottom>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success-light.bs-popover-bottom>.popover-arrow::before,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(92, 184, 92, 0.1);
}

.popover-danger-light.popover {
  border: 1px solid rgba(252, 0, 39, 0.1);
}

.popover-danger-light.popover .popover-header {
  background-color: rgba(252, 0, 39, 0.1);
  border-block-end: 1px solid rgba(252, 0, 39, 0.1);
  color: #FC0027;
  border-radius: 0;
}

.popover-danger-light.popover .popover-body {
  background-color: rgba(252, 0, 39, 0.1);
  color: #FC0027;
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger-light.bs-popover-top>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(252, 0, 39, 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger-light.bs-popover-end>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(252, 0, 39, 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger-light.bs-popover-start>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(252, 0, 39, 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger-light.bs-popover-bottom>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger-light.bs-popover-bottom>.popover-arrow::before,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(252, 0, 39, 0.1);
}

.popover-purple-light.popover {
  border: 1px solid rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.popover .popover-header {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--purple-rgb), 0.1);
  color: #4A00E5;
  border-radius: 0;
}

.popover-purple-light.popover .popover-body {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: #4A00E5;
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple-light.bs-popover-top>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple-light.bs-popover-end>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple-light.bs-popover-start>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple-light.bs-popover-bottom>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple-light.bs-popover-bottom>.popover-arrow::before,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--purple-rgb), 0.1);
}

.popover-teal-light.popover {
  border: 1px solid rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.popover .popover-header {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--teal-rgb), 0.1);
  color: #02a8b5;
  border-radius: 0;
}

.popover-teal-light.popover .popover-body {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: #02a8b5;
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal-light.bs-popover-top>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal-light.bs-popover-end>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal-light.bs-popover-start>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal-light.bs-popover-bottom>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal-light.bs-popover-bottom>.popover-arrow::before,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--teal-rgb), 0.1);
}

.timeline {
  position: relative;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0 20px;
}

.timeline:before {
  content: " ";
  position: absolute;
  width: 3px;
  margin-left: -1.5px;
  background-color: #898989;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline>li {
  position: relative;
  margin-bottom: 20px;
}

.timeline>li::before,
.timeline>li::after {
  content: " ";
  display: table;
}

.timeline>li>.timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #6F7A7A;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline>li>.timeline-panel::before {
  content: " ";
  display: inline-block;
  position: absolute;
  border-top: 8px solid transparent;
  border-right: 0 solid #6F7A7A;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #6F7A7A;
  position: absolute;
  top: 26px;
  right: -8px;
}

.timeline>li>.timeline-panel::after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #FFF;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #FFF;
}

.timeline>li:after {
  clear: both;
}

.timeline>li>.timeline-badge {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #FFF;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 50%;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel::before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel::after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #E41F07;
}

.timeline-badge.success {
  background-color: #5CB85C;
}

.timeline-badge.warning {
  background-color: #FDA700;
}

.timeline-badge.danger {
  background-color: #FC0027;
}

.timeline-badge.info {
  background-color: #1ECBE2;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: regular;
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

.form-wrap {
  margin-bottom: 24px;
}

.col-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #262A2A;
}

.form-control {
  border-color: #E8E8E8;
  color: #6F6F6F;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 5px;
  padding: 0.5rem 0.85rem;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  min-height: 42px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #E8E8E8;
  background-color: #FFF;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.05);
  color: #6F6F6F;
}

.form-control[type=file] {
  padding: 0.3rem 0.5rem;
}

.form-control[type=file].form-control-sm {
  padding: 0.25rem 0.5rem !important;
}

.form-control[type=file].form-control-lg {
  padding: 0.5rem 1rem !important;
}

.col-form-label {
  font-size: 14px;
  font-weight: 500;
  color: #262A2A;
  padding: 0 0 5px;
}

.form-text {
  color: #9595b5;
}

.form-control-light {
  background-color: #FDFDFE;
  border: 0;
}

.form-control-light:focus {
  background-color: #FDFDFE;
}

.form-control-primary {
  background-color: rgba(228, 31, 7, 0.1);
  border: 0;
  color: #E41F07;
}

.form-control-primary:focus {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.form-input-color {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  color: #9595b5;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  background-color: transparent;
}

.form-select {
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  color: #6F6F6F;
  font-size: 14px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.25rem;
  line-height: 2;
}

.form-select option {
  background-color: #FFF;
  padding: 0.35rem 0.75rem;
  border-radius: 0.25rem;
  margin-block-end: 0.25rem;
}

.form-select option:last-child {
  margin-block-end: 0;
}

.form-select option:checked {
  background-color: rgba(var(#E41F07), 0.2);
  color: #E41F07;
}

.form-select option:select {
  background-color: rgba(var(#E41F07), 0.2);
  color: #E41F07;
}

.form-select option:hover {
  background-color: rgba(var(#E41F07), 0.2);
  color: #E41F07;
}

.form-select option:focus {
  background-color: rgba(var(#E41F07), 0.2);
  color: #E41F07;
}

.form-check-input {
  width: 0.9rem;
  height: 0.9rem;
  background-color: #FFF;
  border: 1px solid #E8E8E8;
}

.form-check-input:checked {
  background-color: #E41F07;
  border-color: #E41F07;
}

.form-check-input.form-checked-outline:checked {
  background-color: transparent;
  border-color: #E41F07;
}

.form-check-input.form-checked-secondary:checked {
  background-color: #FFA201;
  border-color: #FFA201;
}

.form-check-input.form-checked-warning:checked {
  background-color: #FDA700;
  border-color: #FDA700;
}

.form-check-input.form-checked-info:checked {
  background-color: #1ECBE2;
  border-color: #1ECBE2;
}

.form-check-input.form-checked-success:checked {
  background-color: #5CB85C;
  border-color: #5CB85C;
}

.form-check-input.form-checked-danger:checked {
  background-color: #FC0027;
  border-color: #FC0027;
}

.form-check-input.form-checked-light:checked {
  background-color: #FDFDFE;
  border-color: #FDFDFE;
}

.form-check-input.form-checked-dark:checked {
  background-color: #171724;
  border-color: #171724;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-checked-outline:checked[type=checkbox] {
  background-image: none;
  position: relative;
  background-color: transparent;
}

.form-checked-outline:checked[type=checkbox]:before {
  content: "\f633";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: #E41F07;
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1px;
  inset-inline-start: 0px;
  font-size: 0.688rem;
}

.form-checked-outline:checked[type=checkbox].form-checked-secondary:before {
  color: #FFA201;
}

.form-checked-outline:checked[type=checkbox].form-checked-warning:before {
  color: #FDA700;
}

.form-checked-outline:checked[type=checkbox].form-checked-info:before {
  color: #1ECBE2;
}

.form-checked-outline:checked[type=checkbox].form-checked-success:before {
  color: #5CB85C;
}

.form-checked-outline:checked[type=checkbox].form-checked-danger:before {
  color: #FC0027;
}

.form-checked-outline:checked[type=checkbox].form-checked-light:before {
  color: #FDFDFE;
}

.form-checked-outline:checked[type=checkbox].form-checked-dark:before {
  color: #171724;
}

.form-checked-outline:checked[type=radio] {
  background-image: none;
  position: relative;
  background-color: transparent;
}

.form-checked-outline:checked[type=radio]:before {
  content: "\f309";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: #E41F07;
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1.13rem;
  inset-inline-start: -0.6rem;
  font-size: 2rem;
}

.form-checked-outline:checked[type=radio].form-checked-secondary:before {
  color: #FFA201;
}

.form-checked-outline:checked[type=radio].form-checked-warning:before {
  color: #FDA700;
}

.form-checked-outline:checked[type=radio].form-checked-info:before {
  color: #1ECBE2;
}

.form-checked-outline:checked[type=radio].form-checked-success:before {
  color: #5CB85C;
}

.form-checked-outline:checked[type=radio].form-checked-danger:before {
  color: #FC0027;
}

.form-checked-outline:checked[type=radio].form-checked-light:before {
  color: #FDFDFE;
}

.form-checked-outline:checked[type=radio].form-checked-dark:before {
  color: #171724;
}

fieldset:disabled .btn {
  color: #FFF;
  background-color: #E41F07;
  border-color: #E41F07;
}

.form-select:focus {
  border-color: rgba(var(#E41F07), 0.5);
  outline: 0;
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn:focus {
  background-color: none;
  border-color: none;
  outline: 0;
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: none;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #FDFDFE;
  color: #6F6F6F;
}

.form-control-plaintext {
  color: #6F6F6F;
}

.form-control::-webkit-file-upload-button {
  color: #6F6F6F;
  background-color: #f7f8f9;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f7f8f9;
}

.form-control:focus::-webkit-input-placeholder,
.form-control:focus::placeholder {
  padding-inline-start: 0.3rem;
  transition: padding-inline-start 0.1s ease-in;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.7;
  color: #6F6F6F;
  padding-inline-start: 0px;
  transition: padding-inline-start 0.1s ease-in;
}

.form-control:-moz-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: #6F6F6F;
}

.form-control::-moz-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: #6F6F6F;
}

.form-control:-ms-input-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: #6F6F6F;
}

.form-control-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
}

.form-control-lg {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.form-check-md .form-check-input {
  width: 1.15rem;
  height: 1.15rem;
}

.form-check-md.form-switch .form-check-input {
  width: 2.25rem;
}

.form-check-md label {
  margin-inline-start: 0.5rem;
  font-size: 0.95rem;
  margin-block-start: 3px;
}

.form-check-lg .form-check-input {
  width: 1.35rem;
  height: 1.35rem;
}

.form-check-lg.form-switch .form-check-input {
  width: 2.5rem;
}

.form-check-lg label {
  margin-inline-start: 0.5rem;
  font-size: 1rem;
  margin-block-start: 3px;
}

.toggle {
  width: 3.75rem;
  height: 1.563rem;
  background-color: #FDFDFE;
  margin-inline-start: 0.625rem;
  margin-block-end: 0.313rem;
  padding: 0.125rem;
  border-radius: 0.188rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}

.toggle span {
  position: absolute;
  inset-block-start: 0.188rem;
  inset-block-end: 0.25rem;
  inset-inline-start: 0.188rem;
  display: block;
  width: 1.25rem;
  border-radius: 0.125rem;
  background-color: #FFF;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle span::before {
  content: "on";
  inset-inline-start: -1.563rem;
}

.toggle span::after {
  content: "off";
  inset-inline-end: -1.813rem;
  color: #9595b5;
}

.toggle span::before,
.toggle span::after {
  position: absolute;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  inset-block-start: 0.188rem;
  line-height: 1.38;
  transition: all 0.2s ease;
}

.toggle.on {
  background-color: rgba(var(#E41F07), 0.3);
}

.toggle.on span {
  background-color: #E41F07;
}

.toggle.on span::before {
  color: #E41F07;
}

.toggle.on.toggle-secondary {
  background-color: rgba(255, 162, 1, 0.4);
}

.toggle.on.toggle-secondary span {
  background-color: #FFA201;
}

.toggle.on.toggle-secondary span::before {
  color: #FFA201;
}

.toggle.on.toggle-warning {
  background-color: rgba(253, 167, 0, 0.4);
}

.toggle.on.toggle-warning span {
  background-color: #FDA700;
}

.toggle.on.toggle-warning span::before {
  color: #FDA700;
}

.toggle.on.toggle-info {
  background-color: rgba(30, 203, 226, 0.4);
}

.toggle.on.toggle-info span {
  background-color: #1ECBE2;
}

.toggle.on.toggle-info span::before {
  color: #1ECBE2;
}

.toggle.on.toggle-success {
  background-color: rgba(92, 184, 92, 0.4);
}

.toggle.on.toggle-success span {
  background-color: #5CB85C;
}

.toggle.on.toggle-success span::before {
  color: #5CB85C;
}

.toggle.on.toggle-danger {
  background-color: rgba(252, 0, 39, 0.4);
}

.toggle.on.toggle-danger span {
  background-color: #FC0027;
}

.toggle.on.toggle-danger span::before {
  color: #FC0027;
}

.toggle.on.toggle-light {
  background-color: rgba(253, 253, 254, 0.4);
}

.toggle.on.toggle-light span {
  background-color: #FDFDFE;
}

.toggle.on.toggle-light span::before,
.toggle.on.toggle-light span::after {
  color: #9595b5;
}

.toggle.on.toggle-dark {
  background-color: rgba(23, 23, 36, 0.4);
}

.toggle.on.toggle-dark span {
  background-color: #171724;
}

.toggle.on.toggle-dark span::before {
  color: #FFF;
}

.toggle.on span {
  inset-inline-start: 2.313rem;
}

.toggle.on.toggle-sm span {
  inset-inline-start: 2.313rem;
}

.toggle.on.toggle-sm span::before {
  inset-block-start: -1px;
  inset-inline-start: -1.563rem;
}

.toggle.on.toggle-lg span {
  inset-inline-start: 2.563rem;
}

.toggle.on.toggle-lg span::before {
  inset-block-start: 0.5rem;
  inset-inline-start: -1.75rem;
}

.toggle.toggle-sm {
  height: 1.063rem;
  width: 3.125rem;
}

.toggle.toggle-sm span {
  width: 0.625rem;
  height: 0.625rem;
}

.toggle.toggle-sm span::after {
  inset-inline-end: -1.875rem;
  inset-block-start: -1px;
}

.toggle.toggle-lg {
  height: 2.125rem;
  width: 4.5rem;
}

.toggle.toggle-lg span {
  width: 1.75rem;
}

.toggle.toggle-lg span::after {
  inset-block-start: 0.5rem;
  inset-inline-end: -1.938rem;
}

.custom-toggle-switch>input[type=checkbox] {
  display: none;
}

.custom-toggle-switch>label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 2.5rem;
}

.label-primary {
  background: #E41F07;
  color: #FFF;
}

.label-secondary {
  background: #FFA201;
  color: #FFF;
}

.label-warning {
  background: #FDA700;
  color: #FFF;
}

.label-info {
  background: #1ECBE2;
  color: #FFF;
}

.label-success {
  background: #5CB85C;
  color: #FFF;
}

.label-danger {
  background: #FC0027;
  color: #FFF;
}

.label-light {
  background: #FDFDFE;
  color: #FFF;
}

.label-dark {
  background: #171724;
  color: #FFF;
}

.custom-toggle-switch>input[type=checkbox]:checked+label::before {
  background: inherit;
  opacity: 0.5;
}

.custom-toggle-switch>label::before {
  background: #9595b5;
  box-shadow: inset 0px 0px 0.625rem rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
  content: "";
  height: 1rem;
  margin-block-start: -0.5rem;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 2.5rem;
}

.custom-toggle-switch>label::after {
  background: #FFF;
  border-radius: 1rem;
  box-shadow: 0 0 0.313rem rgba(228, 229, 237, 0.8);
  content: "";
  height: 1.5rem;
  inset-inline-start: -0.25rem;
  margin-block-start: -0.5rem;
  position: absolute;
  inset-block-start: -0.25rem;
  transition: all 0.3s ease-in-out;
  width: 1.5rem;
}

.custom-toggle-switch>input[type=checkbox]:checked+label::after {
  background: inherit;
  inset-inline-start: 50%;
}

.custom-toggle-switch.toggle-sm>label::before {
  height: 10px;
  width: 27px;
  border-radius: 10px;
}

.custom-toggle-switch.toggle-sm input[type=checkbox]:checked+label::after {
  inset-inline-start: 13px;
}

.custom-toggle-switch.toggle-sm>label::after {
  height: 17px;
  width: 17px;
  border-radius: 50%;
}

.custom-toggle-switch.toggle-lg>label::before {
  height: 27px;
  width: 55px;
  border-radius: 20px;
}

.custom-toggle-switch.toggle-lg input[type=checkbox]:checked+label::after {
  inset-inline-start: 77%;
}

.custom-toggle-switch.toggle-lg>label::after {
  height: 35px;
  width: 35px;
  margin-block-start: -8px;
  border-radius: 50%;
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
  color: transparent !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem - 4px);
}

.form-floating.floating-primary label {
  color: #E41F07;
  opacity: 1;
}

.form-floating.floating-primary input {
  border: 1px solid #E41F07;
}

.form-floating.floating-primary>.form-control:focus~label {
  color: #E41F07 !important;
}

.form-floating.floating-secondary label {
  color: #FFA201;
  opacity: 1;
}

.form-floating.floating-secondary input {
  border: 1px solid #FFA201;
}

.form-floating.floating-secondary>.form-control:focus~label {
  color: #FFA201 !important;
}

.form-floating.floating-warning label {
  color: #FDA700;
  opacity: 1;
}

.form-floating.floating-warning input {
  border: 1px solid #FDA700;
}

.form-floating.floating-warning>.form-control:focus~label {
  color: #FDA700 !important;
}

.form-floating.floating-info label {
  color: #1ECBE2;
  opacity: 1;
}

.form-floating.floating-info input {
  border: 1px solid #1ECBE2;
}

.form-floating.floating-info>.form-control:focus~label {
  color: #1ECBE2 !important;
}

.form-floating.floating-success label {
  color: #5CB85C;
  opacity: 1;
}

.form-floating.floating-success input {
  border: 1px solid #5CB85C;
}

.form-floating.floating-success>.form-control:focus~label {
  color: #5CB85C !important;
}

.form-floating.floating-danger label {
  color: #FC0027;
  opacity: 1;
}

.form-floating.floating-danger input {
  border: 1px solid #FC0027;
}

.form-floating.floating-danger>.form-control:focus~label {
  color: #FC0027 !important;
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
  background-color: #FFF;
  border-radius: 4px;
}

.form-wizard-1 {
  border-bottom: 0;
  border-radius: 50px;
}

.form-wizard-1 .nav-link {
  border: 0;
}

.form-wizard-1.nav-tabs .nav-item {
  margin-inline-end: 1rem;
  position: relative;
}

.form-wizard-1.nav-tabs .nav-link {
  padding: 0.25rem 0.4rem;
  color: #9595b5;
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 2px solid transparent;
}

.form-wizard-1.nav-tabs .nav-link i {
  font-size: 12px;
  padding: 4px;
  line-height: 1;
  border-radius: 50px;
  border: 2px solid #6F6F6F;
}

.form-wizard-1.nav-tabs .nav-link:focus,
.form-wizard-1.nav-tabs .nav-link:hover {
  border: 2px solid transparent;
}

.form-wizard-1.nav-tabs .nav-link.active {
  border: 2px solid #E41F07;
  border-radius: 50px;
  color: #E41F07;
  background-color: transparent;
}

.form-wizard-1.nav-tabs .nav-link.active i {
  border: 2px solid #E41F07;
}

.form-wizard-1 .nav-item:focus-visible {
  outline: none;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #5CB85C;
  box-shadow: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #5CB85C;
}

.form-control-color {
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  padding: 0;
}

.example-picker .pcr-button {
  border-radius: 0.35rem !important;
  overflow: hidden;
}

[dir=rtl] [type=email],
[dir=rtl] [type=number],
[dir=rtl] [type=tel],
[dir=rtl] [type=url] {
  direction: rtl;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 1.125rem;
  background-size: 16px 28px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invoice-quantity-container .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

input[type=range]::-webkit-slider-runnable-track {
  background-color: #FDFDFE;
}

[dir=rtl] .form-checked-outline:checked[type=checkbox]:before {
  inset-inline-start: 1px;
}

.form-check {
  min-height: inherit !important;
}

.col-form-label-lg,
.col-form-label-sm,
.col-form-label {
  font-weight: 500;
}

#exampleColorInput::-webkit-color-swatch-wrapper {
  direction: rtl;
}

[data-theme-mode=dark] input[type=week]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=month]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=date]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=datetime-local]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

[dir=rtl] input[type=week],
[dir=rtl] input[type=month],
[dir=rtl] input[type=date],
[dir=rtl] input[type=datetime-local],
[dir=rtl] input[type=time] {
  text-align: end;
}

.notes-page-wrapper .content {
  padding: 24px;
}

.notes-page-wrapper .content .page-add-notes {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 25px;
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .notes-page-wrapper .content .page-add-notes {
    flex-direction: column;
    align-items: start;
  }
}

.notes-page-wrapper .content .page-add-notes .notes-tog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: absolute;
  bottom: -12px;
  border-radius: 30px;
  background: #1B2850;
  color: #FFF;
  width: 24px;
  height: 24px;
  padding: 5px;
}

@media (max-width: 991.98px) {
  .notes-page-wrapper .content .page-add-notes .notes-tog {
    display: none;
  }
}

.notes-page-wrapper .content .section-bulk-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 30px 0 24px 0;
  padding: 20px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
}

@media (max-width: 767.98px) {
  .notes-page-wrapper .content .section-bulk-wrap {
    flex-direction: column;
    align-items: start;
  }
}

.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media (max-width: 767.98px) {
  .notes-page-wrapper .content .section-bulk-wrap .bulk-action-type {
    margin-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .notes-page-wrapper .content .section-bulk-wrap .bulk-action-type {
    margin-bottom: 0;
    flex-direction: column;
    align-items: self-start;
  }
}

.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .select-bluk {
  margin-right: 8px;
  height: 40px;
}

.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .select-bluk .select2-container .selection .select2-selection .select2-selection__rendered {
  padding-left: 10px !important;
}

.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .btn-added {
  background: #6F7A7A;
  padding: 8px 20px;
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  margin-right: 15px;
  height: 38px;
}

.notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .btn-added:hover {
  background: #E41F07;
}

@media (max-width: 575.98px) {
  .notes-page-wrapper .content .section-bulk-wrap .bulk-action-type .btn-added {
    margin: 20px 0;
  }
}

.notes-page-wrapper .content .section-bulk-wrap .form-sort .fa-filter {
  z-index: 100 !important;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 16px;
  height: 16px;
}

.notes-page-wrapper .content .section-notes-slider {
  position: relative;
  padding-bottom: 40px;
  border-bottom: 1px solid #E8E8E8;
}

.notes-page-wrapper .content .section-notes-slider .notes-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px;
}

.notes-page-wrapper .content .section-notes-slider .notes-content .notes-close a {
  color: #FC0027;
  font-size: 15px;
  font-weight: 400;
}

.notes-page-wrapper .content .section-notes-slider .notes-content .notes-close a i {
  margin-right: 6px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card {
  padding: 24px;
  border-radius: 8px;
  background: rgba(255, 159, 67, 0.03);
}

.notes-page-wrapper .content .section-notes-slider .notes-card.medium {
  background: rgba(116, 41, 248, 0.03);
}

.notes-page-wrapper .content .section-notes-slider .notes-card.high {
  background: rgba(234, 84, 85, 0.03);
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body p.badged {
  border-radius: 8px;
  border: 1px solid #FDA700;
  padding: 4px 10px;
  margin-bottom: 0;
  color: #FDA700;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body p.badged i {
  font-size: 7px;
  margin-right: 4px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body p.badged.medium {
  color: #7429F8;
  border: 1px solid #7429F8;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body p.badged.high {
  color: #FC0027;
  border: 1px solid #FC0027;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #6F7A7A;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-card-body a.dropdown-item:hover {
  color: #FFF;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-content {
  margin: 24px 0;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-content h4 a {
  margin-bottom: 10px;
  color: #6F7A7A;
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-content h4 a:hover {
  color: #FDA700;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-content p {
  color: #6F6F6F;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo a {
  margin-right: 15px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo a span {
  width: 32px;
  height: 32px;
  display: flex;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo a span img {
  border-radius: 100px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.medium-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.medium-square i {
  color: #5CB85C;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.high-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.high-square i {
  color: #FDA700;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.low-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo span.low-square i {
  color: #288EC7;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #288EC7;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo p i.fa-draw-polygon {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo p.medium {
  color: #5CB85C;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-logo p.high {
  color: #FDA700;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-star-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-star-delete span .fa-star {
  color: #FDA700;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.notes-page-wrapper .content .section-notes-slider .notes-card .notes-slider-widget .notes-star-delete span .feather-trash-2 {
  color: #FC0027;
  width: 16px;
  height: 16px;
}

.notes-page-wrapper .content .section-card-body {
  padding-top: 40px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details {
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.3);
  background: #FFF;
  box-shadow: 0px 4px 60px 0px rgba(190, 190, 190, 0.27);
  padding: 24px;
  margin-bottom: 24px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body p.badged {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  border-radius: 8px;
  border: 1px solid #FDA700;
  padding: 4px 10px;
  margin-bottom: 0;
  color: #FDA700;
  font-size: 14px;
  font-weight: 600;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body p.badged i {
  font-size: 7px;
  margin-right: 4px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body p.badged.medium {
  color: #7429F8;
  border: 1px solid #7429F8;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body p.badged.high {
  color: #FC0027;
  border: 1px solid #FC0027;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body .dropdown-item span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #6F7A7A;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body .dropdown-item span svg {
  width: 14px;
  height: 14px;
  color: #6F6F6F;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-card-body .dropdown-item:hover span svg {
  color: #FFF;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content {
  padding: 20px 0;
  border-bottom: 1px solid #6F6F6F;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content h4 a {
  color: #6F7A7A;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content h4 a:hover {
  color: #FDA700;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content .wrap-cal {
  color: #6F6F6F;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content .wrap-cal svg {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-wrap-content p {
  color: #6F6F6F;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget.notes-widget-profile {
  padding-top: 20px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo a {
  margin-right: 15px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo a span img {
  border-radius: 100px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.medium-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.medium-square i {
  color: #5CB85C;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.high-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.high-square i {
  color: #FDA700;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.low-square {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo span.low-square i {
  color: #288EC7;
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo p {
  color: #288EC7;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo p i.fa-draw-polygon {
  margin-right: 5px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo p.medium {
  color: #5CB85C;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-logo p.high {
  color: #FDA700;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-star-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-star-delete span .feather-star {
  color: #6F6F6F;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.notes-page-wrapper .content .section-card-body .notes-card-details .notes-slider-widget .notes-star-delete span .feather-trash-2 {
  color: #FC0027;
  width: 16px;
  height: 16px;
}

.notes-card-details .notes-card-body a {
  color: #6F7A7A;
}

.star-todo-inbox svg {
  width: 13px;
  height: 13px;
  color: #6F6F6F;
}

.inbox-call-profile {
  border-radius: 5px;
  background: #E92990;
  border: 1px solid #E92990;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 700;
  color: #FFF;
  margin: 0 20px;
}

.inbox-call-profile:hover {
  color: #E92990;
  background: #FFF;
}

.notes-tog i {
  transform: rotate(180deg);
}

.notes-tog.active i {
  transform: rotate(0deg);
}

.section-bulk-wrap .form-sort {
  position: relative;
}

.section-bulk-wrap .form-sort svg.feather-filter {
  position: absolute;
  z-index: 101;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 10px;
}

.page-add-notes.active svg {
  transform: rotate(0) !important;
}

.dropdown-menu.notes-menu {
  z-index: 99999;
}

.dropdown-menu.notes-menu a.dropdown-item {
  color: #6F7A7A;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.dropdown-menu.notes-menu a.dropdown-item:hover {
  background: #FDA700;
  color: #FFF;
}

.dropdown-menu.notes-menu a.dropdown-item:hover svg {
  color: #FFF;
}

.dropdown-menu.notes-menu span {
  margin-right: 8px;
}

.dropdown-menu.notes-menu svg {
  width: 14px;
  height: 14px;
  color: #6F6F6F;
}

.edit-note-head a {
  border-radius: 30px;
  border: 1px solid rgba(145, 158, 171, 0.3);
  background: #FFF;
  color: #6F7A7A;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.edit-note-head a:hover {
  border-radius: 20px;
  background: #E41F07;
  color: #FFF;
}

.custom-modal-body .select2-container .selection .select2-selection .select2-selection__rendered {
  padding-left: 10px !important;
}

.bulk-con-wrap {
  width: 100%;
}

.section-bulk-widget {
  transition: 0.6s all;
}

.section-notes-dashboard {
  display: none;
  transition: 0.6s all;
}

.notes-tog i {
  transition: 0.5s all;
}

.notes-tag-left .notes-tog i {
  transform: rotate(360deg);
  transition: 0.5s all;
}

.budgeted-role-notes {
  width: 100%;
}

.notes-summernote .card {
  margin-bottom: 0 !important;
}

.dropdown-item:active {
  background: transparent;
}

.notes-summernote p {
  color: #6F6F6F;
  font-size: 15px;
  font-weight: 400;
}

.budget-role-notes .owl-carousel .owl-nav {
  margin: 0;
  position: absolute;
}

.notes-dash {
  margin-top: 30px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.3);
  background: #FFF;
  transition: 0.6s all;
}

.notes-dash .notes-top-head {
  border-bottom: 1px solid #E8E8E8;
}

.notes-dash .notes-top-head h5 {
  font-size: 18px;
  font-weight: 700;
  color: #6F7A7A;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.notes-dash .notes-top-head h5 svg {
  width: 19px;
  height: 19px;
  margin-right: 6px;
  color: #6F6F6F;
}

.notes-dash .notes-top-head-submenu {
  padding: 15px 0;
  border-bottom: 1px solid #E8E8E8;
}

.notes-dash .notes-top-head-submenu ul {
  padding: 15px 0;
}

.notes-dash .notes-top-head-submenu ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 10px;
}

.notes-dash .notes-top-head-submenu ul li.active {
  border-radius: 10px;
  background: #6F7A7A;
}

.notes-dash .notes-top-head-submenu ul li.active svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.notes-dash .notes-top-head-submenu ul li.active p {
  color: #FFF;
}

.notes-dash .notes-top-head-submenu ul li p {
  font-size: 15px;
  font-weight: 600;
  color: #6F6F6F;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.notes-dash .notes-top-head-submenu ul li p svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.notes-dash .notes-top-head-submenu ul li span {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 0px 3px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #E41F07;
  color: #FFF;
}

.notes-dash .content-submenu-tag {
  margin-top: 15px;
  padding: 10px;
}

.notes-dash .content-submenu-tag h6 {
  font-size: 16px;
  font-weight: 600;
  color: #6F7A7A;
}

.notes-dash .content-submenu-tag ul {
  padding: 10px 0;
}

.notes-dash .content-submenu-tag ul.tags-list {
  margin-bottom: 24px;
}

.notes-dash .content-submenu-tag ul li {
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.personal span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.personal span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.personal a {
  color: #288EC7;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.social span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.social span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.social a {
  color: #FDA700;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.work span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.work span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.work a {
  color: #5CB85C;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.public span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.public span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.public a {
  color: #A02F7A;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.medium span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.medium span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.medium a {
  color: #4A00E5;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.high span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.high span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.high a {
  color: #FC0027;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li.low span {
  margin-right: 8px;
}

.notes-dash .content-submenu-tag ul li.low span i {
  transform: rotate(45deg);
  font-size: 10px;
}

.notes-dash .content-submenu-tag ul li.low a {
  color: #FDA700;
  display: flex;
  align-items: center;
}

.notes-dash .content-submenu-tag ul li+li {
  margin-top: 10px;
}

.notes-logo a {
  display: flex;
  align-items: center;
}

.notes-logo a span {
  width: 32px;
  height: 32px;
}

.notes-logo a span img {
  border-radius: 32px;
}

.notes-top-head-submenu {
  padding: 15px 0;
  border-bottom: 1px solid #6F6F6F;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #6F6F6F;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 10px;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox.active {
  border-radius: 10px;
  background: #212B36;
  padding: 10px;
  color: #FFF;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox.active svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox:hover {
  background: #212B36;
  color: #FFF;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.notes-top-head-submenu .nav.todo-inbox button.todo-inbox span {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0px 3px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #E41F07;
  color: #FFF;
}

.notes-trash-btn {
  background: #FC0027;
  color: #FFF;
  border-radius: 8px;
  margin: 4px 0 20px 0;
  padding: 10px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes-trash-btn span {
  display: flex;
  align-items: center;
}

.notes-trash-btn span svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.delete-popup {
  padding: 24px;
  border-radius: 8px;
  background: #FFF;
}

.delete-popup .delete-image {
  margin-bottom: 20px;
}

.delete-popup .delete-heads {
  text-align: center;
}

.delete-popup .delete-heads h4 {
  color: #6F7A7A;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.delete-popup .delete-heads p {
  color: #6F6F6F;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
}

.delete-popup .delete-footer {
  text-align: center;
}

.edit-page-title {
  display: flex;
  align-items: center;
}

.edit-page-title h4 {
  font-size: 18px;
  font-weight: 700;
  color: #6F7A7A;
  margin-bottom: 0;
  margin-right: 10px;
}

.edit-page-title p {
  font-size: 15px;
  font-weight: 400;
  color: #288EC7;
}

.edit-head-view {
  margin-bottom: 20px;
  border-bottom: rgba(145, 158, 171, 0.3);
}

.edit-head-view h6 {
  font-size: 16px;
  font-weight: 600;
  color: #6F7A7A;
  margin-bottom: 15px;
}

.edit-head-view p {
  font-size: 15px;
  font-weight: 400;
  color: #6F6F6F;
  margin-bottom: 15px;
}

.edit-head-view p.badged {
  border-radius: 8px;
  border: 1px solid #FDA700;
  padding: 4px 10px;
  margin-bottom: 0;
  color: #FDA700;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}

.edit-head-view p.badged i {
  font-size: 7px;
  margin-right: 7px;
}

.edit-footer-menu a {
  background-color: #FC0027;
}

.edit-noted-head a span i.fa-trash-alt {
  color: #FC0027;
  margin-right: 10px;
}

.edit-noted-head a span i.fa-star {
  color: #FDA700;
}

.edit-head-view p.badged.high {
  border-radius: 8px;
  border: 1px solid #FC0027;
  padding: 4px 10px;
  margin-bottom: 0;
  color: #FC0027;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}

.edit-head-view p.badged.high i {
  font-size: 7px;
  margin-right: 7px;
}

.people-profile-pic {
  border: none !important;
  margin-left: 14px;
  position: relative;
}

.people-profile-pic img {
  border-radius: 10px;
}

.people-profile-pic a {
  position: absolute;
  top: 15px;
  right: 7px;
}

.people-profile-pic a .x-square-add {
  border-radius: 3px;
  background: #FC0027;
  color: #FFF;
}

.people-customer-walk {
  max-width: 35px;
}

.custom-pagination .paginations {
  align-items: center;
}

.custom-pagination .paginations span {
  display: flex;
  align-items: center;
  color: #6F6F6F;
  width: 16px;
  height: 16px;
}

.custom-pagination .paginations span .fa-chevron-left {
  margin-right: 8px;
}

.custom-pagination .paginations .page-wrap li a {
  margin-right: 8px;
  border-radius: 85px;
  color: #6F6F6F;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.custom-pagination .paginations .page-wrap li a.active {
  background: #E41F07;
  color: #FFF;
}

.custom-pagination .paginations .page-wrap li :hover {
  background: #E41F07;
  color: #FFF;
  transition: 0.3s all;
}

.section-notes-slider .notes-slider.owl-carousel .owl-nav {
  top: -42px;
  right: unset;
  left: 170px;
}

.section-notes-slider .notes-slider.owl-carousel .owl-nav button {
  background-color: transparent;
  width: 15px;
  height: auto;
}

.section-notes-slider .notes-slider.owl-carousel .owl-nav button i {
  color: #6F7A7A;
}

.section-notes-slider .notes-slider.owl-carousel .owl-nav button:hover {
  background-color: transparent;
}

.section-notes-slider .notes-slider.owl-carousel .owl-nav button:hover i {
  color: #E41F07;
}

.form-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 35px;
}

.form-sort span.select2-container.select2-container--default.select2-container--open {
  z-index: 99;
}

.accordion-button:after,
.accordion-button:not(.collapsed)::after {
  display: none;
}

.todo-accordion .accordion-item {
  background: transparent;
  margin-bottom: 10px;
  border: 0;
  padding: 0;
}

.search-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.search-set .search-input {
  position: relative;
  margin-right: 15px;
}

.search-set .search-input input[type=search] {
  height: 38px;
  border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
  background: var(--White, #FFF);
  width: 298px;
  border-radius: 8px;
  padding: 10px;
}

.search-set .search-input input[type=search]::placeholder {
  color: #000;
}

@media (max-width: 1199.98px) {
  .search-set .search-input input[type=search] {
    width: 220px;
  }
}

@media (max-width: 991.98px) {
  .search-set .search-input input[type=search] {
    width: 200px;
  }
}

@media (max-width: 575.98px) {
  .search-set .search-input input[type=search] {
    width: auto;
  }
}

.search-set .search-input .btn-searchset {
  border-radius: 50px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}

.search-set .search-input .btn-searchset .feather-search {
  width: 14px;
  height: 14px;
}

.todo-inbox-check {
  margin-right: 15px;
}

.todo-inbox-check .checkboxs {
  margin-bottom: 0;
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.todo-inbox-check .checkboxs input~.checkmarks {
  border-color: rgba(145, 158, 171, 0.3);
  border-radius: 5px;
  margin-top: 2px;
  width: 20px;
  height: 20px;
}

.todo-inbox-check .checkboxs input:checked~.checkmarks {
  border-color: #E41F07;
  background-color: #E41F07;
}

.btn-cancel {
  background: #565E5E;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.btn-cancel:hover {
  background: #424b52;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .btn-cancel {
    min-width: auto;
  }
}

.swal2-confirm {
  margin-right: 5px;
}

.btn-submit {
  background: #E41F07;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn-submit:hover {
  background: #b31805;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .btn-submit {
    min-width: auto;
  }
}

.sections-notes-slider .accordion-button:after {
  display: none;
}

.todo-wrapper-list-content {
  margin-left: 5px;
}

.notes-header h3 {
  color: #6F7A7A;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.todo-inbox-check .checkboxs .checkmarks:after {
  left: 9px;
  top: 10px;
}

.checkboxs input:checked~.checkmarks:after {
  display: block;
}

.todo-widget {
  margin-bottom: 25px;
}

.todo-today-content {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.todo-today-content .todo-drop-down span i {
  color: #6F7A7A;
}

.todo-today-content .todo-today-header {
  display: flex;
  align-items: center;
}

.todo-today-content .todo-today-header span {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.todo-today-content .todo-today-header span svg {
  width: 16px;
  height: 16px;
  color: #6F6F6F;
}

.todo-today-content .todo-today-header h3 {
  font-size: 16px;
  font-weight: 600;
  color: #6F7A7A;
  margin-bottom: 0;
  margin-right: 5px;
}

.todo-today-content .todo-today-header h6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #E41F07;
  border-radius: 50px;
  color: #FFF;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0;
}

.todo-wrapper-list {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.3);
  background: #FFF;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 575.98px) {
  .todo-wrapper-list {
    flex-direction: column;
  }
}

.todo-wrapper-list .todo-inbox-check {
  display: flex;
  align-items: center;
}

.todo-wrapper-list .todo-inbox-check .todo-wrapper-list-content h4 {
  font-size: 16px;
  font-weight: 700;
  color: #6F7A7A;
  margin-bottom: 5px;
}

.todo-wrapper-list .todo-inbox-check .todo-wrapper-list-content p {
  font-size: 13px;
  font-weight: 500;
  color: #6F6F6F;
  margin-bottom: 0;
}

.todo-wrapper-list .todo-inbox-check.todo-inbox-check-list {
  width: 45%;
}

@media (max-width: 575.98px) {
  .todo-wrapper-list .todo-inbox-check.todo-inbox-check-list {
    width: auto;
  }
}

.todo-wrapper-list .todo-inbox-check.todo-inbox-check-list .checkboxs {
  margin-bottom: 12px;
}

.todo-wrapper-list .todo-inbox-check.todo-inbox-check-list .checkboxs input~.checkmarks {
  border-radius: 5px;
  margin-top: 0;
}

.todo-wrapper-list .notes-card-body {
  width: 35%;
}

@media (max-width: 575.98px) {
  .todo-wrapper-list .notes-card-body {
    width: auto;
    margin: 20px 0;
  }
}

.todo-wrapper-list .notes-card-body p {
  display: flex;
  align-items: center;
}

.todo-wrapper-list .notes-card-body p.high {
  border-radius: 8px;
  border: 1px solid #FC0027;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #FC0027;
  margin-right: 10px;
}

.todo-wrapper-list .notes-card-body p.medium {
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid #423B93;
  font-size: 14px;
  font-family: 600;
  color: #423B93;
}

.todo-wrapper-list .notes-card-body p.pending {
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid #288EC7;
  font-size: 14px;
  font-family: 600;
  color: #288EC7;
}

.todo-wrapper-list .notes-card-body p.low {
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid #FDA700;
  font-size: 14px;
  font-family: 600;
  color: #FDA700;
}

.todo-wrapper-list .notes-card-body p.success {
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid #5CB85C;
  font-size: 14px;
  font-family: 600;
  color: #5CB85C;
}

.todo-wrapper-list .notes-card-body p i.fa-circle {
  font-size: 7px;
  margin-right: 4px;
}

.todo-wrapper-list .todo-strike-content h4 {
  text-decoration-line: line-through;
}

.todo-wrapper-list .todo-strike-content p {
  text-decoration-line: line-through;
}

.todo-wrapper-list .todo-profile {
  width: 20%;
  justify-content: end;
}

.todo-wrapper-list .todo-profile img {
  border-radius: 30px;
  margin-right: 16px;
  width: 30px;
  height: 30px;
}

.todo-wrapper-list .todo-profile a .fa-ellipsis-v {
  color: #6F7A7A;
}

.datanew tbody {
  border-top: 1px solid #E8E8E8;
}

.form-sort {
  position: relative;
  width: 149px;
  height: 40px;
}

.form-sort .info-img {
  position: absolute;
  top: 52%;
  z-index: 100;
  width: 16px;
  height: 16px;
  left: 0;
  margin-left: 10px;
  color: #6F6F6F;
  transform: translateY(-50%);
  line-height: normal;
}

@media (max-width: 575.98px) {
  .form-sort {
    margin-top: 24px;
  }
}

.todo-calendar .input-groupicon.calender-input input {
  padding-left: 10px;
}

.todo-calendar {
  position: relative;
}

.todo-calendar .calender-input {
  position: relative;
}

.todo-calendar .calender-input .info-img {
  position: absolute;
  top: 11px;
  z-index: 100;
  width: 16px;
  height: 16px;
  right: 5px !important;
  color: #6F6F6F;
}

.todo-accordion .accordion-item {
  background: transparent;
  margin-bottom: 30px;
}

.todo-accordion .accordion-item .accordion-header {
  cursor: pointer;
}

.todo-accordion .accordion-item .accordion-header .accordion-button {
  background: transparent;
}

.todo-accordion .accordion-item .accordion-header .accordion-button.collapsed .todo-today-content {
  margin-bottom: 0;
}

.todo-accordion .accordion-item .accordion-header .todo-today-content {
  width: 100%;
}

.todo-accordion .accordion-item .accordion-header .todo-drop-down a span i {
  color: #6F6F6F;
}

.todo-accordion .accordion-item .todo-widget {
  margin-bottom: 0;
}

.todo-accordion .accordion-item .todo-widget .todo-wrapper-list:last-child {
  margin-bottom: 0;
}

caption {
  color: #9595b5;
}

.table-active {
  --bs-table-color-state: var(--default-text-color);
}

.table {
  color: #6F6F6F;
  border-color: #6F6F6F;
  margin-block-end: 0;
}

.table .checkboxs {
  margin-bottom: 15px;
}

.table tbody tr th {
  font-weight: 500;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  line-height: 1.462;
  font-size: 0.813rem;
  white-space: nowrap;
}

.table thead tr th {
  font-weight: 600;
  font-size: 15px;
}

.table.table-sm> :not(caption)>*>* {
  padding: 0.3rem;
}

.table.table-dark {
  color: #F2ECFF;
  border-color: rgba(255, 255, 255, 0.1);
}

.table .thead-primary th {
  background-color: #E41F07;
  color: #FFF;
}

.table .thead-secondary th {
  background-color: #FFA201;
  color: #FFF;
}

.table .thead-info th {
  background-color: #1ECBE2;
  color: #FFF;
}

.table .thead-warning th {
  background-color: #FDA700;
  color: #FFF;
}

.table .thead-danger th {
  background-color: #FC0027;
  color: #FFF;
}

.table .thead-success th {
  background-color: #5CB85C;
  color: #FFF;
}

.table .thead-dark th {
  background-color: #171724;
  color: #FFF;
}

.table .thead-light th {
  background-color: #FAFAFA;
  color: #171724;
}

.table.table-primary {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.2);
}

.table.table-primary.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.2);
  color: #000;
}

.table.table-primary.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.4);
  color: #FFF;
}

.table.table-primary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--primary-rgb));
  color: #FFF;
}

.table.table-secondary {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.2);
}

.table.table-secondary.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.2);
  color: #000;
}

.table.table-secondary.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.4);
  color: #FFF;
}

.table.table-secondary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--secondary-rgb));
  color: #FFF;
}

.table.table-warning {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.2);
}

.table.table-warning.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.2);
  color: #000;
}

.table.table-warning.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.4);
  color: #FFF;
}

.table.table-warning .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--warning-rgb));
  color: #FFF;
}

.table.table-success {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.2);
}

.table.table-success.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.2);
  color: #000;
}

.table.table-success.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.4);
  color: #FFF;
}

.table.table-success .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--success-rgb));
  color: #FFF;
}

.table.table-danger {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.2);
}

.table.table-danger.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.2);
  color: #000;
}

.table.table-danger.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.4);
  color: #FFF;
}

.table.table-danger .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--danger-rgb));
  color: #FFF;
}

.table.table-info {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.2);
}

.table.table-info.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.2);
  color: #000;
}

.table.table-info.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.4);
  color: #FFF;
}

.table.table-info .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--info-rgb));
  color: #FFF;
}

.table.table-light {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.2);
}

.table.table-light.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.2);
  color: #000;
}

.table.table-light.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.4);
  color: #FFF;
}

.table.table-light .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--light-rgb));
  color: #FFF;
}

.table.table-dark {
  background-color: #FFF;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.2);
}

.table.table-dark.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.2);
  color: #000;
}

.table.table-dark.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.4);
  color: #FFF;
}

.table.table-dark .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--dark-rgb));
  color: #FFF;
}

.table.table-bordered.border-primary tbody,
.table.table-bordered.border-primary td,
.table.table-bordered.border-primary tfoot,
.table.table-bordered.border-primary th,
.table.table-bordered.border-primary thead,
.table.table-bordered.border-primary tr {
  border-color: rgba(228, 31, 7, 0.1);
}

.table.table-bordered.border-info tbody,
.table.table-bordered.border-info td,
.table.table-bordered.border-info tfoot,
.table.table-bordered.border-info th,
.table.table-bordered.border-info thead,
.table.table-bordered.border-info tr {
  border-color: rgba(30, 203, 226, 0.1);
}

.table.table-bordered.border-secondary tbody,
.table.table-bordered.border-secondary td,
.table.table-bordered.border-secondary tfoot,
.table.table-bordered.border-secondary th,
.table.table-bordered.border-secondary thead,
.table.table-bordered.border-secondary tr {
  border-color: rgba(255, 162, 1, 0.1);
}

.table.table-bordered.border-warning tbody,
.table.table-bordered.border-warning td,
.table.table-bordered.border-warning tfoot,
.table.table-bordered.border-warning th,
.table.table-bordered.border-warning thead,
.table.table-bordered.border-warning tr {
  border-color: rgba(253, 167, 0, 0.1);
}

.table.table-bordered.border-success tbody,
.table.table-bordered.border-success td,
.table.table-bordered.border-success tfoot,
.table.table-bordered.border-success th,
.table.table-bordered.border-success thead,
.table.table-bordered.border-success tr {
  border-color: rgba(92, 184, 92, 0.1);
}

.table.table-bordered.border-danger tbody,
.table.table-bordered.border-danger td,
.table.table-bordered.border-danger tfoot,
.table.table-bordered.border-danger th,
.table.table-bordered.border-danger thead,
.table.table-bordered.border-danger tr {
  border-color: rgba(252, 0, 39, 0.1);
}

.table.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.0125);
  color: #6F6F6F;
}

.table.table-striped-columns> :not(caption)>tr> :nth-child(2n) {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.025);
  color: #6F6F6F;
}

.table tbody.table-group-divider {
  border-top: 1px solid #6F6F6F;
}

.table.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: #f7f8f9;
  color: #6F6F6F;
}

.table .table-active {
  --bs-table-accent-bg: #FDFDFE;
  color: #6F6F6F;
}

.table thead.table-primary {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-secondary {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-warning {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-success {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-danger {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-info {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-light {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-dark {
  background-color: #FFF;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--primary-rgb), 0.1);
  background-color: #FFF;
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--secondary-rgb), 0.1);
  background-color: #FFF;
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--warning-rgb), 0.1);
  background-color: #FFF;
}

.table-success {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--success-rgb), 0.1);
  background-color: #FFF;
}

.table-info {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--info-rgb), 0.1);
  background-color: #FFF;
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--danger-rgb), 0.1);
  background-color: #FFF;
}

.table-dark {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--dark-rgb), 0.1);
  background-color: #FFF;
}

.table-light {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--light-rgb), 0.1);
  background-color: #FFF;
}

[data-theme-mode=dark] .table-dark {
  border-color: rgba(0, 0, 0, 0.025);
}

[dir=rtl] .table {
  margin-inline-end: 1px;
}

.table {
  margin: 0;
}

.table tbody tr td {
  color: #424848;
  font-size: 14px;
}

.table tbody tr td .table-avatar a {
  color: #262A2A;
  font-weight: 500;
}

.table tbody tr td .table-avatar a.avatar {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.table tbody tr td .table-avatar a.avatar img {
  border-radius: 5px;
}

.table tbody tr td .table-avatar a.profile-split span {
  color: #6F6F6F;
  font-weight: 400;
}

.table tbody tr td .product-img img {
  border: 0;
}

.table tbody tr td .userslist-img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.table tbody tr td .userslist-img img {
  border-radius: 0;
}

.table tbody tr td .userslist-img.bg-img img {
  border: 1px solid var(--stroke-table, #E8EBED);
  background: url(), lightgray 50%/cover no-repeat;
}

.table tbody tr td a {
  color: #FFA201;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.table tbody tr td a.product-img {
  margin-right: 5px;
  flex-shrink: 0;
}

.table tbody tr td a .info-img {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.table tbody tr td a:hover {
  color: #E41F07;
}

.table tbody tr td .emp-name {
  line-height: 1;
}

.table tbody tr td .emp-name a {
  color: #FFA201;
}

.table tbody tr td .emp-name p {
  line-height: 2;
  font-size: 15px;
  font-weight: 500;
  color: #6F6F6F;
}

.table tbody tr td .emp-name .role {
  line-height: unset;
}

.table tbody tr td .product-imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 40px;
  height: 40px;
  background: #FFEBD8;
  font-size: 14px;
  color: #FF9F43 !important;
  font-weight: 500;
  margin-right: 10px;
}

.table tbody tr .data-header {
  font-weight: 600;
  color: #FFA201;
  font-size: 15px;
  border-bottom: none;
}

.table tbody tr .ref-number {
  color: #4A00E5;
}

.table tbody tr.active {
  border-bottom: 0;
}

.table tbody tr.active td {
  font-size: 18px;
  color: #424848;
  font-weight: 600;
  border-bottom: 0;
}

.table tbody .loss-highlight td {
  font-weight: 700;
  color: #FFA201;
  font-size: 14px;
  border-top: 1px solid #898989;
}

.table tbody .userimgname {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.table tbody .userimgname img {
  margin-right: 8px;
  border-radius: 6px;
}

.table tbody .purchase-count td {
  padding-bottom: 20px;
}

.table .Expense-list tr td {
  padding: 15px 17px;
}

.table .Expense-list tr td a {
  margin-bottom: 0;
}

.table .sales-list tr td {
  padding: 20px 17px;
}

.table .Expense-list-blk tr td {
  padding: 20px 17px;
}

.new tbody tr td {
  border-bottom: none;
}

.table-height {
  min-height: 300px;
}

@media (max-width: 991.98px) {
  .table-height {
    min-height: auto;
    margin-bottom: 15px;
  }
}

.max-widthauto {
  max-width: 100% !important;
}

.dataTables_filter+.dataTables_filter {
  display: none;
}

.dataview .dataTables_length,
.dataview .dataTables_info,
.dataview .dataTables_paginate {
  display: none !important;
}

.dataTables_length {
  font-size: 12px;
}

.productimgname {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.productimgname a {
  color: #FFA201;
  font-weight: 500;
}

.productimgname .view-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  background: #F8F8F8;
}

.input-blocks .calc-no {
  width: 50px !important;
  text-align: center;
}

.table .input-blocks input[type=text] {
  height: 40px;
}

.table .scanner-set {
  width: 40px;
  height: 40px;
}

.card.table-list-card .card-body {
  padding: 0;
}

.card.table-list-card .table-top {
  padding: 24px 24px 0;
}

.card.table-list-card .table-responsive {
  padding: 24px;
}

.card.table-list-card .table-responsive .dataTables_wrapper {
  border: 0;
  padding: 0;
}

.card.table-list-card .tabs-set .nav-tabs {
  padding: 24px 24px 0;
  margin-bottom: 0;
}

.table.profit-table thead {
  background-color: #FAFBFE;
  border-radius: 8px 8px 0px 0px;
}

.table.profit-table thead tr:hover {
  background-color: #FAFBFE;
}

.table.profit-table tbody td {
  border-bottom: 0;
}

.table.profit-table tbody .profit-table-bg td {
  color: #FFA201;
  font-weight: 700;
  border-top: 1px solid #C0C0C0;
  background-color: #FAFBFE;
}

.table.profit-table tbody .table-heading:hover {
  background-color: #FFF;
}

.table.profit-table tbody .table-heading td {
  color: #FFA201;
  font-weight: 700;
}

.profit-table-top .daterange-wraper {
  margin: 0 10px;
}

.profit-table-top .daterange-wraper input {
  padding-left: 35px;
}

table td .shield {
  width: 14px;
  height: 14px;
}

.paysilp-table-border {
  border: 1px solid #C0C0C0;
}

.paysilp-table-border th {
  border-right: 1px solid #C0C0C0;
  border-left: 1px solid #C0C0C0;
  padding: 15px;
  font-weight: 700;
  font-size: 16px;
}

.paysilp-table-borders td {
  border-right: 1px solid #C0C0C0;
  border-left: 1px solid #C0C0C0;
  padding: 5px 15px;
  color: #6F6F6F;
}

.payslip-border-bottom {
  border: 1px solid #C0C0C0;
}

.payslip-border-bottom th {
  padding: 5px 15px;
  font-weight: 700;
  font-size: 16px;
}

.payslip-border-bottom td {
  color: #6F6F6F;
}

.emp-details {
  margin: 20px 0;
}

.emp-details .pay-slip-salery h6 {
  font-weight: 700 !important;
  margin-bottom: 10px;
  font-size: 18px !important;
}

.emp-details .emp-name-id.pay-slip-salery {
  margin-right: 30px;
}

@media (max-width: 575.98px) {
  .slip-logo {
    flex-shrink: 0;
    margin-right: 15px;
  }
}

.product-name-slip h4 {
  color: #E41F07;
  font-weight: 700;
  font-size: 18px;
}

.pay-slip-box .modal-header {
  border-radius: 8px 8px 0 0;
}

.pay-slip-box .custom-modal-body {
  padding: 0;
}

.pay-slip-box .card {
  border: 0;
  border-radius: 0 0 10px 10px;
}

.pay-slip-box .card .card-body {
  padding: 20px;
}

.pay-slip-box .card .card-body .emp-details.justify-content-start {
  max-width: 760px;
  margin: 20px auto 20px;
}

.table {
  margin: 0;
}

.table tbody tr td {
  color: #424848;
  font-size: 14px;
}

.table tbody tr td .product-img img {
  border: 0;
}

.table tbody tr td .userslist-img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.table tbody tr td .userslist-img img {
  border-radius: 0;
}

.table tbody tr td .userslist-img.bg-img img {
  border: 1px solid var(--stroke-table, #E8EBED);
  background: url(), lightgray 50%/cover no-repeat;
}

.table tbody tr td a {
  color: #565E5E;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.table tbody tr td a.product-img {
  margin-right: 5px;
  flex-shrink: 0;
}

.table tbody tr td a .info-img {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.table tbody tr td a:hover {
  color: #E41F07;
}

.table tbody tr td a:hover i {
  color: #E41F07;
}

.table tbody tr td .emp-name {
  line-height: 1;
}

.table tbody tr td .emp-name a {
  color: #FFA201;
}

.table tbody tr td .emp-name p {
  line-height: 2;
  font-size: 15px;
  font-weight: 500;
  color: #6F6F6F;
}

.table tbody tr td .emp-name .role {
  line-height: unset;
}

.table tbody tr td .product-imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 40px;
  height: 40px;
  background: #FFEBD8;
  font-size: 14px;
  color: #FF9F43 !important;
  font-weight: 500;
  margin-right: 10px;
}

.table tbody tr .data-header {
  font-weight: 600;
  color: #FFA201;
  font-size: 15px;
  border-bottom: none;
}

.table tbody tr .ref-number {
  color: #4A00E5;
}

.table tbody tr.active {
  border-bottom: 0;
}

.table tbody tr.active td {
  font-size: 18px;
  color: #424848;
  font-weight: 600;
  border-bottom: 0;
}

.table tbody .loss-highlight td {
  font-weight: 700;
  color: #FFA201;
  font-size: 14px;
  border-top: 1px solid #898989;
}

.table tbody .userimgname {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.table tbody .userimgname img {
  margin-right: 8px;
  border-radius: 6px;
}

.table tbody .purchase-count td {
  padding-bottom: 20px;
}

.table .Expense-list tr td {
  padding: 15px 17px;
}

.table .Expense-list tr td a {
  margin-bottom: 0;
}

.table .sales-list tr td {
  padding: 20px 17px;
}

.table .Expense-list-blk tr td {
  padding: 20px 17px;
}

.table .userimgname .product-img {
  display: inherit;
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

table tbody td.action-table-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

table tbody td.action-table-data a {
  border: 1px solid rgba(145, 158, 171, 0.3);
  background-color: #FFF;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  padding: 8px;
}

table tbody td.action-table-data a .feather-edit {
  color: #339DFF;
  width: 14px;
  height: 14px;
}

table tbody td.action-table-data a .action-edit {
  color: #6F6F6F;
  width: 14px;
  height: 14px;
}

table tbody td.action-table-data a .feather-trash-2 {
  color: #FC0027;
  width: 14px;
  height: 14px;
}

table tbody td.action-table-data a .feather-eye {
  width: 14px;
  height: 14px;
}

table tbody td.action-table-data a .action-download {
  color: #339DFF;
  width: 14px;
  height: 14px;
}

table tbody td.action-table-data a .action-eye {
  color: #FFA201;
  width: 14px;
  height: 14px;
}

table tbody .productimgname {
  min-width: 200px;
}

table tbody .productimgname a.stock-img.product-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 8px;
}

table tbody .productimgname a.stock-img.product-img img {
  width: 50px;
  height: 50px;
  max-width: 100%;
  margin-right: 0;
}

table .edit-delete-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  height: 50px;
}

table .edit-delete-action a {
  padding: 0;
}

table .edit-delete-action.data-view {
  min-height: 52px;
}

table .edit-delete-action.data-row {
  height: auto;
}

table .edit-delete-action.data {
  height: 44px;
}

.accordion-button {
  background-color: #FFF;
  color: #6F6F6F;
  padding: 0.75rem 1rem;
  font-size: 0.85rem;
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: #6F6F6F;
  background-color: #f7f8f9;
  box-shadow: none;
}

.accordion-button:focus {
  border-color: rgba(228, 31, 7, 0.3);
  box-shadow: none;
}

.accordion-button:after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-button:after {
  content: "\f078";
  font-family: "Fontawesome";
  font-size: 12px;
  line-height: 1;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  color: #6F6F6F;
}

.accordion-body {
  padding: 0.75rem 1rem;
  font-size: 0.8125rem;
  color: #9595b5;
}

.accordion-item {
  color: #6F6F6F;
  background-color: #FFF;
  border: 1px solid #E8E8E8;
}

.accordions-items-seperate .accordion-item:not(:first-of-type) {
  margin-block-start: 0.5rem;
}

.accordions-items-seperate .accordion-item {
  border-block-start: 1px solid #E8E8E8;
  border-radius: 4px;
  overflow: hidden;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

.accordion.accordion-primary .accordion-button {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.accordion.accordion-primary .accordion-button:after {
  background-color: #E41F07;
  color: #FFF;
}

.accordion.accordion-primary .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-primary .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-secondary .accordion-button {
  background-color: rgba(255, 162, 1, 0.1);
  color: #FFA201;
}

.accordion.accordion-secondary .accordion-button:after {
  background-color: #FFA201;
  color: #FFF;
}

.accordion.accordion-secondary .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-secondary .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-success .accordion-button {
  background-color: rgba(92, 184, 92, 0.1);
  color: #5CB85C;
}

.accordion.accordion-success .accordion-button:after {
  background-color: #5CB85C;
  color: #FFF;
}

.accordion.accordion-success .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-success .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-danger .accordion-button {
  background-color: rgba(252, 0, 39, 0.1);
  color: #FC0027;
}

.accordion.accordion-danger .accordion-button:after {
  background-color: #FC0027;
  color: #FFF;
}

.accordion.accordion-danger .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-danger .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-warning .accordion-button {
  background-color: rgba(253, 167, 0, 0.1);
  color: #FDA700;
}

.accordion.accordion-warning .accordion-button:after {
  background-color: #FDA700;
  color: #FFF;
}

.accordion.accordion-warning .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-warning .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-info .accordion-button {
  background-color: rgba(30, 203, 226, 0.1);
  color: #1ECBE2;
}

.accordion.accordion-info .accordion-button:after {
  background-color: #1ECBE2;
  color: #FFF;
}

.accordion.accordion-info .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-info .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-primary .accordion-button {
  background-color: #E41F07;
  color: #FFF;
}

.accordion.accordion-solid-primary .accordion-button:after {
  background-color: #FFF;
  color: #E41F07;
}

.accordion.accordion-solid-primary .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-primary .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-secondary .accordion-button {
  background-color: #FFA201;
  color: #FFF;
}

.accordion.accordion-solid-secondary .accordion-button:after {
  background-color: #FFF;
  color: #FFA201;
}

.accordion.accordion-solid-secondary .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-success .accordion-button {
  background-color: #5CB85C;
  color: #FFF;
}

.accordion.accordion-solid-success .accordion-button:after {
  background-color: #FFF;
  color: #5CB85C;
}

.accordion.accordion-solid-success .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-success .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-danger .accordion-button {
  background-color: #FC0027;
  color: #FFF;
}

.accordion.accordion-solid-danger .accordion-button:after {
  background-color: #FFF;
  color: #FC0027;
}

.accordion.accordion-solid-danger .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-danger .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-warning .accordion-button {
  background-color: #FDA700;
  color: #FFF;
}

.accordion.accordion-solid-warning .accordion-button:after {
  background-color: #FFF;
  color: #FDA700;
}

.accordion.accordion-solid-warning .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-warning .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-solid-info .accordion-button {
  background-color: #1ECBE2;
  color: #FFF;
}

.accordion.accordion-solid-info .accordion-button:after {
  background-color: #FFF;
  color: #1ECBE2;
}

.accordion.accordion-solid-info .accordion-button.collapsed {
  background-color: #FFF;
  color: #6F6F6F;
}

.accordion.accordion-solid-info .accordion-button.collapsed:after {
  background-color: #f7f8f9;
  color: #6F6F6F;
}

.accordion.accordion-border-primary .accordion-item {
  border: 1px solid #E41F07;
}

.accordion.accordion-border-primary .accordion-button {
  background-color: #FFF;
  color: #E41F07;
  border-block-end: 0px;
}

.accordion.accordion-border-primary .accordion-button:after {
  background-color: #FFF;
  color: #E41F07;
  border: 1px solid #E41F07;
}

.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #E41F07;
}

.accordion.accordion-border-secondary .accordion-item {
  border: 1px solid #FFA201;
}

.accordion.accordion-border-secondary .accordion-button {
  background-color: #FFF;
  color: #FFA201;
  border-block-end: 0px;
}

.accordion.accordion-border-secondary .accordion-button:after {
  background-color: #FFF;
  color: #FFA201;
  border: 1px solid #FFA201;
}

.accordion.accordion-border-secondary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #FFA201;
}

.accordion.accordion-border-success .accordion-item {
  border: 1px solid #5CB85C;
}

.accordion.accordion-border-success .accordion-button {
  background-color: #FFF;
  color: #5CB85C;
  border-block-end: 0px;
}

.accordion.accordion-border-success .accordion-button:after {
  background-color: #FFF;
  color: #5CB85C;
  border: 1px solid #5CB85C;
}

.accordion.accordion-border-success .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #5CB85C;
}

.accordion.accordion-border-danger .accordion-item {
  border: 1px solid #FC0027;
}

.accordion.accordion-border-danger .accordion-button {
  background-color: #FFF;
  color: #FC0027;
  border-block-end: 0px;
}

.accordion.accordion-border-danger .accordion-button:after {
  background-color: #FFF;
  color: #FC0027;
  border: 1px solid #FC0027;
}

.accordion.accordion-border-danger .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #FC0027;
}

.accordion.accordion-border-warning .accordion-item {
  border: 1px solid #FDA700;
}

.accordion.accordion-border-warning .accordion-button {
  background-color: #FFF;
  color: #FDA700;
  border-block-end: 0px;
}

.accordion.accordion-border-warning .accordion-button:after {
  background-color: #FFF;
  color: #FDA700;
  border: 1px solid #FDA700;
}

.accordion.accordion-border-warning .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #FDA700;
}

.accordion.accordion-border-info .accordion-item {
  border: 1px solid #1ECBE2;
}

.accordion.accordion-border-info .accordion-button {
  background-color: #FFF;
  color: #1ECBE2;
  border-block-end: 0px;
}

.accordion.accordion-border-info .accordion-button:after {
  background-color: #FFF;
  color: #1ECBE2;
  border: 1px solid #1ECBE2;
}

.accordion.accordion-border-info .accordion-button:not(.collapsed) {
  border-block-end: 1px solid #1ECBE2;
}

.accordion.accordionicon-none .accordion-button:after {
  display: none;
}

.accordion.accordionicon-left .accordion-button {
  padding-inline-start: 2.5rem;
}

.accordion.accordionicon-left .accordion-button:after {
  position: absolute;
  inset-inline-start: 0.625rem;
}

.accordion.accordion-customicon1 .accordion-button:after {
  content: "+";
  font-family: "Fontawesome";
  background-color: #f7f8f9;
}

.accordion.accordion-customicon1 .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: "Fontawesome";
  background-color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button {
  background-color: rgba(228, 31, 7, 0.1);
  border-inline-start: 0.25rem solid rgba(228, 31, 7, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button:after {
  background-color: rgba(228, 31, 7, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
  background-color: rgba(228, 31, 7, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(228, 31, 7, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button {
  background-color: rgba(255, 162, 1, 0.1);
  border-inline-start: 0.25rem solid rgba(255, 162, 1, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button:after {
  background-color: rgba(255, 162, 1, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-body {
  background-color: rgba(255, 162, 1, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(255, 162, 1, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button {
  background-color: rgba(253, 167, 0, 0.1);
  border-inline-start: 0.25rem solid rgba(253, 167, 0, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button:after {
  background-color: rgba(253, 167, 0, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-body {
  background-color: rgba(253, 167, 0, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(253, 167, 0, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button {
  background-color: rgba(30, 203, 226, 0.1);
  border-inline-start: 0.25rem solid rgba(30, 203, 226, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
  background-color: rgba(30, 203, 226, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-body {
  background-color: rgba(30, 203, 226, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(30, 203, 226, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button {
  background-color: rgba(92, 184, 92, 0.1);
  border-inline-start: 0.25rem solid rgba(92, 184, 92, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button:after {
  background-color: rgba(92, 184, 92, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-body {
  background-color: rgba(92, 184, 92, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(92, 184, 92, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button {
  background-color: rgba(252, 0, 39, 0.1);
  border-inline-start: 0.25rem solid rgba(252, 0, 39, 0.6);
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button:after {
  background-color: rgba(252, 0, 39, 0.6);
  color: #FFF;
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
  background-color: rgba(252, 0, 39, 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(252, 0, 39, 0.6);
}

.page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea65");
  font-family: "tabler-icons" !important;
}

[dir=rtl] .page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea64");
  font-family: "tabler-icons" !important;
}

.breadcrumb .breadcrumb-item a {
  color: #9595b5;
}

/* .breadcrumb .breadcrumb-item.active {
  color: #E41F07;
} */

.breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  opacity: 0.4;
}

.breadcrumb-arrow .breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  content: "\f101";
  font-family: "Fontawesome" !important;
}

.breadcrumb-pipe .breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  content: "\f061";
  font-family: "Fontawesome" !important;
}

.breadcrumb-line .breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  content: "-";
}

.breadcrumb-dot .breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  content: "•";
}

.breadcrumb-divide .breadcrumb-item+.breadcrumb-item::before {
  color: #9595b5;
  content: "\f054";
  font-family: "Fontawesome" !important;
}

.breadcrumb-separatorless .breadcrumb-item+.breadcrumb-item::before {
  content: "";
  display: none;
}

.breadcrumb-colored {
  padding: 12px 15px;
  border-radius: 4px;
}

.breadcrumb-colored li a {
  color: #FFF;
}

.breadcrumb-colored .breadcrumb-item a {
  color: #FFF;
}

.breadcrumb-colored .breadcrumb-item.active {
  color: #FFF;
  opacity: 0.8;
}

.breadcrumb-colored .breadcrumb-item+.breadcrumb-item a {
  color: #FFF;
}

.breadcrumb-colored .breadcrumb-item+.breadcrumb-item::before {
  color: #FFF;
}

.embedded-breadcrumb:before {
  opacity: 0.7;
}

[data-theme-mode=dark] .embedded-breadcrumb:before {
  filter: invert(1);
}

.card {
  margin-bottom: 24px;
  background-color: #FFF;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
}

.card .card-body {
  padding: 24px;
}

.card .card-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}

.card .card-header {
  border-color: #E8E8E8;
  position: relative;
  background: transparent;
  padding: 20px 24px;
}

.card .card-header .card-title {
  margin-bottom: 0rem;
}

.card .card-header .subtitle {
  padding-top: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.card .card-footer {
  border-color: #E8E8E8;
  background: transparent;
  padding: 1rem 1.25rem 1rem;
}

.card-group .card {
  margin-bottom: 1.5rem;
}

.card.custom-card {
  border-radius: 4px;
  border: 0;
  background-color: #FFF;
  position: relative;
  margin-block-end: 1.5rem;
  width: 100%;
}

.card.custom-card .card-header {
  padding: 1rem 1.25rem;
  background-color: transparent !important;
  border-block-end: 1px solid #6F6F6F;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.card.custom-card .card-header:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}

.card.custom-card .card-header .card-title {
  position: relative;
  margin-block-end: 0;
  font-size: 0.9375rem;
  font-weight: 700;
  text-transform: capitalize;
}

.card.custom-card .card-header .card-title .subtitle {
  margin-block-end: 0;
  text-transform: initial;
}

.card.custom-card .card-footer {
  background-color: transparent !important;
  border-block-start: 1px solid #6F6F6F;
  padding: 1rem 1.5rem;
  font-size: 14px;
}

.card.custom-card .card-body {
  padding: 1.5rem;
  color: #6F6F6F;
}

.card.custom-card .card-link {
  font-size: 0.75rem;
}

.card .card-text {
  font-size: 0.813rem;
}

.card .footer-card-icon {
  width: 60px;
  height: 60px;
}

.card.overlay-card {
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.9);
}

.card.overlay-card .card-header {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}

.card.overlay-card .card-footer {
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}

.card.overlay-card .over-content-bottom {
  top: auto;
}

.card.overlay-card:before {
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.card.card-bg-primary {
  background-color: #E41F07;
  color: #FFF;
}

.card.card-bg-primary .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-primary .card-header:before {
  background-color: #FFF;
}

.card.card-bg-primary .card-body {
  color: #FFF;
}

.card.card-bg-primary .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-secondary {
  background-color: #FFA201;
  color: #FFF;
}

.card.card-bg-secondary .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-secondary .card-header:before {
  background-color: #FFF;
}

.card.card-bg-secondary .card-body {
  color: #FFF;
}

.card.card-bg-secondary .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-warning {
  background-color: #FDA700;
  color: #FFF;
}

.card.card-bg-warning .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-warning .card-header:before {
  background-color: #FFF;
}

.card.card-bg-warning .card-body {
  color: #FFF;
}

.card.card-bg-warning .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-info {
  background-color: #1ECBE2;
  color: #FFF;
}

.card.card-bg-info .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-info .card-header:before {
  background-color: #FFF;
}

.card.card-bg-info .card-body {
  color: #FFF;
}

.card.card-bg-info .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-success {
  background-color: #5CB85C;
  color: #FFF;
}

.card.card-bg-success .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-success .card-header:before {
  background-color: #FFF;
}

.card.card-bg-success .card-body {
  color: #FFF;
}

.card.card-bg-success .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-danger {
  background-color: #FC0027;
  color: #FFF;
}

.card.card-bg-danger .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-danger .card-header:before {
  background-color: #FFF;
}

.card.card-bg-danger .card-body {
  color: #FFF;
}

.card.card-bg-danger .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-light {
  background-color: #FDFDFE;
  color: #6F6F6F;
}

.card.card-bg-light .card-header {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #6F6F6F;
  border-block-end: #6F6F6F;
}

.card.card-bg-light .card-body {
  color: #6F6F6F;
}

.card.card-bg-light .card-footer {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #6F6F6F;
  border-block-start: #6F6F6F;
}

.card.card-bg-dark {
  background-color: #171724;
  color: #FFF;
}

.card.card-bg-dark .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.card-bg-dark .card-header:before {
  background-color: #FFF;
}

.card.card-bg-dark .card-body {
  color: #FFF;
}

.card.card-bg-dark .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #FFF;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card .card-anchor {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
}

.card .collapse-close {
  display: none;
}

.card.card .collapsed .collapse-close {
  display: block;
}

.card.card .collapsed .collapse-open {
  display: none;
}

.card.card-fullscreen {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-start: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}

.cals-icon {
  font-size: 50px;
  color: #6F7A7A;
}

.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: #FDFDFE;
  border-inline-start: 0.25rem solid #6F6F6F;
}

.callout-info {
  background-color: rgba(30, 203, 226, 0.075);
  border-color: rgba(30, 203, 226, 0.5);
}

.callout-warning {
  background-color: rgba(253, 167, 0, 0.075);
  border-color: rgba(253, 167, 0, 0.5);
}

.callout-danger {
  background-color: rgba(252, 0, 39, 0.075);
  border-color: rgba(252, 0, 39, 0.5);
}

.btn-facebook {
  background-color: #3B5998;
  color: #FFF;
  border: 1px solid #3B5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
  background-color: #3B5998 !important;
  color: #FFF !important;
  border: 1px solid #3B5998 !important;
}

.btn-instagram {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
  color: #FFF;
  border: 1px solid transparent;
}

.btn-instagram:hover,
.btn-instagram:focus,
.btn-instagram:active {
  color: #FFF;
  border: 1px solid transparent;
}

.btn-twitter {
  background-color: #00ACEE;
  color: #FFF;
  border: 1px solid #00ACEE;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
  background-color: #00ACEE !important;
  color: #FFF !important;
  border: 1px solid #00ACEE !important;
}

.btn-icon {
  width: 2.313rem;
  height: 2.313rem;
  font-size: 0.95rem;
  flex-shrink: 0;
}

.btn-wave {
  display: inline-block !important;
}

@media (max-width: 575px) {
  .overlay-card .card-text {
    font-size: 0.613rem !important;
  }
}

@media (max-width: 400px) {
  .overlay-card .card-text {
    font-size: 0.413rem !important;
  }
}

.tooltip.tooltip-primary .tooltip-inner {
  background-color: #E41F07;
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #E41F07;
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #E41F07;
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #E41F07;
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #E41F07;
}

.tooltip.tooltip-secondary .tooltip-inner {
  background-color: #FFA201;
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #FFA201;
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #FFA201;
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #FFA201;
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #FFA201;
}

.tooltip.tooltip-warning .tooltip-inner {
  background-color: #FDA700;
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #FDA700;
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #FDA700;
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #FDA700;
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #FDA700;
}

.tooltip.tooltip-info .tooltip-inner {
  background-color: #1ECBE2;
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #1ECBE2;
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #1ECBE2;
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #1ECBE2;
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #1ECBE2;
}

.tooltip.tooltip-success .tooltip-inner {
  background-color: #5CB85C;
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #5CB85C;
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #5CB85C;
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #5CB85C;
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #5CB85C;
}

.tooltip.tooltip-danger .tooltip-inner {
  background-color: #FC0027;
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #FC0027;
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #FC0027;
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #FC0027;
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #FC0027;
}

.tooltip.tooltip-dark .tooltip-inner {
  background-color: #171724;
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #171724;
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #171724;
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #171724;
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #171724;
}

.tooltip.tooltip-dark .tooltip-inner {
  color: #FFF;
}

.tooltip.tooltip-light .tooltip-inner {
  background-color: #FDFDFE;
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #FDFDFE;
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #FDFDFE;
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #FDFDFE;
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: #FDFDFE;
}

.tooltip.tooltip-light .tooltip-inner {
  color: #6F6F6F;
}

.nav-item {
  margin-inline-end: 0.5rem;
}

.nav-item:last-child {
  margin-inline-end: 0;
}

.nav-link {
  color: #6F6F6F;
  border-radius: 4px;
  font-weight: 600;
}

.nav-link.active {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.navbar-brand {
  color: #6F6F6F;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: #6F6F6F;
  opacity: 0.9;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: #E41F07;
}

.nav-link.disabled {
  color: #6F6F6F;
  opacity: 0.3;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: rgba(23, 23, 36, 0.5);
}

.navbar-text {
  color: #6F6F6F;
}

.navbar-dark.bg-dark .nav-link,
.navbar-dark.bg-dark .navbar-brand {
  color: #FFF;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #FFF;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #FFF;
  background-color: #E41F07;
}

.nav.nav-style-1 {
  border: 0;
}

.nav.nav-style-1 .nav-item {
  margin-inline-end: 0.25rem;
}

.nav.nav-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}

.nav.nav-style-1 .nav-link.active {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.nav.nav-style-1 .nav-link.active:hover {
  background: rgba(228, 31, 7, 0.1);
}

.nav.nav-style-1 .nav-link:hover {
  background: transparent;
  color: #E41F07;
}

.nav.nav-style-2 .nav-item .nav-link {
  border-block-start: 3px solid transparent;
}

.nav.nav-style-2 .nav-item .nav-link.active {
  background-color: rgba(228, 31, 7, 0.1);
  border-block-start: 3px solid #E41F07;
  color: #E41F07;
}

.nav.nav-style-3 .nav-link {
  border-radius: 0;
}

.nav.nav-style-3 .nav-link {
  border-block-end: 3px solid transparent;
}

.nav.nav-style-3 .nav-link.active {
  background-color: transparent;
  border-block-end: 3px solid #E41F07;
  color: #E41F07;
}

.nav.nav-style-4 {
  border: 0;
}

.nav.nav-style-4 .nav-link {
  color: #9595b5;
  border: 0;
}

.nav.nav-style-4 .nav-link {
  margin-bottom: 3px;
}

.nav.nav-style-4 .nav-link.active {
  background-color: #FDFDFE;
  color: #6F6F6F;
  border: 0;
}

.nav.nav-style-4 .nav-link:hover,
.nav.nav-style-4 .nav-link:focus {
  border: 0;
}

.nav.nav-style-4 .nav-link:hover {
  background-color: #FDFDFE;
  color: #6F6F6F;
}

.nav-tabs.nav-style-5 {
  border: 0;
}

.nav-tabs.nav-style-5 .nav-link {
  color: #9595b5;
  border: 0;
  margin-bottom: 3px;
}

.nav-tabs.nav-style-5 .nav-link.active {
  background-color: #E41F07;
  color: #FFF;
  border: 0;
}

.nav-tabs.nav-style-5 .nav-link:hover,
.nav-tabs.nav-style-5 .nav-link:focus {
  border: 0;
  background-color: #E41F07;
  color: #FFF;
}

.nav-style-6 {
  border: 0;
}

.nav-style-6.nav-pills .nav-link.active {
  border: 1px solid #E41F07;
  border-block-end: 2px solid #E41F07;
  background-color: transparent;
  color: #E41F07;
}

.nav-tabs-header {
  border: 0 !important;
}

.nav-tabs-header .nav-item {
  margin-inline-end: 0.5rem;
}

.nav-tabs-header .nav-item:last-child {
  margin-inline-end: 0;
}

.nav-tabs-header .nav-item .nav-link {
  border: 1px solid transparent;
  font-size: 0.8rem;
  padding: 0.4rem 0.5rem;
  font-weight: 500;
}

.nav-tabs-header .nav-item .nav-link.active {
  background: rgba(228, 31, 7, 0.1);
  color: #E41F07;
  border: 1px solid transparent;
}

.nav-tabs-header .nav-item .nav-link:hover,
.nav-tabs-header .nav-item .nav-link:focus {
  border: 1px solid transparent;
  background-color: transparent;
  color: #E41F07;
}

.nav-tabs-footer {
  border: 0;
}

.nav-tabs-footer .nav-item {
  margin-inline-end: 0.5rem;
}

.nav-tabs-footer .nav-item:last-child {
  margin-inline-end: 0;
}

.nav-tabs-footer .nav-item .nav-link {
  background-color: transparent;
  color: #E41F07;
  border: 1px solid #E41F07;
  font-size: 0.8rem;
}

.nav-tabs-footer .nav-item .nav-link.active {
  background: #E41F07;
  color: #FFF;
  border: 1px solid #E41F07;
}

.nav-tabs-footer .nav-item .nav-link:hover,
.nav-tabs-footer .nav-item .nav-link:focus {
  border: 1px solid #E41F07;
  background-color: transparent;
  color: #E41F07;
}

.vertical-tabs-2 {
  border: 0;
}

.vertical-tabs-2 .nav-item {
  margin-inline-end: 0.5rem;
}

.vertical-tabs-2 .nav-item:last-child {
  margin-inline-end: 0;
}

.vertical-tabs-2 .nav-item .nav-link {
  min-width: 7.5rem;
  max-width: 7.5rem;
  text-align: center;
  border: 1px solid #6F6F6F;
  margin-bottom: 0.5rem;
  color: #9595b5;
  background-color: #FDFDFE;
}

.vertical-tabs-2 .nav-item .nav-link i {
  font-size: 1rem;
}

.vertical-tabs-2 .nav-item .nav-link.active {
  background-color: #E41F07;
  color: #FFF;
  position: relative;
}

.vertical-tabs-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-end: -0.5rem;
  inset-block-start: 38%;
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
  background-color: #E41F07;
}

.vertical-tabs-2 .nav-item .nav-link.active:hover {
  color: #FFF;
}

.vertical-tabs-2 .nav-item .nav-link:hover {
  color: #E41F07;
}

.vertical-tabs-3 {
  border: 0;
}

.vertical-tabs-3 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}

.vertical-tabs-3 .nav-item:last-child {
  margin-inline-end: 0;
}

.vertical-tabs-3 .nav-item .nav-link {
  min-width: 160px;
  max-width: 160px;
  border: 1px solid #E41F07;
  padding: 1.3rem;
  margin-block-end: 0.5rem;
}

.vertical-tabs-3 .nav-item .nav-link i {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  background: #E41F07;
  color: #FFF;
  border-radius: 3.125rem;
  font-weight: 500;
}

.vertical-tabs-3 .nav-item .nav-link.active {
  background: #E41F07;
  border: 1px solid #E41F07;
  color: #FFF;
}

.vertical-tabs-3 .nav-item .nav-link.active:hover {
  background: #E41F07;
  border: 1px solid #E41F07;
  color: #FFF;
}

.vertical-tabs-3 .nav-item .nav-link.active i {
  background: #FFF;
  color: #E41F07;
}

.vertical-tabs-3 .nav-item .nav-link:hover,
.vertical-tabs-3 .nav-item .nav-link:focus {
  border: 1px solid #E41F07;
  color: #E41F07;
  background: transparent;
}

.nav.tab-style-1 {
  border-block-end: 0;
  padding: 0.65rem;
  background-color: #FDFDFE;
  border-radius: 7px;
  margin-block-end: 1rem;
}

.nav.tab-style-1 .nav-item {
  margin-inline-end: 0.5rem;
}

.nav.tab-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}

.nav.tab-style-1 .nav-link {
  border-radius: 7px;
  padding: 0.35rem 1rem;
}

.nav.tab-style-1 .nav-link.active {
  background-color: #E41F07 !important;
  color: #FFF !important;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  transition: color 0.15s ease-in;
}

.nav-tabs.tab-style-1 .nav-link:hover {
  background: transparent;
  color: #E41F07;
}

.tab-style-2 {
  border-block-end: 0;
}

.tab-style-2 .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-2 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-2 .nav-item .nav-link {
  border: 0;
}

.tab-style-2 .nav-item .nav-link i {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.4rem;
  border-radius: 50px;
  background-color: #FDFDFE;
  color: #6F6F6F;
  display: inline-block;
}

.tab-style-2 .nav-item .nav-link:hover {
  border: 0;
  background: transparent;
  color: #E41F07;
}

.tab-style-2 .nav-item .nav-link:hover i {
  color: #E41F07;
}

.tab-style-2 .nav-item .nav-link.active {
  background-color: transparent;
  position: relative;
  border: 0;
  color: #E41F07;
}

.tab-style-2 .nav-item .nav-link.active i {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.tab-style-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  width: 100%;
  height: 0.175rem;
  background-color: #E41F07;
  border-radius: 50px;
}

.tab-style-3 {
  border-block-end: 0;
}

.tab-style-3 .nav-item {
  position: relative;
  margin-inline-end: 0.5rem;
}

.tab-style-3 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-3 .nav-item .nav-link {
  position: relative;
  border: 0;
  font-size: 0.75rem;
}

.tab-style-3 .nav-item .nav-link:before {
  position: absolute;
  content: "";
  inset-block-end: -1.063rem;
  inset-inline-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #FDFDFE;
  border-radius: 50%;
}

.tab-style-3 .nav-item .nav-link:after {
  position: absolute;
  content: "";
  inset-block-end: -2rem;
  inset-inline-start: 1.75rem;
  inset-inline-end: 0;
  inset-inline-start: 30%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 50%;
  border: 2px solid #6F6F6F;
}

.tab-style-3 .nav-item .nav-link.home:after {
  content: "1";
}

.tab-style-3 .nav-item .nav-link.about:after {
  content: "2";
}

.tab-style-3 .nav-item .nav-link.services:after {
  content: "3";
}

.tab-style-3 .nav-item .nav-link:hover {
  border: 0;
  background: transparent;
  color: #E41F07;
}

.tab-style-3 .nav-item .nav-link.active {
  background-color: transparent;
  border: 0;
  color: #E41F07;
}

.tab-style-3 .nav-item .nav-link.active:before {
  background-color: #E41F07;
}

.tab-style-3 .nav-item .nav-link.active:after {
  border-color: #E41F07;
  color: #E41F07;
}

.tab-style-4.nav-tabs {
  border: 0;
}

.tab-style-4.nav-tabs .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-4.nav-tabs .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-4.nav-tabs .nav-link {
  border: 0;
}

.tab-style-4.nav-tabs .nav-link:hover {
  border: 0;
  background: transparent;
  color: #E41F07;
}

.tab-style-4.nav-tabs .nav-link.active {
  background-color: transparent;
  border: 0;
  color: #E41F07;
  position: relative;
  overflow: hidden;
}

.tab-style-4.nav-tabs .nav-link.active::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  inset-block-start: 31px;
  transform: rotate(45deg);
  inset-inline-start: 47%;
  overflow: hidden;
  z-index: 1;
  height: 0.75rem;
  background-color: #E41F07;
  border: 1px solid #E41F07;
}

.tab-style-4.nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  width: 40%;
  height: 1px;
  inset-inline-start: 30%;
  inset-block-end: 0;
  background-color: #E41F07;
}

#tab-style-4 .tab-pane.active {
  border: 1px solid #E41F07;
  background-color: #E41F07;
  color: #FFF;
  padding: 16px;
  border-radius: 7px;
}

.tab-style-5 .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-5 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-5 .nav-item .nav-link.active {
  background: #E41F07;
}

.tab-style-6 {
  border: 0;
  background-color: #FFF;
  border-radius: 7px;
  color: #E41F07;
  padding: 0.5rem;
}

.tab-style-6 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}

.tab-style-6 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-6 .nav-item .nav-link {
  color: #E41F07;
  padding: 0.5rem 1rem;
  font-size: 0.813rem;
  border: 0;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.tab-style-6 .nav-item .nav-link.active {
  border: 0;
  background-color: #E41F07;
  color: #FFF;
  border: 0;
  box-shadow: 0px 3px 10px 0px rgba(var(--dark-rgb), 0.05);
}

.tab-style-6 .nav-item .nav-link:hover,
.tab-style-6 .nav-item .nav-link:focus {
  border: 0;
}

.tab-style-7 .nav-link {
  margin-block-end: 0.5rem;
  font-size: 0.813rem;
  color: #9595b5;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.tab-style-7 .nav-link:active,
.tab-style-7 .nav-link.active {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

.nav-style-1 .nav-item .nav-link.active {
  background-color: rgba(228, 31, 7, 0.1);
  color: #E41F07;
}

@media screen and (max-width: 400px) {
  .tab-style-4 .nav-link {
    padding-inline: 0.5rem;
  }
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs.nav-tabs-bottom li {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link.active {
  border-color: #898989;
}

.nav-tabs li a {
  color: #565E5E;
  border-bottom-width: 2px;
}

.nav-tabs li a.active {
  border-color: transparent;
  border-bottom-color: #E41F07;
  background-color: transparent;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.nav-tabs .nav-tabs-solid {
  background-color: #FFF;
  border: 0;
}

.nav-tabs .nav-tabs-solid .active {
  background-color: #E41F07;
  border-color: #E41F07;
  color: #FFF;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #E41F07;
}

.nav-tabs.nav-tabs-top li a {
  border-width: 2px 0 0 0;
}

.nav-tabs.nav-tabs-top li a.active {
  border-top-color: #E41F07;
  border-radius: 0;
}

.nav-tabs.nav-tabs-top li a:hover {
  border-top-color: #E41F07;
  background-color: transparent;
  border-radius: 0;
}

.nav-tabs.nav-tabs-bottom li a {
  border-width: 0 0 2px 0;
}

.nav-tabs.nav-tabs-bottom li a.active,
.nav-tabs.nav-tabs-bottom li a:hover {
  border-bottom-color: #E41F07;
  background: transparent;
  border-radius: 0;
}

.nav-tabs.nav-tabs-solid {
  background-color: #FFF;
  border: 0;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded li a.active {
  background-color: #E41F07;
  border-color: #E41F07;
  color: #FFF;
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid li a.active {
  background-color: #E41F07;
  border-color: #E41F07;
  color: #FFF;
  border-radius: 0;
}

.nav-link:hover {
  color: #E41F07;
}

.page-menu .nav-tabs li a {
  font-size: 16px;
}

.tabs-set .nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px;
}

.tabs-set .nav-tabs .nav-link {
  color: #FFA201;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  min-width: 120px;
  margin-right: 10px;
  border-radius: 8px;
  background: var(--Stroke, rgba(145, 158, 171, 0.3));
}

.tabs-set .nav-tabs .nav-link:focus {
  border-color: transparent;
}

.tabs-set .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.tabs-set .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #E41F07;
  background: #E41F07;
  color: #FFF;
}

.tabs-sets .nav-tabs {
  border-bottom: 0;
  margin: 0 0 40px;
}

.tabs-sets .nav-tabs .nav-link {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border: 0;
  background: #898989;
  margin-right: 10px;
  padding: 8px 15px;
  border-radius: 8px;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tabs-sets .nav-tabs .nav-link:focus,
.tabs-sets .nav-tabs .nav-link:hover,
.tabs-sets .nav-tabs .nav-link.active {
  background: #E41F07;
  color: #FFF;
}

@media (max-width: 768px) {
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #FFF;
    border: 1px solid #eee;
    padding: 5px 0;
    border-radius: 3px;
  }
}

.ribbon1 {
  position: absolute;
  inset-block-start: -6.1px;
  inset-inline-end: 10px;
  color: #FFF;
  z-index: 1;
}

.ribbon1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 20px solid transparent;
  border-inline-end: 24px solid transparent;
  border-block-start: 13px solid #FC0027;
}

.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #FC0027;
  font-size: 14px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-start-end-radius: 8px;
}

.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: "";
}

.ribbon1 span:before {
  height: 6px;
  width: 6px;
  inset-inline-start: -6px;
  inset-block-start: 0;
  background: #FC0027;
}

.ribbon1 span:after {
  height: 6px;
  width: 8px;
  inset-inline-start: -8px;
  inset-block-start: 0;
  border-radius: 8px 8px 0 0;
  background: #FC0027;
}

.ribbone {
  width: 100%;
  position: relative;
  background-size: cover;
  text-transform: uppercase;
  color: #FFF;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 6;
}

.ribbone::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid;
}

.ribbone::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid;
}

.ribbone span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 3px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #FFF;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
}

.ribbone1 {
  position: absolute;
  inset-block-start: -6.1px;
  inset-inline-start: 10px;
  z-index: 1;
}

.ribbone1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 20px solid transparent;
  border-inline-end: 24px solid transparent;
  border-block-start: 13px solid #FC0027;
}

.ribbone1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #FC0027;
  font-size: 14px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-start-end-radius: 8px;
}

.ribbone1 span:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 8px;
  inset-inline-start: -8px;
  inset-block-start: 0;
  border-radius: 8px 8px 0 0;
  background: #FC0027;
}

.ribbone1 span:before {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  inset-inline-start: -6px;
  inset-block-start: 0;
  background: #FC0027;
}

.arrow-ribbone-left {
  color: #FFF;
  padding: 2px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 0;
  z-index: 6;
  font-size: 13px;
}

.arrow-ribbone-left.bg-purple:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #4A00E5;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-danger:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 112px solid transparent;
  border-inline-start: 12px solid #FC0027;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-primary:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-secondary:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #FFA201;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-success:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #E41F07;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-info:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #1ECBE2;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-warning:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #FDA700;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-pink:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid #EA00B7;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-teal:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #02a8b5;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right {
  color: #FFF;
  padding: 2px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 0;
  z-index: 6;
  font-size: 13px;
}

.arrow-ribbone-right.bg-purple:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #4A00E5;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-danger:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #FC0027;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-primary:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-secondary:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #FFA201;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-success:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #E41F07;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-info:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #1ECBE2;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-warning:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #FDA700;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-pink:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #EA00B7;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-teal:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid #02a8b5;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone2 {
  color: #FFF;
  padding: 3px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: -1px;
  z-index: 6;
  font-size: 30px;
}

.arrow-ribbone2:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -24px;
  border-block-start: 24px solid transparent;
  border-block-end: 24px solid transparent;
  width: 0;
}

.power-ribbone {
  width: 56px;
  height: 56px;
  overflow: hidden;
  position: absolute;
  z-index: 6;
}

.power-ribbone span {
  position: absolute;
  display: block;
  width: 82px;
  padding: 8px 0;
  color: #FFF;
  font: 500 16px/1 Lato, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  text-align: center;
}

.power-ribbone-top-left {
  inset-block-start: -6px;
  inset-inline-start: -9px;
}

.power-ribbone-top-left span {
  inset-inline-end: -5px;
  inset-block-start: 1px;
  transform: rotate(-45deg);
}

.power-ribbone-top-left span i {
  transform: rotate(45deg);
  padding-block-start: 2px;
  padding-inline-start: 7px;
}

.power-ribbone-bottom-left {
  inset-block-end: -6px;
  inset-inline-start: -9px;
}

.power-ribbone-bottom-left span {
  inset-inline-end: -5px;
  inset-block-end: 1px;
  transform: rotate(45deg);
}

.power-ribbone-bottom-left span i {
  transform: rotate(-45deg);
  padding-block-end: 2px;
  padding-inline-start: 7px;
}

.power-ribbone-top-right {
  inset-block-start: -6px;
  inset-inline-end: -9px;
}

.power-ribbone-top-right span {
  inset-inline-start: -5px;
  inset-block-start: 1px;
  transform: rotate(45deg);
}

.power-ribbone-top-right span i {
  transform: rotate(-45deg);
  padding-block-start: 2px;
  padding-inline-end: 7px;
}

.power-ribbone-bottom-right {
  inset-block-end: -6px;
  inset-inline-end: -9px;
}

.power-ribbone-bottom-right span {
  inset-inline-start: -5px;
  inset-block-end: 1px;
  transform: rotate(-45deg);
}

.power-ribbone-bottom-right span i {
  transform: rotate(45deg);
  padding-block-end: 2px;
  padding-inline-end: 7px;
}

.ribbone-top-left {
  inset-block-start: -10px;
  inset-inline-start: -10px;
}

.ribbone-top-left::after {
  border-block-start-color: transparent;
  border-inline-start-color: transparent;
  inset-block-end: 50px;
  inset-inline-start: 0;
}

.ribbone-top-left::before {
  border-block-start-color: transparent;
  border-inline-start-color: transparent;
  inset-block-start: 0;
  inset-inline-end: 50px;
}

.ribbone-top-left span {
  inset-inline-end: 0px;
  inset-block-start: 24px;
  transform: rotate(-45deg);
}

.ribbone-top-right {
  inset-block-start: -10px;
  inset-inline-end: -10px;
}

.ribbone-top-right::after {
  border-block-start-color: transparent;
  border-inline-end-color: transparent;
  inset-block-end: 50px;
  inset-inline-end: 0;
}

.ribbone-top-right::before {
  border-block-start-color: transparent;
  border-inline-end-color: transparent;
  inset-block-start: 0;
  inset-inline-start: 50px;
}

.ribbone-top-right span {
  inset-inline-start: 0px;
  inset-block-start: 24px;
  transform: rotate(45deg);
}

.ribbone-warning-right {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-end: 10px;
  color: #FFF;
}

.ribbone-warning-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid #FDA700;
  z-index: 6;
}

.ribbone-warning-right span {
  position: relative;
  display: block;
  text-align: center;
  background: #FDA700;
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-end-radius: 10px;
  z-index: 6;
}

.ribbone-warning-right span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: #FDA700;
  height: 6px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 0;
  border-start-start-radius: 50px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-warning-right span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 3px;
  background: #FDA700;
}

.ribbone-warning-right i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-danger-right {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-end: 10px;
  color: #FFF;
}

.ribbone-danger-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid #FC0027;
  z-index: 6;
}

.ribbone-danger-right span {
  position: relative;
  display: block;
  text-align: center;
  background: #FC0027;
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-end-radius: 10px;
  z-index: 6;
}

.ribbone-danger-right span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: #FC0027;
  height: 6px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 0;
  border-start-start-radius: 50px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-danger-right span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 3px;
  background: #FC0027;
}

.ribbone-danger-right i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-success-left {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-start: 10px;
  color: #FFF;
}

.ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid #5CB85C;
  z-index: 6;
}

.ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: #5CB85C;
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-start-radius: 10px;
  z-index: 6;
}

.ribbone-success-left span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: #5CB85C;
  height: 6px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 50px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-success-left span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 3px;
  background: #5CB85C;
}

.ribbone-success-left i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-info-left {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-start: 10px;
  color: #FFF;
}

.ribbone-info-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid #1ECBE2;
  z-index: 6;
}

.ribbone-info-left span {
  position: relative;
  display: block;
  text-align: center;
  background: #1ECBE2;
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-start-radius: 10px;
  z-index: 6;
}

.ribbone-info-left span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: #1ECBE2;
  height: 6px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 50px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-info-left span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 3px;
  background: #1ECBE2;
}

.ribbone-info-left i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-success-left {
  position: absolute;
  inset-inline-start: 10px;
  color: #FFF;
}

.bookmark-ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-start: 0;
  border-inline-start: 18px solid #5CB85C;
  border-inline-end: 18px solid #5CB85C;
  border-block-end: 10px solid transparent;
}

.bookmark-ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: #5CB85C;
  font-size: 13px;
  line-height: 1;
  padding: 12px 3px 20px;
  z-index: 6;
}

.bookmark-ribbone-success-left i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-danger-left {
  position: absolute;
  inset-inline-start: 10px;
  color: #FFF;
  width: 30px;
}

.bookmark-ribbone-danger-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-start: 0;
  border-inline-start: 15px solid #FC0027;
  border-inline-end: 15px solid #FC0027;
  border-block-end: 8px solid transparent;
}

.bookmark-ribbone-danger-left span {
  position: relative;
  display: block;
  text-align: center;
  background: #FC0027;
  font-size: 12px;
  line-height: 1;
  padding: 12px 0px;
  z-index: 6;
}

.bookmark-ribbone-danger-left i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-secondary-right {
  position: absolute;
  inset-inline-end: 10px;
  color: #FFF;
  width: 30px;
}

.bookmark-ribbone-secondary-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-end: 0;
  border-inline-start: 15px solid #FFA201;
  border-inline-end: 15px solid #FFA201;
  border-block-end: 8px solid transparent;
}

.bookmark-ribbone-secondary-right span {
  position: relative;
  display: block;
  text-align: center;
  background: #FFA201;
  font-size: 11px;
  line-height: 1;
  padding: 12px 0px;
  z-index: 6;
}

.bookmark-ribbone-secondary-right i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-sideleft-ribbone-success-left {
  position: absolute;
  inset-block-start: 10px;
  color: #FFF;
}

.bookmark-sideleft-ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-block-start: 0;
  inset-inline-end: -8px;
  border-block-start: 13px solid #5CB85C;
  border-block-end: 13px solid #5CB85C;
  border-inline-end: 8px solid transparent;
}

.bookmark-sideleft-ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: #5CB85C;
  font-size: 11px;
  padding: 5px 13px;
  z-index: 6;
  height: 26px;
}

.bookmark-sideleft-ribbone-success-left i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-sideright-ribbone-primary-right {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 0;
  color: #FFF;
}

.bookmark-sideright-ribbone-primary-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-block-start: 0;
  inset-inline-start: -8px;
  border-block-start: 13px solid #E41F07;
  border-block-end: 13px solid #E41F07;
  border-inline-start: 8px solid transparent;
}

.bookmark-sideright-ribbone-primary-right span {
  position: relative;
  display: block;
  text-align: center;
  background: #E41F07;
  font-size: 11px;
  padding: 5px 13px;
  z-index: 6;
  height: 26px;
}

.bookmark-sideright-ribbone-primary-right i {
  color: #FFF;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-1 span:after {
  position: absolute;
  content: "";
}

.arrow-ribbone-2 {
  color: #FFF;
  padding: 3px 8px;
  position: absolute;
  z-index: 6;
  font-size: 20px;
}

.arrow-ribbone-2:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -24px;
  border-block-start: 16px solid transparent;
  border-inline-start: 24px solid #4A00E5;
  border-block-end: 16px solid transparent;
  width: 0;
}

.ribbone-card {
  overflow: hidden;
}

.width-100 {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mb-10 {
  margin-bottom: 80px;
}

.font-20 {
  font-size: 18px;
}

.fullwidth-primary-ribbons .bar {
  color: #FFF;
  background-color: #E41F07;
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px #FDFDFE;
}

.fullwidth-primary-ribbons .bar span {
  position: absolute;
}

.fullwidth-primary-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-primary-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-primary-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-color: #E41F07 #E41F07 #E41F07 transparent;
  border-block-start-color: #E41F07;
  border-inline-end-color: #E41F07;
  border-block-end-color: #E41F07;
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-primary-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: #E41F07;
  border-inline-end-color: transparent;
  border-block-end-color: #E41F07;
  border-inline-start-color: #E41F07;
  inset-inline-end: -30px;
}

.fullwidth-secondary-ribbons .bar {
  color: #FFF;
  background-color: #FFA201;
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px #FDFDFE;
}

.fullwidth-secondary-ribbons .bar span {
  position: absolute;
}

.fullwidth-secondary-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: #FFA201;
  border-inline-end-color: #FFA201;
  border-block-end-color: #FFA201;
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: #FFA201;
  border-inline-end-color: transparent;
  border-block-end-color: #FFA201;
  border-inline-start-color: #FFA201;
  inset-inline-end: -30px;
}

.fullwidth-arrow-warning-ribbons .bar {
  color: #FFF;
  background-color: #FDA700;
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px #FDFDFE;
}

.fullwidth-arrow-warning-ribbons .bar span {
  position: absolute;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: #FDA700;
  border-inline-end-color: #FDA700;
  border-block-end-color: #FDA700;
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start: 18px solid transparent;
  border-inline-start: 18px solid #FDA700;
  border-block-end: 18px solid transparent;
  border-inline-end: transparent;
  inset-inline-end: -20px;
  inset-block-start: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar {
  color: #FFF;
  background-color: #FC0027;
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px #FDFDFE;
}

.fullwidth-arrow-danger-ribbons-right .bar span {
  position: absolute;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: #FC0027;
  border-inline-end-color: transparent;
  border-block-end-color: #FC0027;
  border-inline-start-color: #FC0027;
  inset-inline-end: -30px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start: 18px solid transparent;
  border-inline-end: 18px solid #FC0027;
  border-block-end: 18px solid transparent;
  border-inline-start: transparent;
  inset-inline-start: -20px;
  inset-block-start: -2px;
}

.power-ribbone span svg {
  width: 15px;
  height: 15px;
}

.ribbone-card .card-body {
  padding: 25px;
}

.ribbone-row .feather-zap {
  width: 15px;
  height: 15px;
}

[data-bs-toggle=card-fullscreen] svg {
  width: 15px;
  height: 15px;
}

[data-bs-toggle=card-remove] svg {
  width: 15px;
  height: 15px;
}

svg.collapse-open,
svg.collapse-close {
  width: 15px;
  height: 15px;
}

.btn-icon.btn-danger-light {
  color: #FC1A3D;
  background: #FFEEEC;
}

.live-rating.badge {
  background: #E0F5D7;
  color: #6CBF6C;
}

@media (min-width: 576px) {
  .mx-sm-7 {
    margin-inline: 3rem !important;
  }
}

[dir=rtl] .power-ribbone-top-left span {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-top-left span i {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-bottom-left span {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-bottom-left span i {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-top-right span {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-top-right span i {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-bottom-right span {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-bottom-right span i {
  transform: rotate(-45deg);
}

[dir=rtl] .ribbone-top-left span {
  transform: rotate(45deg);
}

[dir=rtl] .ribbone-top-right span {
  transform: rotate(-45deg);
}

[dir=rtl] .ribbon-price .badge {
  transform: rotate(-45deg);
}

.modal-barcode ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.modal-barcode ul li {
  border-radius: 7px;
  width: 25%;
  margin-bottom: 10px;
  padding: 0 5px;
}

@media (max-width: 991.98px) {
  .modal-barcode ul li {
    width: 50%;
  }
}

.modal-barcode ul li a {
  border: 1px solid #6F6F6F;
  border-radius: 10px;
  display: block;
  padding: 10px;
  text-align: center;
}

.modal .modal-content {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
  border-radius: 5px;
  padding: 24px;
}

.modal .modal-title {
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .modal .modal-title {
    font-size: 16px;
  }
}

.modal .logo-lg img {
  width: 150px;
}

.modal .modal-header {
  border-color: #E8E8E8;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #E8E8E8;
}

.modal .modal-header .close,
.modal .modal-header .btn-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #FD4D68;
  opacity: 1;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border: 0;
  font-weight: 700;
  background-color: transparent;
  border-radius: 50px;
}

.modal .modal-header .close:hover,
.modal .modal-header .btn-close:hover {
  background-color: transparent;
  color: #FFF;
}

.modal .modal-body {
  padding: 0;
}

.modal .modal-footer {
  border: 0;
  justify-content: flex-start;
}

.modal .modal-footer .btn {
  min-width: 130px;
  padding: 8px;
}

@media (max-width: 991.98px) {
  .modal .modal-footer .btn {
    min-width: auto;
    padding: 7px 12px;
  }
}

.modal .modal-footer .btn-print {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E41F07;
  color: #E41F07;
}

.modal .modal-footer .btn-print img {
  margin-left: 10px;
}

.modal .modal-footer .btn-print:hover {
  background: #E41F07;
  color: #FFF;
}

.modal .modal-footer .btn-print:hover img {
  filter: brightness(0) invert(1);
}

.modal .modal-footer .btn-secondary {
  background: #FFA201;

}

.modal .modal-footer .btn-secondary:hover {
  -webkit-box-shadow: inset 0 0px 0px 100px #FFF;
  -moz-box-shadow: inset 0 0px 0px 100px #FFF;
  box-shadow: inset 0 0px 0px 100px #FFF;
  color: #FFA201 !important;
}

.modal .modal-dialog.right-slider-modal {
  margin: 0;
  margin-left: auto;
  max-width: 1102px;
}

.modal .modal-dialog.add-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  max-width: 1192px;
}

.modal .modal-dialog.sales-details-modal {
  max-width: 1192px;
}

.modal .modal-dialog.edit-sales-modal {
  max-width: 1192px;
}

.modal .edit-add.card.edit-expense {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.modal .note-editor.note-frame.card {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.offcanvas.offcanvas-end {
  width: auto !important;
}

.offcanvas.offcanvas-end.em-payrol-add {
  max-width: 1192px !important;
}

.modal.animated .modal-dialog {
  transform: translate(0, 0);
}

.modal.effect-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
  transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.modal.effect-fall {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

.modal.effect-flip-horizontal {
  perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}

.modal.effect-flip-vertical {
  perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-sign {
  perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-bottom {
  perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-left {
  perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

.grid-showcase {
  margin-bottom: -10px;
}

.grid-showcase span,
.grid-showcase .grid-wrapper {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(242, 243, 247, 0.7);
}

.grid-align {
  margin-bottom: -16px;
}

.grid-align .row {
  padding: 15px 0;
  min-height: 7rem;
  margin-bottom: 16px;
}

.grid-vertical {
  min-height: 150px;
  padding: 10px;
  color: #000;
}

table.table-bordered thead tr th p {
  margin-bottom: 0;
}

/******* Pages ******/
.page-header {
  margin-bottom: 20px;
}

.page-header .page-title {
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .page-header .page-title {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  .page-header .page-title {
    margin-bottom: 14px;
  }
}

.page-header .page-title .count-title {
  background: #F3EDFF;
  border-radius: 5px;
  margin: 0 0 0 8px;
  padding: 5px;
  color: #4A00E5;
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .page-header .title-head {
    margin-top: 0;
  }
}

.page-header .head-icons {
  margin-bottom: 10px;
}

.page-header .head-icons a {
  width: 32px;
  height: 32px;
  background: #FFF;
  color: #262A2A;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin-right: 8px;
}

.page-header .head-icons a.active,
.page-header .head-icons a:hover {
  background: #00918E;
  color: #FFF;
}

.page-header .head-icons a:last-child {
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .page-header .head-icons a {
    margin-right: 4px !important;
  }

  .page-header .head-icons a:last-child {
    margin-right: 0 !important;
  }
}

.page-header .form-sort {
  margin-top: 0;
}

.card.main-card {
  margin-bottom: 24px;
  background-color: #FFF;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 5px;
  border: 0;
  box-shadow: none;
}

.card.main-card .card-body {
  padding: 15px;
}

.set-star i {
  color: #E8E8E8;
}

.set-star i.filled {
  color: #FF9D0A;
}

.rating-select.filled i {
  color: #FF9D0A;
}

.search-section {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 20px;
}

.search-section .form-control {
  max-width: 320px;
  min-height: 40px;
}

@media (max-width: 575.98px) {
  .search-section .form-control {
    width: 100%;
    max-width: unset;
  }
}

.icon-form {
  position: relative;
}

.icon-form .form-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #262A2A;
}

.icon-form .form-control {
  padding: 8px 15px 8px 34px;
}

.icon-form-end {
  position: relative;
}

.icon-form-end .form-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #262A2A;
}

.icon-form-end .form-control {
  padding: 8px 34px 8px 15px;
}

.filter-list>ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;
  margin-bottom: 5px;
}

.filter-list>ul>li {
  margin: 0 0 10px 8px;
}

@media (max-width: 575.98px) {
  .filter-list>ul>li .btn {
    width: 100%;
  }
}

.filter-list>ul>li .btn i {
  margin-right: 8px;
}

.filter-list>ul>li .btn-purple-light i {
  margin-right: 8px;
}

.filter-list>ul>li .btn-purple-light:hover,
.filter-list>ul>li .btn-purple-light.active,
.filter-list>ul>li .btn-purple-light:focus,
.filter-list>ul>li .btn-purple-light.show {
  background-color: #F3EDFF;
  border-color: #F3EDFF;
  color: #4A00E5;
}

.filter-list>ul>li:first-child {
  margin-left: 0;
}

.filter-list>ul>li .view-icons {
  float: unset;
}

@media (max-width: 575.98px) {
  .filter-list>ul>li {
    margin-left: 0;
  }
}

@media (max-width: 1199.98px) {
  .filter-list>ul {
    justify-content: start;
  }
}

@media (max-width: 575.98px) {
  .filter-list>ul {
    display: block;
  }
}

.view-icons {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.view-icons a {
  width: 34px;
  height: 36px;
  background: #FFF;
  color: #262A2A;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.view-icons a:first-child {
  border-radius: 5px 0 0 5px;
}

.view-icons a:last-child {
  border-radius: 0 5px 5px 0;
}

.view-icons a.active,
.view-icons a:hover {
  background: #00918E;
  color: #FFF;
}

.export-list>ul>li {
  display: inline-block;
  margin: 0 8px 20px 0;
}

.export-list>ul>li:last-child {
  margin-right: 0;
}

.export-list>ul>li .btn i {
  margin-right: 8px;
}

.export-dropdwon>a {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9px 15px;
}

.export-dropdwon>a i {
  margin-right: 8px;
}

.export-dropdwon>a[aria-expanded=true]::after {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.export-dropdwon>a::after {
  font-size: 14px;
  margin-left: 8px;
}

.export-dropdwon>a:hover {
  color: #262A2A;
}

.export-dropdwon>a.show {
  border: 1px solid #0092E4;
}

.export-dropdwon .dropdown-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.export-dropdwon .dropdown-menu li a i {
  margin-right: 8px;
}

.export-dropdwon .dropdown-menu li a:hover {
  background: #F6F6F6;
  color: #262A2A;
}

.dropdown-menu {
  border: 1px solid #E8E8E8;
  padding: 15px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
}

.manage-dropdwon .dropdown-menu {
  border: 1px solid #E8E8E8;
  padding: 15px;
  max-width: 313px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
}

.manage-dropdwon .dropdown-menu h4 {
  font-weight: 600;
  margin-bottom: 10px;
}

.manage-dropdwon .dropdown-menu p {
  margin-bottom: 20px;
}

.manage-dropdwon .dropdown-menu ul {
  border-top: 1px solid #E8E8E8;
  padding-top: 20px;
}

.manage-dropdwon .dropdown-menu ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
}

.manage-dropdwon .dropdown-menu ul li p {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  margin: 0;
}

.manage-dropdwon .dropdown-menu ul li p i {
  margin-right: 8px;
}

.manage-dropdwon .dropdown-menu ul li:last-child {
  margin: 0;
}

@media (max-width: 767.98px) {
  .manage-dropdwon .dropdown-menu {
    max-width: 270px;
  }

  body .page_div {
    text-align: center !important;
    display: flex;
    align-items: center !important;
    margin-bottom: 20px !important;
  }

  body .page_two {
    flex-flow: column-reverse;
    justify-content: center;
  }

  .page_div h3 {
    text-align: center !important;
  }
}

.status-toggle.small-toggle-btn {
  float: unset;
}

.status-toggle.small-toggle-btn .label-text {
  color: #6F6F6F;
  font-size: 14px;
  font-weight: 400;
}

.status-toggle.small-toggle-btn .checktoggle {
  width: 24px;
  height: 12px;
}

.status-toggle.small-toggle-btn .checktoggle:after {
  width: 8px;
  height: 8px;
}

.status-toggle.small-toggle-btn .check:checked+.checktoggle:after {
  right: 0;
  width: 8px;
  height: 8px;
}

.status-toggle .check {
  display: block;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin: 0;
  padding: 0;
}

.status-toggle .check:checked+.checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 3px), -50%);
  right: 0;
  width: 10px;
  height: 10px;
}

.status-toggle .check:checked+.checktoggle {
  background: #1ABE17;
}

.status-toggle .checktoggle {
  background: #CDCDCD;
  cursor: pointer;
  display: block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
  border-radius: 12px;
  width: 30px;
  height: 14px;
}

.status-toggle .checktoggle:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #FFF;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: translate(3px, -50%);
  -webkit-transform: translate(3px, -50%);
  -ms-transform: translate(3px, -50%);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
}

div.badge-status {
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 8px;
  min-width: 64px;
  color: #FFF;
}

.badge-tag {
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 4px;
  font-weight: 400;
}

.badge-tag.badge-success-light {
  background: #E0F5D7;
  color: #5CB85C;
}

.badge-tag.badge-warning-light {
  background: #FFF7D8;
  color: #FDA700;
}

.badge-tag.badge-purple-light {
  background: #F3EDFF;
  color: #4A00E5;
}

.badge-tag.badge-danger-light {
  background: #FFEEEC;
  color: #FC0027;
}

.custom-table table td ul.social-links li a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: #6F6F6F;
}

.custom-table table td ul.social-links li a:hover {
  background: #E8E8E8;
  color: #6F6F6F;
}

.table-action .action-icon {
  width: 33px;
  height: 33px;
  background: #F6F6F6;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #262A2A;
}

.table-action .action-icon:hover {
  color: #262A2A;
}

.table-action .action-icon:hover i {
  color: #262A2A;
}

.table-action .dropdown-menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: #262A2A;
  white-space: nowrap;
}

.table-action .dropdown-menu a i {
  margin-right: 8px;
}

.table-action .dropdown-menu a:hover {
  background: #F6F6F6;
  color: #262A2A;
}

.daterangepicker {
  font-family: "Noto Sans", sans-serif;
}

.daterangepicker .calendar-table th {
  color: #262A2A;
}

.daterangepicker .calendar-table td {
  color: #262A2A;
}

.daterangepicker .calendar-table td.in-range {
  background-color: #FFEEEC;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #FFEEEC;
  color: #FC0027;
}

.daterangepicker .ranges li.active {
  background-color: #00918E;
}

.filter-section.filter-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
}

@media (max-width: 575.98px) {
  .filter-section.filter-flex {
    display: block;
  }
}

.sortby-list .form-control {
  max-width: 212px;
}

.sortby-list>ul {
  margin-bottom: 5px;
}

.sortby-list>ul>li {
  display: inline-block;
  margin: 0 8px 15px 0;
}

.sortby-list>ul>li:last-child {
  margin-right: 0;
}

.sortby-list>ul>li .form-wrap {
  margin-bottom: 0;
}

.sort-dropdown.drop-down .dropdown-menu li a {
  white-space: nowrap;
}

.drop-down {
  position: relative;
}

.drop-down>a {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9px 15px;
}

.drop-down>a i {
  margin-right: 8px;
}

.drop-down>a:hover {
  color: #262A2A;
}

.drop-down>a.show {
  border: 1px solid #0092E4;
}

.drop-down>a::after {
  font-size: 14px;
  margin-left: 8px;
}

.drop-down>a[aria-expanded=true]::after {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.drop-down .dropdown-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.drop-down .dropdown-menu li a i {
  margin-right: 8px;
}

.drop-down .dropdown-menu li a:hover {
  background: #F6F6F6;
  color: #262A2A;
}

.form-sorts {
  position: relative;
}

.form-sorts>a {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9px 21px;
}

@media (max-width: 991.98px) {
  .form-sorts>a {
    padding: 9px 12px;
  }
}

.form-sorts>a i {
  margin-right: 8px;
}

.form-sorts>a:hover {
  color: #262A2A;
}

.form-sorts>a.show {
  border: 1px solid #0092E4;
}

@media (max-width: 575.98px) {
  .form-sorts>a {
    width: 100%;
  }
}

.form-sorts .filter-dropdown-menu {
  padding: 24px;
  border-radius: 5px;
  background: #FFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922);
  position: absolute;
  z-index: 1;
  min-width: 400px;
  right: 0;
  top: 40px;
}

@media (max-width: 767.98px) {
  .form-sorts .filter-dropdown-menu {
    min-width: 270px;
  }
}

@media (max-width: 575.98px) {
  .form-sorts .filter-dropdown-menu {
    right: unset;
    left: 0;
  }
}

.form-sorts.table-filter-show .filter-dropdown-menu {
  display: block;
  opacity: 1;
}

.form-sorts .filter-set-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 14px;
}

.form-sorts .filter-set-head h4 {
  margin-bottom: 10px;
}

.form-sorts .filter-set-head h4 i {
  margin-right: 8px;
}

@media (max-width: 991.98px) {
  .form-sorts .filter-set-head h4 {
    font-size: 16px;
  }
}

.form-sorts .filter-set-head a {
  color: #4A00E5;
  margin-bottom: 10px;
}

.form-sorts .filter-set-head a:hover {
  color: #E41F07;
}

.form-sorts .filter-set-content {
  margin-bottom: 24px;
}

.form-sorts .filter-set-content .filter-set-content-head a {
  display: flex;
  align-items: center;
  color: #262A2A;
  font-weight: 600;
  position: relative;
  padding-left: 16px;
}

.form-sorts .filter-set-content .filter-set-content-head a::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 14px;
}

.form-sorts .filter-set-content .filter-set-content-head a:not(.collapsed) {
  padding-left: 20px;
}

.form-sorts .filter-set-content .filter-set-content-head a:not(.collapsed)::before {
  content: "\f078";
}

.form-sorts .filter-reset-btns a {
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  width: 100%;
}

@media (max-width: 575.98px) {
  .form-sorts .filter-reset-btns a {
    padding: 10px 30px;
  }
}

.form-sorts .filter-reset-btns .btn-light {
  background: #F5F5F5;
  border: 1px solid #E8E8E8;
}

.header-set {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 24px;
}

.header-set .select2-container--default {
  margin-bottom: 15px;
}

.header-set .select2-container--default .select2-selection--single {
  border-color: #E8E8E8;
}

.header-set .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #262A2A;
}

.header-set .dropdown-toggle {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 12px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
}

.radio-btn-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.radio-btn-items .radio-btn {
  margin: 0 30px 10px 0;
}

.radio-btn-items .radio-btn:last-child {
  margin-right: 0;
}

.filter-set-contents .filter-content-list {
  background: #F9F9FC;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}

.filter-set-contents .filter-content-list .form-wrap {
  margin-bottom: 15px;
}

.filter-set-contents .filter-content-list .form-wrap .form-control {
  max-width: 100%;
}

.filter-set-contents .filter-content-list ul {
  max-height: 150px;
  overflow-y: auto;
  padding: 1px 0;
}

.filter-set-contents .filter-content-list ul::-webkit-scrollbar {
  width: 3px;
  background: #E41F07;
  height: 1px;
}

.filter-set-contents .filter-content-list ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(231, 231, 231);
  border-radius: 4px;
}

.filter-set-contents .filter-content-list ul::-webkit-scrollbar-thumb {
  background: #E41F07;
  border-radius: 4px;
}

.filter-set-contents .filter-content-list ul li {
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.filter-set-contents .filter-content-list ul li:last-child {
  margin-bottom: 0;
}

.filter-set-contents .filter-content-list ul li .checkboxs .checkmarks {
  top: 1px;
}

.filter-set-contents .filter-content-list ul li .rating {
  margin-left: 0;
}

.filter-set-contents .filter-content-list ul li .rating i {
  color: #E8E8E8;
}

.filter-set-contents .filter-content-list ul li .rating i.filled {
  color: #FDA700;
}

.filter-set-contents .filter-content-list ul li .rating span {
  margin-left: 5px;
}

.filter-set-contents .filter-content-list ul li .collapse-inside-text {
  margin-left: 0;
}

.filter-set-contents .filter-content-list ul li .collapse-inside-text h5 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}

.filter-filelds .filter-row .btn {
  background: #FFA201;
  color: #FFF;
  border-color: #FFA201;
}

.checkboxs {
  display: block;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 25px;
}

.checkboxs input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxs input~.checkmarks {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.checkboxs input:checked~.checkmarks {
  background-color: #4A00E5;
  border-color: #4A00E5;
}

.checkboxs input:checked~.checkmarks:after {
  display: block;
}

.checkboxs .checkmarks {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.checkboxs .checkmarks:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxs .checkmarks:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  color: #FFF;
  font-weight: 600;
  font-size: 10px;
}

.status-radio:checked,
.status-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

.status-radio:checked+label,
.status-radio:not(:checked)+label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #262A2A;
}

.status-radio:checked+label:before,
.status-radio:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #E8E8E8;
  border-radius: 100%;
  background: #FFF;
}

.status-radio:checked+label:before {
  background: #0092E4;
  border-color: #0092E4;
}

.status-radio[type=radio]:checked+label:after,
.status-radio[type=radio]:not(:checked)+label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #FFF;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type=radio]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=radio]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.text-blue {
  color: #4A00E5 !important;
}

.w-maxcontent {
  width: fit-content;
}

.w-b {
  width: fit-content;
}

@media screen and (max-width: 526px) {
  .w-b {
    width: 100%;
    justify-content: space-around;
  }
}

.d-heading {
  display: flex;
}

.w-smallS {
  width: auto;
}

@media screen and (max-width: 420px) {
  .d-heading {

    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 420px) {
  .w-smallS {

    width: 80%
  }
}

.w-btn {
  width: max-content
}

@media screen and (max-width: 420px) {
  .w-btn {

    width: 100%
  }
}

.text-blue-light {
  color: #0092E4 !important;
}

@media (min-width: 576px) {
  .col-sm-button {
    flex: 0 0 auto;
    width: 28.333333% !important;
  }

  .col-sm-button-sml {
    flex: 0 0 auto;
    width: 40% !important;

  }
}

.content-position {
  justify-content: end;
}

.upperBox-content {
  justify-content: end;
}

.content-position-coupon {
  justify-content: end;
}

@media screen and (max-width: 644px) {
  .content-position-coupon {
    justify-content: start;
  }
}

@media screen and (max-width: 600px) {
  .content-assign {
    flex-direction: column;
  }

}

.float-end-btn {
  float: inline-end;
}

.withdraw-btn {

  float: inline-end;

}

@media screen and (max-width: 600px) {

  .withdraw-btn {
    float: inline-start;
  }
}

@media screen and (max-width: 600px) {

  .float-end-btn {
    float: inline-start;
  }
}

@media screen and (max-width: 1250px) {
  .upperBox-content {

    justify-content: start;

  }

  .content-position {
    justify-content: start;
  }
}




@media screen and (max-width: 526px) {
  .content-position {

    justify-content: center;
  }
}

.box-margin {
  text-align: end;
}

@media screen and (max-width: 570px) {
  .content-assign-teacher {
    flex-direction: column-reverse;
  }

  .box-margin {
    text-align: center;
  }
}


.d-certificate {
  display: flex;
}

@media screen and (max-width: 500px) {
  .d-certificate {
    display: flex;
    flex-direction: column;
  }
}

.flex-end-btn-input {
  justify-content: flex-end;
}

@media screen and (max-width: 650px) {
  .flex-end-btn-input {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 450px) {
  /* .text-behaviourpoint {
    text-align: center;
  } */
}



.content-teacher-ls {
  display: flex;
}

@media screen and (max-width: 650px) {
  .content-teacher-ls {
    display: flex;
    flex-direction: column;
  }
}

.btn-purple-light {
  background-color: #F3EDFF;
  border-color: #F3EDFF;
  color: #4A00E5;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 9px 20px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media (max-width: 991.98px) {
  .btn-purple-light {
    padding: 9px 14px;
  }
}

.btn-green {
  background: #00918E;
  border: 1px solid #00918E;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  padding: 9px 19px;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.btn-green:hover,
.btn-green:active {
  background: #005e5c !important;
  border-color: #005e5c !important;
  color: #FFF;
}

@media (max-width: 991.98px) {
  .btn-green {
    min-width: auto;
  }
}

.modal-btn .btn {
  margin: 0 10px 0 0;
  padding: 7px 15px;
}

.modal-btn .btn:last-child {
  margin-right: 0;
}

.custom-modal .modal-header .btn-close {
  margin: 0;
  padding: 0;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 50%;
  color: #6F6F6F;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  height: 26px;
  width: 26px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.custom-modal .modal-header .btn-close:hover {
  border: 1px solid #E41F07;
  background: #E41F07;
  color: #FFF;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
  transform: rotateZ(360deg);
}

.toggle-body {
  height: calc(100% - 68px);
}

.toggle-height {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  height: 100%;
}

.toggle-popup,
.toggle-popup1,
.toggle-popup2 {
  width: 700px;
  position: fixed;
  top: 0;
  right: -700px;
  height: 100vh;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
  background: rgba(50, 48, 56, 0.4);
  z-index: 1002;
}

.toggle-popup.sidebar-popup,
.toggle-popup1.sidebar-popup,
.toggle-popup2.sidebar-popup {
  right: 0;
  width: 100%;
  transition: ease all 0.8s;
  -webkit-transition: ease all 0.8s;
  -ms-transition: ease all 0.8s;
}

.toggle-popup .sidebar-layout,
.toggle-popup1 .sidebar-layout,
.toggle-popup2 .sidebar-layout {
  background-color: #fff;
  max-width: 700px;
  width: 100%;
  position: absolute;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.toggle-popup .sidebar-layout .sidebar-header,
.toggle-popup1 .sidebar-layout .sidebar-header,
.toggle-popup2 .sidebar-layout .sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #EFF2F5;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.toggle-popup .sidebar-layout .sidebar-header h4,
.toggle-popup1 .sidebar-layout .sidebar-header h4,
.toggle-popup2 .sidebar-layout .sidebar-header h4 {
  margin: 0;
  font-weight: 600;
}

.toggle-popup .sidebar-layout .sidebar-header a,
.toggle-popup1 .sidebar-layout .sidebar-header a,
.toggle-popup2 .sidebar-layout .sidebar-header a {
  color: #FF0000;
  font-size: 24px;
}

.toggle-popup .sidebar-layout .sidebar-header .toggle-btn,
.toggle-popup1 .sidebar-layout .sidebar-header .toggle-btn,
.toggle-popup2 .sidebar-layout .sidebar-header .toggle-btn {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 50%;
  color: #6F6F6F;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  height: 26px;
  width: 26px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.toggle-popup .sidebar-layout .sidebar-header .toggle-btn:hover,
.toggle-popup1 .sidebar-layout .sidebar-header .toggle-btn:hover,
.toggle-popup2 .sidebar-layout .sidebar-header .toggle-btn:hover {
  border: 1px solid #E41F07;
  background: #E41F07;
  color: #FFF;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
  transform: rotateZ(360deg);
}

.toggle-popup .sidebar-layout .toggle-body,
.toggle-popup1 .sidebar-layout .toggle-body,
.toggle-popup2 .sidebar-layout .toggle-body {
  padding: 20px 20px 0;
}

.toggle-popup .sidebar-layout .toggle-body .select2-container--default .select2-selection--single,
.toggle-popup1 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single,
.toggle-popup2 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single {
  height: 42px;
}

.toggle-popup .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__rendered,
.toggle-popup1 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__rendered,
.toggle-popup2 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 42px;
  line-height: 42px;
}

.toggle-popup .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__arrow,
.toggle-popup1 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__arrow,
.toggle-popup2 .sidebar-layout .toggle-body .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
}

.toggle-popup .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple,
.toggle-popup1 .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple,
.toggle-popup2 .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple {
  height: 42px;
  overflow-y: auto;
}

.toggle-popup .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple .select2-selection__choice,
.toggle-popup1 .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple .select2-selection__choice,
.toggle-popup2 .sidebar-layout .toggle-body .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 4px;
}

.toggle-popup .sidebar-layout .toggle-body .submit-button,
.toggle-popup1 .sidebar-layout .toggle-body .submit-button,
.toggle-popup2 .sidebar-layout .toggle-body .submit-button {
  padding-bottom: 20px;
}

@media (max-width: 767.98px) {

  .toggle-popup .sidebar-layout,
  .toggle-popup1 .sidebar-layout,
  .toggle-popup2 .sidebar-layout {
    max-width: 500px;
  }
}

.profile-pic-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.profile-pic-upload .profile-pic {
  border: 2px dashed #E8E8E8;
  margin: 0 15px 10px 0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
}

.profile-pic-upload .profile-pic span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-pic-upload .profile-pic img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.profile-pic-upload .profile-pic .close-img {
  background: #FC0027;
  color: #FFF;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-pic-upload .upload-content p {
  margin-bottom: 10px;
}

.profile-pic-upload .upload-btn {
  background: #F3EDFF;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
}

.profile-pic-upload .upload-btn span {
  padding: 9px 12px;
  cursor: pointer;
  color: #4A00E5;
  display: inline-block;
}

.profile-pic-upload .upload-btn span i {
  margin-right: 10px;
}

.profile-pic-upload .upload-btn input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.modal {
  z-index: 1050 !important;
}

.modal-backdrop {
  z-index: 1001 !important;
}

.bootstrap-tagsinput {
  border-color: #F6F6F6;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  border-radius: 5px;
  font-size: 14px;
  color: #6F6F6F;
  min-height: 42px;
  line-height: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 42px;
  overflow-y: auto;
}

.bootstrap-tagsinput .tag {
  background: #F6F6F6;
  color: #6F6F6F;
  padding: 2px 5px;
  line-height: normal;
  border-radius: 5px;
  font-size: 14px;
}

.user-accordion-item {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
  margin-bottom: 15px;
}

.user-accordion-item .accordion-wrap {
  padding: 10px 25px 10px 10px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.user-accordion-item .accordion-wrap span {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 8px;
}

.user-accordion-item .accordion-wrap:hover {
  color: #262A2A;
}

.user-accordion-item .accordion-wrap::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
}

.user-accordion-item .accordion-wrap:not(.collapsed)::before {
  content: "\f078";
}

.user-accordion-item .accordion-wrap:not(.collapsed) span {
  background: #E41F07;
  color: #FFF;
}

.user-accordion-item .content-collapse {
  border-top: 1px solid #E8E8E8;
  margin: 5px 10px 10px;
  padding: 15px 0 0;
}

.user-accordion-item .accordion-collapse .form-wrap {
  margin-bottom: 24px;
}

.user-accordion-item .accordion-collapse .form-wrap.mb-wrap {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .user-accordion-item .accordion-collapse .form-wrap.mb-wrap {
    margin-bottom: 24px;
  }
}

.submit-button .btn {
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin: 0 10px 0 0;
  padding: 7px 15px;
}

.submit-button .btn:last-child {
  margin-right: 0;
}

.radio-wrap .col-form-label {
  padding-bottom: 12px;
}

.radio-wrap .radio-btn {
  margin-right: 15px;
}

.radio-wrap .radio-btn:last-child {
  margin-right: 0;
}

.success-message h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.success-message .del-info {
  max-width: 300px;
  margin: 0 auto;
}

.success-message p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .success-message p {
    font-size: 14px;
  }
}

.success-message .modal-btn {
  margin-top: 40px;
}

.success-popup-icon {
  background: #FFEEEC;
  color: #FC0027;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  font-size: 42px;
}

.success-popup-icon.bg-light-blue {
  background: #F3EDFF;
  color: #4A00E5;
}

.access-wrap {
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 10px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
}

.access-wrap li {
  margin: 0 0 5px;
  padding: 0;
}

.access-wrap li.checkbox-checked label {
  background: #F6F6F6;
}

.access-wrap li label {
  width: 100%;
  position: relative;
  padding: 5px;
  background: transparent;
}

.access-wrap li label.checkboxs .checkmarks {
  right: 10px;
  left: unset;
  top: 50%;
  transform: translatey(-50%);
}

.access-wrap li label.checkboxs input:checked~.checkmark {
  background: #E41F07;
  border-color: #E41F07;
}

.access-wrap li label .people-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.access-wrap li label .people-profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.access-wrap li label .people-profile a {
  color: #6F6F6F;
  font-size: 14px;
}

.access-wrap li:hover {
  background-color: #F6F6F6;
}

.access-wrap li:last-child {
  margin-bottom: 0;
}

.contact-grid {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 24px;
  background: #FFF;
  color: #6F6F6F;
  margin-bottom: 24px;
}

.contact-grid .grid-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-grid .grid-head .users-profile {
  display: flex;
  align-items: center;
}

.contact-grid .grid-head .users-profile .avatar {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}

.contact-grid .grid-head .users-profile .avatar img {
  border-radius: 5px;
}

.contact-grid .grid-head .users-profile .name-user {
  margin-bottom: 0;
}

.contact-grid .grid-head .users-profile .name-user h6 {
  font-size: 14px;
  font-weight: 500;
}

.contact-grid .grid-head .users-profile .name-user h6 a {
  color: #262A2A;
}

.contact-grid .grid-head .dropdown .dropdown-toggle::after {
  display: none;
}

.contact-grid .grid-head .dropdown .dropdown-toggle i {
  color: #FFAB1A;
}

.contact-grid .grid-body .address-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px 0;
}

.contact-grid .grid-body .address-info p {
  display: inline-flex;
  align-items: center;
  line-height: normal;
  margin-bottom: 10px;
  color: #6F6F6F;
}

.contact-grid .grid-body .address-info p i {
  color: #262A2A;
  margin-right: 8px;
}

.contact-grid .grid-body .address-info p:last-child {
  margin-bottom: 0;
}

.contact-grid .grid-body .grid-badges span+span {
  margin-left: 5px;
}

.contact-grid .grid-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E8E8E8;
}

.contact-grid .grid-footer .social-links li a {
  color: #6F6F6F;
  width: 26px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.5s all;
}

.contact-grid .grid-footer .social-links li a:hover {
  background: #FFEEEC;
  color: #E41F07;
  transition: 0.5s all;
}

.contact-grid .grid-footer .star-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-grid .grid-footer .star-user .set-star {
  margin-right: 10px;
}

.load-btn .btn i {
  margin-left: 4px;
  animation: spin 3000ms linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

.table-avatar .company-img {
  flex-shrink: 0;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.img-select {
  position: relative;
}

.img-select .select-box-img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 9999;
}

.img-select .select2-selection__rendered {
  padding-left: 40px !important;
}

.company-profile .company-img {
  flex-shrink: 0;
  margin-right: 10px;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.users-group ul {
  display: flex;
  align-items: center;
}

.users-group ul li a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  margin-left: -11px;
}

.users-group ul li a img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.users-group ul li a.total-group-member {
  background: #E41F07;
  color: #FFF;
  font-size: 10px;
}

.users-group ul li a:hover {
  position: relative;
}

.users-group ul li:first-child a {
  margin-left: 0;
}

.add-new {
  color: #FC0027;
}

.contact-head {
  margin-bottom: 5px;
}

.contact-head .contact-breadcrumb {
  margin-bottom: 5px;
}

.contact-head .contact-breadcrumb li {
  color: #6F6F6F;
  position: relative;
  display: inline-block;
  margin: 0 2px 5px 0;
  padding: 0 0 0 10px;
}

.contact-head .contact-breadcrumb li a {
  color: #6F6F6F;
}

.contact-head .contact-breadcrumb li a i {
  color: #E41F07;
  margin-right: 8px;
}

.contact-head .contact-breadcrumb li a:hover {
  color: #E41F07;
}

.contact-head .contact-breadcrumb li:last-child {
  margin-right: 0;
}

.contact-head .contact-breadcrumb li:first-child {
  padding-left: 0;
}

.contact-head .contact-breadcrumb li:first-child::before {
  content: none;
}

.contact-head .contact-breadcrumb li::before {
  content: "/";
  position: absolute;
  top: 0;
  left: 0;
}

.contact-head .contact-breadcrumb li.before-none {
  padding-left: 5px;
}

.contact-head .contact-breadcrumb li.before-none::before {
  content: none;
}

.contact-head .contact-breadcrumb li .btn {
  color: #FFF;
}

.contact-head .contact-breadcrumb li .btn i {
  color: #FFF;
}

.contact-head .contact-breadcrumb li .btn:hover {
  color: #FFF;
}

.contact-head .contact-pagination {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 10px;
}

.contact-head .contact-pagination p {
  margin: 0 15px 0 0;
}

.contact-head .contact-pagination ul li {
  display: inline-block;
  margin-right: 5px;
}

.contact-head .contact-pagination ul li a {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #FFF;
  color: #262A2A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.contact-head .contact-pagination ul li a:hover {
  background: #E41F07;
  color: #FFF;
}

.contact-head .contact-pagination ul li:last-child {
  margin-right: 0;
}

.contact-wrap {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  background: #FFF;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px 24px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.contact-wrap .contact-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 10px;
}

.contact-wrap .contact-profile .avatar {
  margin-right: 15px;
  flex-shrink: 0;
}

.contact-wrap .contact-profile .avatar img {
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .contact-wrap .contact-profile .avatar {
    width: 60px;
    height: 60px;
  }
}

.contact-wrap .contact-profile .avatar.company-avatar {
  border: 1px solid #E8E8E8;
  width: 80px;
  height: 80px;
}

.contact-wrap .contact-profile .avatar.company-avatar img {
  width: 48px;
  height: auto;
}

@media (max-width: 768px) {
  .contact-wrap .contact-profile .avatar.company-avatar {
    width: 60px;
    height: 60px;
  }
}

.contact-wrap .contact-profile .avatar.company-avatar .text-icon {
  color: #B8BCC9;
  font-size: 18px;
  font-weight: 500;
}

.contact-wrap .contact-profile h5 {
  margin-bottom: 5px;
}

.contact-wrap .contact-profile p {
  margin-bottom: 10px;
}

.contact-wrap .contact-profile .status {
  border: 2px solid #FFF;
  height: 10px;
  width: 10px;
  background-color: #5CB85C;
  margin: 0;
  position: absolute;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  display: inline-block;
}

.contact-wrap .contact-profile .badge-rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.contact-wrap .contact-profile .badge-rate .badge {
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  padding: 6px 6px;
  color: #6F6F6F;
  display: inline-block;
  margin-right: 15px;
  background: #F4F4F4;
}

.contact-wrap .contact-profile .badge-rate .badge i {
  margin-right: 5px;
}

.contact-wrap .contact-profile .badge-rate p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 0;
}

.contact-wrap .contact-profile .badge-rate p i {
  color: #FF9D0A;
  margin-right: 5px;
}

@media (max-width: 767.98px) {
  .contact-wrap {
    margin: 0 0 24px;
    padding: 15px 15px 5px;
  }
}

.contacts-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
}

.contacts-action>a {
  margin: 0 8px 10px 0;
}

.contacts-action>a:last-child {
  margin-right: 0;
}

.contacts-action .badge {
  margin: 0 8px 10px 0;
  padding: 7px 6px;
  font-size: 14px;
  font-weight: 400;
}

.contacts-action .badge.badge-light {
  background: #F4F4F4;
  color: #6F6F6F;
}

.contacts-action .badge i {
  margin-right: 8px;
}

.contacts-action .action-drops {
  margin-bottom: 10px;
}

.contacts-action .action-drops .dropdown-toggle {
  font-weight: 600;
  color: #FFF;
  display: inline-block;
}

.contacts-action .action-drops .dropdown-toggle span {
  display: inline-block;
  border-radius: 5px;
  padding: 4px 10px;
  color: #FFF;
}

.contacts-action .dropdown-toggle {
  margin-right: 0;
  color: #FFAB1A;
}

.contacts-action .dropdown-toggle::after {
  content: none;
}

.contacts-action .btn {
  border-radius: 5px;
  padding: 6px 13px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: 15px;
  font-weight: 500;
}

.contacts-action .btn i {
  margin-right: 5px;
}

.contacts-action .btn-icon {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  color: #262A2A;
}

.contacts-action .btn-icon:hover {
  background: #E41F07;
  color: #FFF;
}

.contacts-action .btn-icon.rating {
  border-radius: 50%;
  background: #FFF;
  border: 1px solid #E8E8E8;
  color: #FF9D0A;
}

.contacts-action .act-dropdown {
  margin-bottom: 10px;
}

.contacts-action .act-dropdown>a {
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #FFF;
  border: 1px solid #E8E8E8;
}

.contacts-action .act-dropdown>a:hover {
  background: #E8E8E8;
  color: #262A2A;
}

.contacts-action .act-dropdown .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: #262A2A;
}

.contacts-action .act-dropdown .dropdown-item i {
  margin-right: 8px;
}

.contacts-action .act-dropdown .dropdown-item:hover {
  background: #F6F6F6;
  color: #262A2A;
}

.contact-sidebar {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  background: #FFF;
  margin: 0 0 24px;
  padding: 15px;
  border-radius: 5px;
}

.contact-sidebar h6 {
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-sidebar .com-add {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 15px;
}

.contact-sidebar ul {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #E8E8E8;
}

.contact-sidebar ul:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}

.contact-sidebar ul li {
  font-size: 14px;
  margin-bottom: 15px;
}

.contact-sidebar ul li:last-child {
  margin-bottom: 0;
}

.contact-sidebar .project-mem li {
  margin-bottom: 0;
}

.contact-sidebar .project-mem li.more-set a {
  color: #6F6F6F;
}

.contact-sidebar .social-info {
  padding-bottom: 15px;
}

.contact-sidebar .social-info li {
  margin: 0 10px 5px 0;
}

.contact-sidebar .tag-info {
  margin-bottom: 15px;
}

.contact-sidebar .tag-info li {
  margin-bottom: 5px;
}

.basic-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: 14px;
}

.basic-info li span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #F6F6F6;
  color: #262A2A;
  margin-right: 10px;
  flex-shrink: 0;
}

.set-info li a {
  color: #6F6F6F;
}

.set-info li a:hover {
  color: #E41F07;
}

.set-info li i {
  margin-right: 5px;
}

.other-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.other-info li .other-title {
  min-width: 110px;
  display: inline-block;
}

.other-info li .avatar-xs {
  width: 16px;
  height: 16px;
  border-radius: 5px;
}

.con-sidebar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
}

.con-sidebar-title a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #4A00E5;
}

.con-sidebar-title a:hover {
  color: #E41F07;
}

.company-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.company-info li span {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  margin-right: 10px;
}

.company-info li h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.company-info li p {
  font-size: 14px;
  margin-bottom: 0;
}

.company-info.com-info li span {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
}

.company-info.com-info li p i {
  margin-right: 5px;
  color: #FDA700;
}

.deals-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.deals-info li img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 10px;
}

.deals-info li p {
  margin-bottom: 0;
}

.social-info li {
  display: inline-block;
}

.social-info li a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: #FFF;
  color: #262A2A;
}

.social-info li a:hover {
  background: #262A2A;
  color: #FFF;
}

.tag-info li {
  margin-right: 5px;
  display: inline-block;
}

.tag-info li a {
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
}

.tag-info li:last-child {
  margin-right: 0;
}

.projects-info li {
  margin-right: 10px;
  display: inline-block;
}

.projects-info li a {
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 6px 6px;
  display: inline-block;
  color: #6F6F6F;
}

.projects-info li a:hover {
  color: #262A2A;
}

.projects-info li a.badge-light {
  background: #F6F6F6;
  color: #6F6F6F;
}

.projects-info li:last-child {
  margin-right: 0;
}

.priority-info .dropdown .dropdown-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
}

.priority-info .dropdown .dropdown-toggle[aria-expanded=true]>i {
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.priority-info .dropdown .dropdown-toggle::after {
  content: none;
}

.priority-info .dropdown .dropdown-toggle span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.priority-info .dropdown .dropdown-toggle span i {
  font-size: 10px;
}

.priority-info .dropdown a {
  font-size: 14px;
  color: #262A2A;
}

.priority-info .dropdown a span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.priority-info .dropdown a span i {
  font-size: 10px;
}

.priority-info .dropdown .dropdown-menu {
  width: 100%;
}

.star-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  color: #FF9D0A;
}

.star-icon i {
  font-size: 14px;
}

.select-pipeline {
  position: relative;
  margin-right: 8px;
}

.select-pipeline .pipe-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 99999;
  transform: translateY(-50%);
  color: #262A2A;
}

.select-pipeline .select2-container .select2-selection.select2-selection--single {
  height: 40px;
  border-radius: 5px;
  color: #262A2A;
  font-weight: 500;
}

.select-pipeline .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-left: 30px;
  line-height: 40px;
  color: #262A2A;
  font-weight: 500;
  padding-right: 30px;
}

.select-pipeline .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  height: 40px;
}

.pipeline-list {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 15px;
}

.pipeline-list ul {
  margin-bottom: 15px;
}

.pipeline-list ul li {
  display: inline-block;
}

.pipeline-list ul li:first-child a {
  border-radius: 10px 0 0 10px;
}

.pipeline-list ul li:last-child a {
  border-radius: 0;
}

.pipeline-list ul li a {
  margin: 0 0 3px;
  padding: 12px 25px 12px 50px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  background: #F6F6F6;
  color: #262A2A;
  position: relative;
  min-width: 185px;
}

.pipeline-list ul li a::after {
  content: "";
  position: absolute;
  top: 0;
  right: -23px;
  width: 45px;
  height: 45px;
  -webkit-transform: scale(0.707) rotate(45deg);
  -ms-transform: scale(0.707) rotate(45deg);
  -moz-transform: scale(0.707) rotate(45deg);
  -transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #F6F6F6;
  -webkit-box-shadow: 2px -2px 0 1px #fff, 3px -3px 0 2px rgba(255, 255, 255, 0.1);
  box-shadow: 2px -2px 0 1px #fff, 3px -3px 0 2px rgba(255, 255, 255, 0.1);
  border-radius: 0 5px 0 50px;
}

.pipeline-list ul li a.bg-info {
  color: #FFF;
}

.pipeline-list ul li a.bg-info::after {
  background: #1ECBE2;
}

.pipeline-list ul li a.bg-info:hover {
  background: #1ECBE2 !important;
}

.pipeline-list ul li a.bg-warning {
  color: #FFF;
}

.pipeline-list ul li a.bg-warning::after {
  background: #FDA700;
}

.pipeline-list ul li a.bg-warning:hover {
  background: #FDA700 !important;
}

.pipeline-list ul li a.bg-pending {
  color: #FFF;
  background: #4A00E5 !important;
}

.pipeline-list ul li a.bg-pending::after {
  background: #4A00E5;
}

.pipeline-list ul li a.bg-pending:hover {
  background: #4A00E5 !important;
}

.pipeline-list ul li a.bg-pink {
  color: #FFF;
}

.pipeline-list ul li a.bg-pink::after {
  background: #EA00B7;
}

.pipeline-list ul li a.bg-pink:hover {
  background: #EA00B7 !important;
}

.pipeline-list ul li a.bg-success {
  color: #FFF;
}

.pipeline-list ul li a.bg-success::after {
  background: #5CB85C;
}

.pipeline-list ul li a.bg-success:hover {
  background: #5CB85C !important;
}

.pipeline-list ul li a.bg-danger {
  color: #FFF;
}

.pipeline-list ul li a.bg-danger::after {
  background: #FC0027;
}

.pipeline-list ul li a.bg-danger:hover {
  background: #FC0027 !important;
}

.pipeline-list ul li a.bg-gray {
  color: #FFF;
  background: #E0E2E7;
}

.pipeline-list ul li a.bg-gray::after {
  background: #E0E2E7;
}

.pipeline-list ul li a.bg-gray:hover {
  background: #E0E2E7 !important;
}

@media (max-width: 575.98px) {
  .pipeline-list ul li a {
    width: calc(100% - 25px);
  }
}

@media (max-width: 575.98px) {
  .pipeline-list ul li {
    display: block;
  }
}

.contact-tab-wrap {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  background: #FFF;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 24px 24px 0;
}

.contact-tab-wrap h4 {
  font-weight: 600;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .contact-tab-wrap h4 {
    font-size: 16px;
  }
}

.contact-tab-view {
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  background: #FFF;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
}

@media (max-width: 575.98px) {
  .contact-nav {
    display: block;
  }
}

.contact-nav li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.contact-nav li a {
  color: #6F6F6F;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 3px solid #FFF;
  text-align: center;
  padding: 5px 5px 10px 5px;
}

.contact-nav li a:hover,
.contact-nav li a.active {
  color: #E41F07;
  border-color: #E41F07;
}

.contact-nav li a i {
  margin-right: 5px;
}

@media (max-width: 575.98px) {
  .contact-nav li {
    display: block;
  }
}

.form-sort {
  position: relative;
}

.form-sort i {
  position: absolute;
  z-index: 101;
  width: 16px;
  height: 16px;
  top: 14px;
  left: 7px;
}

.form-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 35px;
}

.form-sort span.select2-container.select2-container--default.select2-container--open {
  z-index: 99;
}

.view-header {
  margin: 0 0 20px;
  padding: 0 0 5px;
  border-bottom: 1px solid #E8E8E8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
}

.view-header h4 {
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .view-header h4 {
    font-size: 16px;
  }
}

.view-header .com-add {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #4A00E5;
}

.view-header .com-add:hover {
  color: #E41F07;
}

.view-header .com-add.create-mail {
  color: #E41F07;
}

.view-header ul li {
  display: inline-block;
  margin: 0 20px 15px 0;
}

.view-header ul li:last-child {
  margin-right: 0;
}

.view-header .form-sort .select2-container {
  min-width: 150px !important;
}

.view-header .form-sort .select2-container .select2-selection .select2-selection__rendered {
  font-size: 14px;
  color: #262A2A;
}

.view-header .form-sort {
  margin-top: 0;
}

.badge-day {
  background: #F3EDFF;
  color: #4A00E5;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  margin: 0 0 20px;
  padding: 4px 8px;
}

.badge-day i {
  margin-right: 5px;
}

.activity-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #E8E8E8;
  margin: 0 0 15px;
  padding: 15px;
  border-radius: 5px;
}

.activity-wrap .activity-icon {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #FFF;
  margin-right: 8px;
  flex-shrink: 0;
}

.activity-wrap .activity-info h6 {
  font-weight: 500;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
}

.activity-wrap .activity-info p {
  margin-bottom: 5px;
}

.activity-wrap .activity-info p:last-child {
  margin-bottom: 0;
}

.activity-wrap .activity-info .avatar-xs img {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin: 0 8px 0;
}

.contact-activity ul:last-child .activity-wrap:last-child {
  margin-bottom: 0;
}

.bg-pending {
  background: #676DFF !important;
  color: #FFF !important;
}

.bg-secondary-success {
  background: #00918E !important;
}

.bg-orange {
  background: #FF9D0A;
}

.upcoming-info {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 10px 0 0;
  padding: 15px 15px 5px;
}

.upcoming-info p {
  font-weight: 500;
  margin-bottom: 8px;
}

.upcoming-info .dropdown {
  margin-bottom: 10px;
}

.upcoming-info .dropdown .dropdown-toggle {
  color: #262A2A;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: 14px;
}

.upcoming-info .dropdown .dropdown-toggle::after {
  content: none;
}

.upcoming-info .dropdown .avatar-xs {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 10px;
}

.upcoming-info .dropdown .circle {
  font-size: 10px;
}

.upcoming-info .dropdown .dropdown-menu a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-size: 14px;
  color: #262A2A;
}

.action-drop .dropdown-toggle {
  width: 33px;
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  color: #262A2A;
}

.action-drop .dropdown-toggle::after {
  content: none;
}

.action-drop .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: #262A2A;
}

.action-drop .dropdown-item i {
  margin-right: 5px;
}

.action-drop .dropdown-item:hover {
  background-color: #F6F6F6;
  color: #262A2A;
}

.notes-editor .dropdown-toggle::after {
  content: none;
}

@media (max-width: 767.98px) {
  .calls-activity .calls-box .caller-info {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .calls-activity .calls-box .caller-info .calls-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.calls-box {
  margin: 0 0 20px;
  padding: 24px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
  border-radius: 5px;
}

.calls-box:last-child {
  margin-bottom: 0;
}

.calls-box .caller-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 5px;
}

.calls-box .caller-info .calls-user {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 10px;
}

.calls-box .caller-info .calls-user img {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  margin-right: 8px;
}

.calls-box .caller-info .calls-user p {
  margin-bottom: 0;
}

.calls-box .caller-info .calls-user p span {
  color: #262A2A;
  font-weight: 500;
}

.calls-box .caller-info .calls-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.calls-box .caller-info .calls-action .dropdown {
  margin: 0 8px 10px 0;
}

.calls-box .caller-info .calls-action .dropdown:last-child {
  margin-right: 0;
}

.calls-box .caller-info .calls-action .dropdown.call-drop .dropdown-toggle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: #FC0027;
  padding: 7px 12px;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
}

.calls-box .caller-info .calls-action .dropdown-toggle {
  font-size: 14px;
  border-radius: 5px;
  padding: 0;
  color: #262A2A;
}

.calls-box .caller-info .calls-action .dropdown-toggle::after {
  content: none;
}

.calls-box .caller-info .calls-action .dropdown-menu {
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922);
  border: 1px solid #FFECCC;
  border-radius: 5px;
  padding: 10px;
}

.calls-box .caller-info .calls-action .dropdown-menu a {
  font-size: 14px;
}

.calls-box .caller-info .calls-action .dropdown-menu a:hover {
  background-color: #F6F6F6;
  color: #262A2A;
  border-radius: 5px;
}

.calls-box p {
  font-size: 14px;
}

.calls-box .add-comment {
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #4A00E5;
}

.text-purple {
  color: #4A00E5 !important;
}

.note-edit-wrap {
  display: none;
  margin-bottom: 15px;
}

.notes-activity .calls-box h5 {
  font-weight: 500;
  margin-bottom: 7px;
}

.notes-activity .calls-box p {
  margin-bottom: 15px;
}

.notes-activity .calls-box .calls-user h6 {
  font-weight: 500;
  margin-bottom: 0;
}

.notes-activity .calls-box>ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex-wrap: wrap;
}

.notes-activity .calls-box>ul>li {
  display: inline-block;
  margin-right: 15px;
}

.notes-activity .calls-box>ul>li:last-child {
  margin-right: 0;
}

@media (max-width: 575.98px) {
  .notes-activity .calls-box>ul>li {
    display: block;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .notes-activity .calls-box>ul {
    display: block;
  }
}

.notes-activity .note-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  margin: 0 0 15px;
  padding: 15px;
  border-radius: 5px;
}

.notes-activity .note-download .note-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-right: 24px;
}

.notes-activity .note-download .note-info h6 {
  font-weight: 500;
  margin-bottom: 5px;
}

.notes-activity .note-download .note-info p {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .notes-activity .note-download .note-info {
    margin-right: 15px;
  }
}

.notes-activity .note-download .note-icon {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  color: #FFF;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 8px;
  flex-shrink: 0;
}

.notes-activity .note-download .note-icon img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.notes-activity .note-download:hover {
  background: #F6F6F6;
}

.notes-activity .note-download:hover a {
  background: #FFF;
}

.notes-activity .note-download a {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: #262A2A;
  background: #F6F6F6;
}

@media (max-width: 767.98px) {
  .notes-activity .note-download {
    margin: 0 0 15px;
    padding: 10px;
  }
}

.notes-activity .reply-box {
  background: #F9F9FC;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
}

.notes-activity .reply-box p {
  margin-bottom: 10px;
}

.notes-activity .reply-box .btn {
  background: #FFF;
  border-color: #FFF;
  border-radius: 5px;
  color: #262A2A;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 5px 10px;
}

.notes-activity .reply-box .btn i {
  margin-right: 3px;
}

.notes-activity .reply-box .btn:hover {
  background: #FAFAFA;
}

.avatar-xxs {
  width: 16px;
  height: 16px;
}

.note-btns {
  margin-top: 5px;
}

.note-btns .btn {
  margin: 10px 10px 0 0;
  padding: 7px 15px;
}

.note-btns .btn:last-child {
  margin-right: 0;
}

.note-btns .btn.btn-primary {
  min-width: 110px;
}

.files-wrap {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 24px 24px 9px;
}

.files-wrap .file-info {
  margin-bottom: 15px;
}

.files-wrap .file-info h4 {
  font-weight: 600;
  margin-bottom: 5px;
}

@media (max-width: 767.98px) {
  .files-wrap .file-info h4 {
    font-size: 16px;
  }
}

.files-wrap .file-info p {
  margin-bottom: 10px;
}

.files-wrap .file-info p:last-child {
  margin-bottom: 0;
}

.files-wrap .file-info .file-user {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.files-wrap .file-info .file-user img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.files-wrap .file-info .file-user p {
  font-size: 14px;
  margin-bottom: 0;
}

.files-wrap .file-info .file-user p span {
  display: block;
  font-size: 12px;
}

.files-wrap .file-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 15px;
}

.files-wrap .file-action li {
  display: inline-block;
}

.files-wrap .file-action .btn {
  padding: 9px 19px;
}

@media (max-width: 767.98px) {
  .files-wrap .file-action .btn {
    padding: 8px 10px;
  }
}

.files-wrap .file-action .badge {
  font-size: 12px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin: 0 5px 0 0;
  padding: 4px 5px;
  font-weight: 400;
}

.files-wrap .file-action .badge i {
  margin-right: 5px;
}

.files-wrap .file-action .badge.priority-badge i {
  font-size: 4px;
}

.files-wrap:last-child {
  margin-bottom: 0;
}

.drag-attach {
  border: 1px dashed #E8E8E8;
  border-radius: 5px;
  position: relative;
  text-align: center;
}

.drag-attach input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.drag-attach .img-upload {
  background: #F3EDFF;
  color: #4A00E5;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin: 24px 0;
  padding: 9px 17px;
  border-radius: 5px;
}

.drag-attach .img-upload i {
  margin-right: 10px;
}

.drag-upload {
  background: #FAFAFA;
  border: 1px dashed #FFECCC;
  border-radius: 5px;
  position: relative;
}

.drag-upload input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.drag-upload i {
  font-size: 24px;
  margin-bottom: 10px;
}

.drag-upload p {
  font-size: 14px;
  margin-bottom: 0;
}

.drag-upload .img-upload {
  text-align: center;
  padding: 36px 20px;
}

.upload-file {
  background: #F9F9FC;
  border: 1px solid #E8E8E8;
  margin: 0 0 5px;
  padding: 15px;
  border-radius: 5px;
}

.upload-file h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.upload-file p {
  font-size: 14px;
  margin-bottom: 0;
}

.upload-file p.black-text {
  color: #262A2A;
}

.upload-file .progress {
  height: 3px;
  margin: 15px 0 4px;
}

.upload-file.upload-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.form-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.sync-radio .radio-item {
  margin-bottom: 15px;
}

.sync-radio .radio-item label {
  font-size: 14px;
}

.sync-radio .radio-item:last-child {
  margin-bottom: 0;
}

.signature-wrap {
  background: #F2ECFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px 15px 0;
}

.signature-wrap h4,
.signature-wrap p {
  margin-bottom: 15px;
}

.signature-wrap h4 {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .signature-wrap h4 {
    font-size: 16px;
  }
}

.add-sign {
  font-size: 16px;
  color: #FDA700;
}

.sign-item li {
  margin-bottom: 15px;
}

.sign-item li .sign-title {
  display: block;
  font-weight: 500;
  margin-bottom: 3px;
}

.trash-sign {
  color: #FC0027;
}

.send-success {
  border: 1px solid #5CB85C;
  background: #CEEACE;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
}

.send-success p {
  color: #262A2A;
  margin-bottom: 0;
}

.send-success p i {
  margin-right: 5px;
}

fieldset {
  display: none;
}

fieldset#first-field-file {
  display: block;
}

.add-details-wizard ul {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 575.98px) {
  .add-details-wizard ul {
    flex-direction: column;
  }
}

.add-details-wizard ul li {
  line-height: normal;
  background: #F6F6F6;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .add-details-wizard ul li {
    width: 100%;
    margin-bottom: 10px;
  }
}

.add-details-wizard ul li span {
  margin-right: 5px;
}

.add-details-wizard ul li .multi-step-info h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #6F6F6F;
}

.add-details-wizard ul li.active {
  background: #E41F07;
  color: #FFF;
}

.add-details-wizard ul li.active h6 {
  color: #FFF;
}

.pipeline-item {
  border: 1px solid #E8E8E8;
  margin: 0 0 5px;
  padding: 15px 15px 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
}

.pipeline-item::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 2px;
  height: 100%;
  background: #FFA201;
}

.pipeline-item p {
  color: #262A2A;
  font-weight: 500;
  margin-bottom: 10px;
}

.pipeline-item .action-pipeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
}

.pipeline-item .action-pipeline a {
  margin: 0 15px 10px 0;
}

.pipeline-item .action-pipeline a i {
  margin-right: 8px;
}

.pipeline-item .action-pipeline a:last-child {
  margin-right: 0;
}

.pipeline-item:last-child {
  margin-bottom: 0;
}

.access-item .radio-btn {
  margin-right: 15px;
}

.access-item .radio-btn:last-child {
  margin-right: 0;
}

.access-wrapper {
  margin-top: 20px;
}

.access-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  border: 1px solid #E8E8E8;
  margin: 0 0 5px;
  padding: 15px 15px 5px;
  border-radius: 5px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.access-view .access-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.access-view .access-img img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 10px;
}

.access-view a {
  font-weight: 600;
  color: #FC0027;
}

.header-collapse .header-user {
  display: none;
  height: 0;
}

.header-collapse .page-wrapper {
  padding-top: 0;
}

.header-collapse .header {
  border-bottom: none;
  background: transparent;
  width: 260px;
}

.header-collapse .header .header-left {
  background: white;
}

.header-collapse #collapse-header i {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.bootstrap-datetimepicker-widget {
  z-index: 99999;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td:hover {
  background-color: #00918E;
}

.note-modal-backdrop {
  z-index: 9;
}

.note-modal-footer {
  height: 57px;
}

.table tbody tr td a.title-name {
  color: #262A2A;
  font-weight: 500;
}

.table tbody tr td a.title-name:hover {
  color: #E41F07;
}

.title-name {
  color: #262A2A;
  font-weight: 500;
}

.daterange-picker .form-sort {
  margin-left: 10px;
}

.daterange-picker .form-sort input {
  padding-left: 25px;
  height: 38px;
}

.daterange-picker .form-sort .select2-container .select2-selection.select2-selection--single {
  height: 38px;
}

.daterange-picker .form-sort .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.daterange-picker .form-sort .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  height: 38px;
}

.card-select ul {
  display: flex;
  align-items: center;
}

.card-select ul li+li {
  margin-left: 5px;
}

.card-select ul li .select2-container .select2-selection.select2-selection--single {
  height: 38px;
}

.card-select ul li .select2-container .select2-selection.select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.card-select ul li .select2-container .select2-selection.select2-selection--single .select2-selection__arrow {
  height: 38px;
}

.view-icons {
  float: right;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .view-icons {
    margin-right: 0;
  }
}

.view-icons .btn {
  background-color: #FFF;
  border: 1px solid #E8E8E8;
  color: #FFB534;
  font-size: 18px;
  min-width: 40px;
  margin: 0 5px 0 0;
  padding: 7px 0 3px 0;
}

.view-icons .btn.active {
  color: #FFAB1A;
}

@media (max-width: 767.98px) {
  .view-icons .btn {
    min-width: 37px;
    margin: 0 5px 0 0;
    padding: 5px 0 3px 0;
  }
}

@media (max-width: 767.98px) {
  .page-header .view-icons {
    margin-right: 10px;
  }
}

@media (max-width: 575.98px) {
  .page-header .view-icons {
    margin-right: 0;
  }
}

.title-head {
  justify-content: end;
  align-items: center;
}

@media (max-width: 767.98px) {
  .title-head {
    justify-content: start;
    margin-top: 20px;
  }
}

@media (max-width: 575.98px) {
  .title-head {
    flex-direction: column;
    row-gap: 20px;
    align-items: start;
  }
}

.title-head .add-btn {
  margin-left: 10px;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  .title-head .add-btn {
    margin-left: 0;
  }
}

.title-head .form-sort .list-view {
  padding: 10px 10px 10px 30px;
  background-color: #FFF;
  border: 1px solid #E8E8E8;
  color: #FFC767;
  font-size: 14px;
  text-decoration: none;
  line-height: normal;
}

.daterange-picker .form-sort {
  min-width: 200px;
}

.statistic-dropdown .btn-green {
  padding: 6px 15px;
  height: 35px;
}

.badge-dots {
  width: 12px;
  height: 12px;
  border-radius: 5px;
  margin-right: 3px;
  display: inline-flex;
}

.error-page {
  background: #FFF;
}

.error-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 50px 0;
}

@media (max-width: 991.98px) {
  .error-box {
    padding: 40px 0;
  }
}

@media (max-width: 767.98px) {
  .error-box {
    padding: 30px 0;
  }
}

.error-box .error-img {
  padding-bottom: 50px;
}

@media (max-width: 991.98px) {
  .error-box .error-img {
    padding-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .error-box .error-img {
    padding-bottom: 20px;
  }
}

.error-box .error-content {
  text-align: center;
}

.error-box .error-content .btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.error-box .error-content .btn i {
  font-size: 18px;
  line-height: normal;
  margin-right: 6px;
}

.error-box h3 {
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  .error-box h3 {
    font-size: 24px;
  }
}

.error-box p {
  font-size: 16px;
  max-width: 430px;
  margin: 0 auto 15px;
}

@media (max-width: 767.98px) {
  .error-box p {
    font-size: 14px;
  }
}

.comming-soon {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
}

@media (max-width: 991.98px) {
  .comming-soon {
    height: 100%;
  }
}

.comming-soon .coming-soon-box {
  width: 800px;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  position: relative;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box {
    padding: 20px 40px;
    max-width: 800px;
  }
}

@media (max-width: 991.98px) {
  .comming-soon .coming-soon-box {
    width: auto;
    padding: 30px;
  }
}

@media (max-width: 575.98px) {
  .comming-soon .coming-soon-box {
    padding: 15px;
  }
}

.comming-soon .coming-soon-box .crms-logo {
  margin-bottom: 30px;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box .crms-logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .crms-logo img {
    width: 140px;
  }
}

.comming-soon .coming-soon-box span {
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box span {
    font-size: 18px;
  }
}

.comming-soon .coming-soon-box h1 {
  font-size: 60px;
  line-height: normal;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box h1 {
    font-size: 50px;
  }
}

@media (max-width: 991.98px) {
  .comming-soon .coming-soon-box h1 {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .comming-soon .coming-soon-box h1 {
    font-size: 26px;
  }
}

.comming-soon .coming-soon-box h1 span {
  font-size: 60px;
  color: #E41F07;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box h1 span {
    font-size: 50px;
  }
}

@media (max-width: 991.98px) {
  .comming-soon .coming-soon-box h1 span {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .comming-soon .coming-soon-box h1 span {
    font-size: 28px;
  }
}

.comming-soon .coming-soon-box p {
  font-size: 15px;
  max-width: 400px;
  text-align: center;
  margin: auto;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box p {
    font-size: 14px;
  }
}

.comming-soon .coming-soon-box .coming-soon-timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 40px 0;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box .coming-soon-timer {
    margin: 20px 0;
  }
}

.comming-soon .coming-soon-box .coming-soon-timer li {
  font-size: 16px;
  font-weight: 500;
  color: #6F6F6F;
  border: 1px solid rgba(145, 158, 171, 0.3019607843);
  background-color: #FFF;
  width: 85px;
  height: 85px;
  margin-right: 14px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .coming-soon-timer li {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .comming-soon .coming-soon-box .coming-soon-timer li {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
}

.comming-soon .coming-soon-box .coming-soon-timer li span {
  font-size: 28px;
  font-weight: 700;
  color: #262A2A;
  line-height: normal;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .coming-soon-timer li span {
    font-size: 18px;
  }
}

.comming-soon .coming-soon-box .coming-soon-timer li.seperate-dot {
  color: #262A2A;
  width: auto;
  height: auto;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.comming-soon .coming-soon-box .coming-soon-timer li:last-child {
  margin-right: 0;
}

.comming-soon .coming-soon-box .subscribe-form {
  position: relative;
}

.comming-soon .coming-soon-box .subscribe-form .subscribe-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.comming-soon .coming-soon-box .subscribe-form input {
  font-size: 16px;
  font-weight: 600;
  height: 58px;
  padding-right: 130px;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .subscribe-form input {
    font-size: 14px;
  }
}

.comming-soon .coming-soon-box .subscribe-form input::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #6F6F6F;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .subscribe-form input::placeholder {
    font-size: 14px;
  }
}

.comming-soon .coming-soon-box .subscribe-form label {
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .comming-soon .coming-soon-box .subscribe-form label {
    font-size: 14px;
  }
}

.comming-soon .coming-soon-box .social-media-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-top: 30px;
}

@media (max-width: 1199.98px) {
  .comming-soon .coming-soon-box .social-media-icons {
    margin-top: 20px;
  }
}

.comming-soon .coming-soon-box .social-media-icons li {
  margin-right: 5px;
}

.comming-soon .coming-soon-box .social-media-icons li:last-child {
  margin-right: 0;
}

.comming-soon .coming-soon-box .social-media-icons li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  width: 32px;
  height: 32px;
  background-color: #323737;
  border-radius: 5px;
}

.comming-soon .coming-soon-box .social-media-icons li a:hover {
  background-color: #6F6F6F;
}

.comming-soon .coming-soon-box .social-media-icons li a i {
  color: #FFF;
}

.comming-shapes {
  position: relative;
}

@media (max-width: 991.98px) {
  .comming-shapes {
    display: none;
  }
}

.comming-shapes .comming-right-shape {
  position: fixed;
  top: 0;
  left: 0;
}

.comming-shapes .comming-left-shape {
  position: fixed;
  bottom: -5px;
  right: 0;
}

.comming-shapes img {
  width: 500px;
}

.account-page {
  background: #FFF;
}

.login-wrapper {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.login-wrapper.account-bg {
  /* background-image: url(../../../public/assets/img/authentication/login-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-wrapper.account-bg .login-content {
  background: rgba(255, 255, 255, 0.9490196078);
  backdrop-filter: blur(10px);
}

.login-wrapper.account-bg.register-bg {
  /* background-image: url(../../../public/assets/img/authentication/register-bg.jpg); */
}

.login-wrapper.account-bg.forgot-bg {
  /* background-image: url(../../../public/assets/img/authentication/forgot-bg.jpg); */
}

.login-wrapper.account-bg.reset-bg {
  /* background-image: url(../../../public/assets/img/authentication/reset-bg.jpg); */
}

.login-wrapper .login-content {
  width: 50%;
  height: 100vh;
  padding: 24px;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content {
    padding: 20px;
  }
}

.login-wrapper .login-content .login-logo {
  display: block;
  max-width: 150px;
  margin: auto;
  margin-bottom: 60px;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content .login-logo {
    margin-bottom: 40px;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content .login-logo {
    margin-bottom: 24px;
  }
}

.login-wrapper .login-content .success-login-logo {
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content .success-login-logo {
    margin-bottom: 24px;
  }
}

.login-wrapper .login-content .login-user-info .copyright-text {
  margin: 40px 0 0;
  text-align: center;
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content .login-user-info .copyright-text {
    margin: 0;
  }
}

.login-wrapper .login-content .login-user-info .copyright-text p {
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 0;
}

.login-wrapper .login-content .login-user-info .otp-box {
  margin-bottom: 34px;
}

.login-wrapper .login-content .login-user-info .otp-box input {
  width: 74px;
  height: 74px;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-weight: 700;
  font-size: 26px;
  color: #000;
  outline: none;
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content .login-user-info .otp-box input {
    width: 50px;
    height: 50px;
  }
}

.login-wrapper .login-content .login-user-info .otp-box input::placeholder {
  color: #74788d;
}

.login-wrapper .login-content .login-user-info .otp-box input:last-child {
  margin-right: 0px;
}

.login-wrapper .login-content .login-user-info .otp-expire {
  border-radius: 5px;
  background: #F3EDFF;
  padding: 3px 0;
  max-width: 150px;
  text-align: center;
  margin: 0 auto 24px;
}

.login-wrapper .login-content .login-user-info .otp-expire p {
  color: #4A00E5;
  font-size: 12px;
  font-weight: 400;
}

.login-wrapper .login-content .login-heading {
  margin: 0 0 24px;
}

.login-wrapper .login-content .login-heading h4 {
  font-size: 20px;
  margin: 0 0 10px;
}

.login-wrapper .login-content .login-heading p {
  font-weight: 400;
  color: #6F6F6F;
  max-width: 410px;
  margin: 0;
}

.login-wrapper .login-content .login-heading .verfy-mail-content {
  max-width: 400px;
  margin-bottom: 40px;
}

.login-wrapper .login-content .login-heading .welcome-content {
  margin-bottom: 24px;
}

.login-wrapper .login-content .login-heading i {
  font-size: 60px;
  color: #5CB85C;
  display: inline-block;
  margin-bottom: 24px;
}

.login-wrapper .login-content .form-wrap .btn {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
}

.login-wrapper .login-content .form-wrap .alreadyuser {
  margin-bottom: 15px;
}

.login-wrapper .login-content .form-wrap .alreadyuser h4 {
  font-size: 14px;
  font-weight: 400;
  color: #B8BCC9;
}

.login-wrapper .login-content .form-wrap .alreadyuser h4 a {
  color: #E41F07;
  font-weight: 600;
}

.login-wrapper .login-content .form-wrap .custom-control.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content .form-wrap .custom-control.custom-checkbox {
    justify-content: start;
  }
}

.login-wrapper .login-content .form-wrap .custom-control.custom-checkbox .custom-control-input {
  width: auto;
  height: auto;
}

.login-wrapper .login-content .form-wrap .custom-control.custom-checkbox .custom-control-label {
  margin-bottom: 0;
  margin-left: 8px;
}

.login-wrapper .login-content .form-wrap .forgot-link {
  position: relative;
  color: #E41F07;
  font-weight: 500;
}

.login-wrapper .login-content .form-wrap .forgot-link:after {
  content: "";
  height: 2px;
  width: 100%;
  background: #E41F07;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.login-wrapper .login-content .form-wrap .forgot-link:hover:after {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.login-wrapper .login-content .form-wrap .forgot-link:hover {
  color: #E41F07;
}

.login-wrapper .login-content .login-form {
  margin-bottom: 24px;
}

.login-wrapper .login-content .login-form h6 {
  font-size: 14px;
  font-weight: 400;
  color: #6F6F6F;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content .login-form h6 {
    font-size: 14px;
  }
}

.login-wrapper .login-content .login-form h6 a {
  color: #4A00E5;
  font-weight: 600;
}

.login-wrapper .login-content .form-set-login {
  text-align: center;
  position: relative;
  color: #B8BCC9;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 23px;
}

.login-wrapper .login-content .form-set-login h4 {
  position: relative;
  font-size: 14px;
  color: #6F6F6F;
  font-weight: 700;
}

.login-wrapper .login-content .form-set-login h4:after {
  content: "";
  background: #B8BCC9;
  width: 21px;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 200px;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content .form-set-login h4:after {
    height: 0px;
  }
}

.login-wrapper .login-content .form-set-login h4:before {
  content: "";
  background: #B8BCC9;
  width: 21px;
  height: 1px;
  position: absolute;
  top: 10px;
  right: 200px;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content .form-set-login h4:before {
    height: 0px;
  }
}

.login-wrapper .login-content .form-set-login.or-text {
  max-width: 365px;
  margin: auto;
}

.login-wrapper .login-content .toggle-password {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #262A2A;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.login-wrapper .login-content .toggle-password:hover {
  color: #E41F07;
}

.login-wrapper .login-content .toggle-passwords {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #262A2A;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.login-wrapper .login-content .toggle-passwords:hover {
  color: #E41F07;
}

.login-wrapper .login-content .toggle-password-new {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: #262A2A;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.login-wrapper .login-content .toggle-password-new:hover {
  color: #E41F07;
}

.login-wrapper .login-content .pass-group {
  position: relative;
  font-size: 14px;
}

.login-wrapper .login-content .login-social-link {
  margin-top: 24px;
}

.login-wrapper .login-content .login-social-link ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.login-wrapper .login-content .login-social-link ul li {
  text-align: center;
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content .login-social-link ul li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.login-wrapper .login-content .login-social-link ul li:last-child {
  padding-right: 0;
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content .login-social-link ul li:last-child {
    padding-right: 0;
  }
}

.login-wrapper .login-content .login-social-link ul li a {
  width: 100%;
  border: 1px solid #E8E8E8;
  background: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 10px;
  margin: 0 10px 0 0;
  padding: 15px 53px;
  font-weight: 500;
}

@media (max-width: 1199.98px) {
  .login-wrapper .login-content .login-social-link ul li a {
    margin: 0 10px 0 0;
    padding: 15px 50px;
  }
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content .login-social-link ul li a {
    margin: 0 10px 0 0;
    padding: 15px 46px;
  }
}

.login-wrapper .login-content .login-social-link ul li a:hover {
  border-color: #dbdbed;
}

.login-wrapper .login-content .login-social-link ul li a img {
  width: 24px;
  height: 24px;
}

.login-wrapper .login-content .login-social-link ul li .facebook-logo {
  background: #0092E4;
}

.login-wrapper .login-content .login-social-link ul li .facebook-logo:hover {
  background: #0071b1;
}

.login-wrapper .login-content .login-social-link ul li .apple-logo {
  background: #171724;
}

.login-wrapper .login-content .login-social-link ul li .apple-logo:hover {
  background: #323737;
}

.login-wrapper .login-content.user-login {
  width: 100%;
  padding: 0;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  height: auto;
  margin: 50px 0;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content.user-login {
    margin: 30px 0;
  }
}

.login-wrapper .login-content.user-login .login-user-info {
  background: #FFF;
  box-shadow: 0px 4px 54px 0px rgba(224, 224, 224, 0.2509803922);
  margin: 0;
  padding: 40px;
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  width: 100%;
  min-width: 490px;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content.user-login .login-user-info {
    padding: 30px;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content.user-login .login-user-info {
    padding: 24px;
    min-width: inherit;
  }
}

@media (max-width: 575.98px) {
  .login-wrapper .login-content.user-login .login-user-info {
    padding: 24px 24px 4px;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content.user-login .login-user-inner {
    padding-bottom: 24px;
  }
}

.login-wrapper .login-content.user-login .login-logo {
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-content.user-login .login-logo {
    margin-bottom: 40px;
  }
}

@media (max-width: 767.98px) {
  .login-wrapper .login-content.user-login .login-logo {
    margin-bottom: 24px;
  }
}

.login-wrapper .login-img {
  width: 50%;
  background: #FFEEEC;
}

@media (max-width: 991.98px) {
  .login-wrapper .login-img {
    display: none;
  }
}

.login-wrapper .login-img img {
  width: 100%;
  height: 100vh;
}

.login-wrapper .login-user-info .login-logo.login-info {
  display: block;
  max-width: 150px;
  margin: auto;
}

.login-wrapper .login-logo {
  max-width: 150px;
  margin: auto;
}

.form-wrap .form-wrap-icon {
  position: relative;
}

.form-wrap .form-wrap-icon i {
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.form-wrap-checkbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px;
}

.form-wrap-checkbox .check {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #262A2A;
  margin-bottom: 0;
}

.form-wrap-checkbox .check input {
  display: none;
}

.form-wrap-checkbox .check input:checked+.box {
  background: #FFA201;
}

.form-wrap-checkbox .check input:checked+.box:after {
  top: 0;
}

.form-wrap-checkbox .check .box {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 8px;
  transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  background: #F9F9FC;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.form-wrap-checkbox .check .box:after {
  width: 12px;
  height: 6px;
  content: "";
  position: absolute;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: #FFF;
  transform: rotate(-45deg) translate3d(0, 0, 0);
  transform-origin: center center;
  transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  left: 0;
  right: 0;
  top: 200%;
  bottom: 3px;
  margin: auto;
}

.hover-a {
  position: relative;
  color: #4A00E5;
}

.hover-a:after {
  content: "";
  height: 2px;
  width: 100%;
  background: #4A00E5;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.hover-a:hover:after {
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.hover-a:hover {
  color: #4A00E5;
}

.login-wrapper.login-new {
  overflow: auto;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
}

.login-wrapper.login-new .login-shapes {
  position: relative;
}

@media (max-width: 991.98px) {
  .login-wrapper.login-new .login-shapes {
    display: none;
  }
}

.login-wrapper.login-new .login-shapes .login-right-shape {
  position: fixed;
  top: 0;
  left: 0;
}

.login-wrapper.login-new .login-shapes .login-left-shape {
  position: fixed;
  bottom: 0;
  right: 0;
}

.login-wrapper.login-new .login-content.user-login {
  position: relative;
}

.login-wrapper.login-new .login-content.user-login .login-userset form .otp-box {
  margin-bottom: 34px;
}

.login-wrapper.login-new .login-content.user-login .login-userset form .otp-box input {
  width: 74px;
  height: 74px;
  background: #FFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-weight: 700;
  font-size: 26px;
  color: #000;
  outline: none;
}

@media (max-width: 575.98px) {
  .login-wrapper.login-new .login-content.user-login .login-userset form .otp-box input {
    width: 40px;
    height: 40px;
  }
}

.login-wrapper.login-new .copyright-text {
  margin: 40px 0;
  text-align: center;
}

@media (max-width: 767.98px) {
  .login-wrapper.login-new .copyright-text {
    margin: 0 0 30px;
  }
}

.login-wrapper.login-new .copyright-text p {
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 0;
}

.login-wrapper.login-new .lock-screen-list {
  text-align: center;
}

.login-wrapper.login-new .lock-screen-list .nav {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.login-wrapper.login-new .lock-screen-list .nav li {
  margin-right: 20px;
}

.login-wrapper.login-new .lock-screen-list .nav li:last-child {
  margin-right: 0;
}

.login-wrapper.login-new .lock-screen-list .nav li a {
  font-weight: 500;
}

.login-wrapper.login-new .lock-screen-list .copyright-text {
  margin: 20px 0 40px;
}

@media (max-width: 767.98px) {
  .login-wrapper.login-new .lock-screen-list .copyright-text {
    margin: 20px 0 30px;
  }
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-toggle {
  border: 0;
  padding: 0;
  border-radius: 5px;
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #6F6F6F;
  border-right: 2px solid #6F6F6F;
  content: "";
  display: inline-block;
  pointer-events: none;
  width: 8px;
  height: 8px;
  vertical-align: 2px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-toggle[aria-expanded=true]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-menu {
  padding: 15px;
  background: #FFF;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  border: 1px solid #FFF6E6;
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px;
  background: #FFF;
  border-radius: 5px;
  display: block;
}

.login-wrapper.login-new .lock-screen-list .language-dropdown .dropdown-menu .dropdown-item:hover {
  background: #F6F6F6;
}

.copyright-text {
  margin: 40px 0;
  text-align: center;
}

@media (max-width: 767.98px) {
  .copyright-text {
    margin: 0;
  }
}

.copyright-text p {
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 0;
}

.lock-screen-profile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgba(145, 158, 171, 0.3019607843);
}

.lock-screen-profile h6 {
  font-size: 18px;
  font-weight: 600;
  color: #262A2A;
  margin-top: 24px;
  margin-bottom: 0;
}

.settings-menu .nav li {
  margin-right: 24px;
}

.settings-menu .nav li:last-child {
  margin-right: 0;
}

.settings-menu .nav li a {
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-bottom: 24px;
  border-bottom: 4px solid transparent;
}

.settings-menu .nav li a.active {
  color: #E41F07;
  border-bottom: 4px solid #E41F07;
}

.settings-menu .nav li a i {
  font-size: 16px;
  margin-right: 8px;
}


.flex-wrap {
  flex-wrap: wrap;
}

.ml-auto {
  float: right;
}

.no-wrap {

  flex-wrap: nowrap !important;
}

.settings-sidebar h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
}

@media (max-width: 767.98px) {
  .settings-sidebar h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.settings-sidebar ul li a {
  font-weight: 500;
  color: #6F6F6F;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  position: relative;
}

.settings-sidebar ul li a.active {
  background: #F6F6F6;
  color: #262A2A;
}

.settings-sidebar ul li a.active:before {
  position: absolute;
  content: "\f054";
  font-family: "FontAwesome";
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.settings-sidebar ul li a:hover {
  color: #262A2A;
}

.settings-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 24px;
}

.settings-header h4 {
  font-size: 20px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .settings-header h4 {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  .settings-header {
    margin-bottom: 15px;
  }
}

.settings-sub-header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.settings-sub-header h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.settings-sub-header p {
  color: #6F6F6F;
  margin-bottom: 0;
}

.profile-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.profile-upload .profile-upload-img {
  width: 100px;
  min-width: 100px;
  height: 100px;
  position: relative;
  margin-right: 15px;
  border-radius: 5px;
  border: 2px dashed #E8E8E8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.profile-upload .profile-upload-img span {
  color: #6F6F6F;
  position: absolute;
}

.profile-upload .profile-upload-content .profile-upload-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: #4A00E5;
  background: #F3EDFF;
  border: 1px solid #F3EDFF;
  border-radius: 5px;
  padding: 8px 10px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.5s;
}

.profile-upload .profile-upload-content .profile-upload-btn:hover {
  color: #FFF;
  background: #4A00E5;
  border: 1px solid #4A00E5;
  transition: all 0.5s;
}

.profile-upload .profile-upload-content .profile-upload-btn i {
  font-size: 16px;
  margin-right: 8px;
}

.profile-upload .profile-upload-content .profile-upload-btn input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.profile-upload .profile-upload-content p {
  font-weight: 500;
  color: #6F6F6F;
}

.profile-upload .preview1 {
  display: none;
}

.profile-upload .it {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  display: block;
  position: relative;
  background: #FFF;
  object-fit: cover;
}

.profile-upload .profile-remove {
  display: none;
}

.profile-upload .profile-remove-btn {
  width: 14px;
  height: 14px;
  font-size: 10px;
  color: #FFF;
  background: #FC0027;
  border: 1px solid #FC0027;
  border-radius: 5px;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 8px;
}

.profile-upload .profile-remove-btn:hover {
  background: #c9001f;
  border: 1px solid #c9001f;
}

.profile-details {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 24px;
}

.security-grid {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .security-grid {
    margin: 0 0 15px;
    padding: 15px;
  }
}

.security-grid .security-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 5px;
}

.security-grid .security-heading h5 {
  font-size: 14px;
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 0;
}

.security-grid .security-content p {
  margin-bottom: 24px;
}

.security-grid .security-content p span {
  color: #262A2A;
}

.security-grid .security-content .badge-light-success {
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
}

.security-grid .security-btn .btn {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 14px;
  border-radius: 5px;
}

@media (max-width: 767.98px) {
  .security-grid .security-btn .btn {
    padding: 3px 15px;
  }
}

.security-grid .security-btn .btn-remove {
  color: #FC0027;
  margin-left: 15px;
  padding: 0;
}

.security-grid .security-btn .btn-remove:hover {
  color: #c9001f;
}

.security-grid .badge-light-success {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  background-color: #E0F5D7;
  border-radius: 5px;
  color: #5CB85C;
  padding: 5px 4px;
}

.notification-wrap {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 24px;
}

.notification-wrap ul {
  margin-bottom: 24px;
}

.notification-wrap ul li {
  margin-bottom: 15px;
}

.notification-wrap ul li .security-checkbox .checkboxs {
  font-weight: 500;
  color: #262A2A;
  padding: 0 25px 0 0;
}

.notification-wrap ul li .security-checkbox .checkboxs .checkmarks {
  right: 0;
  left: auto;
}

.notification-wrap ul li:last-child {
  margin-bottom: 0;
}

.notificaion-table .table thead tr:first-child th {
  padding-top: 0;
}

.notificaion-table .table thead tr th {
  font-size: 16px;
  padding: 10px;
}

.notificaion-table .table tbody tr td {
  font-weight: 500;
  color: #262A2A;
  padding: 10px;
}

.notificaion-table .table tbody tr:last-child td {
  padding-bottom: 0;
}

.integration-grid {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
  position: relative;
}

@media (max-width: 767.98px) {
  .integration-grid {
    padding: 15px;
  }
}

.integration-grid .integration-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 24px;
}

.integration-grid .integration-calendar .connect-btn a {
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #E8E8E8;
  color: #262A2A;
  background-color: #FFF;
}

.integration-grid .integration-calendar .connect-btn a.connected {
  border: 1px solid #E0F5D7;
  color: #5CB85C;
  background-color: #E0F5D7;
}

.integration-grid .integration-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.integration-grid .integration-content p {
  margin-bottom: 0;
}

.storage-wrap {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

@media (max-width: 767.98px) {
  .storage-wrap {
    padding: 15px;
  }
}

.storage-wrap .storage-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.storage-wrap .storage-icon .system-app-icon {
  width: 52px;
  height: 52px;
  background: #FAFAFA;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
}

.storage-wrap .storage-icon h6 {
  font-weight: 600;
}

.storage-wrap .setting-gateway {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.storage-wrap .setting-gateway a {
  margin-right: 24px;
}

.storage-wrap .setting-gateway a i {
  font-size: 20px;
}

.storage-wrap.prefer-wrap .storage-icon img {
  margin-right: 15px;
}

.mod-toggle .status-toggle {
  margin-right: 20px;
}

.ip-wrap {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

@media (max-width: 767.98px) {
  .ip-wrap {
    padding: 15px;
  }
}

.ip-wrap .ip-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ip-wrap .ip-icon a {
  color: #6F6F6F;
  margin-right: 8px;
}

.ip-wrap .ip-icon a:hover {
  color: #6F6F6F;
}

.ip-wrap .ip-icon h6 {
  font-weight: 400;
}

.ip-wrap .table-action .action-icon {
  background-color: #FFF;
}

.ip-wrap .table-action .action-icon:hover {
  background-color: #F6F6F6;
}

.btn-add {
  width: 32px;
  height: 32px;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #262A2A;
}

.btn-add:hover {
  background: #00918E;
  color: #FFF;
}

.mail-wrapper {
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  margin: 0 0 24px;
  padding: 24px;
  position: relative;
}

.mail-wrapper .mail-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

@media (max-width: 767.98px) {
  .mail-wrapper .mail-wrap {
    display: block;
    padding: 15px;
  }
}

.mail-wrapper .mail-wrap .mail-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
}

@media (max-width: 767.98px) {
  .mail-wrapper .mail-wrap .mail-item {
    width: 100%;
    margin-bottom: 15px;
  }
}

.mail-wrapper .mail-wrap .mail-item .mail-img {
  width: 50px;
  height: 50px;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-right: 10px;
}

.mail-wrapper .mail-wrap .mail-item .mail-img.lg-img {
  width: 80px;
  height: 40px;
}

.mail-wrapper .mail-wrap .mail-item h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.mail-wrapper .mail-wrap .mail-item .mail-info a {
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #F3EDFF;
  color: #4A00E5;
  background-color: #F3EDFF;
}

.mail-wrapper .mail-wrap .mail-item .mail-info a.connected {
  border: 1px solid #E0F5D7;
  color: #5CB85C;
  background-color: #E0F5D7;
}

.mail-wrapper .mail-wrap .email-action {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
}

@media (max-width: 767.98px) {
  .mail-wrapper .mail-wrap .email-action {
    width: 100%;
  }
}

.mail-wrapper .mail-wrap .email-action .info-icon {
  position: relative;
  margin-right: 15px;
  padding-right: 15px;
  color: #6F6F6F;
}

.mail-wrapper .mail-wrap .email-action .info-icon::after {
  content: "";
  width: 1px;
  height: 25px;
  background: #E8E8E8;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.mail-wrapper .mail-wrap .email-action .info-icon:hover {
  color: #262A2A;
}

.mail-wrapper .mail-wrap .email-action .btn {
  padding: 9px 16px;
  font-weight: 600;
  border-radius: 5px;
}

.mail-wrapper .mail-wrap .email-action .btn i {
  margin-right: 5px;
}

.mail-wrapper .mail-collapse {
  border-top: 1px solid #E8E8E8;
  padding-top: 24px;
  margin-top: 24px;
}

.settings-form .setting-title {
  margin-bottom: 15px;
}

.settings-form .setting-title h6 {
  font-size: 14px;
  font-weight: 500;
}

.settings-form .setting-title p {
  font-size: 12px;
}

.settings-form .form-wrap {
  margin-bottom: 15px;
}

.settings-form .submit-button {
  margin-top: 14px;
}

.settings-sub-header.setting-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}

.settings-sub-header.setting-item .table-action .action-icon {
  background-color: #FFF;
}

.settings-sub-header.setting-item .table-action .action-icon:hover {
  background-color: #F6F6F6;
}

.bank-box {
  background: #F9F9FC;
  border-radius: 5px;
  border: 1px solid #F9F9FC;
  position: relative;
  margin: 0 0 24px;
  padding: 40px;
}

@media (max-width: 991.98px) {
  .bank-box {
    margin: 0 0 24px;
    padding: 20px;
  }
}

.bank-box.active {
  border-color: #5CB85C;
}

.bank-box.active::after {
  position: absolute;
  top: -8px;
  right: -8px;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #6CBF6C;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.bank-box .bank-header .bank-name {
  margin-bottom: 40px;
}

.bank-box .bank-header .bank-name h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.bank-box .bank-header .bank-name p {
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .bank-box .bank-header .bank-name {
    margin-bottom: 20px;
  }
}

.bank-box .bank-info h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.bank-box .bank-info p {
  font-size: 12px;
  font-weight: 400;
}

.bank-box .table-action .action-icon {
  background-color: transparent;
}

.bank-box .table-action .action-icon:hover {
  background-color: #F6F6F6;
}

.inv-days .select2-container {
  min-width: 62px !important;
}

.inv-days p {
  color: #000;
  margin-left: 10px;
}

.round-off .status-toggle {
  margin-right: 10px;
}

.lang-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.lang-type li {
  margin-right: 5px;
}

.lang-type li a {
  background-color: #F6F6F6;
  border-radius: 5px;
  color: #6F6F6F;
  font-weight: 500;
  padding: 8px 15px;
  display: inline-block;
}

.lang-type li a:hover {
  background-color: #00918E;
  color: #FFF !important;
}

.theme-type-images {
  flex-wrap: wrap;
}

.theme-type-images .theme-image {
  background: #FFF;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  padding: 10px;
  margin: 0 24px 15px 0;
  text-align: center;
}

.theme-type-images .theme-image img {
  width: 150px;
}

@media (max-width: 1399.98px) {
  .theme-type-images .theme-image img {
    width: 130px;
  }
}

.theme-type-images .theme-image:last-child {
  margin-right: 0;
}

.theme-type-images .theme-image span {
  font-weight: 500;
  font-size: 14px;
  color: #262A2A;
  margin-top: 10px;
  display: block;
}

.theme-type-images .theme-image.active {
  border-color: #E41F07;
}

.theme-type-images .theme-image.active span {
  color: #E41F07;
}

.theme-colors ul {
  display: flex;
  align-items: center;
}

.theme-colors ul li {
  margin-right: 10px;
}

.theme-colors ul li span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E41F07;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.theme-colors ul li span.active {
  background-color: #FCE9E6;
}

.theme-colors ul li span.active:after {
  content: "";
  width: 25px;
  height: 25px;
  background-color: #E41F07;
  border-radius: 50%;
  opacity: 1;
}

.theme-colors ul li span.theme-secondary {
  background-color: #FFA201;
}

.theme-colors ul li span.theme-secondary.active {
  background-color: #FFF6E6;
}

.theme-colors ul li span.theme-secondary.active:after {
  background-color: #FFA201;
}

.theme-colors ul li span.theme-violet {
  background-color: #3C2371;
}

.theme-colors ul li span.theme-violet.active {
  background-color: rgba(60, 35, 113, 0.1);
}

.theme-colors ul li span.theme-violet.active:after {
  background-color: #3C2371;
}

.theme-colors ul li span.theme-blue {
  background-color: #0092E4;
}

.theme-colors ul li span.theme-blue.active {
  background-color: #E6F4FC;
}

.theme-colors ul li span.theme-blue.active:after {
  background-color: #0092E4;
}

.theme-colors ul li span.theme-brown {
  background-color: #4A00E5;
}

.theme-colors ul li span.theme-brown.active {
  background-color: #F3EDFF;
}

.theme-colors ul li span.theme-brown.active:after {
  background-color: #4A00E5;
}

.theme-colors ul li:last-child {
  margin-right: 0;
}

.lang-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.lang-flag {
  margin-right: 15px;
}

.lang-flag a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #262A2A;
  font-weight: 500;
  padding: 8px 8px;
}

.lang-flag a img {
  margin-right: 8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.lang-direct {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.lang-direct li {
  margin: 0 8px 0 0;
}

.lang-direct li a {
  background-color: #9B9B9B;
  color: #262A2A;
  border-radius: 5px;
  padding: 8px 15px;
  display: inline-block;
}

.lang-direct li a:hover,
.lang-direct li a.active {
  background-color: #3C2371;
  color: #FFF;
}

.lang-direct li:last-child {
  margin-right: 0;
}

.translate-wrap h6 {
  font-weight: 500;
  margin-bottom: 15px;
}

.translate-wrap .form-wrap {
  margin-bottom: 15px;
}

.translate-wrap.translate-title h6 {
  font-size: 16px;
  font-weight: 600;
}

.translate-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.translate-item .btn,
.translate-item .lang-flag {
  margin-bottom: 24px;
}

.lang-flag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.lang-progress {
  margin-bottom: 24px;
}

.lang-progress p {
  font-weight: 500;
}

.lang-progress .progress {
  min-width: 100px;
  max-width: 150px;
  height: 7px;
}

.black-text {
  color: #262A2A !important;
}

.settings-tab .card-body {
  padding-top: 0;
}

@media (max-width: 767.98px) {
  .settings-tab .card-body {
    padding-top: 16px;
  }
}

.settings-tab .card-body li a {
  padding-top: 24px;
}

@media (max-width: 767.98px) {
  .settings-tab .card-body li a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.statistic-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 10px;
}

.statistic-header h4 {
  font-size: 18px;
  font-weight: 600;
  color: #262A2A;
  margin-bottom: 14px;
}

.statistic-header .statistic-dropdown {
  margin-bottom: 14px;
}

.statistic-header .statistic-dropdown .dropdown-toggle {
  color: #262A2A;
  border: 1px solid #E8E8E8;
  padding: 6px 10px;
  border-radius: 5px;
  display: inline-block;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
}

.statistic-header .statistic-dropdown .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #262A2A;
  border-right: 2px solid #262A2A;
  content: "";
  display: inline-block;
  pointer-events: none;
  width: 8px;
  height: 8px;
  vertical-align: 2px;
  transform-origin: 66% 66%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.statistic-header .statistic-dropdown .dropdown-toggle[aria-expanded=true]:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
}

.statistic-header .statistic-dropdown .dropdown-menu {
  padding: 15px;
  background: #FFF;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  border: 1px solid #C0C0C0;
}

.statistic-header .statistic-dropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px;
  color: #323737;
  background: #FFF;
  border-radius: 5px;
}

.statistic-header .statistic-dropdown .dropdown-menu .dropdown-item:hover {
  color: #262A2A;
  background: #F6F6F6;
}

.statistic-header .important-notification a {
  color: #262A2A;
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  line-height: normal;
}

.statistic-header .important-notification a:hover {
  color: #E41F07;
}

.statistic-header .important-notification a i {
  margin-left: 4px;
}

.report-header {
  padding-bottom: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.source-report {
  color: #00918E;
  font-weight: 500;
}

.card.report-card .card-body {
  padding: 24px;
}

@media (max-width: 767.98px) {
  .card.report-card .card-body {
    padding: 20px;
  }
}

.card.report-card-table {
  background: transparent;
  box-shadow: none;
  margin-bottom: 0;
}

.card.report-card-table .card-body {
  padding: 0;
}

.time-text p {
  font-weight: 500;
  white-space: nowrap;
  margin-left: 15px;
}

.activity-title {
  margin-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity-title h4 {
  font-weight: 600;
  margin: 0 15px 10px 0;
  padding: 0 15px 0 0;
  position: relative;
}

.activity-title h4::after {
  content: "";
  background-color: #E8E8E8;
  width: 1px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 767.98px) {
  .activity-title h4::after {
    content: none;
  }
}

@media (max-width: 767.98px) {
  .activity-title {
    display: block;
  }
}

.active-list ul {
  margin-bottom: 10px;
}

.active-list ul li {
  display: inline-block;
  margin-right: 10px;
}

.active-list ul li a {
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #262A2A;
}

.active-list ul li a:hover,
.active-list ul li a.active {
  background-color: #00918E;
  border-color: #00918E;
  color: #FFF;
}

.active-list ul li:last-child {
  margin-right: 0;
}

.radio-activity li {
  display: inline-block;
}

.radio-activity li .active-type input[type=radio] {
  display: none;
}

.radio-activity li .active-type input[type=radio]~label {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  margin: 0 10px 5px 0;
  padding: 8px 20px;
  border-radius: 5px;
  color: #262A2A;
  cursor: pointer;
}

.radio-activity li .active-type input[type=radio]~label i {
  margin-right: 5px;
}

.radio-activity li .active-type input[type=radio]:checked~label {
  border-color: #00918E;
  color: #00918E;
}

.radio-activity li:last-child {
  margin-right: 0;
}

.label-add {
  color: #E41F07;
  margin-bottom: 5px;
  font-weight: 500;
}

.label-add i {
  margin-right: 8px;
}

.label-add:hover {
  color: #b31805;
}

.tab-activity {
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 15px 15px 0;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
}

.tab-activity ul li {
  display: inline-block;
  margin-right: 20px;
}

.tab-activity ul li a {
  padding: 10px 5px 10px;
  font-weight: 500;
  border-bottom: 4px solid #FFF;
  display: inline-block;
}

.tab-activity ul li a span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #E8E8E8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: 10px;
  color: #262A2A;
  font-weight: 600;
  font-size: 10px;
}

.tab-activity ul li a.active {
  color: #E41F07;
  border-color: #E41F07;
}

.comment-wrap {
  margin: 0 0 15px;
  padding: 15px;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.comment-wrap h6 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #6F6F6F;
}

.comment-wrap p span {
  color: #4A00E5;
}

.comment-wrap:last-child {
  margin-bottom: 0;
}

.activity-badge {
  color: #FFF;
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 5px;
}

.activity-badge i {
  margin-right: 5px;
}

.title-head {
  font-weight: 500;
  color: #262A2A;
}

.tasks-activity.tasks>ul {
  margin-top: 15px;
}

.tasks-activity.tasks>ul .task-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: wrap;
}

.tasks-activity.tasks>ul .task-wrap.pending::before {
  background-color: #676DFF;
}

.tasks-activity.tasks>ul .task-wrap.warning::before {
  background-color: #FDA700;
}

.tasks-activity.tasks>ul .task-wrap.success::before {
  background-color: #5CB85C;
}

.tasks-activity.tasks>ul .task-wrap .task-info .task-icon {
  margin: 0 20px 10px 0;
}

.tasks-activity.tasks>ul .task-wrap .task-info .task-checkbox {
  margin: 0 20px 10px 0;
}

.tasks-activity.tasks>ul .task-wrap .task-info .task-checkbox .checkboxs .checkmarks {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.tasks-activity.tasks>ul .task-wrap .task-info .set-star {
  margin: 0 20px 10px 0;
}

.tasks-activity.tasks>ul .task-wrap .task-info p {
  font-weight: 500;
}

.tasks-activity.tasks>ul .task-wrap .task-info .badge {
  font-weight: 600;
  font-size: 12px;
  margin: 0 5px 10px 0;
  padding: 5px 7px;
  color: #FFF;
}

.tasks-activity.tasks>ul .task-wrap .task-info .badge:last-child {
  margin-right: 0;
}

.tasks-activity.tasks>ul .task-wrap .task-actions ul li {
  margin: 0 10px 10px 0;
}

.tasks-activity.tasks>ul .task-wrap .task-actions ul li .badge-tag {
  padding: 5px 4px;
}

.tasks-activity.tasks>ul .task-wrap .task-actions ul li:last-child {
  margin-right: 0;
}

.all-read .checkboxs .checkmarks {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.task-drops .dropdown-toggle {
  border: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  box-shadow: none;
}

.task-drops .dropdown-toggle.show {
  border: 0;
}

@media (max-width: 767.98px) {
  .task-drops .dropdown-toggle {
    font-size: 16px;
  }
}

.task-wrapper {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.task-wrapper .tasks-activity.tasks>ul {
  margin-bottom: 15px;
}

.task-wrapper:last-child {
  padding-bottom: 0;
}

.task-wrapper:last-child .tasks-activity.tasks>ul {
  margin-bottom: 0;
}

.task-wrapper .task-accordion {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  display: block;
}

@media (max-width: 767.98px) {
  .task-wrapper .task-accordion h4 {
    font-size: 16px;
  }
}

.task-wrapper .task-accordion span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #E8E8E8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin-left: 10px;
  color: #262A2A;
  font-weight: 600;
  font-size: 10px;
}

.task-wrapper .task-accordion::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-size: 14px;
  font-weight: 600;
}

.task-wrapper .task-accordion:not(.collapsed)::before {
  content: "\f078";
}

.task-wrapper:last-child {
  border: 0;
  margin-bottom: 0;
}

.card.analytics-card {
  background: #FFF;
  padding: 24px;
  border-color: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922);
}

.card.analytics-card .card-header {
  padding: 0;
  background: #FFF;
  border: 0;
  margin-bottom: 14px;
}

@media (max-width: 575.98px) {
  .card.analytics-card .card-header {
    flex-direction: column;
    align-items: start !important;
  }
}

.card.analytics-card .card-header h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.card.analytics-card .card-header h3 i {
  margin-right: 5px;
}

.card.analytics-card .card-header h3 a {
  color: #262A2A;
  margin-left: 5px;
}

@media (max-width: 575.98px) {
  .card.analytics-card .card-header h3 {
    font-size: 16px;
  }
}

.card.analytics-card .card-header .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 14px;
  font-weight: 500;
  color: #262A2A;
}

.card.analytics-card .card-header .select2-results__option {
  font-size: 14px;
}

.card.analytics-card .card-header .card-select ul li {
  display: inline-block;
  margin: 0 10px 10px 0;
}

.card.analytics-card .card-header .card-select ul li .btn i {
  margin-right: 4px;
}

.card.analytics-card .card-body {
  padding: 0;
}

.card.analytics-card .card-body .table th {
  padding: 10px;
  background: #F9F9FC;
  color: #262A2A;
}

.card.analytics-card .card-body .table th:first-child {
  border-left: 1px solid #FFF6E6;
}

.card.analytics-card .card-body .table th:last-child {
  border-right: 1px solid #FFF6E6;
}

.card.analytics-card .card-body .table td:first-child {
  border-left: 1px solid #FFF6E6;
}

.card.analytics-card .card-body .table td:last-child {
  border-right: 1px solid #FFF6E6;
}

.activities-list ul li {
  background: #F6F6F6;
  padding: 15px 10px;
  margin-bottom: 20px;
}

.activities-list ul li:last-child {
  margin-bottom: 0;
}

.activities-list ul li .activity-name h5 {
  font-size: 14px;
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.activities-list ul li .activity-name p {
  font-size: 14px;
  margin-bottom: 15px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.activities-list ul li .activity-name .badge {
  color: #FFF;
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 5px;
}

.activities-list ul li .activity-name .badge i {
  margin-right: 5px;
}

.activities-list ul li .user-activity {
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .activities-list ul li .user-activity {
    margin: 20px 0;
  }
}

.activities-list ul li .user-activity span {
  flex-shrink: 0;
  display: inline-flex;
  margin-right: 5px;
}

.activities-list ul li .user-activity span img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.activities-list ul li .user-activity h6 {
  margin-bottom: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

@media (max-width: 575.98px) {
  .card-select ul {
    flex-direction: column;
    align-items: start !important;
  }
}

.campaign-card {
  overflow-x: auto;
}

.campaign-card ul {
  width: max-content;
}

.campaign-card ul .campaign-wrap {
  background-color: #F9F9FC;
  margin: 0 0 15px;
  padding: 15px 15px 5px;
}

.campaign-card ul .campaign-wrap .campaign-info {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  white-space: nowrap;
  border-bottom: 1px solid #E8E8E8;
  margin: 0 0 15px 0;
}

.campaign-card ul .campaign-wrap .campaign-info h5 {
  font-weight: 600;
  margin-bottom: 5px;
}

.campaign-card ul .campaign-wrap .campaign-info .campaign-name {
  margin: 0 50px 10px 0;
}

.campaign-card ul .campaign-wrap .campaign-info .list-progress {
  margin-bottom: 10px;
}

.campaign-card ul .campaign-wrap .campaign-action {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
}

.campaign-card ul .campaign-wrap .campaign-action .project-mem {
  margin-bottom: 10px;
}

.campaign-card ul .campaign-wrap .campaign-date {
  margin-bottom: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.campaign-card ul .campaign-wrap .campaign-date .badge {
  margin: 0 15px 0 0;
}

.campaign-card ul .campaign-wrap:last-child {
  margin-bottom: 0;
}

.priority.badge-tag {
  color: #262A2A;
  min-width: 80px;
  text-align: left;
  font-weight: 600;
}

.priority.badge-tag i {
  margin-right: 4px;
}

.priority.badge-tag.badge-danger-light i {
  color: #FC0027;
}

.priority.badge-tag.badge-success-light i {
  color: #5CB85C;
}

.priority.badge-tag.badge-warning-light i {
  color: #FDA700;
}

.contact-grid.project {
  padding: 15px;
}

.contact-grid.project .status-item {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 15px;
}

.contact-grid.project .grid-head {
  background-color: #F9F9FC;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 10px;
}

.contact-grid.project .grid-head .users-profile .con-avatar {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border: 1px solid #E8E8E8;
  background-color: #FFF;
}

.contact-grid.project .grid-head .users-profile .name-user h6 {
  font-size: 16px;
  font-weight: 600;
}

.contact-grid.project .grid-body p {
  margin-bottom: 15px;
}

.contact-grid.project .grid-body .client-wrap {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-bottom: 15px;
}

.contact-grid.project .grid-body .client-wrap .company-item {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.contact-grid.project .grid-body .client-wrap .company-item span {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  border: 1px solid #E8E8E8;
  background-color: #FFF;
  margin-right: 8px;
}

.contact-grid.project .grid-body .client-wrap .company-item p {
  font-weight: 500;
  margin-bottom: 0;
}

.rate-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  color: #FF9D0A;
}

.grid-timeline {
  background-color: #F3EDFF;
  color: #4A00E5;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px;
}

.grid-timeline i {
  margin-right: 8px;
}

.msg-list li {
  display: inline-block;
  margin-right: 5px;
}

.msg-list li i {
  margin-right: 5px;
}

.msg-list li:last-child {
  margin-right: 0;
}

.task-wrap {
  border: 1px solid #E8E8E8;
  margin: 0 0 10px;
  padding: 15px 15px 5px;
  position: relative;
  border-radius: 5px;
}

.task-wrap::before {
  content: "";
  background-color: #FC0027;
  width: 3px;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.task-wrap:last-child {
  margin-bottom: 0;
}

.task-info {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
}

.task-info p {
  display: inline-block;
  margin: 0 24px 10px 0;
  color: #262A2A;
}

.task-info p i {
  color: #6F6F6F;
  margin-right: 10px;
}

.task-info .badge {
  margin: 0 10px 10px 0;
}

.task-info .badge:last-child {
  margin-right: 0;
}

.task-info .badge.badge-pill i {
  margin-right: 5px;
}

.task-actions {
  float: right;
}

.task-actions ul {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
}

.task-actions ul li {
  margin: 0 24px 10px 0;
  color: #262A2A;
}

.task-actions ul li:last-child {
  margin-right: 0;
}

.task-actions ul li.task-time i {
  margin-right: 5px;
}

.task-actions ul li.task-date i {
  margin-right: 5px;
}

.task-actions ul li.task-owner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

.task-actions ul li.task-owner .task-user {
  margin-right: 10px;
}

.task-actions ul li.task-owner .task-user img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

@media (max-width: 767.98px) {
  .task-actions {
    float: left;
  }
}

.select-priority {
  position: relative;
}

.select-priority .select-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 99999;
  color: #E41F07;
}

.select-priority .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 30px;
}

.project-name {
  margin-bottom: 15px;
}

.project-name p {
  font-weight: 500;
  margin-bottom: 5px;
}

.project-name h4 {
  font-weight: 600;
}

.con-sidebar-title .priority {
  margin-bottom: 15px;
}

.con-sidebar-title p {
  font-weight: 500;
  color: #262A2A;
  margin-bottom: 15px;
}

.con-sidebar-title.border-line {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 20px;
}

.kanban-wrapper {
  display: flex;
  overflow-x: auto;
  align-items: self-start;
  margin-bottom: 24px;
}

.kanban-wrapper .kanban-list-items {
  border: 1px solid #E8E8E8;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
  border-radius: 5px;
  margin: 0 20px 20px 0;
  padding: 15px;
}

.kanban-wrapper .kanban-list-items:last-child {
  margin-right: 0;
}

.kanban-wrapper .kanban-list-items .kanban-list-head {
  background: #FFF;
  padding: 15px;
  border-radius: 5px;
  min-width: 285px;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .action-icon::after {
  display: none;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head h5:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head span {
  font-size: 14px;
  font-weight: 500;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head.dot-success h5:before {
  background: #5CB85C;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head.dot-info h5:before {
  background: #1ECBE2;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head.dot-warning h5:before {
  background: #FDA700;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head.dot-purple h5:before {
  background: #4A00E5;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-title-head.dot-danger h5:before {
  background: #FC0027;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-action-btns .plus-btn {
  color: #4A00E5;
  margin-right: 10px;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-action-btns .plus-btn:hover {
  color: #E41F07;
}

.kanban-wrapper .kanban-list-items .kanban-list-head .kanban-action-btns .table-action .action-icon {
  background-color: #FFF;
}

.kanban-wrapper .kanban-list-items .kanban-drag-wrap {
  padding-top: 24px;
}

.kanban-card {
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922);
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 15px;
  min-width: 285px;
  margin-bottom: 15px;
}

.kanban-card .kanban-card-head .bar-design {
  display: block;
  width: 100%;
  height: 4px;
}

.kanban-card .kanban-card-head .bar-design.bg-purple {
  background: #4A00E5;
}

.kanban-card .kanban-card-head .kanban-card-title {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.kanban-card .kanban-card-head .kanban-card-title span {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background: #E0E2E7;
  margin-right: 8px;
}

.kanban-card .kanban-card-head .kanban-card-title h6 {
  font-size: 14px;
  font-weight: 500;
  max-width: 143px;
}

.kanban-card .kanban-card-body ul {
  margin-bottom: 15px;
}

.kanban-card .kanban-card-body ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #6F6F6F;
}

.kanban-card .kanban-card-body ul li i {
  margin-right: 8px;
}

.kanban-card .kanban-card-body ul li:last-child {
  margin-bottom: 0;
}

.kanban-card .kanban-card-body .badge {
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding: 7px 6px;
  color: #FFF;
}

.kanban-card .kanban-card-body .badge i {
  margin-right: 5px;
}

.kanban-card .kanban-card-body .card-user-info {
  display: flex;
  align-items: center;
}

.kanban-card .kanban-card-body .card-user-info .avatar {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.kanban-card .kanban-card-body .card-user-info .user-name {
  color: #6F6F6F;
}

.kanban-card .kanban-card-footer {
  border-top: 1px solid #E8E8E8;
  margin-top: 15px;
  padding-top: 15px;
}

.kanban-card .kanban-card-footer ul {
  display: flex;
  align-items: center;
}

.kanban-card .kanban-card-footer ul li {
  margin-right: 5px;
}

.kanban-card .kanban-card-footer ul li a {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #262A2A;
  background: #F6F6F6;
}

.kanban-card .kanban-card-footer ul li a:hover {
  background: #FFEEEC;
  color: #E41F07;
}

.kanban-card .kanban-card-footer ul li:last-child {
  margin-right: 0;
}

.kanban-drag-wrap>li:last-child .kanban-card {
  margin-bottom: 0;
}

.leads-kanban-wrapper .kanban-card .kanban-card-head .kanban-card-title span {
  background: #E0E2E7;
}

.leads-kanban-wrapper .kanban-card .kanban-card-footer span {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

.pipeline-progress .progress {
  background: transparent;
  width: 70px;
  height: 5px;
}

.pipeline-progress .progress .progress-bar {
  width: 70px;
  height: 5px;
  border-radius: 5px;
}

.pipeline-progress .progress .progress-bar.progress-bar-success {
  background: #5CB85C;
}

.pipeline-progress .progress .progress-bar.progress-bar-violet {
  background: #4A00E5;
}

.pipeline-progress .progress .progress-bar.progress-bar-info {
  background: #1ECBE2;
}

.pipeline-progress .progress .progress-bar.progress-bar-warning {
  background: #FDA700;
}

.pipeline-progress .progress .progress-bar.progress-bar-green {
  background: #00918E;
}

.pipeline-progress .progress .progress-bar.progress-bar-danger {
  background: #FC0027;
}

.pipeline-progress span {
  margin-left: 10px;
  color: #262A2A;
  font-weight: 500;
}

.pipe-title .add-stage {
  color: #E41F07;
  font-weight: 500;
  margin-bottom: 15px;
  display: inline-block;
}

.pipe-title .add-stage i {
  margin-right: 8px;
}

.campaign-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin: 0 0 24px;
  padding: 24px;
  border-radius: 5px;
}

@media (max-width: 1399.98px) {
  .campaign-box {
    margin: 0 0 24px;
    padding: 15px;
  }
}

.campaign-box .campaign-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.campaign-box .campaign-img span {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  color: #FFF;
  margin-right: 10px;
}

.campaign-box .campaign-img p {
  color: #262A2A;
  font-weight: 500;
}

.campaign-box h2 {
  color: #6F6F6F;
}

@media (max-width: 1399.98px) {
  .campaign-box h2 {
    font-size: 24px;
  }
}

@media (max-width: 991.98px) {
  .campaign-box h2 {
    font-size: 20px;
  }
}

.campaign-box.bg-danger-light {
  background-color: #FFEEEC;
}

.campaign-box.bg-danger-light span {
  background-color: #FC0027;
}

.campaign-box.bg-warning-light {
  background-color: #FFF7D8;
}

.campaign-box.bg-warning-light span {
  background-color: #FFA201;
}

.campaign-box.bg-purple-light {
  background-color: #F3EDFF;
}

.campaign-box.bg-purple-light span {
  background-color: #3C2371;
}

.campaign-box.bg-success-light {
  background-color: #E0F5D7;
}

.campaign-box.bg-success-light span {
  background-color: #00918E;
}

.campaign-tab {
  margin: 0 0 20px;
  padding: 15px 15px 0;
  box-shadow: 0px 4px 74px 0px rgba(208, 208, 208, 0.2509803922);
  background-color: #FFF;
  border-radius: 5px;
}

.campaign-tab ul li {
  margin-right: 15px;
}

.campaign-tab ul li a {
  position: relative;
  font-weight: 500;
  border-bottom: 4px solid #FFF;
  padding: 10px 10px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.campaign-tab ul li a.active {
  border-color: #E41F07;
}

.campaign-tab ul li a span {
  width: 20px;
  height: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border: 1px solid #E8E8E8;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #6F6F6F;
}

.campaign-tab ul li:last-child {
  margin-right: 0;
}

ul.project-mem {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

ul.project-mem li {
  margin-left: -10px;
}

ul.project-mem li:first-child {
  margin-left: 0;
}

ul.project-mem li a img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #FFF;
}

ul.project-mem li.more-set a {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #E8E8E8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  font-size: 12px;
  font-weight: 600;
  color: #6F6F6F;
}

.list-progress li {
  display: inline-block;
  margin-right: 15px;
}

.list-progress li h6 {
  font-weight: 600;
}

.list-progress li p {
  font-size: 12px;
  font-weight: 600;
  color: #6F6F6F;
}

.list-progress li:last-child {
  margin-right: 0;
}

.membership-plans .plan-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.membership-plans .plan-selected h4 {
  color: #6F6F6F;
  font-size: 20px;
}

.membership-plans .plan-selected .status-toggle {
  margin: 0 24px;
}

.membership-plan-grid {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 24px;
  background: #FFF;
  margin-bottom: 24px;
}

.membership-plan-grid.active {
  background: #F9F9FC;
}

.membership-plan-grid .plan-price-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 15px;
  padding-bottom: 15PX;
}

.membership-plan-grid .plan-price-head .plan-type {
  color: #262A2A;
  font-weight: 500;
}

.membership-plan-grid .plan-price-head h4 {
  display: flex;
  align-items: end;
  font-size: 28px;
  margin-top: 5px;
}

.membership-plan-grid .plan-price-head h4 span {
  color: #262A2A;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}

.membership-plan-grid .plans-features ul li {
  font-weight: 500;
  color: #262A2A;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.membership-plan-grid .plans-features ul li span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: #5CB85C;
  color: #FFF;
  font-size: 12px;
  margin-right: 8px;
}

.membership-plan-grid .plans-features ul li+li {
  margin-top: 10px;
}

.membership-plan-grid .plan-btn {
  margin-top: 15px;
}

.form-wrap .checkmarks.mem-plane-check {
  top: 3px;
}

.table-imgname.flag-image img {
  width: 24px;
  height: 18px;
  border-radius: 5px;
}

.table-imgname.flag-image {
  display: flex;
  align-items: center;
}

.location-flag-img {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.table-imgname.flag-image span {
  font-weight: 500;
  color: #262A2A;
}

#delete_request td span.badge {
  cursor: pointer;
}

/******* Vendors ******/
.select2-container {
  min-width: 100% !important;
  z-index: 99;
}

.select2-container .select2-selection--single {
  height: 40px;
}

.select2-container--default .select2-selection--single {
  border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
  border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #6F6F6F;
  line-height: 38px;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  padding-right: 25px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__rendered span img {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 5px;
}

.select2-container--default .select2-selection--single .select {
  width: 219px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
  right: 6px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #637381;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #000;
  border-width: 2px 0 0 2px;
  margin-top: 3px;
  padding: 3px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #E41F07;
  color: #FFF;
}

.select2-container--focus .select2-selection--single {
  background: #FFF !important;
  border-color: #E41F07;
}

span.select2-container.select2-container--default.select2-container--open {
  z-index: 9999;
}

.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple,
.select2-container--classic .select2-selection--single .select2-selection__arrow,
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  border-color: #E8E8E8;
  color: #6F6F6F;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 4px 4px 0px rgba(219, 219, 219, 0.2509803922);
}

.select2-container--default .select2-selection--multiple {
  line-height: 27px;
  height: auto;
  min-height: 40px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding-left: 5px;
  padding-right: 20px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display span img {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  right: 0;
  left: auto;
}

.select2-results__option span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select2-results__option span img {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  color: #6F6F6F;
  border-radius: 5px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #E8E8E8;
}

table.dataTable {
  margin: 0 !important;
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:after {
  right: 0.5em !important;
  content: "\f175" !important;
  font-family: "Font Awesome 5 Free";
  bottom: 12px !important;
  color: #BABFC7 !important;
  font-size: 12px !important;
  opacity: 1 !important;
  position: absolute;
  font-weight: 600;
  line-height: 1px !important;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:before {
  right: 13px !important;
  content: "\f176" !important;
  font-family: "Font Awesome 5 Free";
  top: 50% !important;
  transform: translateY(-50%);
  color: #BABFC7 !important;
  font-size: 12px !important;
  opacity: 1 !important;
  position: absolute;
  font-weight: 600;
  line-height: 1px !important;
}

.dataTables_paginate .paging_numbers,
#dataTables_paginate .paging_numbers {
  float: right;
}

.dataTables_info {
  padding-right: 15px;
  font-size: 12px;
  color: #424848;
  font-weight: 600;
}

.custom-select {
  min-width: 80px;
}

.dataTables_paginate .pagination,
#dataTables_paginate .pagination {
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}

.dataTables_paginate .pagination li,
#dataTables_paginate .pagination li {
  margin: 0 2px;
}

.dataTables_paginate .pagination li a,
#dataTables_paginate .pagination li a {
  background: #FFF;
  border-color: transparent;
  border-radius: 5px !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6F6F6F;
}

.dataTables_paginate .pagination li a:hover,
#dataTables_paginate .pagination li a:hover {
  background: #00918E;
  color: #FFF;
}

.dataTables_paginate .pagination li.active a.page-link,
#dataTables_paginate .pagination li.active a.page-link {
  background: #00918E;
  border-color: #00918E;
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataTables_paginate .pagination li.previous.disabled,
.dataTables_paginate .pagination li.previous,
#dataTables_paginate .pagination li.previous.disabled,
#dataTables_paginate .pagination li.previous {
  width: auto;
  margin: 0 13px 0 0;
}

.dataTables_paginate .pagination li.previous.disabled a,
.dataTables_paginate .pagination li.previous a,
#dataTables_paginate .pagination li.previous.disabled a,
#dataTables_paginate .pagination li.previous a {
  background-color: transparent;
  border: 0;
  width: auto;
  padding: 0;
}

.dataTables_paginate .pagination li.previous.disabled a i,
.dataTables_paginate .pagination li.previous a i,
#dataTables_paginate .pagination li.previous.disabled a i,
#dataTables_paginate .pagination li.previous a i {
  margin-right: 8px;
}

.dataTables_paginate .pagination li.previous.disabled a:hover,
.dataTables_paginate .pagination li.previous a:hover,
#dataTables_paginate .pagination li.previous.disabled a:hover,
#dataTables_paginate .pagination li.previous a:hover {
  color: #00918E;
}

.dataTables_paginate .pagination li.next.disabled,
.dataTables_paginate .pagination li.next,
#dataTables_paginate .pagination li.next.disabled,
#dataTables_paginate .pagination li.next {
  width: auto;
  margin: 0 0 0 13px;
}

.dataTables_paginate .pagination li.next.disabled a,
.dataTables_paginate .pagination li.next a,
#dataTables_paginate .pagination li.next.disabled a,
#dataTables_paginate .pagination li.next a {
  background-color: transparent;
  border: 0;
  width: auto;
  padding: 0;
}

.dataTables_paginate .pagination li.next.disabled a i,
.dataTables_paginate .pagination li.next a i,
#dataTables_paginate .pagination li.next.disabled a i,
#dataTables_paginate .pagination li.next a i {
  margin-left: 8px;
}

.dataTables_paginate .pagination li.next.disabled a:hover,
.dataTables_paginate .pagination li.next a:hover,
#dataTables_paginate .pagination li.next.disabled a:hover,
#dataTables_paginate .pagination li.next a:hover {
  color: #00918E;
}

@media (max-width: 767.98px) {

  .dataTables_paginate .pagination,
  #dataTables_paginate .pagination {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }
}

.dataTables_length,
#dataTables_length .dataTables_paginate,
#dataTables_paginate {
  margin-top: 15px !important;
}

.dataTables_length label,
#dataTables_length label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #6F6F6F;
  font-size: 14px;
}

.dataTables_length label .form-select,
#dataTables_length label .form-select {
  width: 73px;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  color: #262A2A;
  margin: 0 8px;
}

@media (max-width: 767.98px) {

  .dataTables_length label,
  #dataTables_length label {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 767.98px) {
  .datatable-length {
    text-align: center;
  }
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 23px;
}

table.dataTable thead>tr>th.no-sort.sorting_asc:before {
  display: none;
}

table.dataTable thead>tr>th.no-sort.sorting_asc::after {
  display: none;
}

table.dataTable thead>tr>th.no-sort.sorting:before {
  display: none;
}

table.dataTable thead>tr>th.no-sort.sorting::after {
  display: none;
}

.custom-table .table-responsive {
  border: 1px solid #E8E8E8;
  border-radius: 6px;
}

table.table.dataTable>tbody>tr {
  border-color: #E8E8E8;
}

table.table.dataTable>tbody>tr td {
  color: #6F6F6F;
}

table.table.dataTable>thead>tr {
  border-color: #E8E8E8;
}

table.table.dataTable>thead>tr th {
  color: #262A2A;
  font-size: 14px;
  font-weight: 500;
}

.active>.page-link,
.page-link.active {
  z-index: unset;
}

/*# sourceMappingURL=style.css.map */


/* Custom CSS */
.reportview .red,
.reportview .green {
  font-weight: bold;
  color: #ffffff;
  padding: 3px;
}

.customType .form-check {
  position: static
}

.customType {
  position: relative
}

.customType .invalid-feedback {
  bottom: -15px;
  left: 0;
  position: absolute;
  text-align: right;
  width: 100%
}

.ant-table-wrapper {
  margin-top: 48px;
}

/* Custom CSS */


.menu_div p {
  font-size: 14px;
  font-weight: 500;
  color: #202C4B;
  float: left;
  margin-bottom: 10px;
  margin-top: 13px;
}


.menu_div {
  display: flex;
  flex-flow: column;
}



h1.labelText {
  font-size: 20px;
  font-weight: 600;
  color: #202C4B;
  float: left;
  margin-bottom: 0x;
  margin-top: 16px;
}


.menu_div .form-check-input {
  border: 1px solid #0f1012;
}

.accordion-body {
  background-color: white !important;
}


.accordion-body>.row {
  border-bottom: 1px solid #80808038;
  margin-bottom: 10px;
  padding-bottom: 10px;


}

.rounded-btn {
  border-radius: 17px !important;
  padding: 4px 15px !important;

}


.sidebar .sidebar-menu>ul>li.active-path>a span {
  color: #3D5EE1 !important;
}

.sidebar .sidebar-menu>ul>li.active-path a {
  background: #F2F5FF;
  border-radius: 5px;
  color: #FFF;
  width: 100%;
}



.form-control.is-valid,
.was-validated .form-control:valid~.addFormPasword {
  top: 60%;
  right: 20px;
}


.was-validated .form-control:invalid~.addFormPasword {
  top: 55%;
  right: 20px;
}

.form-control~.addFormPasword {
  top: 75%;
  right: 20px;
  z-index: 9;
}



.form-control.is-invalid~.addFormPasword {
  top: 60%;
  right: 20px;
}




:where(.css-dev-only-do-not-override-5wsri9).ant-modal-root .ant-modal-wrap {

  z-index: 9999 !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-modal-root .ant-modal-mask {

  z-index: 9999 !important;

}


.col.menu_pass .form-control.is-invalid~.addFormPasword {
  top: 35%;
  right: 20px;
}


.col.menu_pass .form-control~.addFormPasword {
  right: 20px;
  top: 55%;
  z-index: 9;
}


.is-invalid~.valid-feedback {
  display: none !important;
}

button.btn {
  text-transform: capitalize !important;
}

/* new css */
select#activity {
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  body.mini-sidebar .sidebar {
    margin-left: 0px;
  }



  .ant-col.ant-col-12.css-dev-only-do-not-override-5wsri9 {
    flex: 0 0 100%;
  }
}

@media (max-width: 1440px) {
  th.ant-table-cell {
    white-space: pre;
  }

  button.ant-btn.css-dev-only-do-not-override-5wsri9.ant-btn-default.ant-btn-color-default.ant-btn-variant-outlined.me-2 {
    margin-bottom: 10px;
  }


}


@media (max-width: 1024px) {

  .mb-2.mbil_css {
    width: 100%;
  }

  .school_fix {
    flex-flow: column;
  }

  body .nav-search-inputs span.fs-4 {
    font-size: 18px !important;
  }


}

input.ant-input.css-dev-only-do-not-override-5wsri9.ant-input-outlined {
  margin: 0 4px;
}

ul.ant-pagination.css-dev-only-do-not-override-5wsri9 {
  margin-top: 10px;
}


.react-datepicker__input-container input[type="text"] {
  border: 1px solid #E9EDF4;
  color: #202C4B;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 5px;
  padding: 0.5rem 0.625rem;
  height: 42px;
  transition: all 0.5s;
}

svg.password-eye-icon {
  /* left: 95%; */
  position: absolute;
  top: 77%;
  right: 6%;
  transform: translate(-50%, -50%);
}

.pass_div {
  position: relative;
}


ul.pagination {
  margin-top: 20px;
}


.row.space_tag {
  padding-bottom: 10px;
}




body.mini-sidebar .header .header-left .logo,
body.mini-sidebar .header .header-left .logo img {
  display: none !important
}

.accordion-button:after,
.accordion-button:not(.collapsed):after {
  display: block !important;
}

.number_note {
  font-size: 12px;
  font-weight: bolder;
  text-transform: capitalize;
  margin: 5px;
  color: black !important;
}


.stutdent-list .li.ant-pagination-options {
  display: none;
}





.bordered-table .ant-table {
  border: 1px solid #f0f0f0
    /* Adds a border to the main table structure */
}

.bordered-table .ant-table-tbody>tr>td,
.bordered-table .ant-table-thead>tr>th {
  border: 1px solid #f0f0f0
    /* Adds borders to each cell */
}

.card-certificate {
  height: auto !important;
}

.display-inline {
  display: inline !important;

}

.image-size {
  height: 190px
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /* Make sure it's on top if needed */
  background-color: rgba(0, 0, 0, 0.4);
  /* Optional: light overlay */
}

.Back_btn {
  /* float: right; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}



.slimscroll .menu-item {
  position: relative;
}

.slimscroll .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  /* font-size: 12px;
  padding: 0;
  margin-left: 8px;
  position: absolute;
  height: 100%;
  width: 100%; */
}

.slimscroll .dropdown-menu {
  position: absolute;
  left: 20px;
  top: 100%;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 8px 0;
  margin: 0;
}

.slimscroll .dropdown-item a {
  display: block;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
}

.slimscroll .dropdown-item a:hover {
  background-color: #e2e6ea;
}

body .slimscroll .dropdown-toggle:empty::after {
  margin-left: 70px !important;
}


.sidebar .sidebar-menu>ul li {
  position: relative;
}

li.menu-item.d-flex.has-dropdown.active-path li.active-path a {
  background: red;
  background: aliceblue;
  border-radius: 5px;
  color: #3D5EE1 !important;
}


li.menu-item.d-flex.has-dropdown.active-path li.active-path a span,
.dropdown-toggle::after,
li.menu-item.d-flex.has-dropdown.active-path ul.dropdown-menu li a {
  color: #3D5EE1 !important;
}


li.menu-item.d-flex.has-dropdown.active-path ul.dropdown-menu li {
  color: #3D5EE1 !important;
  background-color: #ffff;
}


.ant-pagination-options-size-changer::before,
.ant-pagination-options-size-changer::after {
  display: none;
}


body .ant-pagination-options {
  position: absolute;
  left: 0;
  top: -48px;
  margin-left: 0 !important;
}

body .css_div {
  display: flex;
  justify-content: end;
}

body .new_css {
  display: flex;
  padding-left: 77px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-pagination .ant-pagination-item {
  margin-inline-end: 0;
  margin: 0 2px;
}


.menu_div {
  width: 100%;
  max-width: 600px;
  gap: 18px;
  border: 1px solid #8080804a;
  border-radius: 9px;
  padding: 35px 28px;
  margin-top: 30px;
}

.header .header-left .logo img.school_logo {
  width: 31px;
  object-fit: contain !important;
}

.all_data .checkbox.checked {
  background: #1588ef;
  color: #fff;
}

.all_data .checkbox {
  background: #6A7287;
  width: 100%;
  max-width: 128px;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 700;
  justify-content: center;
}

.all_data .checkbox label {
  margin-bottom: 0;
}

.all_data {
  display: flex;
  flex-flow: wrap;
  gap: 16px;
}


button.passwordchaange {
  position: absolute;
  right: 3%;
  top: 74%;
  transform: translate(-50%, -50%);
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 1;
}

/* Media query for small screens */
@media screen and (max-width: 568px) {
  button.passwordchaange {
    right: 4%;
    /* Change right value for small screens */
  }
}


body .avatar img {
  width: auto;
}


span#demo1 {
  color: red;
}



.sp_div {
  position: relative;
}

.hide_sp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.date-range-picker .react-daterange-picker__wrapper {
  border-color: #E9EDF4;
  color: #202C4B;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 5px;
  padding: 0.5rem 0.625rem;
  height: 38px;
  transition: all 0.5s;
}

body .modal .modal-header {
  border-bottom: none;
  margin-bottom: 0;
}


body .modal .modal-footer {
  border-top: none;
}


.modal-header button.btn.btn-close {
  /* background: gray; */
  color: gray !important;
  border: 2px solid gray !important;
  --bs-btn-close-color: gray !important;
  width: 0px !important;
  height: 21px !important;
  filter: contrast(0.5);
}



body .time_piker {
  width: 100%;

}

body .time_piker .react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid red;
  border-color: #E9EDF4;
  color: #202C4B;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 5px;
  padding: 0.5rem 0.625rem;
  height: 38px;
  transition: all 0.5s;
}


.image-inner-container {
  border: 1px solid black;
  padding: 10px;
}

.school_admin {
  background-image: url("../../assets/images/schooladmin.jpg");
  background-size: cover;
  background-position: center;
}

.Div_admin {
  background-image: url("../../assets/images/adm.jpg");
  background-size: cover;
  background-position: center;
}


.school_bg {
  background-image: url("../../assets/images/school.jpg");
  background-size: cover;
  background-position: center;
}

h1.Admin_color {
  color: #ffff;
  font-weight: bold;
  font-size: 40px;
  text-decoration: underline;
}

.btn[class*="btn-outline-"] {
  padding: 4px 15px !important;
}

.btn {
  padding: 4px 15px !important;
}


.react-datepicker-popper {
  z-index: 2;
}


span.red_colour {
  color: red;
}



.cap_list td {
  text-transform: capitalize;
}

body ul.ul_btn li button {
  background: #F2F5FF;
  width: 100%;
  max-width: 370px;
  padding: 61px 0px !important;
  color: #3D5EE1;
  min-width: 370px;
  margin-bottom: 23px;
}


ul.ul_btn {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 50px;
}

body ul.ul_btn li button:hover {
  background: #3D5EE1;
  color: #F2F5FF;
}



.button_text {
  color: #3D5EE1 !important;
  font-weight: 500;
  padding: 8px 9px;
  border-radius: 4px;
  cursor: pointer;
}


th.ant-table-cell {
  white-space: nowrap;
}

.img_privew {
  position: relative;
}

.img_privew input#inputGroupFile02 {
  position: absolute;
  width: 131px;
  height: 100%;
  top: 10%;
  left: 0;
  min-height: 30px;
  opacity: 0;
}


img.img-width {
  width: 100%;
  height: 220px;
  object-fit: contain;
}

body input.date_input {
  width: 100%;

}

.margin-auto {
  margin: auto !important;
}

.text-center {
  text-align: center !important;
}



.student-img-singleViewN {
  width: 50px;
  height: 50px;


  border-radius: 50%;
}

.action-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.button_text {
  padding: 8px 16px;

  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button_text:hover {
  background-color: #007bff;
  color: white;
}

@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .button_text {
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
  }
}

body span.Totalcount {
  position: relative;
}

body span.Totalcount span {
  align-items: center;
  background: red;
  border-radius: 20px;
  color: #fff !important;
  display: flex;
  font-size: 10px !important;
  height: 22px !important;
  justify-content: center;
  padding: 10px;
  position: absolute;
  right: -23px;
  text-align: center;
  top: -12px;
  width: 22px !important;
}

body ul li.menu-item.d-flex span.Totalcount span {
  color: #fff !important;
  font-weight: 800;
}


/* 
.new {
  display: flex;
  align-items: baseline;
} */

.seleted_year .form-select {
  padding: 0 10px;
}

.seleted_year {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-right: 18px;
}
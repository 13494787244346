.account-page {
  background: $white;
}
.login-wrapper {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  &.basic-type {
    overflow: auto;
    height: 100%;
    justify-content: center;
    &::before {
      display: none;
    }
    .login-content {
      overflow: unset;
      height: 100%;
    }
  }
  &.account-bg {
    @include respond-below(lg) {
      background-image: none;
    }
    .login-content {
      background: #fffffff2;
      backdrop-filter: blur(10px);
      margin-left: auto;
    }
  }
  .btn-outline-light {
    &:hover {
      background-color: $white !important;
    }
  }
}
.login-background {
  position: relative;
  &::before {
    content: "";
    background: #4b6fff99;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(6px);
    @include respond-below(lg) {
      display: none;
    }
  }
}
.hover-a {
  position: relative;
  color: $primary;
  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background: $primary;
    @include transform(scale(0));
    @include transition(all 0.3s ease);
    position: absolute;
    bottom: -3px;
    left: 0;
  }
  &:hover:after {
    @include transform(scale(1));
  }
  &:hover {
    color: $primary;
  }
}
.login-wrapper.login-new {
  overflow: auto;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  position: relative;
  .login-shapes {
    position: relative;
    @include respond-below(lg) {
      display: none;
    }
    .login-right-shape {
      position: fixed;
      top: 0;
      left: 0;
    }
    .login-left-shape {
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
  .login-content.user-login {
    position: relative;
    .login-userset {
      form .otp-box {
        margin-bottom: 34px;
        input {
          width: 74px;
          height: 74px;
          background: $white;
          border: 1px solid $light-900;
          border-radius: 8px;
          text-align: center;
          margin-right: 15px;
          font-weight: $font-weight-bold;
          font-size: $font-size-26;
          color: $black;
          outline: none;
          @include respond-below(sm) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .copyright-text {
    margin: 40px 0;
    text-align: center;
    @include respond-below(md) {
      margin: 0 0 30px;
    }
    p {
      font-weight: $font-weight-medium;
      color: $gray-900;
      margin-bottom: 0;
    }
  }
  .lock-screen-list {
    text-align: center;
    .nav {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      li {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        a {
          font-weight: $font-weight-medium;
        }
      }
    }
    .copyright-text {
      margin: 20px 0 40px;
      @include respond-below(md) {
        margin: 20px 0 30px;
      }
    }
    .language-dropdown {
      .dropdown-toggle {
        border: 0;
        padding: 0;
        border-radius: $border-radius-lg;
        &:after {
          border-top: 0;
          border-left: 0;
          border-bottom: 2px solid $gray-400;
          border-right: 2px solid $gray-400;
          content: "";
          display: inline-block;
          pointer-events: none;
          width: 8px;
          height: 8px;
          vertical-align: 2px;
          @include transform-origin(66% 66%);
          @include transition(all 0.2s ease);
          @include transform(rotate(45deg));
        }
        &[aria-expanded="true"]:after {
          @include transform(rotate(-135deg));
        }
      }
      .dropdown-menu {
        padding: 15px;
        background: $white;
        box-shadow: $box-shadow-lg;
        border: 1px solid $secondary-100;
        .dropdown-item {
          padding: 5px 10px;
          background: $white;
          border-radius: $border-radius-lg;
          display: block;
          &:hover {
            background: $light-300;
          }
        }
      }
    }
  }
}
.login-or {
  text-align: center;
  font-size: 15px;
  color: $gray-700;
  font-weight: $font-weight-medium;
  margin: 5px 0;
  padding: 10px 0;
  position: relative;
  &::after {
    width: 100%;
    content: "";
    border-top: 1px solid $gray-200;
    position: absolute;
    top: 50%;
    right: 0;
  }
  &::before {
    width: 100%;
    content: "";
    border-top: 1px solid $gray-200;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .span-or {
    background-color: $white;
    padding: 0 10px;
    z-index: 9;
    position: relative;
  }
}
.authen-overlay-item {
  background: rgb(10 9 9 / 53%);
  border-radius: 25px;
  padding: 24px;
  position: relative;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 570px;
  text-align: center;
}
